import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
// import * as R from 'ramda';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import PopupContainer from 'components/PopupContainer';
import MUITable from 'components/MUITable';
import PropTypes from 'prop-types';
import './TotalDebt.css';
import { selectors as evalSelector } from 'ducks/eval';
import { NON_CURRENCY_ONLY_REGEX, PAST_DUE_PAYMENTS_TABLE_COLUMNS } from 'constants/eval';
import { convertToUSCurrency, FORMAT } from 'lib/Formatters';

const TotalDebt = ({
  handleCalcButton, pastDuePayments: paymentData,
  updateMonthlyPaymentDetails, isEditable, caseHeaderInfo, isCaseRejected,
  caseSpecificFieldValues,
}) => {
  const [disabledStates, toggleDisabledStates] = useState({
    disableFees: true,
    disableAdvances: true,
  });
  const [isValueUpdated, setIsValueUpdated] = useState(false);
  const [fees, setFees] = useState(0);
  const [advances, setAdvances] = useState(0);
  const [pastDuePayment, setpastDuePayment] = useState(0);

  useEffect(() => {
    if (isValueUpdated) {
      handleCalcButton();
    }
  }, [isValueUpdated]);

  // const {
  //   pastDuePayments,
  // } = monthlyPaymentDetails;

  const {
    lockFlag,
  } = caseHeaderInfo;

  useEffect(() => {
    if (caseSpecificFieldValues.length > 0) {
      const feesValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalFees');
      const advanceValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalAdvance');
      const pastDuePaymentvalue = caseSpecificFieldValues.filter(e => e.columnName === 'totalContractual');
      setFees(feesValue && feesValue[0]
        && feesValue[0].columnVal ? feesValue[0].columnVal : 0);
      setAdvances(advanceValue && advanceValue[0]
        && advanceValue[0].columnVal ? advanceValue[0].columnVal : 0);
      setpastDuePayment(pastDuePaymentvalue && pastDuePaymentvalue[0]
        && pastDuePaymentvalue[0].columnVal ? pastDuePaymentvalue[0].columnVal : 0);
    }
  }, [caseSpecificFieldValues]);

  const [isPastDuePaymentDialogOpen, togglePastDuePaymentDialog] = React.useState(false);

  const renderPastDuePaymentsPopOver = () => (isPastDuePaymentDialogOpen ? (
    <PopupContainer
      handleClose={value => togglePastDuePaymentDialog(value)}
      show={isPastDuePaymentDialogOpen}
      title="Past Due Payments"
    >
      <div styleName="table-container">
        <MUITable
          columns={PAST_DUE_PAYMENTS_TABLE_COLUMNS}
          data={paymentData || []}
        />
      </div>
    </PopupContainer>
  ) : null);

  return (
    <Grid id="totalDebt" item xs={7}>
      <Paper
        elevation={2}
        square
        styleName="totalDebtPaper"
      >
        <Grid
          container
          direction="column"
          id="totalDebtPaper"
          item
          justifyContent="space-around"
        >
          <Grid
            container
            direction="row"
            id="totalDebtValue"
            item
            styleName="totalDebtValueGrid"
          >
            <Grid item xs={3}>
              <div styleName="card-header">
                Total Debt
              </div>
            </Grid>
            <Grid item xs={9}>
              <div styleName="card-header">
                {
                  convertToUSCurrency
                    .format(parseFloat(fees || 0)
                    + parseFloat(advances || 0) + parseFloat(pastDuePayment || 0) || 0)
                }
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            id="paymentDetails"
            item
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="textColor">Fees</div>
                <div>
                  <OutlinedInput
                    disabled={disabledStates.disableFees}
                    endAdornment={(
                      <InputAdornment position="end">
                        { isEditable && (
                        <IconButton
                          disabled={lockFlag || isCaseRejected}
                          edge="end"
                          style={{ padding: '0' }}
                        >
                          <EditIcon
                            onClick={() => {
                              toggleDisabledStates({
                                ...disabledStates,
                                disableFees: false,
                              });
                            }}
                            styleName="editIcon"
                          />
                        </IconButton>
                        )
                    }
                      </InputAdornment>
)}
                    id="outlined-fees-textBox"
                    onBlur={() => {
                      if (!FORMAT.currencyFloat(fees)) {
                        setFees(0);
                      }
                      toggleDisabledStates({
                        ...disabledStates,
                        disableFees: true,
                      });
                      setIsValueUpdated(true);
                    }}
                    onChange={(e) => {
                      if (
                        !NON_CURRENCY_ONLY_REGEX.test(
                          e.target.value,
                        )
                      ) {
                        const amt = FORMAT.currencyFloat(e.target.value);
                        if (amt) {
                          updateMonthlyPaymentDetails('originalFees', amt);
                          setFees(amt);
                        } else {
                          updateMonthlyPaymentDetails('originalFees', 0);
                          setFees(e.target.value);
                        }
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    styleName={disabledStates.disableFees
                      ? 'disabled-background'
                      : 'enabled-background'}
                    type="text"
                    value={FORMAT.currency(fees)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="textColor">Advances</div>
                <div>
                  <OutlinedInput
                    disabled={disabledStates.disableAdvances}
                    endAdornment={(
                      <InputAdornment position="end">
                        { isEditable && (
                        <IconButton
                          disabled={lockFlag || isCaseRejected}
                          edge="end"
                          style={{ padding: '0' }}
                        >
                          <EditIcon
                            onClick={() => {
                              toggleDisabledStates({
                                ...disabledStates,
                                disableAdvances: false,
                              });
                            }}
                            styleName="editIcon"
                          />
                        </IconButton>
                        )
                    }
                      </InputAdornment>
)}
                    id="outlined-advances-textBox"
                    onBlur={() => {
                      if (!FORMAT.currencyFloat(advances)) {
                        setAdvances(0);
                      }
                      toggleDisabledStates({
                        ...disabledStates,
                        disableAdvances: true,
                      });
                      setIsValueUpdated(true);
                    }}
                    onChange={(e) => {
                      if (
                        !NON_CURRENCY_ONLY_REGEX.test(
                          e.target.value,
                        )
                      ) {
                        const amt = FORMAT.currencyFloat(e.target.value);
                        if (amt) {
                          updateMonthlyPaymentDetails('originalAdvance', amt);
                          setAdvances(amt);
                        } else {
                          updateMonthlyPaymentDetails('originalAdvance', 0);
                          setAdvances(e.target.value);
                        }
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    styleName={disabledStates.disableAdvances
                      ? 'disabled-background'
                      : 'enabled-background'}
                    type="text"
                    value={FORMAT.currency(advances)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item styleName="pastDuePaymentGrid">
              <div styleName="displayInColumn">
                <div style={{ alignItems: 'center' }} styleName="displayInRow">
                  <div styleName="textColor">Past Due Payments</div>
                  <MenuIcon
                    onClick={() => {
                      togglePastDuePaymentDialog(true);
                    }}
                    styleName="pastDuePayment"
                  />
                </div>
                <div styleName="pastDuePaymentValue">{convertToUSCurrency.format(pastDuePayment)}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {renderPastDuePaymentsPopOver()}
    </Grid>
  );
};

TotalDebt.defaultProps = {
  caseSpecificFieldValues: [],
};

TotalDebt.propTypes = {
  // advances: PropTypes.number.isRequired,
  caseHeaderInfo: PropTypes.shape().isRequired,
  caseSpecificFieldValues: PropTypes.arrayOf(PropTypes.shape()),
  // fees: PropTypes.number.isRequired,
  handleCalcButton: PropTypes.func.isRequired,
  isCaseRejected: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  // monthlyPaymentDetails: PropTypes.shape().isRequired,
  pastDuePayments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // setAdvances: PropTypes.func.isRequired,
  // setFees: PropTypes.func.isRequired,
  updateMonthlyPaymentDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  monthlyPaymentDetails: repaymentEvalSelectors.getMonthlyPaymentDetails(state),
  caseHeaderInfo: evalSelector.caseHeaderInfoData(state),
  isCaseRejected: repaymentEvalSelectors.isCaseRejected(state),
  caseSpecificFieldValues: repaymentEvalSelectors.caseSpecificFieldValues(state),
});

export default connect(mapStateToProps, null)(TotalDebt);
