import * as R from 'ramda';

const getNPVHistory = state => R.pathOr([], ['netpresentvalue', 'npvHistory'], state);
const getProcesseFiledData = state => R.pathOr(null, ['netpresentvalue', 'excelParsedData'], state);
const getUploadStatus = state => R.pathOr(null, ['netpresentvalue', 'uploadStatus'], state);
const getNPVRequestId = state => R.pathOr(null, ['netpresentvalue', 'npvRequestId'], state);
const getNPVResult = state => R.pathOr({}, ['netpresentvalue', 'data'], state);
const getNPVRqstStatus = state => R.pathOr(null, ['netpresentvalue', 'npvRequestStatus'], state);
const getManualFlag = state => R.pathOr(null, ['netpresentvalue', 'manualFlag'], state);
const getNPVRequestFileData = state => R.pathOr({}, ['netpresentvalue', 'requestFileData'], state);
const getNPVInvestorReportData = state => R.pathOr([], ['netpresentvalue', 'npvInvestorReportData'], state);
const getNPVCaseId = state => R.pathOr(null, ['netpresentvalue', 'npvCaseId'], state);

const selectors = {
  getProcesseFiledData,
  getUploadStatus,
  getNPVHistory,
  getNPVRequestId,
  getNPVResult,
  getNPVRqstStatus,
  getNPVRequestFileData,
  getNPVInvestorReportData,
  getNPVCaseId,
  getManualFlag,
};

export default selectors;
