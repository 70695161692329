import {
  GATHER_ESCROW_DATA,
  SET_LOAN_EVAL,
  ON_VALUE_CHANGE,
  HANDLE_CONFIRM_DIALOG_ACTIONS,
  STORE_ACCOUNT_SERVICE_HISTORY,
  STORE_CAPMOD_HISTORY,
  ACCOUNT_SERVICE_HISTORY_VIEW,
  TOGGLE_ANALYSIS_BREAKDOWN,
  CLOSE_DIALOG,
  COMPLETE_ESCROW,
  SET_EVENT_TYPE,
  DISPLAY_ACCOUNT_SERVICE_SCREEN,
  FETCH_TOMBSTONE_DATA,
  STORE_CAPMOD_ID,
  UPDATE_NET_DISBURSEMENT,
  RESET_VALUE_CHANGED,
  IS_REJECT_BTN_CLICKED,
  CLEAR_INFO,
  CLEAR_HISTORY_INFO,
  STORE_LOAN_NUMBER,
  SHOW_LOADER,
  SET_PROMPT_ERROR,
  SET_REQUEST_SUBMITTED,
  STORE_ANALYSIS_BREAKDOWN_DATA,
  SHOW_LOADER_IN_POPUP,
  REFRESH_DATE,
  TO_CREATE_NEW_RQST,
} from './types';

const gatherEscrowData = eventType => ({
  type: GATHER_ESCROW_DATA,
  payload: {
    eventType,
  },
});

const setLoanEval = (loanId, evalId, taskId) => ({
  type: SET_LOAN_EVAL,
  payload: {
    loanId,
    evalId,
    taskId,
  },
});

const onChange = (id, value) => ({
  type: ON_VALUE_CHANGE,
  payload: {
    id, value,
  },
});

const handleConfirmDialogBoxActions = value => ({
  type: HANDLE_CONFIRM_DIALOG_ACTIONS,
  payload: value,
});

const resetValueChangeAction = () => ({
  type: RESET_VALUE_CHANGED,
});

const storeAccountServicesHistory = () => ({
  type: STORE_ACCOUNT_SERVICE_HISTORY,
});

const getCapModHistory = value => ({
  type: STORE_CAPMOD_HISTORY,
  payload: value,
});

const toggleHistoryViewAction = value => ({
  type: ACCOUNT_SERVICE_HISTORY_VIEW,
  payload: value,
});

const toggleAnalysisScreenAction = () => ({
  type: TOGGLE_ANALYSIS_BREAKDOWN,
});

const closeDialogAction = () => ({
  type: CLOSE_DIALOG,
});

const completeEscrowAction = () => ({
  type: COMPLETE_ESCROW,
});

const setEventTypeAction = value => ({
  type: SET_EVENT_TYPE,
  payload: value,
});

const toggleAccountServiceAction = value => ({
  type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
  payload: value,
});

const fetchTombstoneDataAction = payload => ({
  type: FETCH_TOMBSTONE_DATA,
  payload,
});

const storeCapModIdAction = value => ({
  type: STORE_CAPMOD_ID,
  payload: value,
});

const storeLoanNumberAction = value => ({
  type: STORE_LOAN_NUMBER,
  payload: value,
});

const updateNetDisbursement = () => ({
  type: UPDATE_NET_DISBURSEMENT,
});

const isRejectBtnClickedAction = value => ({
  type: IS_REJECT_BTN_CLICKED,
  payload: value,
});

const clearInfo = () => ({
  type: CLEAR_INFO,
});

const clearHistoryInfo = () => ({
  type: CLEAR_HISTORY_INFO,
});

const showLoaderAction = value => ({
  type: SHOW_LOADER,
  payload: value,
});

const showLoaderInPopupAction = value => ({
  type: SHOW_LOADER_IN_POPUP,
  payload: value,
});

const clearPromptErrorAction = () => ({
  type: SET_PROMPT_ERROR,
  payload: { message: '', type: '' },
});

const setRequestSubmittedAction = value => ({
  type: SET_REQUEST_SUBMITTED,
  payload: value,
});

const storeAnalysisBreakdownDataAction = value => ({
  type: STORE_ANALYSIS_BREAKDOWN_DATA,
  payload: value,
});

const refreshDateAction = () => ({
  type: REFRESH_DATE,
});

const setToCreateNewRqstAction = value => ({
  type: TO_CREATE_NEW_RQST,
  payload: value,
});

export {
  setRequestSubmittedAction,
  clearPromptErrorAction,
  updateNetDisbursement,
  storeCapModIdAction,
  storeLoanNumberAction,
  toggleAccountServiceAction,
  completeEscrowAction,
  closeDialogAction,
  toggleHistoryViewAction,
  getCapModHistory,
  storeAccountServicesHistory,
  setLoanEval,
  gatherEscrowData,
  onChange,
  handleConfirmDialogBoxActions,
  toggleAnalysisScreenAction,
  setEventTypeAction,
  fetchTombstoneDataAction,
  resetValueChangeAction,
  isRejectBtnClickedAction,
  clearInfo,
  clearHistoryInfo,
  showLoaderAction,
  showLoaderInPopupAction,
  storeAnalysisBreakdownDataAction,
  refreshDateAction,
  setToCreateNewRqstAction,
};
