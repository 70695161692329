import { makeStyles } from '@material-ui/core/styles';

const BookingStyles = makeStyles(() => ({
  statusBox: {
    height: '45px',
    width: '98%',
    backgroundColor: '#F3F5F9',
    border: '1px solid #F3F5F9',
    borderRadius: '10px',
    marginLeft: '15px !important',
    marginRight: '13px !important',
    marginTop: '25px !important',
  },

  bookingChip: {
    backgroundColor: '#FFFFFF',
    border: hookdata => (hookdata.bookingData.bookingAutomatedTag === 'Yes' ? '1px solid #00AB84' : '1px solid #E4002B'),
    borderRadius: '2px',
    color: hookdata => (hookdata.bookingData.bookingAutomatedTag === 'Yes' ? '#00AB84' : '#E4002B'),
    flexDirection: 'row-reverse',
    width: '142px',
    height: '22px',
    margin: '12px 0px 0px 24px',

    '& .MuiChip-label': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '10px',
      lineHeight: '12px',
      padding: '0px 0px 0px 8px',
    },
  },

  avatar: {
    width: '10px !important',
    height: '12px !important',
    fontFamily: 'Font Awesome',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '10px !important',
    lineHeight: '12px',
    margin: '5px !important',
  },

  nonBookingChip: {
    backgroundColor: '#4E586E',
    border: '1px dashed #4E586E',
    borderRadius: '2px',
    color: '#FFFFFF',
    height: '22px',
    margin: '12px 0px 0px 16px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '10px',
    lineHeight: '12px',
    padding: '0px',
  },

  mainBox: {
    height: '809px',
    width: '1506px',
    marginLeft: '15px !important',
    marginRight: '13px !important',
    marginTop: '16px !important',
  },

  mainGrid: {
    height: '748px',
    width: '1506px',
    '& .MuiGrid-grid-lg-4': {
      marginRight: '12px',
      maxWidth: '452px',
    },
    overflow: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '10px',
      backgroundColor: '#D9D9D9',
    },

  },

  preModGrid: {
    width: '452px',
    padding: '0px !important',
    marginRight: '12px',
    backgroundColor: '#F3F5F9',
    border: '1px solid #F3F5F9',
    borderRadius: '10px',
  },

  header: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4e586e',
    marginBottom: '12px',
    width: '561px',
  },

  postModGrid: {
    width: '561px',
    minHeight: '429px',
    maxHeight: '511px',
    padding: '0px !important',
    marginRight: '12px',
    backgroundColor: '#F3F5F9',
    border: '1px solid #F3F5F9',
    borderRadius: '10px',
  },

  stepRatesGrid: {
    width: '561px',
    minHeight: '122px',
    backgroundColor: '#F3F5F9',
    border: '1px solid #F3F5F9',
    borderRadius: '10px',
    marginTop: '9px !important',
  },

  cardHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4E586E',
    marginTop: '19px',
    marginLeft: '16px',
  },

  bookingLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20%',
  },

}));

export default BookingStyles;
