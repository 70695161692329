import {
  FETCH_PAYMENT_SUPPLEMENT_DATA,
  CLEAR_PAYMENT_SUPPLEMENT_DATA,
} from './types';

const fetchPaymentSupplementData = payload => ({
  type: FETCH_PAYMENT_SUPPLEMENT_DATA,
  payload,
});

const clearPaymentSupplementData = payload => ({
  type: CLEAR_PAYMENT_SUPPLEMENT_DATA,
  payload,
});

export {
  fetchPaymentSupplementData,
  clearPaymentSupplementData,
};
