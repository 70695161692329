import React, { useState, useEffect } from 'react';
// import * as R from 'ramda';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import './RepaymentSummary.css';
import { convertToUSCurrency } from 'lib/Formatters';
import { connect } from 'react-redux';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { DateFormatter } from '../../../lib/DateUtils';

const RepaymentSummary = ({ caseSpecificFieldValues, universalFieldValues }) => {
  const [planStart, setPlanStart] = useState('');
  const [planEnd, setPlanEnd] = useState('');
  const [duration, setDuration] = useState(0);
  const [monthlyRePayment, setMonthlyRePayment] = useState(0);

  useEffect(() => {
    if (caseSpecificFieldValues.length > 0) {
      const planEndValue = caseSpecificFieldValues.filter(e => e.columnName === 'lastPlanDueDate');
      setPlanEnd(planEndValue && planEndValue[0]
        && planEndValue[0].columnVal ? DateFormatter(planEndValue[0].columnVal, 'DD MMM YYYY') : '');
    }
    if (universalFieldValues.length > 0) {
      const planStartValue = universalFieldValues.filter(e => e.columnName === 'caseStartDate');
      const durationValue = universalFieldValues.filter(e => e.columnName === 'planDuration');
      const monthlyRePaymentValue = universalFieldValues.filter(e => e.columnName === 'monthlyRepaymentAmount');
      setPlanStart(planStartValue && planStartValue[0]
        && planStartValue[0].columnVal ? DateFormatter(planStartValue[0].columnVal, 'DD MMM YYYY') : '');
      setDuration(durationValue && durationValue[0]
          && durationValue[0].columnVal ? durationValue[0].columnVal : 0);
      setMonthlyRePayment(monthlyRePaymentValue && monthlyRePaymentValue[0]
            && monthlyRePaymentValue[0].columnVal ? monthlyRePaymentValue[0].columnVal : 0);
    }
  }, [universalFieldValues, caseSpecificFieldValues]);

  return (
    <Grid id="repaymentSummary" item xs={8}>
      <Paper
        elevation={2}
        square
        styleName="repaymentSummaryPaper"
      >
        <Grid container direction="column" justifyContent="space-between">
          <Grid id="repaymentHeader" item>
            <div styleName="card-header">
            Repayment Summary
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            id="repaymentPlan"
            item
            justifyContent="space-between"
            spacing={3}
            styleName="repaymentPlan"
          >
            <Grid id="planStart" item>
              <div styleName="displayInColumn">
                <div styleName="repaymentPlanHeader">
                  Plan Start
                </div>
                <div>{planStart}</div>
              </div>
            </Grid>
            <Grid id="planEnd" item>
              <div styleName="displayInColumn">
                <div styleName="repaymentPlanHeader">
                  Plan End
                </div>
                <div>{planEnd}</div>
              </div>
            </Grid>
            <Grid id="duration" item>
              <div styleName="displayInColumn">
                <div styleName="repaymentPlanHeader">
                  Duration
                </div>
                <div>{duration}</div>
              </div>
            </Grid>
            <Grid id="repaymentMonthlyAmount" item>
              <div styleName="displayInColumn">
                <div styleName="repaymentPlanHeader">
                Monthly Repayment Amount
                </div>
                <div>
                  {convertToUSCurrency.format(monthlyRePayment)}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

RepaymentSummary.defaultProps = {
  caseSpecificFieldValues: [],
  universalFieldValues: [],
};

RepaymentSummary.propTypes = {
  caseSpecificFieldValues: PropTypes.arrayOf(PropTypes.shape()),
  universalFieldValues: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = state => ({
  caseSpecificFieldValues: repaymentEvalSelectors.caseSpecificFieldValues(state),
  universalFieldValues: repaymentEvalSelectors.universalFieldValues(state),
});

export default connect(mapStateToProps, null)(RepaymentSummary);
