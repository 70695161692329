import React from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableFooter, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './EvalTable.css';


function EvalTable(props) {
  const {
    columns, data, footerData, height, selectRowCheckbox, handleSelectedRows,
  } = props;


  const [selected, setSelected] = React.useState([]);

  const processData = () => data && data.map((item) => {
    let newObject = {};
    columns.forEach(
      (obj) => {
        let cell;
        if (obj.cellFormat && obj.iconCheck) {
          cell = obj.cellFormat(item);
        } else if (obj.cellFormat && obj.editable && obj.dateField) {
          cell = obj.cellFormat(item);
        } else if (obj.cellFormat && obj.deleteIcon) {
          cell = obj.cellFormat(item[obj.name1]);
        } else if (obj.cellFormat && obj.sort) {
          cell = obj.cellFormat(item.seq);
        } else if (obj.cellFormat && obj.dateFormat) {
          cell = obj.cellFormat(item[obj.name]);
        } else if (obj.cellFormat) {
          cell = obj.cellFormat(item[obj.name], item, item[obj.name2]);
        } else {
          cell = item[obj.name];
        }
        newObject = R.assoc(
          obj.name,
          cell,
          newObject,
        );
        return newObject;
      },
    );
    return newObject;
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = processData().map(n => n.caseId);
      setSelected(newSelecteds);
      handleSelectedRows(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    handleSelectedRows(newSelected);
  };
  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <>
      <TableContainer style={{ height }}>
        <Table aria-label="simple table" stickyHeader styleName="table-container">
          <TableHead>
            <TableRow>
              {selectRowCheckbox && (
                <TableCell>
                  <Checkbox
                    checked={processData().length > 0 && selected.length === processData().length}
                    onChange={handleSelectAllClick}
                    styleName="checkbox"
                  />
                </TableCell>
              )}
              {columns.map(
                (item, i) => item && (
                  <TableCell
                    key={item.name ? item.name : i}
                    align={item.align}
                    id={item.name}
                    style={{
                      minWidth: item.minWidthHead ? item.minWidthHead : 100,
                    }}
                  >
                    {item.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {processData().map((row) => {
              const isItemSelected = isSelected(row.caseId);
              return (
                <TableRow>
                  {selectRowCheckbox && (
                    <TableCell>
                      <Checkbox
                        checked={isItemSelected}
                        onClick={event => handleClick(event, row.caseId)}
                        styleName="checkbox"
                      />
                    </TableCell>
                  )}
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell
                      align="left"
                      id={`${key}_${value}`}
                    >
                      {value ?? ''}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          {Object.keys(footerData).length > 0 && (
            <TableFooter>
              <TableRow>
                {Object.entries(footerData).map(([key, value]) => (
                  <TableCell key={`${key}_${value}`} align="left">
                    {value || ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

EvalTable.defaultProps = {
  footerData: {},
  height: '',
  selectRowCheckbox: false,
  handleSelectedRows: () => {},
};

EvalTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    minWidthHead: PropTypes.number,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  footerData: PropTypes.shape({}),
  handleSelectedRows: PropTypes.func,
  height: PropTypes.string,
  selectRowCheckbox: PropTypes.bool,
};

export default EvalTable;
