import React from 'react';
import PropTypes from 'prop-types';
import ButtonTabs from './ButtonTabs';

const MapCustomTabs = (props) => {
  const {
    tabsArray,
    currTab,
    setCurrTab,
    navigateTab,
    className,
    position,
    disabled,
  } = props;
  return (
    <div className="pad-cont-10">
      <ButtonTabs
        className={className}
        currTab={currTab}
        disabled={disabled}
        position={position}
        setCurrTab={setCurrTab}
        tabsArray={tabsArray}
      />
      {navigateTab()}
    </div>
  );
};

MapCustomTabs.defaultProps = {
  className: '',
  position: '',
  disabled: false,
};

MapCustomTabs.propTypes = {
  className: PropTypes.string,
  currTab: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  navigateTab: PropTypes.func.isRequired,
  position: PropTypes.string,
  setCurrTab: PropTypes.func.isRequired,
  tabsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MapCustomTabs;
