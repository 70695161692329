/* eslint-disable import/prefer-default-export */
import {
  select,
  takeEvery,
  all,
  put,
  call,
} from 'redux-saga/effects';
import * as Api from 'lib/Api';
import * as R from 'ramda';
import { selectors as smduSelectors } from 'ducks/smdu/index';
import { selectors as dashboardSelectors } from 'ducks/dashboard/index';
import { selectors as loginSelectors } from 'ducks/login/index';
import { selectors as widgetSelectors, actions as widgetActions } from 'ducks/widgets/index';
import { closeWidgets } from 'components/Widgets/WidgetSelects';
import {
  SMDU_OPERATION_TYPE, REQUEST_TYPE_MAP, FAILED_STATUS_CODES,
  PRIOR_WORKOUT_SUCCESS, SUBMIT_FNMA_SUCCESS,
} from 'constants/widgets';
import {
  SUBMIT_TO_FNMA, FETCH_SMDU_HISTORY, SET_SMDU_RESPONSE_DATA,
  SET_SMDU_HISTORY_DATA, SET_SMDU_HISTORY_DARTS_DATA,
  FETCH_TRANSACTION_DATA, SET_TRANSACTION_DATA,
  LOADING_SMDU_WIDGET, HANDLE_SMDU_FAILURE, SET_SMDU_CASE_MANAGEMENT,
  SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN,
  SUBMIT_TO_REPORT_INVESTOR,
  SET_TO_REPORT_INVESTOR, SAVE_PRIOR_WORKOUT, STOP_LOADING_SMDU_WIDGET,
  HANDLE_SMDU_SUCCESS, SET_PRIOR_WORKOUT_HISTORY, HANDLE_ADD_PRIOR_WORKOUT_SUCCESS,
  FETCH_REPORTING_DATA, SET_SMDU_REPORTING_UPDATES,
} from './types';

const fetchFNMARequest = function* fetchFNMARequest() {
  try {
    const operationType = SMDU_OPERATION_TYPE[yield select(smduSelectors.getRequestType)];
    const loanId = yield select(dashboardSelectors.loanNumber);
    const resolutionId = yield select(dashboardSelectors.resolutionId);
    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = 'NSM';
    const application = 'CMOD';
    const useMockData = 'false';


    const requestPayload = {
      loanId,
      resolutionId,
      operationType,
      userEmail: emailId,
    };

    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
      useMockData,
    };
    yield put({ type: LOADING_SMDU_WIDGET });
    const response = yield call(Api.callPost, '/api/cmodsmdu/SmduRequest/LossMitigationWorkoutRequest', requestPayload, headers);
    const hasStatusCode = Object.prototype.hasOwnProperty.call(response, 'statusCode');
    if (hasStatusCode) {
      const statusCode = Number(response.statusCode);
      if (FAILED_STATUS_CODES.includes(statusCode)) {
        yield put({
          type: HANDLE_SMDU_FAILURE,
          payload: response.message,
        });
        yield put({ type: STOP_LOADING_SMDU_WIDGET });
      }
    } else {
      yield put({
        type: HANDLE_SMDU_SUCCESS,
        payload: SUBMIT_FNMA_SUCCESS,
      });
    }
  } catch (e) {
    yield put({
      type: HANDLE_SMDU_FAILURE,
    });
  }
};

const fetchSMDUHistory = function* fetchSMDUHistory() {
  try {
    const loanId = yield select(dashboardSelectors.loanNumber);
    const resolutionId = yield select(dashboardSelectors.resolutionId);
    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = yield select(dashboardSelectors.brand);
    const application = 'CMOD';

    yield put({ type: LOADING_SMDU_WIDGET });
    const requestPayload = {
      loanId,
      resolutionId,
    };

    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
    };

    const queryString = new URLSearchParams(requestPayload).toString();
    const url = `/api/cmodsmdu/SmduData/GetSmduData?${queryString}`;

    const response = yield call(Api.callGet, url, headers);
    if (response !== null) {
      yield put({
        type: SET_SMDU_RESPONSE_DATA,
        payload: response,
      });
    }

    if (!Array.isArray(response.messages)) {
      throw new Error('Expected messages to be an array');
    }

    const dartData = response.dartHistory || [];

    const priorWorkoutData = response.priorWorkoutsselectionoptions || [];

    const priorWorkoutHistory = response.priorWorkouts || [];

    if (!Array.isArray(dartData)) {
      throw new Error('Expected response to be an array');
    }

    const caseManagementData = response.hssnReports || [];

    if (!Array.isArray(caseManagementData)) {
      throw new Error('Expected response to be an array');
    }

    // History Data
    const groupedMessages = response.messages.reduce((accumulator, message) => {
      const messageHeadingPattern = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}) \[([^\]]+)\] ([^[]+(?:\] by \[\d+\])? ?)(.*)/;
      const match = message.messageHeading.match(messageHeadingPattern);

      let date = message.messageDate; let requestType; let fullName; let
        userName;

      if (match) {
        [, , requestType, fullName, userName] = match;

        if (userName) {
          userName = userName.replace(/\[\d+\] /, '').trim();
        } else {
          userName = fullName.trim();
        }
      } else {
        date = null;
        requestType = null;
        userName = message.messageHeading;
      }
      const messageHeading = `${date}`;
      const type = (userName === '76' || userName === 'CMOD.Automation') ? 'Auto' : 'Manual';
      if (!accumulator[messageHeading]) {
        accumulator[messageHeading] = [];
      }

      accumulator[messageHeading].push({
        ...message, requestType, userName, type,
      });
      return accumulator;
    }, {});

    const formattedData = Object.entries(groupedMessages).map(([messageHeading, messages]) => {
      const mappedRequestType = REQUEST_TYPE_MAP[messages[0].requestType]
        || messages[0].requestType;

      const workoutDetailsArray = messages.reduce((workoutDetails, message) => {
        // eslint-disable-next-line max-len
        let workoutTypeEntry = workoutDetails.find(wd => wd.WorkoutTypeCode === message.workoutType);
        if (!workoutTypeEntry) {
          workoutTypeEntry = {
            WorkoutTypeCode: message.workoutType,
            categories: {},
          };
          workoutDetails.push(workoutTypeEntry);
        }

        const workoutTypeCategories = workoutTypeEntry.categories;

        if (!workoutTypeCategories[message.messageCategory]) {
          workoutTypeCategories[message.messageCategory] = [];
        }

        workoutTypeCategories[message.messageCategory].push({
          MessageID: message.messageId.toString(),
          Message: message.message,
          Result: message.result ? message.result.trim() : 'F',
          WorkoutType: message.workoutType ? message.workoutType : '',
        });

        return workoutDetails;
      }, []);

      const uniqueWorkoutTypes = [...new Set(messages.map(message => message.workoutType))];

      return {
        date: messageHeading,
        user: messages[0].userName,
        type: messages[0].type,
        messageDate: messages[0].messageDate,
        operationType: messages[0].requestType,
        requestType: mappedRequestType,
        validationData: uniqueWorkoutTypes.map((workoutType) => {
          const statusColor = messages
            .find(message => message.workoutType === workoutType).recommendation;
          // const isImminentDefaultEligible =
          // messages[0].requestType === 'Imminent-Default'
          // && messages[0].messageCategory === 'Eligibility' && statusColor.includes('\x00');
          const resultColor = (statusColor === 'E' || statusColor === 'P') ? 'green' : 'red';
          return ({
            label: workoutType,
            status: resultColor,
          });
        }),
        workoutDetails: workoutDetailsArray,
      };
    });

    yield put({
      type: SET_SMDU_HISTORY_DATA,
      payload: formattedData,
    });

    // Darts data
    const formattedDartsData = dartData.map(dartHistory => ({
      reportedWorkoutCampaignName: dartHistory.reportedWorkoutCampaignName,
      reportedWorkoutCaseStatus: dartHistory.reportedWorkoutCaseStatus,
      reportedWorkoutPaymentDueDates: dartHistory.reportedWorkoutPaymentDueDates,
      reportedWorkoutServicerName: dartHistory.reportedWorkoutServicerName,
      reportedWorkoutType: dartHistory.reportedWorkoutType,
    }));

    yield put({
      type: SET_SMDU_HISTORY_DARTS_DATA,
      payload: formattedDartsData,
    });

    // Reports Data
    const reportsData = caseManagementData.flatMap(report => report.messages);
    const errorsData = caseManagementData.flatMap(report => report.errorDetail.messages);
    const latestMessage = Array.isArray(reportsData) && reportsData.length > 0
      ? reportsData.reduce((latest, current) => {
        const latestDate = new Date(latest.messageHeading.split(' [')[0]);
        const currentDate = new Date(current.messageHeading.split(' [')[0]);
        return currentDate > latestDate ? current : latest;
      }, reportsData[0])
      : {};

    const date = latestMessage.messageDate ? latestMessage.messageDate.replace('T', ' ') : '';
    const name = latestMessage.messageHeading
      ? `[${latestMessage.messageHeading.split('[')[1].split(']')[0]}]
    by ${latestMessage.messageHeading.split('] ')[1]}` : '';

    const formattedCaseManagementData = {
      reportsData,
      errorsData,
      date,
      name,
    };
    yield put({
      type: SET_SMDU_CASE_MANAGEMENT,
      payload: formattedCaseManagementData,
    });

    // PriorWorkout Data
    if (priorWorkoutData !== null) {
      yield put({
        type: SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN,
        payload: priorWorkoutData,
      });
    } else {
      yield put({
        type: SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN,
        payload: { status: 404 },
      });
    }

    yield put({
      type: SET_PRIOR_WORKOUT_HISTORY,
      payload: priorWorkoutHistory,
    });
  } catch (e) {
    yield put({
      type: HANDLE_SMDU_FAILURE,
    });
    const openWidgetList = yield select(widgetSelectors.getOpenWidgetList);
    const widgetsToBeClosed = {
      openWidgetList,
      page: 'SEARCH',
      closingWidgets: openWidgetList,
    };
    const payload = {
      currentWidget: '',
      openWidgetList: closeWidgets(widgetsToBeClosed),
    };
    yield put(widgetActions.widgetToggle(payload));
  }
};

const fetchTransactionData = function* fetchTransactionData() {
  try {
    const loanId = yield select(dashboardSelectors.loanNumber);
    const resolutionId = yield select(dashboardSelectors.resolutionId);

    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = yield select(dashboardSelectors.brand);
    const application = 'CMOD';
    const requestPayload = {
      loanId,
      resolutionId,
    };
    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
    };

    const queryString = new URLSearchParams(requestPayload).toString();
    const url = `/api/cmodsmdu/SmduData/GetSmduRequestResponseXML?${queryString}`;

    const response = yield call(Api.callGet, url, headers);

    yield put({
      type: SET_TRANSACTION_DATA,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};

const fetchReportInvestor = function* fetchReportInvestor() {
  try {
    const loanId = yield select(dashboardSelectors.loanNumber);
    const resolutionId = yield select(dashboardSelectors.resolutionId);
    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = yield select(dashboardSelectors.brand);
    const application = 'CMOD';
    const reportingPurpose = '1';

    const requestPayload = {
      loanId,
      resolutionId,
      reportingPurpose,
      userEmail: emailId,
    };

    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
      useMockData: false,
    };
    yield put({ type: LOADING_SMDU_WIDGET });
    const response = yield call(Api.callPost, '/api/cmodsmdu/SmduReportingRequest/ReportHssn', requestPayload, headers);
    const hasStatusCode = Object.prototype.hasOwnProperty.call(response, 'statusCode');
    if (hasStatusCode) {
      const statusCode = Number(response.statusCode);
      if (FAILED_STATUS_CODES.includes(statusCode)) {
        yield put({
          type: HANDLE_SMDU_FAILURE,
          payload: response.message,
        });
        yield put({ type: STOP_LOADING_SMDU_WIDGET });
      }
    } else if (response.errorDetail !== null
      && Array.isArray(response.errorDetail.messages)
      && response.errorDetail.messages.length > 0) {
      yield put({
        type: SET_TO_REPORT_INVESTOR,
        payload: { message: 'Errors Found. Please Check Errors Tab for More Details.', level: 'Failed' },
      });
      yield put({ type: FETCH_SMDU_HISTORY });
      yield put({ type: FETCH_TRANSACTION_DATA });
    } else if (!response.hasErrors) {
      yield put({
        type: SET_TO_REPORT_INVESTOR,
        payload: { message: response.responseMessage, level: 'Success' },
      });
      yield put({ type: FETCH_SMDU_HISTORY });
      yield put({ type: FETCH_TRANSACTION_DATA });
    }
    yield put({ type: STOP_LOADING_SMDU_WIDGET });
  } catch (e) {
    yield put({
      type: HANDLE_SMDU_FAILURE,
    });
  }
};

const fetchReportingData = function* fetchReportingData() {
  try {
    const loanId = yield select(dashboardSelectors.loanNumber);
    const resolutionId = yield select(dashboardSelectors.resolutionId);
    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = yield select(dashboardSelectors.brand);
    const application = 'CMOD';
    const requestPayload = {
      LoanId: loanId,
      ResolutionId: resolutionId,
    };

    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
    };

    const queryString = new URLSearchParams(requestPayload).toString();
    const url = `/api/cmodsmdu/SmduData/GetSmduReportingData?${queryString}`;


    const response = yield call(Api.callGet, url, headers);
    const reportsData = response.messages ? response.messages : [];
    const errorsData = !R.isNil(response.errorDetail) ? response.errorDetail.messages : [];
    const latestMessage = Array.isArray(reportsData) && reportsData.length > 0
      ? reportsData.reduce((latest, current) => {
        const latestDate = new Date(latest.messageHeading.split(' [')[0]);
        const currentDate = new Date(current.messageHeading.split(' [')[0]);
        return currentDate > latestDate ? current : latest;
      }, reportsData[0])
      : {};

    const date = latestMessage.messageDate ? latestMessage.messageDate.replace('T', ' ') : '';
    const name = latestMessage.messageHeading ? `[${latestMessage.messageHeading.split('[')[1].split(']')[0]}] by ${latestMessage.messageHeading.split('] ')[1]}` : '';

    const formattedCaseManagementData = {
      reportsData,
      errorsData,
      date,
      name,
    };
    yield put({
      type: SET_SMDU_REPORTING_UPDATES,
      payload: formattedCaseManagementData,
    });
  } catch (e) {
    console.log(e);
  }
};


const savePriorWorkout = function* savePriorWorkout(priorPayload) {
  try {
    yield put({ type: LOADING_SMDU_WIDGET });
    const { payload } = priorPayload;
    const {
      workoutTypes,
      statusTypes,
      statusDate,
      failCancelReasons,
      paymentReduction,
      subsequentDelinquencySeverities,
      activityTypes,
    } = payload;
    const loanId = yield select(dashboardSelectors.loanNumber);
    const emailId = yield select(loginSelectors.getUserPrincipalName);
    const brand = yield select(dashboardSelectors.brand);
    const application = 'CMOD';

    const requestPayload = {
      loanId,
      priorWorkouts: [
        {
          priorWorkoutType: workoutTypes,
          priorWorkoutStatusType: statusTypes,
          priorWorkoutStatusDate: statusDate,
          priorWorkoutFailCancelReason: failCancelReasons,
          priorWorkoutPaymentReductionPercentage: paymentReduction,
          priorWorkoutSubsequentDelinquencyServerity: subsequentDelinquencySeverities,
          priorWorkoutActivityType: activityTypes,
        },
      ],
    };

    const headers = {
      UserId: emailId,
      Brand: brand,
      Application: application,
    };
    const response = yield call(Api.callPostGetResponse, '/api/cmodsmdu/SmduRequest/SavePriorWorkoutHistory', requestPayload, headers);
    const hasStatusCode = response !== null && Object.prototype.hasOwnProperty.call(response, 'statusCode');
    if (hasStatusCode) {
      const statusCode = Number(response.statusCode);
      if (FAILED_STATUS_CODES.includes(statusCode)) {
        yield put({
          type: HANDLE_SMDU_FAILURE,
          payload: response.message,
        });
      }
    }
    if (response.priorWorkouts) {
      const priorWorkoutHistory = response.priorWorkouts || [];
      yield put({
        type: HANDLE_ADD_PRIOR_WORKOUT_SUCCESS,
        payload: PRIOR_WORKOUT_SUCCESS,
      });
      yield put({
        type: SET_PRIOR_WORKOUT_HISTORY,
        payload: priorWorkoutHistory,
      });
      yield put({ type: STOP_LOADING_SMDU_WIDGET });
    }
  } catch (e) {
    yield put({
      type: HANDLE_SMDU_FAILURE,
    });
  }
};

function* watchFetchSMDUHistory() {
  yield takeEvery(FETCH_SMDU_HISTORY, fetchSMDUHistory);
}

function* watchFetchTransactionData() {
  yield takeEvery(FETCH_TRANSACTION_DATA, fetchTransactionData);
}

function* watchFetchFNMARequest() {
  yield takeEvery(SUBMIT_TO_FNMA, fetchFNMARequest);
}


function* watchfetchReportInvestor() {
  yield takeEvery(SUBMIT_TO_REPORT_INVESTOR, fetchReportInvestor);
}

function* watchFetchReportingData() {
  yield takeEvery(FETCH_REPORTING_DATA, fetchReportingData);
}

function* watchSavePriorWorkout() {
  yield takeEvery(SAVE_PRIOR_WORKOUT, savePriorWorkout);
}

export const combinedSaga = function* combinedSaga() {
  yield all([
    watchFetchFNMARequest(),
    watchFetchSMDUHistory(),
    watchFetchTransactionData(),
    watchfetchReportInvestor(),
    watchSavePriorWorkout(),
    watchFetchReportingData(),
  ]);
};
