import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core/index';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EvalDialogBoxStyles from './EvalDialogBoxStyles';

const columns = [
  {
    id: 'code',
    label: 'Code',
    align: 'left',
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'left',
    format: value => `$${value}`,
  },
];

function createData({ code, description, amount }) {
  return { code, description, amount };
}

const EvalDialogBox = ({
  content, onClose, isOpen, scroll, showCloseIcon, title,
}) => {
  const classes = EvalDialogBoxStyles({});
  const rows = content ? content.map(data => createData(data)) : [];
  const total = content.reduce((temp, data) => {
    const finalAmount = temp + data.amount;
    return finalAmount;
  }, 0);


  return (
    <Dialog className={classes.DialogBox} onClose={onClose} open={isOpen} scroll={scroll}>
      {showCloseIcon
        && (
          <IconButton
            aria-label="close"
            className={classes.CloseIcon}
            onClick={onClose}
          >
            <img alt="modal_close_icon" src="/static/img/close.svg" />
          </IconButton>
        )
      }
      <DialogTitle className={classes.DialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <Paper elevation="0" sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxWidth: 435 }}>
            <Table aria-label="sticky table" stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map(row => (
                    <TableRow key={row.code} hover role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                <TableRow key="total" className={classes.Total} hover role="checkbox" tabIndex={-1}>
                  <TableCell key="total" align="left">Total</TableCell>
                  <TableCell key="" align="left" />
                  <TableCell key="totalValue" align="left">{`$${Number(total).toFixed(2)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

EvalDialogBox.defaultProps = {
  isOpen: false,
  scroll: 'Body',
  showCloseIcon: false,
  title: 'Message',
};

EvalDialogBox.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  scroll: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  title: PropTypes.string,
};

export default EvalDialogBox;
