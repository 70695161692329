import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Grid, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { operations as indexerOperations } from 'ducks/indexer';
import DocChecklist from '../IncomeCalc/DocChecklist/DocChecklist';
import Loader from '../Loader/Loader';
import './DocChecklistWidget.css';
import { EDITABLE_DOC_CHECKLIST_WIDGET } from '../../constants/appGroupName';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from '../../state/ducks/document-checklist';
import { selectors as dashboardSelectors } from '../../state/ducks/dashboard';
import { selectors as widgetsSelectors, operations as widgetsOperations } from '../../state/ducks/widgets';

class DocChecklistWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      docChkListData: [],
    };
  }

  componentDidMount() {
    const {
      setRadioSelect, unsavedDocWidgetChangesOperation, fetchBorrowers, loanNumber, processId,
    } = this.props;
    setRadioSelect('');
    unsavedDocWidgetChangesOperation(false);
    fetchBorrowers({ loanNumber, processId });
  }

  componentDidUpdate() {
    const {
      docChecklistData, unsavedDocWidgetChanges, unsavedDocWidgetChangesOperation,
      isWidgetSaved,
    } = this.props;
    const { docChkListData } = this.state;

    if (!R.equals(docChkListData, docChecklistData) && R.isEmpty(docChkListData)) {
      const data = JSON.parse(JSON.stringify(docChecklistData));
      this.updateDocChkData(data);
    }
    if (!R.equals(docChkListData, docChecklistData)
      && unsavedDocWidgetChanges !== true && !R.isEmpty(docChkListData)
      && isWidgetSaved !== true) {
      unsavedDocWidgetChangesOperation(true);
    } else if (R.equals(docChkListData, docChecklistData) && unsavedDocWidgetChanges === true) {
      unsavedDocWidgetChangesOperation(false);
    }
  }

  onSave = () => {
    const {
      docChecklistWigetValidation, docChecklistWigetSave,
    } = this.props;
    docChecklistWigetValidation();
    docChecklistWigetSave();
  }

  handleAlertClose = (event, reason) => {
    const { setDocWidgetSuccessOnSave } = this.props;
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ docChkListData: [] });
    setDocWidgetSuccessOnSave(false);
  }

  updateDocChkData(data) {
    this.setState({ docChkListData: data });
  }

  render() {
    const {
      inProgress, groupName, isWidgetSaved,
    } = this.props;

    if (inProgress) {
      return (
        <div styleName="income-loader">
          <Loader message="Please Wait" />
        </div>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <Grid container style={{ background: 'white' }}>
          <Grid item style={{ display: 'flex' }} xs={6}>
            <span styleName="header"> Document Checklist </span>
          </Grid>
          <Grid item styleName="save-btn" xs={6}>
            <Button color="primary" disabled={!R.contains(groupName, EDITABLE_DOC_CHECKLIST_WIDGET)} onClick={this.onSave} variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
        <div style={{ marginLeft: '1.5rem' }}>
          <DocChecklist />
        </div>

        <Snackbar
          autoHideDuration={6000}
          onClose={this.handleAlertClose}
          open={isWidgetSaved}
        >
          <Alert elevation={6} severity="success">
            DocChecklist Successfully Saved!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

DocChecklistWidget.defaultProps = {
  groupName: null,
};

DocChecklistWidget.propTypes = {
  docChecklistData: PropTypes.shape().isRequired,
  docChecklistWigetSave: PropTypes.func.isRequired,
  docChecklistWigetValidation: PropTypes.func.isRequired,
  failureReason: PropTypes.shape({
    1: PropTypes.number,
    2: PropTypes.number,
  }),
  fetchBorrowers: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  inProgress: PropTypes.bool.isRequired,
  isWidgetSaved: PropTypes.bool.isRequired,
  loanNumber: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string.isRequired,
  }).isRequired,
  processId: PropTypes.string.isRequired,
  setDocWidgetSuccessOnSave: PropTypes.func.isRequired,
  setRadioSelect: PropTypes.func.isRequired,
  unsavedDocWidgetChanges: PropTypes.bool.isRequired,
  unsavedDocWidgetChangesOperation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  groupName: dashboardSelectors.groupName(state),
  docChecklistData: documentChecklistSelectors.getDocChecklistData(state),
  initialDocChecklistData: documentChecklistSelectors.getInitialDocChecklistData(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  isWidgetSaved: documentChecklistSelectors.getIsWidgetSaved(state),
  unsavedDocWidgetChanges: documentChecklistSelectors.getUnsavedDocWidgetChanges(state),
  processId: dashboardSelectors.processId(state),
  loanNumber: dashboardSelectors.loanNumber(state),
});

const mapDispatchToProps = dispatch => ({
  setRadioSelect: documentChecklistOperations.radioSelectOperation(dispatch),
  onWidgetToggle: widgetsOperations.onWidgetToggle(dispatch),
  docChecklistWigetSave: documentChecklistOperations.docChecklistWigetSaveOperation(dispatch),
  docChecklistWigetValidation: documentChecklistOperations.docChecklistWigetValidation(dispatch),
  unsavedDocWidgetChangesOperation:
    documentChecklistOperations.unsavedDocWidgetChangesOperation(dispatch),
  setDocWidgetSuccessOnSave: documentChecklistOperations.setDocWidgetSuccessOnSave(dispatch),
  fetchBorrowers: indexerOperations.fetchBorrowersIndexer(dispatch),
});


DocChecklistWidget.defaultProps = {
  failureReason: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(DocChecklistWidget);
