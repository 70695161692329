const ALL_MILESTONE_HISTORY = 'All Milestone History';
const CLOSED = 'Closed';
const INTERRUPTED = 'Interrupted';


module.exports = {
  ALL_MILESTONE_HISTORY,
  CLOSED,
  INTERRUPTED,
};
