import React from 'react';
import PropTypes from 'prop-types';
import UITaskGenerator from 'components/UITaskGenerator';
import Dialog from '@material-ui/core/Dialog';
import accountServiceTemplate from 'constants/AccountService/accountService';

function AnalysisBreakDownPopover(props) {
  const {
    data, isOpen, closeDialog, processAction,
  } = props;
  return (
    <Dialog maxWidth="lg" onClose={() => { closeDialog(); }} open={isOpen}>
      <UITaskGenerator
        checklistItems={accountServiceTemplate.analysisBreakdownTemplate}
        escrowData={data}
        processAction={processAction}
      />
    </Dialog>
  );
}

AnalysisBreakDownPopover.defaultProps = {
  isOpen: false,
  closeDialog: () => {},
};

AnalysisBreakDownPopover.propTypes = {
  closeDialog: PropTypes.func,
  data: PropTypes.shape.isRequired,
  isOpen: PropTypes.bool,
  processAction: PropTypes.func.isRequired,
};

export default AnalysisBreakDownPopover;
