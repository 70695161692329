import {
  NON_DEL_APPROVAL_DATE, NON_DELEGATED, LOCKED_DELEGATE_TYPE, GEN_INFO,
} from 'constants/eval';
import {
  SET_CALC_LOG_DATA, SET_ELIGIBILITY_DATA,
  SET_UNIVERSAL_ACTION_DATA, SET_FULFILLMENT_CHECKLIST,
  SET_REJECT_REASONS, SET_POPUP_STATUS, SET_TRACKPAYMENT_DATA,
  SHOW_LOADER, HIDE_LOADER, SAVE_FULFILLMENT_CHECKLIST, SET_BOOKING_DATA, FETCH_CALCLOGS_LOADER,
  SAVE_CASEDETAILS, TOGGLE_LOCK_CASE,
  CALCULATE_CASE_LOADER,
  SET_CASEHEADERINFO_DATA, CLEAR_CASEHEADERINFO_DATA,
  SET_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST, SET_EVAL_DETAILS, SET_INV_GRP_CODES, SET_CASE_TYPES,
  SET_WORKOUTTYPE_DATA, SET_CREATE_BUTTON_STATUS, SET_EVALSCREEN_DETAILS,
  SET_STREAMLINE_MOD_ACCEPTANCE, SET_SELECTED_CASEID,
  SET_EVALSCREEN_ICON, SET_PRE_LOCK_CASE_RULES, SET_NEW_MOD_DETAILS,
  CREATE_NEW_EVAL_CASE_LOADER, SET_BOOKING_TAB_DATA,
  SET_BOOKING_TAB_CORP_ADVN_DATA, SET_LETTER_GENERATION,
  SET_STATES,
  TOGGLE_DISASTER_POPUP,
  SET_COUNTYS,
  SET_DISASTERDTLS,
  SET_NPV_DATA,
  DISASTER_POPUP_LOADER,
  UPDATE_TRACKPAYMENT_RESPONSE,
  SET_FINANCIAL_DROPDOWNS,
  UPDATE_HARDSHIP_ID,
  CREATE_EVAL_LOADER,
  FETCH_WORKOUT_TYPE_LOADER,
  SET_EVAL_HEADER_IN_PROGRESS,
  SET_RULE_ENGINE_ERROR_MSG,
  SET_MISC_ERROR_MSG,
  LOAD_MOD_CALC,
  FINANCIAL_DATA_LOADER,
  FETCH_CASE_TYPE_LOADER, FINANCIAL_DROPDOWN_LOADER,
  SET_LOCK_EXT_VALIDATION_MSG,
  CLEAR_INFO, USER_OVERRIDE_RULE_LIST,
  SET_APPROVAL_POP_UP_DATA,
  CLEAR_APPROVAL_POPUP_DATA, SET_SMDU_POPUP,
  SET_REJ_CASE_DATA,
  SET_INV_WATERFALL_INFO,
} from './types';

const intialState = {
  generalInformation: [],
  caseInformation: [],
  corporateAdvances: [],
  stepRates: [],
  ratios: [],
  eligibility: [],
  calcLogs: [],
  amortizationDetails: [],
  finalHardShipId: 0,
  isCalcLogsLoading: false,
  letterGenerated: false,
  approvalPopUpData: {},
  userOverrideRuleList: [],
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_UNIVERSAL_ACTION_DATA: {
      const actionData = action.payload;
      return {
        ...state,
        ...actionData,
      };
    }

    case SET_ELIGIBILITY_DATA: {
      const eligibility = action.payload;
      return {
        ...state,
        eligibility,
      };
    }

    case SET_CASEHEADERINFO_DATA: {
      const caseHeaderInfo = action.payload;
      return {
        ...state,
        caseHeaderInfo,
      };
    }

    case CLEAR_CASEHEADERINFO_DATA: {
      const caseHeaderInfo = null;
      return {
        ...state,
        caseHeaderInfo,
      };
    }

    case SET_CALC_LOG_DATA: {
      const calcLogs = action.payload;
      return {
        ...state,
        calcLogs,
      };
    }

    case SET_REJECT_REASONS: {
      const evalRejectReasons = action.payload;
      return {
        ...state,
        evalRejectReasons,
      };
    }

    case SET_POPUP_STATUS: {
      const status = action.payload;
      return {
        ...state,
        status,
      };
    }

    case SET_TRACKPAYMENT_DATA: {
      const trackPayments = action.payload;
      return {
        ...state,
        trackPayments,
      };
    }

    case SET_FULFILLMENT_CHECKLIST: {
      const fulfillmentCheckList = action.payload;
      return {
        ...state,
        fulfillmentCheckList,
      };
    }

    case SAVE_FULFILLMENT_CHECKLIST: {
      let taskStatusUpdate;
      if (action.payload) {
        taskStatusUpdate = action.payload;
      }
      return {
        ...state,
        taskStatusUpdate,
      };
    }

    case SHOW_LOADER: {
      return {
        ...state,
        inProgress: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        inProgress: false,
      };
    }

    case FETCH_CALCLOGS_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (isLoading === true || isLoading === false) ? isLoading : false;
      return {
        ...state,
        isCalcLogsLoading: isLoading,
      };
    }

    case SET_BOOKING_DATA: {
      const bookingData = action.payload;
      return {
        ...state,
        bookingData,
      };
    }

    case SAVE_CASEDETAILS: {
      if (action.payload.type === GEN_INFO) {
        const genInfo = action.payload.data;
        const caseInfo = state.caseInformation;

        let nonDelPosition = 0;
        const nonDelInUnivValue = genInfo.find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
        const nonDelInCaseValue = caseInfo.find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
        if (nonDelInUnivValue) nonDelPosition = 1;
        if (nonDelInCaseValue) nonDelPosition = 2;

        const fetchNonDelegateType = genInfo.find(i => (i.columnName === LOCKED_DELEGATE_TYPE));
        if (fetchNonDelegateType && nonDelPosition === 1) {
          const isNonDelegated = fetchNonDelegateType.columnVal === NON_DELEGATED;
          const updatedgenInfo = genInfo.map(e => (e.columnName === NON_DEL_APPROVAL_DATE
            ? {
              ...e,
              isOverridable: isNonDelegated,
              columnVal: isNonDelegated ? e.columnVal : '',
              isOverride: true,
            }
            : e));
          return { ...state, generalInformation: updatedgenInfo };
        }
        if (fetchNonDelegateType && nonDelPosition === 2) {
          const isNonDelegated = fetchNonDelegateType.columnVal === NON_DELEGATED;
          const updatedCaseInfo = caseInfo.map(e => (e.columnName === NON_DEL_APPROVAL_DATE
            ? {
              ...e,
              isOverridable: isNonDelegated,
              columnVal: isNonDelegated ? e.columnVal : '',
              isOverride: true,
            }
            : e));
          return { ...state, caseInformation: updatedCaseInfo, generalInformation: genInfo };
        }
        return { ...state, generalInformation: action.payload.data };
      }

      return { ...state, caseInformation: action.payload.data };
    }

    case CALCULATE_CASE_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isCalculateLoading: isLoading,
      };
    }

    case SET_CERTAIN_MORGAGE_LOAN_CHECKLIST: {
      const certainMorgageLoanChecklist = action.payload;
      return {
        ...state,
        certainMorgageLoanChecklist,
      };
    }

    case SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST: {
      let saveCMLCStatus;
      if (action.payload) {
        saveCMLCStatus = action.payload;
      }
      return {
        ...state,
        saveCMLCStatus,
      };
    }

    case SET_EVAL_DETAILS: {
      const evalDetails = action.payload;
      return {
        ...state,
        evalDetails,
      };
    }

    case SET_INV_GRP_CODES: {
      const invGrpCodes = action.payload;
      return {
        ...state,
        invGrpCodes,
      };
    }

    case SET_CASE_TYPES: {
      const { caseTypesData, caseTypes } = action.payload;
      return {
        ...state,
        caseTypesData,
        caseTypes,
      };
    }

    case SET_WORKOUTTYPE_DATA: {
      const workoutTypes = action.payload;
      return {
        ...state,
        workoutTypes,
      };
    }

    case SET_CREATE_BUTTON_STATUS: {
      const createButtonStatus = action.payload;
      return {
        ...state,
        createButtonStatus,
      };
    }

    case SET_EVALSCREEN_DETAILS: {
      const { response, pvmId } = action.payload;
      const evalScreenInfo = response;
      evalScreenInfo.pvmId = pvmId;
      return {
        ...state,
        evalScreenInfo,
      };
    }

    case SET_STREAMLINE_MOD_ACCEPTANCE: {
      const acceptanceFlag = action.payload;
      return {
        ...state,
        evalDetails: { ...state.evalDetails, acceptanceFlag },
      };
    }

    case SET_SELECTED_CASEID: {
      const selectedCaseId = action.payload;
      return {
        ...state,
        selectedCaseId,
      };
    }

    case SET_EVALSCREEN_ICON: {
      const evalScreenIcon = action.payload;
      return {
        ...state,
        evalScreenIcon,
      };
    }

    case TOGGLE_LOCK_CASE: {
      const lockValication = action.payload;
      return {
        ...state,
        lockValication,
      };
    }

    case SET_PRE_LOCK_CASE_RULES: {
      const preLockCaseValidation = action.payload;
      return {
        ...state,
        preLockCaseValidation,
      };
    }

    case SET_NEW_MOD_DETAILS: {
      const newCaseDetails = action.payload;
      return {
        ...state,
        newCaseDetails,
      };
    }

    case CREATE_NEW_EVAL_CASE_LOADER: {
      let { isLoading } = action.payload;
      const { loaderMsg } = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        createNewEval: {
          newEvalLoader: isLoading,
          loaderMsg: loaderMsg || '',
          loaderPercentage: 0,
        },
      };
    }

    case SET_BOOKING_TAB_DATA: {
      const bookingTabData = action.payload;
      return {
        ...state,
        bookingTabData,
      };
    }

    case SET_BOOKING_TAB_CORP_ADVN_DATA: {
      const bookingTabCorpAdvnData = action.payload;
      return {
        ...state,
        bookingTabCorpAdvnData,
      };
    }

    case UPDATE_TRACKPAYMENT_RESPONSE: {
      const updateTrackPaymentResponse = action.payload;
      return {
        ...state,
        updateTrackPaymentResponse,
      };
    }

    case SET_APPROVAL_POP_UP_DATA: {
      const approvalPopUpData = {
        ...action.payload,
        isOpen: true,
      };
      return {
        ...state,
        approvalPopUpData,
      };
    }

    case CLEAR_APPROVAL_POPUP_DATA: {
      return {
        ...state,
        approvalPopUpData: {},
      };
    }

    case SET_LETTER_GENERATION: {
      const status = action.payload;
      return {
        ...state,
        letterGenerated: status,
      };
    }

    case SET_STATES: {
      const states = action.payload;
      return {
        ...state,
        states,
      };
    }

    case TOGGLE_DISASTER_POPUP: {
      const disasterPopupOpen = action.payload;
      return {
        ...state,
        disasterPopupOpen,
      };
    }

    case SET_COUNTYS: {
      const countys = action.payload;
      return {
        ...state,
        countys,
      };
    }

    case SET_DISASTERDTLS: {
      const disasterDtls = action.payload;
      return {
        ...state,
        disasterDtls,
      };
    }

    case SET_NPV_DATA: {
      const npvData = action.payload;
      return {
        ...state,
        npvData,
      };
    }

    case DISASTER_POPUP_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isDisasterPopupLoading: isLoading,
      };
    }

    case SET_FINANCIAL_DROPDOWNS: {
      const dropdowns = action.payload;
      return {
        ...state,
        dropdowns,
      };
    }

    case UPDATE_HARDSHIP_ID: {
      const hardShipId = action.payload;
      return {
        ...state,
        finalHardShipId: hardShipId,
      };
    }

    case CREATE_EVAL_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isCreateEvalLoading: isLoading,
      };
    }

    case FETCH_WORKOUT_TYPE_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isworkOutTypeLoading: isLoading,
      };
    }

    case SET_EVAL_HEADER_IN_PROGRESS: {
      const evalHeaderInProgress = action.payload;
      return {
        ...state,
        evalHeaderInProgress,
      };
    }

    case SET_RULE_ENGINE_ERROR_MSG: {
      const errorMsg = action.payload;
      return {
        ...state,
        ruleEngineErrMsg: errorMsg,
      };
    }

    case SET_MISC_ERROR_MSG: {
      const errorMsg = action.payload;
      return {
        ...state,
        miscErrorMsg: errorMsg,
      };
    }

    case LOAD_MOD_CALC: {
      let makeModCalcCall = action.payload;
      makeModCalcCall = makeModCalcCall && (typeof makeModCalcCall === 'boolean') ? makeModCalcCall : false;
      return {
        ...state,
        makeModCalcCall,
      };
    }

    case FINANCIAL_DATA_LOADER: {
      let financialDetailLoading = action.payload;
      financialDetailLoading = financialDetailLoading && (typeof financialDetailLoading === 'boolean') ? financialDetailLoading : false;
      return {
        ...state,
        financialDetailLoading,
      };
    }

    case FETCH_CASE_TYPE_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isCaseTypeLoading: isLoading,
      };
    }

    case FINANCIAL_DROPDOWN_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        isFinancialDropdownLoading: isLoading,
      };
    }

    case SET_LOCK_EXT_VALIDATION_MSG: {
      const { payload } = action;
      return {
        ...state,
        lockExtValidationMsg: payload,
      };
    }

    case CLEAR_INFO: {
      return {
        ...intialState,
      };
    }

    case USER_OVERRIDE_RULE_LIST: {
      const { payload } = action;
      return {
        ...state,
        userOverrideRuleList: payload,
      };
    }

    case SET_SMDU_POPUP: {
      return {
        ...state,
        isSmduPopupOpen: action.payload,
      };
    }
    case SET_REJ_CASE_DATA: {
      const { dataKey, dataValue } = action.payload;
      return {
        ...state,
        [dataKey]: dataValue,
      };
    }

    case SET_INV_WATERFALL_INFO: {
      return {
        ...state,
        invWaterFallInfo: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
