import React from 'react';

const DownloadIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 17 24" width="17" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09375 4H9.59375C10 4 10.3438 4.34375 10.3438 4.75V10H13.0625C13.625 10 13.9062 10.6875 13.5 11.0938L8.75 15.8438C8.53125 16.0625 8.125 16.0625 7.90625 15.8438L3.15625 11.0938C2.75 10.6875 3.03125 10 3.59375 10H6.34375V4.75C6.34375 4.34375 6.65625 4 7.09375 4ZM16.3438 15.75V19.25C16.3438 19.6875 16 20 15.5938 20H1.09375C0.65625 20 0.34375 19.6875 0.34375 19.25V15.75C0.34375 15.3438 0.65625 15 1.09375 15H5.65625L7.1875 16.5312C7.8125 17.1875 8.84375 17.1875 9.46875 16.5312L11 15H15.5938C16 15 16.3438 15.3438 16.3438 15.75ZM12.4688 18.5C12.4688 18.1562 12.1875 17.875 11.8438 17.875C11.5 17.875 11.2188 18.1562 11.2188 18.5C11.2188 18.8438 11.5 19.125 11.8438 19.125C12.1875 19.125 12.4688 18.8438 12.4688 18.5ZM14.4688 18.5C14.4688 18.1562 14.1875 17.875 13.8438 17.875C13.5 17.875 13.2188 18.1562 13.2188 18.5C13.2188 18.8438 13.5 19.125 13.8438 19.125C14.1875 19.125 14.4688 18.8438 14.4688 18.5Z" fill="#6D7483" />
  </svg>

);

export default DownloadIcon;
