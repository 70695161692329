import React, { useState } from 'react';
import {
  Grid, Typography, Popover, Divider, IconButton,
} from '@material-ui/core/index';
import HistoryIcon from '@material-ui/icons/History';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import './NPVResultsHistory.css';
import { connect } from 'react-redux';
import { capitalizeWords } from '../../../../lib/capitalizeWords';


function NPVResultsHistory(props) {
  const {
    historyData, setNPVResultHistoryData,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickView = (value) => {
    setAnchorEl(null);
    setNPVResultHistoryData(value);
  };

  return (
    <div>
      <HistoryIcon onClick={handleClick} />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="column" styleName="npv-results-history-container">
          {
            Object.keys(historyData).length === 0 && (
              <>
                <Typography styleName="no-history-name">No History Found</Typography>
              </>
            )
          }
          {
            historyData && historyData.map(value => (
              <>
                <Grid key={value.requestId} item styleName="npv-results-history-item">
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography styleName="npv-results-typo-id">{value.requestId}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => onClickView(value)} styleName="npv-results-view-button">
                        <img alt="home page placeholder" src="/static/img/viewIconReasonableEffort.png" styleName="view-icon-img" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography styleName="npv-results-typo-id npv-results-typo-value">{capitalizeWords(value.npvStatus)}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography styleName="npv-results-typo-id npv-results-typo-value">{moment(value.createDate).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))
          }
        </Grid>
      </Popover>
    </div>
  );
}


NPVResultsHistory.propTypes = {
  historyData: PropTypes.shape().isRequired,
  setNPVResultHistoryData: PropTypes.func.isRequired,
};


export default connect(null)(NPVResultsHistory);
