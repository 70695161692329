const FETCH_TOMBSTONE_DATA = 'app/tombstone/FETCH_TOMBSTONE_DATA';
const LOADING_TOMBSTONE_DATA = 'app/tombstone/LOADING_TOMBSTONE_DATA';
const ERROR_LOADING_TOMBSTONE_DATA = 'app/tombstone/ERROR_LOADING_TOMBSTONE_DATA';
const SUCCESS_LOADING_TOMBSTONE_DATA = 'app/tombstone/SUCCESS_LOADING_TOMBSTONE_DATA';
const GET_RFDTABLE_DATA = 'app/tombstone/GET_RFDTABLE_DATA';
const SET_RFDTABLE_DATA = 'app/tombstone/SET_RFDTABLE_DATA';
const GET_RFD_DROPDOWN_DATA = 'app/tombstone/GET_RFD_DROPDOWN_DATA';
const SET_RFD_DROPDOWN_DATA = 'app/tombstone/SET_RFD_DROPDOWN_DATA';
const SAVE_RFD_REQUEST = 'app/tombstone/SAVE_RFD_REQUEST';
const SAVE_RFD_RESPONSE = 'app/tombstone/SAVE_RFD_RESPONSE';
const TOGGLE_LOADER = 'app/tombstone/TOGGLE_LOADER';
const CLEAR_TOMBSTONE_DATA = 'app/tombstone/CLEAR_TOMBSTONE_DATA';
const APPEND_RFD_SAVE_DATA = 'app/tombstone/APPEND_RFD_SAVE_DATA';
const POPULATE_COLLATERAL_DROPDOWN = 'app/tombstone/POPULATE_COLLATERAL_DROPDOWN';
const FETCH_COLLATERAL_DATA = 'app/tomstone/FETCH_COLLATERAL_DATA';
const POPULATE_COLLATERAL_DATA = 'app/tombstone/POPULATE_COLLATERAL_DATA';
const ADD_LIEN_LOAN_BALANCE = 'app/tombstone/ADD_LIEN_LOAN_BALANCE';
const SAVE_COLLATERAL_DATA = 'app/tombstone/SAVE_COLLATERAL_DATA';
const SET_CHECKLIST_CENTERPANE = 'app/tombstone/SET_CHECKLIST_CENTERPANE';
const SET_PANDEMIC_FLAG = 'app/tombstone/SET_PANDEMIC_FLAG';
const SAVE_PROPERTY_PRIMARY_USE_DROPDOWN = 'app/tombstone/SAVE_PROPERTY_PRIMARY_USE_DROPDOWN';
const REFRESH_LIEN_BALANCES = 'app/tombstone/REFRESH_LIEN_BALANCES';
const POPULATE_LIEN_BALANCES = 'app/tombstone/POPULATE_LIEN_BALANCES';
const POPULATE_PROPERTY_VALUATIONS = 'app/tombstone/POPULATE_PROPERTY_VALUATIONS';
const TOGGLE_VIEW = 'app/tombstone/TOGGLE_VIEW';
const UPDATE_RFD = 'app/tombstone/UPDATE_RFD';
const UPDATE_OCCUPANCY = 'app/tombstone/UPDATE_OCCUPANCY';
const UPDATE_CONSOLIDATE_EXPENSE_DATA = 'app/tombstone/UPDATE_CONSOLIDATE_EXPENSE_DATA';
const GET_REASONABLE_EFFORT_DATA = 'app/tombstone/GET_REASONABLE_EFFORT_DATA';
const SET_REASONABLE_EFFORT_DATA = 'app/tombstone/SET_REASONABLE_EFFORT_DATA';
const SET_REASONABLE_EFFORT_MIS_DOC_DATA = 'app/tombstone/SET_REASONABLE_EFFORT_MIS_DOC_DATA';
const GET_REASONABLE_EFFORT_HISTORY_DATA = 'app/tombstone/GET_REASONABLE_EFFORT_HISTORY_DATA';
const SET_REASONABLE_EFFORT_HISTORY_DATA = 'app/tombstone/SET_REASONABLE_EFFORT_HISTORY_DATA';
const GET_REASONABLE_EFFORT_DATA_BY_ID = 'app/tombstone/GET_REASONABLE_EFFORT_DATA_BY_ID';
const GET_CFPBTABLE_DATA = 'app/tombstone/GET_CFPBTABLE_DATA';
const SET_CFPBTABLE_DATA = 'app/tombstone/SET_CFPBTABLE_DATA';
const SET_UPDATED_ASSUMPTORS = 'app/tombstone/SET_UPDATED_ASSUMPTORS';
const GET_FORECLOSURE_FIRM_DATA = 'app/tombstone/GET_FORECLOSURE_FIRM_DATA';
const SET_FORECLOSURE_FIRM_DATA = 'app/tombstone/SET_FORECLOSURE_FIRM_DATA';
const SET_LOAN_MASTATE = 'app/tombstone/SET_LOAN_MASTATE';
const FETCH_HARDSHIP_DATA = 'app/tombstone/FETCH_HARDSHIP_DATA';
const SAVE_HARDSHIP_DATA = 'app/tombstone/SAVE_HARDSHIP_DATA';
const SET_HARDSHIP_DATA = 'app/tombstone/SET_HARDSHIP_DATA';
const UPDATE_HARDSHIP_DATA = 'app/tombstone/UPDATE_HARDSHIP_DATA';
const SET_UPDATED_BORR_HARDSHIP_DATA = 'app/tombstone/SET_UPDATED_BORR_HARDSHIP_DATA';
const SAVE_UPDTD_HARDSHIP_DATA = 'app/tombstone/SAVE_UPDTD_HARDSHIP_DATA';
const SAVE_HARDSHIP_SOURCE_DROPDOWN = 'app/tombstone/SAVE_HARDSHIP_SOURCE_DROPDOWN';
const SAVE_HARDSHIP_TYPE_DROPDOWN = 'app/tombstone/SAVE_HARDSHIP_TYPE_DROPDOWN';
const SAVE_SEX_DROPDOWN = 'app/tombstone/SAVE_SEX_DROPDOWN';
const SAVE_RACE_DROPDOWN = 'app/tombstone/SAVE_RACE_DROPDOWN';
const SAVE_ETHNICITY_DROPDOWN = 'app/tombstone/SAVE_ETHNICITY_DROPDOWN';
const POPULATE_HARDSHIP_DROPDOWN = 'app/tombstone/POPULATE_HARDSHIP_DROPDOWN';
const HARDSHIP_DEFAULT_VALUE = 'app/tombstone/HARDSHIP_DEFAULT_VALUE';
const SET_HARDSHIP_TYPE = 'app/tombstone/SET_HARDSHIP_TYPE';
const SET_RESOLUTION_TYPE = 'app/tombstone/SET_RESOLUTION_TYPE';
const SET_CAP_MOD_ID = 'app/tombstone/SET_CAP_MOD_ID';

export {
  GET_FORECLOSURE_FIRM_DATA,
  SET_FORECLOSURE_FIRM_DATA,
  TOGGLE_LOADER,
  GET_RFDTABLE_DATA,
  SET_RFDTABLE_DATA,
  GET_RFD_DROPDOWN_DATA,
  SET_RFD_DROPDOWN_DATA,
  SAVE_RFD_REQUEST,
  SAVE_RFD_RESPONSE,
  FETCH_TOMBSTONE_DATA,
  LOADING_TOMBSTONE_DATA,
  ERROR_LOADING_TOMBSTONE_DATA,
  SUCCESS_LOADING_TOMBSTONE_DATA,
  CLEAR_TOMBSTONE_DATA,
  APPEND_RFD_SAVE_DATA,
  SET_CHECKLIST_CENTERPANE,
  TOGGLE_VIEW,
  POPULATE_COLLATERAL_DROPDOWN,
  FETCH_COLLATERAL_DATA,
  POPULATE_COLLATERAL_DATA,
  ADD_LIEN_LOAN_BALANCE,
  SAVE_COLLATERAL_DATA,
  SAVE_PROPERTY_PRIMARY_USE_DROPDOWN,
  SET_PANDEMIC_FLAG,
  UPDATE_RFD,
  REFRESH_LIEN_BALANCES,
  POPULATE_LIEN_BALANCES,
  POPULATE_PROPERTY_VALUATIONS,
  UPDATE_OCCUPANCY,
  UPDATE_CONSOLIDATE_EXPENSE_DATA,
  GET_REASONABLE_EFFORT_DATA,
  SET_REASONABLE_EFFORT_DATA,
  SET_REASONABLE_EFFORT_MIS_DOC_DATA,
  GET_REASONABLE_EFFORT_HISTORY_DATA,
  SET_REASONABLE_EFFORT_HISTORY_DATA,
  GET_REASONABLE_EFFORT_DATA_BY_ID,
  GET_CFPBTABLE_DATA,
  SET_CFPBTABLE_DATA,
  SET_UPDATED_ASSUMPTORS,
  SET_LOAN_MASTATE,
  FETCH_HARDSHIP_DATA,
  SET_HARDSHIP_DATA,
  SAVE_HARDSHIP_DATA,
  UPDATE_HARDSHIP_DATA,
  SET_UPDATED_BORR_HARDSHIP_DATA,
  SAVE_UPDTD_HARDSHIP_DATA,
  SAVE_HARDSHIP_SOURCE_DROPDOWN,
  SAVE_HARDSHIP_TYPE_DROPDOWN,
  SAVE_SEX_DROPDOWN,
  SAVE_RACE_DROPDOWN,
  SAVE_ETHNICITY_DROPDOWN,
  POPULATE_HARDSHIP_DROPDOWN,
  HARDSHIP_DEFAULT_VALUE,
  SET_HARDSHIP_TYPE,
  SET_RESOLUTION_TYPE,
  SET_CAP_MOD_ID,
};
