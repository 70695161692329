import React, { useState } from 'react';
import {
  Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import './BulkStatus.css';
import PropTypes from 'prop-types';
import BulkStatusCase from './BulkStatusCase/BulkStatusCase';
import BulkStatusEval from './BulkStatusEval';


const BulkStatus = ({ changeRadioSelect, recordsInfo }) => {
  const [status, setStatus] = useState('case');

  const handleChange = (event) => {
    const { value } = event.target;
    setStatus(value);
    changeRadioSelect(value);
  };

  return (
    <div>
      <RadioGroup
        name="status"
        onChange={handleChange}
        row
        styleName="radio-group-container"
        value={status}
      >
        <FormControlLabel
          control={<Radio size="small" />}
          label="Case Status"
          value="case"
        />
        <FormControlLabel
          control={<Radio size="small" />}
          label="Eval Status"
          value="eval"
        />
      </RadioGroup>
      {status === 'case' ? <BulkStatusCase recordsInfo={recordsInfo} type={status} /> : <BulkStatusEval recordsInfo={recordsInfo} type={status} />}
    </div>
  );
};

BulkStatus.propTypes = {
  changeRadioSelect: PropTypes.func.isRequired,
  recordsInfo: PropTypes.shape().isRequired,
};

export default BulkStatus;
