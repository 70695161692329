import * as R from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import '../BoardingTemplatePage.css';

const BoardingPhaseDropDown = ({
  selectedCaseType, boardingPhaseOptions, selectedPhase, setSelectedPhase,
  setIsSubmitDisabled, loanIds,
}) => {
  const handlePhase = (event) => {
    setSelectedPhase(event.target.value);
  };

  useEffect(() => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    if (loanIds === '' || !re.test(loanIds)) {
      setIsSubmitDisabled(R.isEmpty(loanIds.trim())
      || R.isEmpty(selectedPhase) || R.isEmpty(selectedCaseType));
    }
  }, [selectedPhase]);

  return (
    <FormControl variant="outlined">
      <Select
        displayEmpty
        id="eventNamesDropdown"
        input={<OutlinedInput name="selectedEvent" />}
        onChange={handlePhase}
        renderValue={(() => (
          <div>{selectedPhase || 'Please Select'}</div>
        ))}
        styleName="drop-down-select"
        value={selectedPhase}
      >
        {boardingPhaseOptions.map(item => (
          <MenuItem
            key={item}
            value={item}
          >
            {item}
          </MenuItem>
        ))}

      </Select>
    </FormControl>
  );
};

BoardingPhaseDropDown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  boardingPhaseOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  loanIds: PropTypes.string.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setSelectedPhase: PropTypes.func.isRequired,
};

export default BoardingPhaseDropDown;
