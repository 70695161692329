import React from 'react';
import PropTypes from 'prop-types';
import './FilterSection.css';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'; import MomentUtils from '@date-io/moment';
import * as R from 'ramda';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import utils from 'lib/Utils';
import InputSearchFilter from 'components/InputSearchFilter';

const triggerAutoCompleteMatch = utils.debounce((field, inputData, idType, fetchMatchedData) => {
  fetchMatchedData({
    field,
    inputData,
    idType,
  });
}, 300);

class FilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusOption: ['Success', 'UnSuccessful'],
      fromDate: '',
      toDate: '',
      serviceNowTicket: '',
      requestorId: '',
      status: '',
      error: false,
      isDisabled: true,
      isDDvisible: '',
    };
    this.getDropDownSelectOptions = this.getDropDownSelectOptions.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.applySelectedValues = this.applySelectedValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.enableButtons = this.enableButtons.bind(this);
  }

  getDropDownSelectOptions = options => (options
    ? options.map(option => <MenuItem styleName="item" value={option}>{option}</MenuItem>)
    : null);

  handleChange = (e, value, id) => {
    const { idType, fetchMatchedData } = this.props;
    const stateVar = (id === 'serviceNowTicket' || id === 'requestorId') ? id : e.target.id;
    this.setState({ [stateVar || id]: value }, () => {
      if (['serviceNowTicket', 'requestorId'].includes(stateVar)) {
        this.setState({ isDDvisible: id });
        triggerAutoCompleteMatch(stateVar, value, idType, fetchMatchedData);
      }
      if (!['serviceNowTicket', 'requestorId'].includes(stateVar)) {
        this.enableButtons();
      }
    });
  }

  handleBlur = (id) => {
    const { serviceTickets, requestors } = this.props;
    const { [id]: data } = this.state;
    if ((id === 'serviceNowTicket' && !serviceTickets.includes(data)) || (id === 'requestorId' && !requestors.includes(data))) {
      this.setState({ [id]: '', isDDvisible: '' }, () => this.enableButtons());
    }
  }

  getInputValue = (value, name) => {
    this.setState({
      [name]: value,
      isDDvisible: '',
    }, () => this.enableButtons());
  }


  enableButtons = () => {
    const {
      fromDate, toDate, serviceNowTicket, requestorId, status,
    } = this.state;
    let disable = true;
    let errorRsn = '';
    if (!R.isEmpty(serviceNowTicket) || !R.isEmpty(requestorId) || !R.isEmpty(status)) {
      disable = false;
    }
    if (!R.isEmpty(fromDate) && !R.isEmpty(toDate)) {
      const from = moment(fromDate);
      const to = moment(toDate);
      if (!to.isSameOrAfter(from)) {
        errorRsn = 'To Date to be equal or higher than From date';
      }
      this.setState({ error: errorRsn });
      disable = errorRsn !== '';
    } else if ((R.isEmpty(fromDate) && !R.isEmpty(toDate))
       || (!R.isEmpty(fromDate) && R.isEmpty(toDate))) {
      disable = true;
      this.setState({ error: '' });
    }
    this.setState({ isDisabled: disable });
  };


  applySelectedValues = () => {
    const {
      fromDate, toDate, serviceNowTicket,
      requestorId, status: actionStatus,
    } = this.state;
    const { onChangeHandler } = this.props;

    const payload = {
      fromDate,
      toDate,
      serviceNowTicket,
      requestorId,
      // eslint-disable-next-line no-nested-ternary
      status: actionStatus === 'Success' ? 1 : (actionStatus === '' ? '' : 0),
    };
    this.setState({ isDDvisible: '' });
    onChangeHandler(payload);
  }

  resetHandler() {
    const { onReset } = this.props;
    this.setState({
      fromDate: '',
      toDate: '',
      serviceNowTicket: '',
      status: '',
      requestorId: '',
      error: false,
      isDisabled: true,
      isDDvisible: '',
    }, () => {
      onReset();
    });
  }

  render() {
    const {
      serviceNowTicket, requestorId,
      statusOption, fromDate,
      toDate, isDisabled,
      status, error, isDDvisible,
    } = this.state;
    const { serviceTickets, requestors } = this.props;
    return (
      <>
        <Grid
          container
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item styleName="resetSection">
            <div styleName="heading">
              <img alt="filter icon" src="/static/img/filter.png" />
              <h3>
                FILTER
              </h3>
            </div>
          </Grid>
          <Grid item styleName="root">
            <h3 styleName="actionName">Date Range</h3>
            <p styleName="actionSubhead">From</p>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div styleName="dateSection">
                <input
                  id="fromDate"
                  onChange={e => this.handleChange(e, e.target.value, 'fromDate')}
                  styleName="inputTextElem"
                  type="date"
                  value={moment(fromDate).format('YYYY-MM-DD')}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item styleName="root">
            <p styleName="actionSubhead">To</p>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div styleName="dateSection">
                <input
                  id="toDate"
                  onChange={e => this.handleChange(e, e.target.value, 'toDate')}
                  styleName="inputTextElem"
                  type="date"
                  value={moment(toDate).format('YYYY-MM-DD')}
                />
              </div>
            </MuiPickersUtilsProvider>
            {error && (
            <p styleName="error">
              {error}
            </p>
            )}
          </Grid>
          <Grid item styleName="root">
            <h3 styleName="actionName">ServiceNow Ticket</h3>
            <InputSearchFilter
              changeFilterHandler={(e, value) => this.handleChange(e, value, 'serviceNowTicket')}
              getInputValue={this.getInputValue}
              isDDvisible={isDDvisible}
              list={serviceTickets}
              name="serviceNowTicket"
              onBlur={() => this.handleBlur('serviceNowTicket')}
              value={serviceNowTicket}
            />
          </Grid>

          <Grid item styleName="root">
            <h3 styleName="actionName">Requestor ID</h3>
            <InputSearchFilter
              changeFilterHandler={(e, value) => this.handleChange(e, value, 'requestorId')}
              getInputValue={this.getInputValue}
              isDDvisible={isDDvisible}
              list={requestors}
              name="requestorId"
              onBlur={() => this.handleBlur('requestorId')}
              value={requestorId}
            />
          </Grid>
          <Grid item styleName="root">
            <h3 styleName="actionName">Status</h3>
            <Select
              displayEmpty
              onChange={e => this.handleChange(e, e.target.value, 'status')}
              value={status}
            >
              <MenuItem styleName="item" value="">Select</MenuItem>
              {this.getDropDownSelectOptions(statusOption)}
            </Select>
          </Grid>
          <Grid item styleName="actionButtons">
            <Button disabled={isDisabled} onClick={this.resetHandler}>RESET</Button>
            <Button color="primary" disabled={isDisabled} onClick={this.applySelectedValues} variant="contained">APPLY</Button>
          </Grid>

        </Grid>
      </>

    );
  }
}


FilterSection.defaultProps = {
  // activeTab: 'All',
  onChangeHandler: () => {},
  requestors: [],
  serviceTickets: [],
  onReset: () => {},
  idType: '',
};


FilterSection.propTypes = {
  // activeTab: PropTypes.string,
  fetchMatchedData: PropTypes.func.isRequired,
  idType: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onReset: PropTypes.func,
  requestors: PropTypes.arrayOf(PropTypes.string),
  serviceTickets: PropTypes.arrayOf(PropTypes.string),
};


export default FilterSection;
