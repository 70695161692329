import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import './FinancialSection.css';
import Loader from 'components/Loader';
import InputField from 'components/InputField';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  IconButton,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  // InputLabel,
}
  from '@material-ui/core';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import { operations, selectors as evalSelectors } from 'ducks/eval';
import { FINANCIALS_EDIT_USER_GROUPS } from '../../../constants/eval';

// const mockDataSelect = {
//   incomeID: ['income 1', 'income 2', 'income 3'],
//   capModID: ['capmod 1', 'capmod 2', 'capmod 3'],
//   expenseID: ['expense 1', 'expense 2', 'expense 3'],
//   pvmID: ['pvm 1', 'pvm 2', 'pvm 3'],
// };

const FinancialSection = (props) => {
  const {
    isEvalLandingPage, isEditable, userGroupList, fetchFinancialDropdowns,
    financialDetails, pvmId, dropdowns, saveFinancialData, financialDetailLoader,
    isEvalActive, isFinancialDropdownLoading,
  } = props;

  const initialState = {
    incomeId: '',
    expenseId: '',
    capModId: '',
    pvmId: '',
    updateDetails: false,
  };

  const [financialScreenData, setFinancialScreenData] = useState(
    {
      incomeId: financialDetails.incomeId,
      expenseId: financialDetails.expenseId,
      capModId: financialDetails.capModRqstId,
      pvmId,
    },
  );
  const [evalFormData, setEvalFormData] = useState(initialState);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isFinancialsEditIconDisabled, setFinancialsEditIconDisabled] = useState(true);

  useEffect(() => {
    if (FINANCIALS_EDIT_USER_GROUPS.some(group => userGroupList.includes(group))) {
      setFinancialsEditIconDisabled(false);
    }
  }, [userGroupList]);

  useEffect(() => {
    if (isEvalLandingPage) {
      const payload = {
        incomeId: financialDetails.incomeId,
        expenseId: financialDetails.expenseId,
        capModId: financialDetails.capModRqstId,
        pvmId,
        updateDetails: false,
        isEvalActive,
      };
      saveFinancialData(payload);
    }
  }, [financialDetails, pvmId]);

  const setValueFromState = () => {
    if (financialDetails) {
      setEvalFormData(prev => ({
        ...prev,
        incomeId: financialDetails.incomeId,
        expenseId: financialDetails.expenseId,
        capModId: financialDetails.capModRqstId,
        pvmId,
      }));
    }
  };

  useEffect(() => {
    setValueFromState();
  }, [financialDetails, pvmId]);


  const changeHandler = (e, key) => {
    const { value } = e.target;
    if (isEvalLandingPage) {
      setEvalFormData(prev => ({ ...prev, [key]: value }));
    }
  };

  const saveData = () => {
    setAlert(false);
    setFinancialScreenData(evalFormData);
    saveFinancialData({ ...evalFormData, updateDetails: true });
    setVisible(false);
  };

  const handlePopup = () => {
    const currentData = {
      incomeId: financialScreenData.incomeId,
      expenseId: financialScreenData.expenseId,
      capModId: financialScreenData.capModId,
      pvmId,
    };
    if (currentData.incomeId === evalFormData.incomeId
      && currentData.expenseId === evalFormData.expenseId
      && currentData.capModId === evalFormData.capModId) {
      setAlert(false);
      setVisible(false);
    } else if (alert) {
      setFinancialScreenData(currentData);
      setEvalFormData(currentData);
      setAlert(false);
      setVisible(false);
    } else {
      setAlert(true);
    }
  };

  const closeConfirmation = () => {
    setAlert(false);
  };

  const editClickHandler = () => {
    if (isEditable) {
      setVisible(!visible);
      fetchFinancialDropdowns();
    }
  };
  return (
    <Grid container styleName="container">
      <Grid styleName={isEvalLandingPage ? 'grey-bg' : 'caseDetails-bg grey-bg'}>
        <div styleName="table-header">
          <h2>
Financials
            {isEvalLandingPage && (
            <IconButton aria-label="Edit" component="span" disabled={isFinancialsEditIconDisabled} styleName="icon-btn">
              <EditIcon
                fontSize="small"
                onClick={editClickHandler}
                style={{ cursor: isEditable ? 'pointer' : '' }}
                styleName="edit-icon"
              />
            </IconButton>
            )}
          </h2>
        </div>
        { financialDetailLoader ? <Loader message="Please wait while updating financial details..." size={60} /> : (
          <Grid>
            <InputField isDisabled label="Income ID" value={financialScreenData.incomeId} />
            <InputField isDisabled label="CapMod ID" value={financialScreenData.capModId} />
            <InputField isDisabled label="Expense ID" value={financialScreenData.expenseId} />
            {isEvalLandingPage && <InputField isDisabled label="PVM ID" value={pvmId} />}

          </Grid>
        )}
        {visible && (
        <div styleName="backdrop">
          <div styleName={`modal ${alert ? 'centerItem' : ''}`} testId="modal">
            {!alert
              && (
              <>
                <div styleName="header">
                  <h2>Edit Case Details</h2>
                  <IconButton aria-label="close" onClick={() => handlePopup()} testId="closeButton">
                    <CloseIcon />
                  </IconButton>
                </div>
                { isFinancialDropdownLoading ? <Loader message="Please wait while fetching financial dropdown details..." size={40} /> : (
                  <div styleName="selection">
                    <div styleName="item">
                      <h4>Income Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="incomeId"
                          input={<OutlinedInput name="incomeId" />}
                          onChange={e => changeHandler(e, 'incomeId')}
                          styleName="dropDown-select"
                          value={evalFormData.incomeId}
                        >
                          {dropdowns.financialId && dropdowns.financialId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div styleName="item">

                      <h4>Expense Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="expenseId"
                          input={<OutlinedInput name="expenseId" />}
                          onChange={e => changeHandler(e, 'expenseId')}
                          styleName="dropDown-select"
                          value={evalFormData.expenseId}
                        >
                          {dropdowns.borrExpenseId && dropdowns.borrExpenseId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div styleName="item">
                      <h4>Cap Mod Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="capModId"
                          input={<OutlinedInput name="capModId" />}
                          onChange={e => changeHandler(e, 'capModId')}
                          styleName="dropDown-select"
                          value={evalFormData.capModId}
                        >
                          {dropdowns.capModId && dropdowns.capModId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
                {' '}

              </>
              ) }
            {alert && <h2 testId="alert"> Are you sure you want to clear the unsaved changes ? </h2>}
            { !isFinancialDropdownLoading
              && (
              <div styleName="buttons">
                <Button
                  color="primary"
                  onClick={() => (!alert ? saveData() : handlePopup())}
                  styleName="saveBtn btn"
                  testId="btn1"
                  variant="contained"
                >
                  { !alert ? 'Save' : 'Yes' }
                </Button>
                {alert && (
                <Button onClick={() => closeConfirmation()} styleName="btn cancelBtn" testId="btn2">No</Button>
                )}

              </div>
              )}
          </div>
        </div>
        ) }

      </Grid>
    </Grid>

  );
};
FinancialSection.defaultProps = {
  financialDetails: {
    expenseId: null,
    incomeId: null,
    capModRqstId: null,
  },
  isEditable: true,
  isEvalActive: false,
  isEvalLandingPage: false,
  userGroupList: [],
  fetchFinancialDropdowns: () => {},
  dropdowns: {
    financialId: [],
    borrExpenseId: [],
    capModId: [],
  },
  saveFinancialData: () => {},
  pvmId: null,
  financialDetailLoader: false,
  isFinancialDropdownLoading: false,
};

FinancialSection.propTypes = {
  dropdowns: PropTypes.shape({
    borrExpenseId: PropTypes.arrayOf(PropTypes.number),
    capModId: PropTypes.arrayOf(PropTypes.number),
    financialId: PropTypes.arrayOf(PropTypes.number),
  }),
  fetchFinancialDropdowns: PropTypes.func,
  financialDetailLoader: PropTypes.bool,
  financialDetails: PropTypes.shape({
    capModRqstId: PropTypes.number,
    expenseId: PropTypes.number,
    incomeId: PropTypes.number,
  }),
  isEditable: PropTypes.bool,
  isEvalActive: PropTypes.bool,
  isEvalLandingPage: PropTypes.bool,
  isFinancialDropdownLoading: PropTypes.bool,
  pvmId: PropTypes.number,
  saveFinancialData: PropTypes.func,
  userGroupList: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  userGroupList: loginSelectors.getGroupList(state),
  dropdowns: evalSelectors.financialDropdowns(state),
  financialDetails: evalSelectors.getFinancialData(state),
  financialDetailLoader: evalSelectors.financialDetailLoader(state),
  isFinancialDropdownLoading: evalSelectors.isFinancialDropdownLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFinancialDropdowns: operations.fetchFinancialDropdownOperation(dispatch),
  saveFinancialData: operations.saveFinancialDataOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialSection);

const TestHooks = {
  FinancialSection,
};


export { TestHooks };
