import * as R from 'ramda';

const getBoardingTemplateHistory = state => R.pathOr({}, ['boardingTemplate', 'boardingTemplateHistory'], state);

const getSortCriteria = state => R.pathOr({}, ['boardingTemplate', 'sortOrder'], state);

const getOnBoardData = state => R.pathOr([], ['boardingTemplate', 'onBoardData'], state);

const getExceptionData = state => R.pathOr([], ['boardingTemplate', 'exceptionData'], state);

const getProcesseFiledData = state => R.pathOr(null, ['boardingTemplate', 'excelParsedData'], state);

const getCaseTypeOptions = state => R.pathOr([], ['boardingTemplate', 'caseTypes'], state);

const getBoardingPhases = state => R.pathOr([], ['boardingTemplate', 'boardingPhases'], state);

const selectors = {
  getBoardingTemplateHistory,
  getSortCriteria,
  getOnBoardData,
  getExceptionData,
  getProcesseFiledData,
  getCaseTypeOptions,
  getBoardingPhases,
};

export default selectors;
