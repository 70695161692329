import {
  processFileAction,
  downloadFileAction,
  NPVHistoryAction,
  uploadFileAction,
  resetUploadStatus,
  NPVResultAction,
  setRequestIdAction,
  npvInvestorReportDataAction,
  npvRequestFileDataAction,
  npvCancelAction,
} from './actions';


const downloadFile = dispatch => (payload) => {
  dispatch(downloadFileAction(payload));
};

const onProcessFile = dispatch => (payload) => {
  dispatch(processFileAction(payload));
};

const npvHistoryOperation = dispatch => () => {
  dispatch(NPVHistoryAction());
};

const setNpvResultOperation = dispatch => () => {
  dispatch(NPVResultAction());
};

const onUploadFile = dispatch => () => {
  dispatch(uploadFileAction());
};

const onResetUploadStatus = dispatch => () => {
  dispatch(resetUploadStatus());
};

const setRequestIdOperation = dispatch => (payload) => {
  dispatch(setRequestIdAction(payload));
};

const npvInvestorReportData = dispatch => (payload) => {
  dispatch(npvInvestorReportDataAction(payload));
};

const npvRequestFileData = dispatch => (payload) => {
  dispatch(npvRequestFileDataAction(payload));
};

const npvCancel = dispatch => (payload) => {
  dispatch(npvCancelAction(payload));
};

const operations = {
  onProcessFile,
  downloadFile,
  npvHistoryOperation,
  onUploadFile,
  onResetUploadStatus,
  setNpvResultOperation,
  setRequestIdOperation,
  npvInvestorReportData,
  npvRequestFileData,
  npvCancel,
};

export default operations;
