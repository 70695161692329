import React, { useState, useEffect } from 'react';
import {
  Button, RadioGroup, Radio, FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import EvalTable from '../../../components/Eval/EvalTable/EvalTable';
import SweetAlertBox from '../../../components/SweetAlertBox';
import Popup from '../../../components/Popup';
import * as constants from '../../../constants/eval';
import { operations, selectors } from '../../../state/ducks/eval';
import './CertainMortgageLoanChecklist.css';

const CertainMortgageLoanChecklist = (props) => {
  const {
    certainMorgageLoanChecklist, fetchCertainMorgageLoanChecklist,
    inProgress, saveCertainMortgageLoanChecklist, saveCMLCStatus,
    evalScreenIcon, caseHeaderInfo,
  } = props;

  const [compData, setCompData] = useState({
    ruleStatus: false,
    errorDisplay: false,
    isSaveOk: false,
    openClearPopup: false,
  });
  const [tableData, setTableData] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isViewOnly, setViewOnly] = useState(false);
  const [lastQuestion, setLastQuestion] = useState([]);
  const [isCaseEditable, setIsCaseEditable] = useState(false);

  const newObj = arr => R.slice(0, compData.ruleStatus
    ? certainMorgageLoanChecklist.length : 9, arr);

  useEffect(() => {
    fetchCertainMorgageLoanChecklist();
  }, []);

  useEffect(() => {
    if (caseHeaderInfo && caseHeaderInfo.status === constants.OPEN
      && evalScreenIcon === constants.EVAL_WIDGET) {
      setIsCaseEditable(true);
    }
  }, [caseHeaderInfo]);

  useEffect(() => {
    setTableData(newObj(certainMorgageLoanChecklist));
    const secondSetQuestion = R.slice(9, certainMorgageLoanChecklist.length,
      certainMorgageLoanChecklist);
    setLastQuestion(secondSetQuestion);
    if (!R.isEmpty(certainMorgageLoanChecklist)) {
      setIsDataAvailable(certainMorgageLoanChecklist[0].responseYesNoNA);
      setViewOnly(!R.isNil(isDataAvailable));
    }
  }, [inProgress, certainMorgageLoanChecklist]);


  useEffect(() => {
    if (compData.ruleStatus) {
      const fullData = [...tableData, ...lastQuestion];
      setTableData(fullData);
    } else {
      setTableData(newObj(tableData));
    }
  }, [compData.ruleStatus]);

  const handleConfirm = () => {
    setCompData({
      ...compData,
      ruleStatus: false,
      openClearPopup: false,
    });
    const updatedChecklist = certainMorgageLoanChecklist.map(item => (
      { ...item, responseYesNoNA: null }));
    const secondSetQuestion = R.slice(9, certainMorgageLoanChecklist.length, updatedChecklist);
    setLastQuestion(secondSetQuestion);
    setTableData(newObj(updatedChecklist));
  };

  const handleClose = () => {
    setCompData({
      ...compData,
      isSaveOk: false,
      openClearPopup: false,
    });
  };

  const handleSave = () => {
    saveCertainMortgageLoanChecklist(tableData, compData.errorDisplay);
    setCompData({
      ...compData,
      isSaveOk: true,
    });
    setButtonDisabled(true);
    setViewOnly(true);
  };

  const handleClear = () => {
    setCompData({
      ...compData,
      openClearPopup: true,
    });
  };

  const handleChange = (event, id) => {
    setButtonDisabled(false);
    const index = tableData.findIndex(data => data.qstnId === id);
    const { name, value } = event.target;
    const data = [...tableData];
    data[index][name] = +value;
    setTableData([...data]);

    const questionIds = R.map(x => x.qstnId, lastQuestion);
    const validateFunc = (arr, val) => {
      if (arr.responseYesNoNA !== null && !questionIds.includes(arr.qstnId)) {
        if (arr.qstnId === 3 || arr.qstnId === 6) {
          return (arr.responseYesNoNA !== 1);
        }
        return (arr.responseYesNoNA !== 0);
      }
      return val;
    };
    const validateQuestionsRule = data.map(arr => validateFunc(arr, false))
      .slice(0, 9).every(Boolean);
    const validateErrorMsgRule1 = data.map(arr => validateFunc(arr, true)).every(Boolean);
    const validateErrorMsgRule2 = data.length > 9
      ? !data.slice(9, certainMorgageLoanChecklist.length)
        .every(e => e.responseYesNoNA === 1) : true;
    setCompData({
      ...compData,
      ruleStatus: validateQuestionsRule,
      errorDisplay: !(validateErrorMsgRule1 && validateErrorMsgRule2),
    });
    if (questionIds.includes(id) && value === '0') {
      const qidIndex = id;
      const markDetermined = R.slice(qidIndex, Infinity, tableData)
        .map(arr => ({ ...arr, responseYesNoNA: 2 }));
      // setLastQuestion(markDetermined);
      setTableData(arr => ([
        ...arr.slice(0, qidIndex),
        ...markDetermined,
      ]));
    }
  };
  const CMLC_TABLE_COLUMNS = [
    {
      name: 'question',
      label: 'Questions',
      align: 'left',
    },
    {
      name: 'responseYesNoNA',
      label: 'Answer',
      align: 'left',
      cellFormat: (name, value) => (
        <RadioGroup defaultValue="top" name="responseYesNoNA" onChange={event => handleChange(event, value.qstnId)} row value={value.responseYesNoNA}>
          <FormControlLabel control={<Radio color="primary" />} disabled={!isCaseEditable || isViewOnly} label="Yes" value={0} />
          <FormControlLabel control={<Radio color="primary" />} disabled={!isCaseEditable || isViewOnly} label="No" value={1} />
          <FormControlLabel control={<Radio color="primary" />} disabled={!isCaseEditable || isViewOnly} label="Undetermined" value={2} />
        </RadioGroup>
      ),
    }, {
      name: 'instruction',
      label: 'Instructions',
      align: 'left',
    },
  ];

  return (
    <div style={{ maxHeight: '60vh', overflow: 'auto' }} styleName="grey-bg CMLC-container">
      <div styleName="btn-container">
        <Button color="primary" disabled={buttonDisabled} onClick={handleClear} style={{ borderWidth: '2px' }} variant="text">
            CLEAR
        </Button>
        <Button color="primary" disabled={buttonDisabled} onClick={handleSave} variant="text">
            SAVE
        </Button>
        <Popup
          cancelButtonText="NO"
          confirmButtonText="YES"
          message="Are you sure you want to clear the unsaved changes?"
          onCancel={handleClose}
          onConfirm={handleConfirm}
          show={compData.openClearPopup}
          showCancelButton
          showConfirmButton
        />
      </div>
      {inProgress
        ? <Loader size={60} />
        : (
          <>
            {compData.errorDisplay && (
            <div styleName="error-msg"><p>Loan does not meet Certain Mortgage Loan criteria, please reject case</p></div>
            )}
            {!R.isEmpty(tableData) ? (
              <EvalTable
                columns={CMLC_TABLE_COLUMNS}
                data={tableData || []}
              />
            ) : (
              <h4 styleName="no-data">Questions Not Available</h4>
            )
          }
          </>
        )}
      <SweetAlertBox
        message={inProgress ? 'Please wait...' : saveCMLCStatus.statusMessage}
        onConfirm={handleClose}
        show={compData.isSaveOk}
        type={inProgress ? 'Info' : saveCMLCStatus.type}
      />
    </div>
  );
};

CertainMortgageLoanChecklist.defaultProps = {
  fetchCertainMorgageLoanChecklist: () => { },
  inProgress: true,
  saveCertainMortgageLoanChecklist: () => { },
  evalScreenIcon: '',
  status: '',
  caseHeaderInfo: {
    caseType: '',
    status: '',
  },

};

CertainMortgageLoanChecklist.propTypes = {
  caseHeaderInfo: PropTypes.shape(
    {
      caseType: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    },
  ),
  certainMorgageLoanChecklist: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  evalScreenIcon: PropTypes.string,
  fetchCertainMorgageLoanChecklist: PropTypes.func,
  inProgress: PropTypes.bool,
  saveCertainMortgageLoanChecklist: PropTypes.func,
  saveCMLCStatus: PropTypes.shape().isRequired,
  status: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  fetchCertainMorgageLoanChecklist: operations.fetchCertainMorgageLoanChecklistOperation(dispatch),
  saveCertainMortgageLoanChecklist: operations.saveCertainMortgageLoanChecklistOperation(dispatch),
});

const mapStateToProps = state => ({
  certainMorgageLoanChecklist: selectors.certainMorgageLoanChecklist(state),
  inProgress: selectors.inProgress(state),
  saveCMLCStatus: selectors.saveCMLCStatus(state),
  evalScreenIcon: selectors.getEvalScreenIcon(state),
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CertainMortgageLoanChecklist);
