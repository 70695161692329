import {
  select,
  takeEvery,
  all,
  call,
  put,
} from 'redux-saga/effects';

import extName from 'ext-name';
import * as XLSX from 'xlsx';
import * as Api from 'lib/Api';
import { selectors } from 'ducks/netpresentvalue';
import * as R from 'ramda';
import { ERROR, EXCEL_FORMATS, SUCCESS } from 'constants/netPresentValue';
import { checkRequestId } from 'lib/NPV/customFunctions';
import processExcel from '../../../lib/excelParser';
import { selectors as dashboardSelectors } from '../dashboard';
import { selectors as stagerSelectors } from '../stager';
import { selectors as loginSelectors } from '../login';
import {
  DOWNLOAD_FILE,
  SET_DOWNLOAD_RESULT,
  PROCESS_FILE,
  UPLOAD_FILE,
  GET_PROCESSED_DATA,
  SET_RESULT_OPERATION,
  GET_NPV_HISTORY_DATA,
  SET_NPV_HISTORY_DATA,
  GET_NPV_RESULT_DATA,
  SET_NPV_RESULT_DATA,
  SET_NPV_RQST_STS,
  SET_NPV_MAN_FLG,
  GET_NPV_RQST_FILE_DATA,
  SET_NPV_RQST_FILE_DATA,
  GET_NPV_INVESTOR_REPORT_DATA,
  SET_NPV_INVESTOR_REPORT_DATA,
  SET_CASE_ID,
  CANCEL_NPV,
  CANCEL_NPV_STATUS,
  SET_NPV_REQUEST_ID,
} from './types';
import { SHOW_LOADER, HIDE_LOADER } from '../dashboard/types';
import { GET_DASHBOARD_DATA_SAGA, GET_DASHBOARD_COUNTS_SAGA } from '../stager/types';

import DashboardModel from '../../../models/Dashboard';

const {
  Messages:
  {
    LEVEL_FAILED,
    MSG_FILE_DOWNLOAD_FAILURE,
  },
} = DashboardModel;


const onDownloadFile = function* onDownloadFile(action) {
  const fileData = action.payload ? action.payload : action;
  try {
    const ws = XLSX.utils.json_to_sheet(fileData.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileData.fileName);
    XLSX.writeFile(wb, fileData.fileName);

    yield put({
      type: SET_DOWNLOAD_RESULT,
      payload: {
        status: 'Excel File Downloaded Successfully',
        level: 'Success',
      },
    });
  } catch (e) {
    yield put({
      type: SET_DOWNLOAD_RESULT,
      payload: {
        status: MSG_FILE_DOWNLOAD_FAILURE,
        level: LEVEL_FAILED,
      },
    });
  }
};


const onUploadingFile = function* onUploadingFile(isInvalidRequestId) {
  const excelFileData = yield select(selectors.getProcesseFiledData);
  const user = yield select(loginSelectors.getUserPrincipalName);
  const ext = R.compose(
    R.prop('ext'),
    R.head,
  )(excelFileData.fileExtension);

  try {
    if (EXCEL_FORMATS.includes(ext) && excelFileData.data && excelFileData.data[0]) {
      const npvResponse = excelFileData.data[0];
      const dataArr = [];
      npvResponse.manualFlag = 1;
      npvResponse.CRE_DT = new Date();
      npvResponse.CRE_BY = user;
      npvResponse.actualRequestId = excelFileData.actualRequestId;
      dataArr.push(npvResponse);
      const res = yield call(Api.callPost, '/api/stager/stager/npv/saveResponse', dataArr);
      if (isInvalidRequestId !== true) {
        if (res.isSaved) {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              status: 'success',
            },
          });
        } else {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              status: 'error',
            },
          });
        }
      }
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'error',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'error',
      },
    });
  }
};

const OnProcessFile = function* OnProcessFile(action) {
  const file = action.payload;
  const filePayload = {};

  if (file) {
    const data = yield call(processExcel, file, true);
    const fileExtension = extName(file.name);

    filePayload.data = data;
    filePayload.fileExtension = fileExtension;
    const requestId = yield select(selectors.getNPVRequestId);
    if (data) {
      filePayload.actualRequestId = requestId;
      const validRequestId = checkRequestId(data[0], requestId);
      yield put({
        type: GET_PROCESSED_DATA,
        payload: filePayload,
      });
      if (!validRequestId) {
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            status: 'error',
            reason: 'validation',
          },
        });
        yield call(onUploadingFile, true);
      }
    }
  }
};


const getNPVResult = function* getNPVResult() {
  try {
    const requestId = yield select(selectors.getNPVRequestId);
    const response = yield call(Api.callGet, `/api/data-aggregator/npv/getNPVDetails/${requestId}`);

    yield put({
      type: SET_NPV_RESULT_DATA,
      payload: response,
    });
    yield put({
      type: SET_NPV_RQST_STS,
      payload: response,
    });
    yield put({
      type: SET_NPV_MAN_FLG,
      payload: response,
    });
    yield put({
      type: SET_CASE_ID,
      payload: response,
    });
    if (!response) {
      yield put({
        type: SET_NPV_RESULT_DATA,
        payload: {},
      });
    }
  } catch (e) {
    yield put({
      type: SET_NPV_RESULT_DATA,
      payload: {},
    });
  }
};

const getNPVHistory = function* getNPVHistory() {
  try {
    const loanNumber = yield select(dashboardSelectors.loanNumber);
    const response = yield call(Api.callGet, `/api/dataservice/npv/getNPVLoanHistory/${loanNumber}`);
    yield put({
      type: SET_NPV_HISTORY_DATA,
      payload: response,
    });
    if (!response) {
      yield put({
        type: SET_NPV_HISTORY_DATA,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: SET_NPV_HISTORY_DATA,
      payload: [],
    });
  }
};

const getInvestorReportData = function* getInvestorReportData(action) {
  try {
    const response = yield call(Api.callGet, `/api/cmodinvestor/NPVInvestorReport/${action.payload.caseId}/${action.payload.requestId}`);
    if (!response) {
      yield put({
        type: SET_NPV_INVESTOR_REPORT_DATA,
        payload: [],
      });
    } else {
      yield put({
        type: SET_NPV_INVESTOR_REPORT_DATA,
        payload: response,
      });
      const dataArr = [];
      dataArr.push(response);
      const data = {
        data: dataArr,
        fileName: 'NPV_Investor_Report.xlsx',
      };
      yield* onDownloadFile(data);
    }
  } catch (e) {
    yield put({
      type: SET_NPV_INVESTOR_REPORT_DATA,
      payload: [],
    });
  }
};

const getNPVRequestFileData = function* getNPVRequestFileData(action) {
  try {
    const requestId = yield select(selectors.getNPVRequestId);
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callPost, '/api/stager/stager/npv/autoCancelled', action.payload);
    if (!response) {
      yield put({
        type: SET_NPV_RQST_FILE_DATA,
        payload: [],
      });
    } else {
      yield put({
        type: SET_NPV_RQST_FILE_DATA,
        payload: response,
      });
      yield put({
        type: SET_NPV_REQUEST_ID,
        payload: R.propOr(requestId, 'RequestId', response),
      });
      const dataArr = [];
      dataArr.push(response);
      const data = {
        data: dataArr,
        fileName: 'NPV_Request_File.xlsx',
      };
      yield* onDownloadFile(data);
    }
  } catch (e) {
    yield put({
      type: SET_NPV_RQST_FILE_DATA,
      payload: [],
    });
  }
  yield put({ type: HIDE_LOADER });
};

const cancelNPV = function* cancelNPV(action) {
  try {
    const npvIds = action.payload;
    const user = yield select(loginSelectors.getUserPrincipalName);

    const npv = [];
    npvIds.map((val) => {
      const result = { requestId: val, userId: user };
      npv.push(result);
      return result;
    });
    const stagerStartEndDate = yield select(stagerSelectors.getStagerStartEndDate);
    const fromDateMoment = R.propOr({}, 'fromDate', stagerStartEndDate);
    const toDateMoment = R.propOr({}, 'toDate', stagerStartEndDate);
    const fromDate = new Date(fromDateMoment).toISOString();
    const toDate = new Date(toDateMoment).toISOString();
    const stagerType = yield select(stagerSelectors.getStagerValue);
    const activeSearchTerm = yield select(stagerSelectors.getActiveSearchTerm);
    const npvPayload = {
      npv, stagerType, searchTerm: activeSearchTerm, fromDate, toDate,
    };

    const response = yield call(Api.callPost, '/api/stager/stager/npv/cancelNPV', npvPayload);
    if (response && response.length > 0) {
      const tableData = yield select(stagerSelectors.getTableData);
      const pageSize = tableData && tableData.pageSize;
      const sortOrder = tableData && tableData.sortOrder;
      const orderby = (sortOrder && R.propOr('', 'id', R.head(sortOrder))) || 'id';
      const orderType = (sortOrder && R.propOr(false, 'desc', R.head(sortOrder)) ? 'desc' : 'asc') || 'desc';
      const payload = {
        activeSearchTerm,
        top: pageSize || 100,
        page: 1,
        orderby,
        orderType,
      };
      yield put({
        type: GET_DASHBOARD_COUNTS_SAGA,
      });
      yield put({
        type: GET_DASHBOARD_DATA_SAGA,
        payload,
      });
      yield put({
        type: CANCEL_NPV_STATUS,
        payload: SUCCESS,
      });
    }
  } catch (e) {
    yield put({
      type: CANCEL_NPV_STATUS,
      payload: ERROR,
    });
  }
};

function* watchOnDownloadFile() {
  yield takeEvery(DOWNLOAD_FILE, onDownloadFile);
}

function* watchOnProcessFile() {
  yield takeEvery(PROCESS_FILE, OnProcessFile);
}

function* watchOnUploadFile() {
  yield takeEvery(UPLOAD_FILE, onUploadingFile);
}

function* watchGetNPVHistory() {
  yield takeEvery(GET_NPV_HISTORY_DATA, getNPVHistory);
}

function* watchGetNPVInvestorReportData() {
  yield takeEvery(GET_NPV_INVESTOR_REPORT_DATA, getInvestorReportData);
}

function* watchGetNPVResult() {
  yield takeEvery(GET_NPV_RESULT_DATA, getNPVResult);
}

function* watchGetNPVRequestFileData() {
  yield takeEvery(GET_NPV_RQST_FILE_DATA, getNPVRequestFileData);
}

function* watchOnCancelNPV() {
  yield takeEvery(CANCEL_NPV, cancelNPV);
}

export const TestExports = {
  watchOnDownloadFile,
  watchOnProcessFile,
  watchOnUploadFile,
  watchGetNPVInvestorReportData,
  watchGetNPVRequestFileData,
};

export const combinedSaga = function* combinedSaga() {
  yield all([
    watchOnDownloadFile(),
    watchGetNPVHistory(),
    watchOnProcessFile(),
    watchOnUploadFile(),
    watchGetNPVResult(),
    watchGetNPVRequestFileData(),
    watchGetNPVInvestorReportData(),
    watchOnCancelNPV(),
  ]);
};
