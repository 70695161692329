
import React from 'react';
import { connect } from 'react-redux';
import { operations } from 'ducks/tombstone';
import { operations as npvOperations } from 'ducks/netpresentvalue';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import './NPVResultViewIcon.css';
import {
  IconButton,
} from '@material-ui/core/index';

class NPVResultViewIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCenterPaneView = this.handleCenterPaneView.bind(this);
  }

  handleCenterPaneView() {
    const {
      loanInfoComponent, setChecklistCenterPaneData, npvRequestId, setRequestIdData,
    } = this.props;
    setChecklistCenterPaneData(loanInfoComponent);
    setRequestIdData(npvRequestId);
  }

  render() {
    const { npvStatus } = this.props;
    const styleName = (npvStatus === 'NA') ? 'icon-view-disabled' : 'icon-view';
    return (
      <IconButton onClick={this.handleCenterPaneView} size="small" styleName={`${styleName}`}>
        <VisibilityIcon />
      </IconButton>
    );
  }
}

NPVResultViewIcon.propTypes = {
  loanInfoComponent: PropTypes.string.isRequired,
  npvRequestId: PropTypes.string.isRequired,
  npvStatus: PropTypes.string.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setRequestIdData: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  setRequestIdData: npvOperations.setRequestIdOperation(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(NPVResultViewIcon);
