import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import './InputFieldMultiline.css';

const InputFieldMultiline = ({
  name, value, handleChange, label, rows, type, errorMsg,
}) => {
  const handleKeyUp = (e) => {
    // eslint-disable-next-line no-useless-escape
    const allowedRegex = /^[a-zA-Z!@#~`\$%\^\&*\]\[\{\}\)\(\\|<>?/:;\\+=.'"_-]$/g;
    const ctrl = e.ctrlKey ? e.ctrlKey : ((e.keyCode === 17));
    const keyValidate = ctrl
      && (e.keyCode === 86 || e.keyCode === 65 || e.keyCode === 88 || e.keyCode === 67);
    if (e.key.match(allowedRegex)) {
      if (!keyValidate) {
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <div styleName="input-container">
        {label && <p styleName="label-style">{label}</p>}
        <TextField
          hiddenLabel
          inputProps={{ maxLength: 2000 }}
          margin="none"
          multiline
          name={name}
          onChange={handleChange}
          onKeyDown={handleKeyUp}
          rows={rows}
          size="small"
          type={type}
          value={value}
          variant="filled"
        />
      </div>
      {errorMsg && (
        <p styleName="id-error-msg">{errorMsg}</p>
      )}
    </>
  );
};


export default InputFieldMultiline;

InputFieldMultiline.defaultProps = {
  errorMsg: '',
  handleChange: () => { },
  label: '',
  name: '',
  rows: 2,
  value: '',
  type: '',
};

InputFieldMultiline.propTypes = {
  errorMsg: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
};
