import React, { useEffect, useState } from 'react';
import {
  Typography, Button, Divider, Radio, RadioGroup,
  FormControlLabel, Grid, DialogContent, Dialog, DialogTitle, IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations as evalOperations, selectors as evalSelector } from 'ducks/eval';
import './StreamlineModOffer.css';
import { STREAMLINE_MOD_OFFER_VALIDATION_MSG, STREAMLINE_MOD_ACCEPTANCE_TYPES } from 'constants/eval';
import * as R from 'ramda';
import CloseIcon from '@material-ui/icons/Close';

const StreamlineModOffer = (props) => {
  const [open, setOpen] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);
  const [disableAcceptance, setDisableAcceptance] = useState(false);
  const {
    saveAcceptanceFlag, evalDetails, isEditable, evalStatus, groups,
  } = props;

  useEffect(() => {
    if (evalDetails) {
      if (!R.isEmpty(evalDetails.acceptanceFlag)
        && STREAMLINE_MOD_ACCEPTANCE_TYPES.includes(evalDetails.acceptanceFlag)) {
        setValue(evalDetails.acceptanceFlag);
        setOnSave(true);
      } else {
        setValue(null);
      }
    }
  }, [evalDetails]);

  const onAcceptance = () => {
    setValue('V');
    if (evalStatus !== 'Approved') {
      setError(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setError(false);
  };

  const setRadioValue = (event) => {
    setValue(event.target.value);
  };

  const onClickSave = () => {
    setOpen(false);
    setOnSave(true);
    saveAcceptanceFlag(value);
  };

  const onCancel = () => {
    setOpen(false);
    setValue(null);
  };

  useEffect(() => {
    const requiredGroups = [
      'lossmitigation', 'lossmitigation-mgr', 'proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'feuw-beta', 'beuw', 'beuw-mgr', 'beuw-beta', 'docgen', 'docgen-mgr',
      'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr', 'rpsstager-mgr', 'rpsstager',
    ];
    if (groups && requiredGroups.some(group => groups.includes(group)) && isEditable) {
      setDisableAcceptance(false);
    } else setDisableAcceptance(true);
  }, [groups, isEditable]);

  return (
    <>
      <div styleName={`streamline-mod-offer ${onSave ? 'streamline-after-save' : 'streamline-before-save'}`}>
        <Typography styleName={onSave ? 'title2' : 'title'}>Streamline Mod Offer:</Typography>
        { onSave && STREAMLINE_MOD_ACCEPTANCE_TYPES.includes(value) ? <Typography styleName="title">{ value === 'V' ? '  Verbally Accepted' : '  Accepted In Writing' }</Typography> : (
          <Button
            color="primary"
            disabled={disableAcceptance}
            onClick={onAcceptance}
            styleName="submitBtn"
            variant="contained"
          >
            ACCEPTANCE
          </Button>
        )}
      </div>
      { error && (
        <Alert onClose={handleClose} severity="error" styleName="centered-alert" variant="outlined">
          {STREAMLINE_MOD_OFFER_VALIDATION_MSG}
        </Alert>
      )}
      { open
        && (
        <Dialog open={open}>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            style={{
              right: '0px',
              top: '0px',
              padding: '5px 5px 0px 0px',
              justifyContent: 'end',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle style={{ paddingTop: '0px', paddingRight: '50px' }}>
            <Grid>
              <Typography styleName="streamline-pop-title-name">
              Customer has accepted Streamline Mod Offer
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <RadioGroup defaultValue="V" name="streamline" onChange={setRadioValue}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Verbally"
                    labelPlacement="end"
                    styleName="label-style"
                    value="V"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="In Writing"
                    labelPlacement="end"
                    styleName="label-style"
                    value="W"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: '15px', marginTop: '10px' }} />
            <Grid container spacing={6} styleName="save-cancel">
              <Grid item xs={12}>
                <Button color="primary" onClick={onClickSave} styleName="save-btn" variant="contained">
                  <p styleName="saveText">SAVE</p>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        )}
    </>
  );
};

StreamlineModOffer.defaultProps = {
  isEditable: true,
  groups: [],
};

StreamlineModOffer.propTypes = {
  evalDetails: PropTypes.shape({
    acceptanceFlag: PropTypes.string.isRequired,
  }).isRequired,
  evalStatus: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
  isEditable: PropTypes.bool,
  saveAcceptanceFlag: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  evalDetails: evalSelector.evalDetails(state),
});

const mapDispatchToProps = dispatch => ({
  saveAcceptanceFlag: evalOperations.saveAcceptanceFlagOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamlineModOffer);
