const checkRequestId = (data, requestId) => {
  let validRequestId = false;
  if (requestId && data.RequestId && data.RequestId.toString() === requestId.toString()) {
    validRequestId = true;
  }
  return validRequestId;
};


module.exports = {
  checkRequestId,
};
