import {
  Select, MenuItem, FormControl,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DropdownStyles from './DropdownStyles';

const getDropDownSelectOptions = options => options.map(
  option => (
    <MenuItem
      disabled={option.disabled || false}
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ),
);

const Dropdown = ({
  displayLabel, label, options, handleSelect, value, override, ...props
}) => {
  const classes = DropdownStyles({ override });
  return (
    <FormControl className={classes.root} disabled={override} fullWidth>
      <Select
        className={classes.selectFieldDiv}
        id="dropdown-select"
        onChange={(e) => {
          handleSelect(label, e.target.value);
        }}
        value={value}
        {...props}
      >
        {getDropDownSelectOptions(options)}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  displayLabel: PropTypes.bool,
  handleSelect: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  override: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

Dropdown.defaultProps = {
  displayLabel: false,
  options: [],
  handleSelect: () => {},
  label: '',
  value: '',
};

export default Dropdown;
