const PULL_CREDIT_SCORE = 'PULLCREDSCR';
const FEUW_CHECKLIST = [PULL_CREDIT_SCORE];
const AV = [
  'AV_CHK_ACC',
  'AV_SA_MMA',
  'AV_IRA',
  'AV_STOCKS',
];
const ASSUMPTOR = 'Assumptor';
const CONTRIBUTOR = 'Contributor';

module.exports = {
  PULL_CREDIT_SCORE,
  FEUW_CHECKLIST,
  AV,
  ASSUMPTOR,
  CONTRIBUTOR,
};
