import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import {
  DEFAULT_ROWS_PER_PAGE,
  HISTORY_COLUMNS,
} from 'constants/busniessAdmin';
import * as R from 'ramda';
import BusinessAdminTable from 'components/ProductBusiness/BusinessAdminTable/BusinessAdminTable';
import Loader from 'components/Loader/Loader';
import { operations as businessAdminOperations, selectors as businessAdminSelectors } from 'ducks/admin-tools';


const History = (props) => {
  const {
    fetchHistoryData, businessAdminData, loading, activeTab, isFilterEnabled, applyBAFilter,
    filterPayload, investorName,
  } = props;

  useEffect(() => {
    fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE);
  }, [activeTab]);

  const fetchGridData = (page, pageSize) => {
    if (isFilterEnabled) {
      applyBAFilter(filterPayload, page, pageSize);
    } else {
      fetchHistoryData(page, pageSize);
    }
  };

  return (
    <>
      {loading ? <Loader message="Please Wait..." />
        : (
          <BusinessAdminTable
            businessAdminData={businessAdminData}
            columns={HISTORY_COLUMNS[R.contains(activeTab, Object.keys(HISTORY_COLUMNS)) ? activeTab : 'default']}
            handleChange={fetchGridData}
            hidePagination={activeTab === 'Update Interest Rate' && investorName === ''}
            size="small"
          />
        )}
    </>
  );
};

History.defaultProps = {
  activeTab: '',
  loading: false,
  fetchHistoryData: () => {},
  isFilterEnabled: false,
  applyBAFilter: () => {},
  investorName: '',
};

History.propTypes = {
  activeTab: PropTypes.string,
  applyBAFilter: PropTypes.func,
  businessAdminData: PropTypes.shape().isRequired,
  fetchHistoryData: PropTypes.func,
  filterPayload: PropTypes.shape().isRequired,
  investorName: PropTypes.string,
  isFilterEnabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  businessAdminData: businessAdminSelectors.getBusinessAdminGridData(state),
  loading: businessAdminSelectors.getLoader(state),
  isFilterEnabled: businessAdminSelectors.isFilterEnabled(state),
  filterPayload: businessAdminSelectors.filterPayload(state),
  investorName: businessAdminSelectors.getInvestorName(state),
});

const mapDispatchToProps = dispatch => ({
  fetchHistoryData: businessAdminOperations.fetchBusinessAdminHistoryData(dispatch),
  applyBAFilter: businessAdminOperations.applyBAFilter(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
