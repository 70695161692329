const mapRepo = {
  '/frontend-checklist': 'INCOME CALCULATION Agent Dashboard',
  '/backend-checklist': 'UNDERWRITNG Agent Dashboard',
  '/doc-processor': 'PROCESSING Agent Dashboard',
  '/doc-gen': 'DOC GENERATION Agent Dashboard',
  '/docs-in': 'DOCS IN Agent Dashboard',
  '/special-loan': 'SPECIAL LOANS Agent Dashboard',
  '/dg-vendor': 'DOCGEN VENDOR Agent Dashboard',
};

export default mapRepo;
