import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, IconButton, Grid, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import './Indexer.css';
import { operations as indexerOperations, selectors as indexerSelectors } from 'ducks/indexer';
import { operations as dashboardOperations, selectors as dashboardSelectors } from 'ducks/dashboard';
import IndexerGrid from '../../components/Indexer/IndexerGrid';
import Indexing from '../../components/Indexer/Indexing';
import {
  TYPE_IN_REVIEW, TYPE_LINK, TYPE_SEARCH, TYPE_CLEAR,
} from '../../constants/indexer';

const Indexer = ({
  fetchTombstoneData, onGetGroupName, group, indexerGridData, fetchIndexerGridData, inProgress,
  resultOperation, isUserActive, fetchInReviewActiveUserInfo,
  resetResultOperationOperation, indexerSearchGridData, fetchIndexerSearchGridData,
  isIndexingPageVisible, setIndexingPageVisibility, setIsUserActiveInfo,
}) => {
  const [indexerGridList, setindexerGridList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState({
    isActive: false,
    searchType: '',
    searchValue: null,
  });
  const [isActiveUser, setIsActiveUser] = useState(false);

  useEffect(() => {
    const { inReviewActiveUser } = isUserActive;
    if (!R.isNil(inReviewActiveUser) && !R.isEmpty(inReviewActiveUser)) {
      setIsActiveUser(inReviewActiveUser);
      setIndexingPageVisibility(!inReviewActiveUser);
    }
  }, [isUserActive]);

  useEffect(() => {
    onGetGroupName(group);
    if (!isIndexingPageVisible) { fetchIndexerGridData(0, 10); }
  }, []);

  useEffect(() => {
    const data = { ...indexerGridData, isActive: false };
    setindexerGridList(data);
    const { isActive, searchType } = isSearchActive;
    setTableData(isActive
      ? { indexerDetailsList: indexerSearchGridData, isActive, searchType } : data);
  }, [indexerGridData, indexerSearchGridData]);

  const handleLink = (val, type) => {
    if (type === TYPE_LINK) {
      const {
        loanId, evalId, assignedTo, subStatus,
      } = val;
      fetchTombstoneData(loanId);
      if (assignedTo && R.toLower(subStatus) === TYPE_IN_REVIEW) {
        fetchInReviewActiveUserInfo(loanId, evalId, assignedTo);
        setIndexingPageVisibility(false);
      } else {
        setIndexingPageVisibility(true);
      }
    } else if (type === TYPE_SEARCH) {
      fetchIndexerSearchGridData(val);
      setIsSearchActive({
        isActive: true,
        searchType: val.searchType,
        searchValue: val.searchValue,
      });
    } else if (type === TYPE_CLEAR) {
      setIsSearchActive({ isActive: false });
      setTimeout(() => {
        setTableData(indexerGridList);
      }, 0);
    }
  };

  const onPopupClose = () => {
    setIsActiveUser(false);
    setIsUserActiveInfo({ inReviewActiveUser: null });
  };

  const popupRender = () => (
    <Modal
      onClose={onPopupClose}
      open={isActiveUser}
    >
      <div styleName="popup-container">
        <Grid alignItems="center" container direction="row" justify="space-between">
          <Grid item>
            <h2 id="simple-modal-title">Alert</h2>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onPopupClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item styleName="message-container" xs={12}>
            <p>
              A Processor is currently working this Loan.
              Kindly reach out to the processor for further info
            </p>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" onClick={onPopupClose} variant="contained">OK</Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
  return (
    <>
      {!isIndexingPageVisible ? (
        <>
          <IndexerGrid
            closeAlert={resetResultOperationOperation}
            data={tableData}
            handleChange={(page, rowPage) => fetchIndexerGridData(page, rowPage)}
            handleLink={handleLink}
            inProgress={inProgress}
            resultOperation={resultOperation}
          />

          {isActiveUser && popupRender()}
        </>
      ) : (
        <>
          <Indexing
            handleLink={() => { setIndexingPageVisibility(false); }}
            isSearchActive={isSearchActive}
          />
        </>
      )}

    </>
  );
};

Indexer.defaultProps = {
  group: '',
  indexerGridData: {},
  fetchIndexerGridData: () => {},
  fetchInReviewActiveUserInfo: () => {},
  indexerSearchGridData: {},
  fetchIndexerSearchGridData: () => { },
  inProgress: true,
  isUserActive: {},
  setIndexingPageVisibility: () => {},
  setIsUserActiveInfo: () => {},
};

Indexer.propTypes = {
  fetchIndexerGridData: PropTypes.func,
  fetchIndexerSearchGridData: PropTypes.func,
  fetchInReviewActiveUserInfo: PropTypes.func,
  fetchTombstoneData: PropTypes.func.isRequired,
  group: PropTypes.string,
  indexerGridData: PropTypes.shape(),
  indexerSearchGridData: PropTypes.shape(),
  inProgress: PropTypes.bool,
  isIndexingPageVisible: PropTypes.bool.isRequired,
  isUserActive: PropTypes.shape(),
  onGetGroupName: PropTypes.func.isRequired,
  resetResultOperationOperation: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setIndexingPageVisibility: PropTypes.func,
  setIsUserActiveInfo: PropTypes.func,
};

const mapStateToProps = state => ({
  indexerGridData: indexerSelectors.getIndexerGridData(state),
  isUserActive: indexerSelectors.isUserActive(state),
  indexerSearchGridData: indexerSelectors.getIndexerSearchGridData(state),
  inProgress: dashboardSelectors.inProgress(state),
  resultOperation: dashboardSelectors.resultOperation(state),
  isIndexingPageVisible: indexerSelectors.isIndexingPageVisible(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTombstoneData: indexerOperations.fetchTombstoneData(dispatch),
  onGetGroupName: dashboardOperations.onGetGroupName(dispatch),
  fetchIndexerGridData: indexerOperations.fetchIndexerGridData(dispatch),
  fetchInReviewActiveUserInfo: indexerOperations.fetchInReviewActiveUserInfo(dispatch),
  fetchIndexerSearchGridData: indexerOperations.fetchIndexerSearchGridData(dispatch),
  resetResultOperationOperation: dashboardOperations.resetResultOperationOperation(dispatch),
  setIndexingPageVisibility: indexerOperations.setIndexingPageVisibility(dispatch),
  setIsUserActiveInfo: indexerOperations.fetchIsUserActiveInfoOperation(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(Indexer);
