import {
  TOGGLE_LOADER,
  SET_SB_HISTORY_GRID_DATA,
  SET_AUTO_COMPLETE_DATA,
  SET_SA_SELECTED_TAB,
  IS_FILTER_ENABLED,
  FILTER_CRITERIA,
  SET_EVAL_STATUS,
  SET_EVAL_SUBSTATUS,
  SET_CASE_STATUS,
  SET_CASE_SUBSTATUS,
  SET_ID_TYPE,
  SET_RECORDS_INFO,
  SET_CASE_TYPES,
  SET_BULK_ACTION_RSN_CDS,
  SET_BUSINESS_ADMIN_GRID_DATA,
  SET_BA_SELECTED_TAB,
  SET_AUTO_COMPLETE_BA_DATA,
  SET_MOD_SUPPORT_RESPONSE,
  SET_MOD_INVESTORS,
  SET_INVESTOR_NAME,
  SET_ACTION_TYPE,
} from './types';


const defaultState = {
  loading: true,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_LOADER: {
      const toggle = action.payload;
      return {
        ...state,
        loader: toggle,
      };
    }
    case SET_SB_HISTORY_GRID_DATA: {
      const SBHistoryGridData = action.payload;
      return {
        ...state,
        SBHistoryGridData,
      };
    }
    case SET_EVAL_STATUS: {
      const evalStatusOptions = action.payload;
      return {
        ...state,
        evalStatusOptions,
      };
    }
    case SET_EVAL_SUBSTATUS: {
      const evalSubStatusOptions = action.payload;
      return {
        ...state,
        evalSubStatusOptions,
      };
    }
    case SET_CASE_STATUS: {
      const caseStatusOptions = action.payload;
      return {
        ...state,
        caseStatusOptions,
      };
    }
    case SET_CASE_SUBSTATUS: {
      const caseSubStatusOptions = action.payload;
      return {
        ...state,
        caseSubStatusOptions,
      };
    }

    case SET_AUTO_COMPLETE_DATA: {
      const {
        field,
        data,
      } = action.payload;
      return {
        ...state,
        [field]: data,
      };
    }
    case SET_SA_SELECTED_TAB: {
      const saSelectedTab = action.payload;
      return {
        ...state,
        saSelectedTab,
      };
    }
    case SET_BUSINESS_ADMIN_GRID_DATA: {
      const businessAdminGridData = action.payload;
      return {
        ...state,
        businessAdminGridData,
      };
    }
    case IS_FILTER_ENABLED: {
      const isFilterEnabled = action.payload;
      return {
        ...state,
        isFilterEnabled,
      };
    }
    case FILTER_CRITERIA: {
      const filterPayload = action.payload;
      return {
        ...state,
        filterPayload,
      };
    }
    case SET_ID_TYPE: {
      const idType = action.payload;
      return {
        ...state,
        idType,
      };
    }
    case SET_RECORDS_INFO: {
      const recordsInfo = action.payload;
      return {
        ...state,
        recordsInfo,
      };
    }
    case SET_CASE_TYPES: {
      const caseTypes = action.payload;
      return {
        ...state,
        caseTypes,
      };
    }
    case SET_BULK_ACTION_RSN_CDS: {
      const bulkActionReasonCodes = action.payload;
      return {
        ...state,
        bulkActionReasonCodes,
      };
    }
    case SET_BA_SELECTED_TAB: {
      const baSelectedTab = action.payload;
      return {
        ...state,
        baSelectedTab,
        recordsInfo: {},
        investorName: '',
      };
    }
    case SET_AUTO_COMPLETE_BA_DATA: {
      const {
        field,
        data,
      } = action.payload;
      return {
        ...state,
        [field]: data,
      };
    }
    case SET_MOD_SUPPORT_RESPONSE: {
      const modSupportResponse = action.payload;
      return {
        ...state,
        modSupportResponse,
      };
    }
    case SET_MOD_INVESTORS: {
      const modInvestors = action.payload;
      return {
        ...state,
        modInvestors,
      };
    }
    case SET_INVESTOR_NAME: {
      const investorName = action.payload;
      return {
        ...state,
        investorName,
      };
    }
    case SET_ACTION_TYPE: {
      const actionType = action.payload;
      return {
        ...state,
        actionType,
      };
    }
    default:
      return state;
  }
};

export default reducer;
