import * as R from 'ramda';

const processBorrowerData = (borrowers) => {
  const data = borrowers.map((_, index) => {
    const borr = R.nth(index, borrowers);
    return {
      name: `${R.prop('firstName', borr)} ${R.prop('lastName', borr)}`,
      description: R.prop('description', borr),
      value: `${R.prop('firstName', borr)}_${R.prop('borrowerPstnNumber', borr)}`,
      pstnNum: R.prop('borrowerPstnNumber', borr),
      priority: R.propOr(null, 'priority', borr),
      shrtDescp: R.propOr(null, 'shrtDescp', borr),
      firstName: R.propOr(null, 'firstName', borr),
      lastName: R.propOr(null, 'lastName', borr),
      taxpyrIdVal: R.propOr(null, 'taxpyrIdVal', borr),
      birthDate: R.propOr(null, 'birthDate', borr),
      emailAddress: R.propOr(null, 'emailAddress', borr),
      phoneNumber: R.propOr(null, 'phoneNumber', borr),
      borrowerAffilCd: R.propOr(null, 'borrowerAffilCd', borr),
      borrowerId: R.propOr(null, 'borrowerId', borr),
    };
  });
  return data;
};

export default processBorrowerData;
