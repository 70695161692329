import * as R from 'ramda';

const mapGeneralAndCaseInfoData = (generalOrCaseInfo) => {
  if (generalOrCaseInfo && R.length(generalOrCaseInfo) > 0) {
    const data = generalOrCaseInfo.map(
      ({
        columnVal, columnDataType, columnName, columnDisplayName, sortOrder, isOverridable,
      }) => ({
        columnVal,
        columnDataType,
        columnName,
        columnDisplayName,
        sortOrder,
        isOverridable,
      }),
    );
    return data;
  }
  return [];
};

const mapCorporateAdvancesData = (corpAdvData) => {
  if (corpAdvData && R.length(corpAdvData) > 0) {
    const data = corpAdvData.map(
      ({ advanceType, advanceBalance, description }) => ({
        code: advanceType,
        description,
        amount: advanceBalance,
      }),
    );
    return data;
  }

  return [];
};

const mapAmortizationData = (amortData) => {
  if (amortData && R.length(amortData)) {
    const data = amortData.map(
      ({
        date, rate, interest, principal, balloon, total, type, pv,
      }) => ({
        date,
        rate,
        interest,
        principal,
        balloon,
        total,
        type,
        pv,
      }),
    );
    return data;
  }
  return [];
};

const mapEligibilityData = (eligibilityData) => {
  if (eligibilityData && R.length(eligibilityData) > 0) {
    const data = eligibilityData.map(
      ({
        id, eligibilityName, response, overrideEligible,
        overrideReason, updatedBy,
      }) => ({
        id,
        overrideEligible,
        eligibility: eligibilityName,
        description: response,
        comments: overrideReason,
        overiddenDate: new Date(),
        overiddenBy: updatedBy,
      }),
    );
    return data;
  }
  return [];
};

// const updateDataWithCalcResponse = (currentData, calcRes) => {
//   const updatedData = calcRes.map((item) => {
//     const index = currentData.findIndex(e => e.columnKey === item.columnKey);
//     if (index > -1) {
//       return { ...item, isChecked: currentData[index].isChecked };
//     }
//     return item;
//   });
//   return updatedData;
// };

export default {
  mapGeneralAndCaseInfoData,
  mapCorporateAdvancesData,
  mapAmortizationData,
  mapEligibilityData,
  // updateDataWithCalcResponse,
};
