import { Button } from '@material-ui/core/index';
import React from 'react';
import DownloadIcon from 'containers/Dashboard/BoardingTemplate/History/DownloadIcon';
import * as moment from 'moment';

export const DEFAULT_ROWS_PER_PAGE = 500;
export const HISTORY_TABLE_COLUMNS = [
  {
    name: 'id',
    label: 'ID',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'requestorId',
    label: 'User Name',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: 'Date Uploaded',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
    cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
  },
  {
    name: 'importStatus',
    label: 'Status',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'totalRecords',
    label: 'Total Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'successRecords',
    label: 'Successful Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'failedRecords',
    label: 'Unsuccessful Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'fileName',
    label: 'File Name',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'download',
    label: 'Download',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
    cellFormat: (callable) => {
      const { fn, id } = callable;
      // eslint-disable-next-line react/jsx-filename-extension
      return (<Button onClick={() => { fn(id); }} style={{ padding: '0px' }}><DownloadIcon /></Button>);
    },
  },
];

const LOAN_ID = 'Loan ID';
const CASE_TYPE = 'Case Type';
const PHASE = 'Phase';
const PRE_APPROVED = 'Pre Approved';
const NEXT_PAYMENT_DUE = 'Next Payment due';
const PRE_MOD_GROSS_UPB = 'Pre-Mod Gross UPB';
const PRE_MOD_NEXT_DUE_DATE = 'Pre-Mod Next Due Date';
const INFLIGHT = 'InFlight';
const CURRENT_UPB = 'Current UPB';

export const ONBOARD_TABLE_COLUMNS = [LOAN_ID, CASE_TYPE, PHASE, PRE_APPROVED,
  INFLIGHT, PRE_MOD_GROSS_UPB, PRE_MOD_NEXT_DUE_DATE];

export const ONBOARD_TABLE_COLUMNS_MAPPING = {
  [LOAN_ID]: LOAN_ID,
  [CASE_TYPE]: CASE_TYPE,
  [PHASE]: PHASE,
  [INFLIGHT]: INFLIGHT,
  [PRE_APPROVED]: PRE_APPROVED,
  [PRE_MOD_GROSS_UPB]: CURRENT_UPB,
  [PRE_MOD_NEXT_DUE_DATE]: NEXT_PAYMENT_DUE,
};

export const templateDataFileName = 'BoardingTemplate.xlsx';
export const exceptionDataFileName = 'BoardingTemplateException.xlsx';
export const template = 'Template';
export const uploadedTemplate = 'Uploaded Template';
