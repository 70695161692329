import React, { useState, useEffect, useMemo } from 'react';
import { Button, Divider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import './OnBoardTable.css';
import { ONBOARD_TABLE_COLUMNS, ONBOARD_TABLE_COLUMNS_MAPPING } from 'constants/BoardingTemplate';
import ExceptionPopup from '../HandleException/ExceptionPopup';

const OnBoardTable = ({ isUploadCompleted, resultData }) => {
  const [open, setOpen] = useState(false);
  const [selectedErrorData, setSelectedErrorData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [successfulRecords, setSuccessfulRecords] = useState(0);
  const [unsuccessfulRecords, setUnsuccessfulRecords] = useState(0);
  const [criticalExceptions, setCriticalExceptions] = useState(0);
  const [mediumExceptions, setMediumExceptions] = useState(0);
  const [lowExceptions, setLowExceptions] = useState(0);

  useEffect(() => {
    if (resultData && resultData.length > 0) {
      setTotalRecords(resultData.length);
      setSuccessfulRecords(resultData.filter(record => record.success).length);
      setUnsuccessfulRecords(resultData.filter(record => !record.success).length);
      setCriticalExceptions(resultData.reduce((acc, record) => acc + (record['Critical Severity Exceptions'] ? record['Critical Severity Exceptions'].length : 0), 0));
      setMediumExceptions(resultData.reduce((acc, record) => acc + (record['Medium Severity Exceptions'] ? record['Medium Severity Exceptions'].length : 0), 0));
      setLowExceptions(resultData.reduce((acc, record) => acc + (record['Low Severity Exceptions'] ? record['Low Severity Exceptions'].length : 0), 0));
    }
  }, [resultData]);

  const onboardHeaders = useMemo(() => ONBOARD_TABLE_COLUMNS, []);

  const handleRowClick = (errorData) => {
    setSelectedErrorData(Array.isArray(errorData) ? errorData : [errorData]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!resultData || resultData.length === 0) {
    return <div>No data available</div>;
  }

  const headers = isUploadCompleted ? Object.keys(resultData[0]) : onboardHeaders;

  function rowStyle(row) {
    if (isUploadCompleted) {
      return row.inFlight === 'true' ? 'green-line' : 'red-line';
    }
    return '';
  }

  const renderExceptions = (exceptions, color, handleClick) => {
    if (Array.isArray(exceptions) && exceptions.length > 0) {
      const firstTitle = exceptions[0].title;
      const maxLength = 25;
      let trimmedTitle = firstTitle;
      if (firstTitle.length > maxLength) {
        const lastSpaceIndex = firstTitle.lastIndexOf(' ', maxLength);
        if (lastSpaceIndex === -1) {
          trimmedTitle = `${firstTitle.substring(0, maxLength)}...`;
        } else {
          trimmedTitle = `${firstTitle.substring(0, lastSpaceIndex)}...`;
        }
      }

      return (
        <Button
          onClick={() => handleClick(exceptions)}
          style={{
            color,
            border: 'none',
            padding: '4px 8px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {trimmedTitle}
        </Button>
      );
    }
    return '-';
  };

  const exceptionColors = {
    'Critical Severity Exceptions': '#F23563',
    'Medium Severity Exceptions': '#D77E15',
    'Low Severity Exceptions': '#1083B5',
  };
  return (
    <div styleName="container">
      <Divider style={{ marginBottom: '8px' }} />
      { isUploadCompleted && (
      <div styleName="summary">
        <div styleName="left">
          <div>
            {`Total Records: ${totalRecords || '-'} `}
          </div>
          <div>
            {`Successful Records: ${successfulRecords || '-'} `}
          </div>
          <div>
            {`Unsuccessful Records: ${unsuccessfulRecords || '-'} `}
          </div>
        </div>
        <div styleName="right">
          <div>
            {`Critical Severity Exceptions: ${criticalExceptions || '-'} `}
          </div>
          <div>
            {`Medium Severity Exceptions: ${mediumExceptions || '-'} `}
          </div>
          <div>
            {`Low Severity Exceptions: ${lowExceptions || '-'} `}
          </div>
        </div>
      </div>
      )
}

      <div styleName="OnBoardContainer">
        <table style={{ backgroundColor: '#F3F5F9' }}>
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header}>
                  { isUploadCompleted ? header : ONBOARD_TABLE_COLUMNS_MAPPING[header] }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resultData.map(row => (
              <tr
                key={`row-${row['Loan Id']}`}
                styleName={rowStyle(row)}
              >
                {headers.map(header => (
                  <td key={`${header}-${row[header]}`}>
                    {['Critical Severity Exceptions', 'Medium Severity Exceptions', 'Low Severity Exceptions'].includes(header)
                      ? renderExceptions(row[header], exceptionColors[header], handleRowClick)
                      : row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {Array.isArray(selectedErrorData) && selectedErrorData.length > 0 && (
      <ExceptionPopup isOpen={open} onClose={handleClose} selectedErrorData={selectedErrorData} />
      )}

    </div>
  );
};

OnBoardTable.propTypes = {
  isUploadCompleted: PropTypes.bool.isRequired,
  resultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default OnBoardTable;
