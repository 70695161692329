/* eslint-disable no-empty-pattern */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './UITaskGenerator.css';
import MUITable from 'components/MUITable';
import processItem from 'lib/CustomFunctions';
import { getChecklistItems } from 'lib/checklist';
import Divider from '@material-ui/core/Divider';
import RadioButtons from 'components/IncomeCalc/RadioButtons';
import TaskSection from 'components/IncomeCalc/TaskSection';
import CustomButton from 'components/IncomeCalc/CustomButton';
import Dropdown from 'components/IncomeCalc/Dropdown';
import DatePicker from 'components/IncomeCalc/DatePicker';
import TooltipWrapper from 'components/IncomeCalc/TooltipWrapper';
import TextFields from 'components/IncomeCalc/TextFields';
import ComponentTypes from 'constants/componentTypes';
import CheckBox from './Checkbox';

function UITaskGenerator(props) {
  const {
    checklistItems, processAction, onChange, escrowData,
  } = props;

  const [multilineTextDirtyValues, setMultilineTextDirtyValues] = useState({});

  const getMultilineTextValue = (id, initialValue) => {
    const dirtyValue = multilineTextDirtyValues[id];
    if (dirtyValue === undefined) {
      return initialValue;
    }
    return dirtyValue;
  };

  const handleAction = (id, additionalInfo) => {
    const actions = R.propOr(false, 'actions', additionalInfo);
    return (value) => {
      if (actions) {
        const { postProcess, postData } = actions;
        processAction(postProcess, { ...postData, value });
      }
      onChange(id, value);
    };
  };

  const handleChange = (id, additionalInfo) => (event) => {
    onChange(id, event.target.value);
    processItem({ additionalInfo, value: event.target.value }, 'onChange');
  };

  const handleTextChange = id => (event) => {
    setMultilineTextDirtyValues({ ...multilineTextDirtyValues, [id]: event.target.value });
  };


  const handleBlur = id => (event) => {
    if (event) {
      const value = (
        R.is(String, event.target.value)
          ? R.trim(event.target.value)
          : ''
      );
      const dirtyValue = R.isEmpty(value) ? null : value;
      if (R.has(id, multilineTextDirtyValues)) {
        onChange(id, dirtyValue);
        setMultilineTextDirtyValues(R.dissoc(id, multilineTextDirtyValues));
      }
    }
  };

  const renderChildren = disableChecklist => subTasks => renderChecklistItem(getChecklistItems(subTasks,
    disableChecklist));

  const renderChecklistItem = (checklistTasks) => {
    const {
      BUTTON, TASK_SECTION, DROPDOWN, RADIO_BUTTONS, TEXT, DATE, CHECKBOX, TABLE, DIVIDER,
    } = ComponentTypes;
    const {
      checklistLoadStatus, location,
    } = props;
    const skipSubTask = [TASK_SECTION];
    const children = [];
    return checklistTasks.filter(({ visibility, isVisible }) => isVisible || visibility).map((item) => {
      const processedItem = processItem({ ...item, incomeCalcData: [], escrowData }, 'preProcess');
      const {
        disabled,
        id,
        options,
        title,
        type,
        taskCode,
        value,
        source,
        additionalInfo,
        subTasks,
        state,
        failureReason,
      } = processedItem;
      let element = {};
      if (subTasks && !skipSubTask.includes(type)) children.push(subTasks);
      switch (type) {
        case TASK_SECTION: {
          const onValueChange = handleAction(id, additionalInfo);
          const { accHeaderData } = processedItem;
          const prop = {
            title,
            additionalInfo,
            subTasks,
            onChange: onValueChange,
            location,
            value,
            disabled,
            renderChildren: renderChildren(disabled),
            failureReason,
            source,
            accHeaderData,
          };
          element = <TaskSection key={id} {...prop} />;
        } break;
        case DATE: {
          const onValueChange = handleAction(id, additionalInfo);
          const text = title || additionalInfo.placeholder;
          const prop = {
            disabled,
            id,
            taskCode,
            title: text,
            additionalInfo,
            onChange: onValueChange,
            options,
            state,
            source,
            value,
            failureReason,
          };
          element = (<DatePicker key={id} {...prop} />);
        } break;
        case BUTTON: {
          const onValueChange = handleAction(id, additionalInfo);
          const text = title || additionalInfo.placeholder;
          const prop = {
            id,
            taskCode,
            title: text,
            additionalInfo,
            onChange: onValueChange,
            options,
            state,
            source,
            value,
            failureReason,
            disabled,
          };
          element = (<CustomButton key={id} {...prop} />);
        } break;
        case DROPDOWN: {
          const onValueChange = handleChange(id, additionalInfo);
          const getValue = getMultilineTextValue(id, value);
          const prop = {
            disabled,
            onChange: onValueChange,
            title,
            type: DROPDOWN,
            value: getValue,
            source,
            additionalInfo,
            subTasks,
            renderChildren: renderChildren(),
            failureReason,
            checklistLoadStatus,
          };
          element = (<Dropdown key={id} {...prop} />);
        } break;
        case TEXT: {
          const refCallback = handleBlur(id);
          const onValueChange = handleTextChange(id);
          const getValue = getMultilineTextValue(id, value);
          const prop = {
            onBlur: refCallback,
            disabled,
            onChange: onValueChange,
            componentTitle: title,
            type: TEXT,
            value: getValue,
            source,
            additionalInfo,
            subTasks,
            renderChecklistItems: renderChecklistItem,
            failureReason,
            checklistLoadStatus,
          };
          element = <TextFields key={id} {...prop} />;
        } break;
        case RADIO_BUTTONS: {
          const onValueChange = handleChange(id);
          const text = title || additionalInfo.placeholder;
          const prop = {
            id,
            disabled,
            taskCode,
            title: text,
            additionalInfo,
            onChange: onValueChange,
            options,
            state,
            source,
            selectedValue: value,
            failureReason,
            checklistLoadStatus,
          };
          element = (<RadioButtons key={id} {...prop} />);
        } break;
        case CHECKBOX: {
          const onChangeMultipleBox = handleChange(id);
          const prop = {
            id,
            taskCode,
            title,
            additionalInfo,
            options,
            state,
            source,
            value,
            disabled,
            onChangeMultipleBox,
            failureReason,
            checklistLoadStatus,
          };
          element = (<CheckBox key={id} {...prop} />);
        } break;
        case TABLE: {
          const { columnsHeaders, styleName } = additionalInfo;
          const prop = {
            columns: columnsHeaders,
            data: value,
            styleName,
          };
          element = (<MUITable key={id} {...prop} />);
        } break;
        case DIVIDER:
          element = (<Divider orientation="vertical" styleName="divider" />);
          break;
        default:
          element = (
            <div />
          );
      }
      return R.prop('errorToolTip', additionalInfo)
        ? <TooltipWrapper element={element} failureReason={failureReason} /> : element;
    });
  };

  return (
    <section>
      {renderChecklistItem(checklistItems)}
    </section>
  );
}

UITaskGenerator.defaultProps = {
  checklistLoadStatus: null,
  onChange: () => {},
  processAction: () => {},
};

UITaskGenerator.propTypes = {
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      showPushData: PropTypes.bool.isRequired,
      taskCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(ComponentTypes)).isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  checklistLoadStatus: PropTypes.string,
  escrowData: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  processAction: PropTypes.func,
};

export default UITaskGenerator;
