import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductSupport.css';
import {
  BULK_STATUS,
  BULK_ACTION,
  FORCE_CREATE_CASE,
  FORCE_CREATE_EVAL_CASE,
  EVAL_DISASTER_ID_REQUEST,
  UNREJECT_REQUEST,
  SUB_STATUS_REQUEST,
} from '../../constants/SupportAdmin';

class ProductSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allTabs: [
        BULK_STATUS,
        BULK_ACTION,
        FORCE_CREATE_CASE,
        FORCE_CREATE_EVAL_CASE,
        EVAL_DISASTER_ID_REQUEST,
        UNREJECT_REQUEST,
        SUB_STATUS_REQUEST,
      ],
    };
  }

  render() {
    const { allTabs } = this.state;
    const { activeTab, handleChange } = this.props;
    return (
      <>
        {allTabs.map(tab => (
          <div
            aria-hidden="true"
            className={` ${styles.tab} ${
              activeTab === tab ? styles.selected : ''
            }`}
            onClick={() => handleChange(tab)}
          >
            <h4>{tab}</h4>
          </div>
        ))}
      </>
    );
  }
}

ProductSupport.defaultProps = {
  activeTab: BULK_STATUS,
  handleChange: () => {},
};

ProductSupport.propTypes = {
  activeTab: PropTypes.string,
  handleChange: PropTypes.func,
};

export default ProductSupport;
