import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ConfirmationDialogSingleOption = ({
  isOpen, title, message, onClose, option,
}) => (
  <>
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ paddingTop: '25px' }}>{ title }</div>
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={() => onClose(true)}>
          {option}
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

ConfirmationDialogSingleOption.defaultProps = {
  isOpen: false,
  title: 'Message',
};

ConfirmationDialogSingleOption.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ConfirmationDialogSingleOption;
