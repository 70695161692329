import { makeStyles } from '@material-ui/core/styles';

const BookingFieldsStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  singleContainer: {
    margin: '0px 10px',
    width: 'auto',
    height: '36px',
    background: hookData => (hookData.highlightCellsWithBorder ? '#E4E5F9' : ''),
    borderRadius: hookData => (hookData.highlightCellsWithBorder ? '2px' : ''),
  },

  grid1: {
    display: 'flex',
    alignItems: 'center',
  },

  grid2: {
    display: 'flex',
    alignItems: 'center',
  },

  labelName: {
    transform: 'none',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: hookData => (hookData.highlightCells ? '700' : '400'),
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#4E586E',
    height: 'inherit',
    paddingTop: '8px',
    paddingBottom: '8px',
  },

  TextFieldDiv: {
    height: 'inherit',
    paddingTop: '3px',
    paddingBottom: '8px',
    '& .MuiInputBase-root': {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: hookData => (hookData.highlightCells ? '700' : '400'),
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#4E586E',
      border: 'none',
      borderRadius: '0px',
    },
  },

  RadioGroup: {
    height: 'inherit',
    alignItems: 'center',
    paddingLeft: '13px',
    '& .MuiFormControlLabel-root': {
      marginRight: '21px',
    },
    '& .MuiButtonBase-root': {
      width: '15px',
      height: '15px',
      color: '#B8B8BA',
      padding: '0px',
    },

    '& .MuiTypography-root': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#4E586E',
      marginLeft: '7px',
    },
  },

  DetailsButton: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#596FEB',
    marginLeft: '2px',
    height: 'inherit',
    paddingTop: '8px',
    paddingBottom: '8px',
  },

}));

export default BookingFieldsStyles;
