const FETCH_USERS = 'app/user-skills/FETCH_USERS';
const SET_USERS = 'app/user-skills/SET_USERS';
const FETCH_EVENTS = 'app/user-skills/FETCH_EVENTS';
const SET_EVENTS = 'app/user-skills/SET_EVENTS';
const SET_SELECTED_USER = 'app/user-skills/SET_SELECTED_USER';
const SET_SELECTED_EVENT = 'app/user-skills/SET_SELECTED_EVENT';
const FETCH_SKILLS = 'app/user-skills/FETCH_SKILLS';
const SET_SKILLS = 'app/user-skills/SET_SKILLS';
const CHECK_SKILLS = 'app/user-skills/CHECK_SKILLS';
const FETCH_USER_SKILLS = 'app/user-skills/FETCH_USER_SKILLS';
const SET_USER_SKILLS = 'app/user-skills/SET_USER_SKILLS';
const CHECK_USER_SKILLS = 'app/user-skills/CHECK_USER__SKILLS';
const ADD_USER_SKILLS = 'app/user-skills/ADD_USER_SKILLS';
const UPDATE_USER_SKILLS = 'app/user-skills/UPDATE_USER_SKILLS';
const SORT_USER_SKILLS = 'app/user-skills/SORT_USER_SKILLS';
const CLEAR_UPDATE_USER_SKILLS = 'app/user-skills/CLEAR_UPDATE_USER_SKILLS';
const SAVE_UPDATE_USER_SKILLS = 'app/user-skills/SAVE_UPDATE_USER_SKILLS';
const SAVE_SAGA_UPDATE_USER_SKILLS = 'app/user-skills/SAVE_SAGA_UPDATE_USER_SKILLS';
const ENABLE_USER_SKILLS = 'app/user-skills/ENABLE_USER_SKILLS';
const FETCH_SKILL_HISTORY = 'app/user-skills/FETCH_SKILL_HISTORY';
const SET_SKILL_HISTORY = 'app/user-skills/SET_SKILL_HISTORY';
const SET_ADD_SKILL_BTN = 'app/user-skills/SET_ADD_SKILL_BTN';
const SET_CLEAR_SAVE_BTN = 'app/user-skills/SET_CLEAR_SAVE_BTN';
const ENABLE_EDIT = 'app/user-skills/ENABLE_EDIT';
const SET_ADD_SKILL_DIALOG = 'app/user-skills/SET_ADD_SKILL_DIALOG';
const SET_SKILL_HISTORY_DIALOG = 'app/user-skills/SET_SKILL_HISTORY_DIALOG';
const CLEAR_INFO = 'app/user-skills/CLEAR_INFO';
const HIDE_LOADER = 'app/user-skills/HIDE_LOADER';
const SHOW_LOADER = 'app/user-skills/SHOW_LOADER';
const SHOW_USER_SKILL_SCREEN_LOADER = 'app/user-skills/SHOW_USER_SKILL_SCREEN_LOADER';

export {
  FETCH_USERS,
  SET_USERS,
  FETCH_EVENTS,
  SET_EVENTS,
  SET_SELECTED_USER,
  SET_SELECTED_EVENT,
  FETCH_SKILLS,
  SET_SKILLS,
  CHECK_SKILLS,
  FETCH_USER_SKILLS,
  SET_USER_SKILLS,
  CHECK_USER_SKILLS,
  ADD_USER_SKILLS,
  UPDATE_USER_SKILLS,
  SORT_USER_SKILLS,
  CLEAR_UPDATE_USER_SKILLS,
  SAVE_UPDATE_USER_SKILLS,
  SAVE_SAGA_UPDATE_USER_SKILLS,
  ENABLE_USER_SKILLS,
  FETCH_SKILL_HISTORY,
  SET_SKILL_HISTORY,
  SET_ADD_SKILL_BTN,
  SET_CLEAR_SAVE_BTN,
  ENABLE_EDIT,
  SET_ADD_SKILL_DIALOG,
  SET_SKILL_HISTORY_DIALOG,
  CLEAR_INFO,
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_USER_SKILL_SCREEN_LOADER,
};
