import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'ducks/eval';
import * as R from 'ramda';
import DisplayCard from './DisplayCard';

const CapitalizedData = ({
  bookingTabData, bookingTabCorpAdvnData,
}) => {
  const capitalizedDetails = [];
  const nonCapitalizedDetails = [];
  if (bookingTabCorpAdvnData.length > 0) {
    bookingTabCorpAdvnData.forEach((data) => {
      if ((data.advanceBalanceCapitalizedAmount) > 0) {
        const obj = {
          code: data.advanceType,
          description: data.description,
          amount: data.advanceBalanceCapitalizedAmount,
        };
        capitalizedDetails.push(obj);
      }
      if ((data.advanceBalanceNonCapitalizedAmount) > 0) {
        const obj = {
          code: data.advanceType,
          description: data.description,
          amount: data.advanceBalanceNonCapitalizedAmount,
        };
        nonCapitalizedDetails.push(obj);
      }
    });
  }
  const corpAdvances = [
    {
      id: 'Capitalized',
      label: 'Capitalized',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'capitalized'], bookingTabData),
      type: 'currency',
      details: {
        title: 'Corporate Advances (Capitalized)',
        data: capitalizedDetails,
      },
    },
    {
      id: 'NonCapitalized',
      label: 'Noncapitalized',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'nonCapitalized'], bookingTabData),
      type: 'currency',
      details: {
        title: 'Corporate Advances (UnCapitalized)',
        data: nonCapitalizedDetails,
      },
    },
  ];

  const interestDue = [
    {
      id: 'DelinquentInterestDue',
      label: 'Total Delinquent Interest Due',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'delinquentInterestDue'], bookingTabData),
      type: 'currency',
    },
  ];

  const escrowAdvance = [
    {
      id: 'AtLock',
      label: 'At lock',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'atLock'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'NetDisbursements',
      label: 'Net Disbursements',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'netDisbursements'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'ConversionDisbursement',
      label: 'Conversion Disbursement',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'conversionDisbursement'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'FutureEscrowShortage',
      label: 'Future Escrow Shortage',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'futureEscrowShortage'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'PostedEscrow',
      label: 'Posted Escrow',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'postedEscrow'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'AdditionalCapitalized',
      label: 'Additional Capitalized',
      override: 'N',
      value: 0.00,
      type: 'currency',
      // details: {
      //   title: 'Escrow Advance (Additional Capitalized)', // Commenting this part as per #694946
      //   data: [],
      // },
    },
    {
      id: 'CapitalizedEscrowAdvances',
      label: 'Capitalized Escrow Advances',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'capitalizedEscrowAdvances'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'UnpaidLegalFees',
      label: 'Unpaid Legal Fees',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'unpaidLegalFees'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'SubsidyAmount',
      label: 'Subsidy Amount',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'subsidyAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'SuspenseFunds',
      label: 'Suspense Funds (at lock)',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'suspenseFunds'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'SuspenseFundsPostTrialCompletion',
      label: 'Suspense Funds Post Trial Completion',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'suspenseFundsPostTrial'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'TotalCapitalizedAmount',
      label: 'Total Capitalized Amount',
      override: 'N',
      value: R.pathOr('', ['capitalizedData', 'totalCapitalizedAmount'], bookingTabData),
      type: 'currency',
    },
  ];
  return (
    <>
      {corpAdvances && <DisplayCard data={corpAdvances} title="Corporate Advances" />}
      {interestDue && <DisplayCard data={interestDue} highlightCells={['DelinquentInterestDue']} title="Interest Due" />}
      {escrowAdvance && <DisplayCard data={escrowAdvance} hideDivider highlightCells={['CapitalizedEscrowAdvances', 'SuspenseFundsPostTrialCompletion']} title="Escrow Advance" />}
    </>
  );
};

CapitalizedData.propTypes = {
  bookingTabCorpAdvnData: PropTypes.arrayOf(PropTypes.shape({
    advanceBalanceCapitalizedAmount: PropTypes.number,
    advanceBalanceNonCapitalizedAmount: PropTypes.number,
    advanceType: PropTypes.string,
    caseId: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  bookingTabData: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  bookingTabData: selectors.bookingTabData(state),
  bookingTabCorpAdvnData: selectors.bookingTabCorpAdvnData(state),
});

export default connect(mapStateToProps)(CapitalizedData);
