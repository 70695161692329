import * as moment from 'moment-timezone';

export const DISPOSITION_OPTIONS = [{
  value: 'Mod Related Supporting Docs Uploaded/Linked',
  disabled: false,
  label: 'Mod Related Supporting Docs Uploaded/Linked',
  commentCode: 'MRSD',
},
{
  value: 'Mod Related Supporting Docs Received/Not Linked Due to Eval in Missing Docs Status for AQ - FHA 60+',
  disabled: false,
  label: 'Mod Related Supporting Docs Received/Not Linked Due to Eval in Missing Docs Status for AQ - FHA 60+',
  commentCode: 'DRIA',
},
];

export const SOI_OPTIONS = [{
  value: 'Received Complete',
  disabled: false,
  label: 'Received Complete',
  commentCode: ['DSOI', 'PTTL'],
},
{
  value: 'Received Incomplete',
  disabled: false,
  label: 'Received Incomplete',
  commentCode: ['DSOI', 'DILK'],
},
{ value: 'No', disabled: false, label: 'No' }];

export const MMO_OPTIONS = [{
  value: 'Yes',
  disabled: false,
  label: 'Yes',
},
{
  value: 'No',
  disabled: false,
  label: 'No',
}];

export const MMO_SELECTIONS = [{
  value: 'MA Modification Requested',
  disabled: false,
  label: 'MA Modification Requested',
  commentCode: 'MAMR',
},
{
  value: 'MA Foreclosure Alternative Requested',
  disabled: false,
  label: 'MA Foreclosure Alternative Requested',
  commentCode: 'MAAF',
},
{
  value: 'MA No Modification Requested',
  disabled: false,
  label: 'MA No Modification Requested',
  commentCode: 'MANR',
},
{
  value: 'MA Waived Right to Cure, Proceed to Foreclosure',
  disabled: false,
  label: 'MA Waived Right to Cure, Proceed to Foreclosure',
  commentCode: 'MAWP',
}];

const getCSTDateTime = dateTime => (moment.utc(dateTime).tz('America/Chicago').format('MM/DD/YYYY'));

export const INDEXER_TABLE_COLUMNS = [
  {
    name: 'loanId',
    label: 'Loan Number',
    align: 'left',
    width: '12%',
    options: {
      filter: true,
      sort: false,
      searchFilter: true,
      toolTip: {
        title: 'Filter By LoanNumber',
        size: 'small',
        color: 'action',
      },
    },
  },
  {
    name: 'evalId',
    label: 'Eval ID',
    align: 'left',
    width: '12%',
    options: {
      filter: true,
      sort: false,
      searchFilter: true,
      toolTip: {
        title: 'Filter By EvalId',
        size: 'small',
        color: 'action',
      },
    },
  },
  {
    name: 'createDate',
    label: 'Eval Created Date',
    align: 'left',
    width: '12%',
    options: {
      filter: true,
      sort: false,
      searchFilter: false,
      toolTip: {
        title: 'Filter By Date',
        size: 'small',
        color: 'action',
      },
    },
    cellFormat: value => (value ? getCSTDateTime(value) : '-'),
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
    width: '12%',
    options: {
      filter: true,
      sort: false,
      searchFilter: false,
      toolTip: {
        title: 'Filter By Status',
        size: 'small',
        color: 'action',
      },
    },
  },
  {
    name: 'subStatus',
    label: 'Sub-Status',
    align: 'left',
    width: '12%',
    options: {
      filter: true,
      sort: false,
      searchFilter: false,
      toolTip: {
        title: 'Filter By Substatus',
        size: 'small',
        color: 'action',
      },
    },
  },
  {
    name: 'assignedTo',
    label: 'Assigned To',
    align: 'left',
    width: '20%',
    options: {
      filter: true,
      sort: false,
      searchFilter: true,
      toolTip: {
        title: 'Filter By Name',
        size: 'small',
        color: 'action',
      },
    },
  },
];


export const TYPE_LINK = 'link';
export const TYPE_IN_REVIEW = 'in review';
export const TYPE_CLEAR = 'clear';
export const TYPE_SEARCH = 'search';
