export const CLOSED = 'Closed';
export const REJECTED = 'Rejected';
export const OPEN = 'Open';
export const BOOKED = 'Booked';
export const FAILED = 'Failed';
export const SENT_FOR_APPROVAL = 'Sent for Approval';
export const APPROVED = 'Approved';
export const LOCKED = 'Locked';
export const ACTIVE = 'Active';
export const COMPLETED = 'Completed';
export const SUSPENDED = 'Suspended';
export const MISSING_DOCS = 'Missing Docs';
export const FINS = 'FINS';
export const IN_REVIEW = 'In-Review';
