import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import './InputField.css';

const InputField = ({
  name,
  value,
  handleChange,
  label,
  multiline,
  rows,
  forEval,
  type,
  handleBlur,
  placeholder,
  isDisabled = false,
}) => {
  const handleKeyUp = (event) => {
    const ALPHA_NUMERIC_DASH_REGEX = /^[0-9\b]+$/;
    if (
      event.target.type === 'number'
      && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  return (
    <div styleName={`input-container ${forEval ? 'for-eval' : ''}`}>
      {label && <p styleName="label-style">{label}</p>}
      <TextField
        disabled={isDisabled}
        hiddenLabel
        inputProps={{ maxLength: 1000 }}
        multiline={multiline}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={handleKeyUp}
        placeholder={placeholder}
        rows={rows}
        size="small"
        type={type}
        value={value}
        variant="filled"
      />
    </div>
  );
};

export default InputField;

InputField.defaultProps = {
  handleChange: () => {},
  label: '',
  multiline: false,
  name: '',
  value: '',
  handleBlur: () => {},
  forEval: false,
  type: 'text',
  rows: 1,
  placeholder: '',
  isDisabled: false,
};

InputField.propTypes = {
  forEval: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
};
