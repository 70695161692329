import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Center from 'components/Center';

function WidgetLoader() {
  return (
    <Center>
      <CircularProgress size="2.5rem" />
    </Center>
  );
}

export default WidgetLoader;
