import React, { useState, useEffect } from 'react';
// import * as R from 'ramda';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import './RepaymentTotalAmount.css';
import { convertToUSCurrency } from 'lib/Formatters';
import { connect } from 'react-redux';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';

const RepaymentTotalAmount = ({ setIsRepaymentSectionOpened, caseSpecificFieldValues }) => {
  const [isRepaymentSplitOpened, toggleRepaymentScreen] = useState(false);
  // const { repaymentTotalAmount } = monthlyPaymentDetails;
  const [repaymentTotalAmount, setRepaymentTotalAmount] = useState(0);

  useEffect(() => {
    if (caseSpecificFieldValues.length > 0) {
      const repaymentTotalAmountValue = caseSpecificFieldValues.filter(e => e.columnName === 'totalRepayment');
      setRepaymentTotalAmount(repaymentTotalAmountValue && repaymentTotalAmountValue[0]
        && repaymentTotalAmountValue[0].columnVal ? repaymentTotalAmountValue[0].columnVal : 0);
    }
  }, [caseSpecificFieldValues]);

  return (
    <Grid id="repaymentTotalAmount" item xs={4}>
      <Paper
        elevation={2}
        square
        styleName={
          isRepaymentSplitOpened
            ? 'repaymentTotalAmount-opened'
            : 'repaymentTotalAmount-closed'
        }
      >
        <div styleName="displayInColumn">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <div styleName="card-header">
                Repayment Total Amount
              </div>
            </Grid>
            <Grid item>
              {!isRepaymentSplitOpened ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    toggleRepaymentScreen(!isRepaymentSplitOpened);
                    setIsRepaymentSectionOpened(!isRepaymentSplitOpened);
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => {
                    toggleRepaymentScreen(!isRepaymentSplitOpened);
                    setIsRepaymentSectionOpened(!isRepaymentSplitOpened);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <div styleName="repaymentTotalAmountValue">{convertToUSCurrency.format(repaymentTotalAmount)}</div>
        </div>
      </Paper>
    </Grid>
  );
};

RepaymentTotalAmount.defaultProps = {
  caseSpecificFieldValues: [],
};

RepaymentTotalAmount.propTypes = {
  caseSpecificFieldValues: PropTypes.arrayOf(PropTypes.shape()),
  setIsRepaymentSectionOpened: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  caseSpecificFieldValues: repaymentEvalSelectors.caseSpecificFieldValues(state),
});

export default connect(mapStateToProps, null)(RepaymentTotalAmount);
