/* eslint-disable no-param-reassign */
import * as R from 'ramda';

const gatherEscrowData = (item) => {
  const { escrowData } = item;
  item.subTasks.forEach((val) => {
    const id = R.prop('id', val);
    if (escrowData && escrowData[id] !== null && escrowData[id] !== undefined) {
      val.value = escrowData[id];
    }
  });
  return {
    ...item,
    value: escrowData,
  };
};

export default gatherEscrowData;
