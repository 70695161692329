import {
  SET_NPV_HISTORY_DATA,
  GET_PROCESSED_DATA,
  SET_NPV_REQUEST_ID,
  SET_NPV_RESULT_DATA,
  SET_RESULT_OPERATION,
  RESET_UPLOAD_STATUS,
  SET_NPV_RQST_STS,
  SET_NPV_MAN_FLG,
  SET_NPV_RQST_FILE_DATA,
  SET_NPV_INVESTOR_REPORT_DATA,
  SET_CASE_ID,
  CANCEL_NPV_STATUS,
} from './types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROCESSED_DATA:
      return {
        ...state,
        excelParsedData: action.payload,
      };

    case SET_NPV_HISTORY_DATA:
      return {
        ...state,
        npvHistory: action.payload,
      };

    case SET_RESULT_OPERATION:
      return {
        ...state,
        uploadStatus: action.payload,
      };
    case RESET_UPLOAD_STATUS:
      return {
        ...state,
        uploadStatus: null,
      };
    case SET_NPV_REQUEST_ID:
      return {
        ...state,
        npvRequestId: action.payload,
      };
    case SET_NPV_RESULT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_NPV_MAN_FLG: {
      const response = action.payload;
      const { manualFlag } = response;
      return {
        ...state,
        manualFlag,
      };
    }
    case SET_NPV_RQST_STS: {
      const response = action.payload;
      const { npvResults } = response;
      const npvResult = npvResults.find(item => item.title === 'NPV Result');
      const npvStatus = npvResult && npvResult.value;
      return {
        ...state,
        npvRequestStatus: npvStatus,
      };
    }
    case SET_NPV_RQST_FILE_DATA:
      return {
        ...state,
        npvRequestFileData: action.payload,
      };
    case SET_NPV_INVESTOR_REPORT_DATA:
      return {
        ...state,
        npvInvestorReportData: action.payload,
      };
    case SET_CASE_ID: {
      const response = action.payload;
      const { npvInfo } = response;
      const caseId = npvInfo[0].value;
      return {
        ...state,
        npvCaseId: caseId,
      };
    }
    case CANCEL_NPV_STATUS:
      return {
        ...state,
        cancelNPVStatus: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
