import React from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { operations, selectors } from 'ducks/income-calculator';
import { selectors as loginSelectors } from 'ducks/login';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './AddChecklist.css';
import { selectors as dashboardSelectors } from 'ducks/dashboard';

const AddChecklist = (props) => {
  const {
    getFinancialChecklist, userGroupList, historyView, incomeCalcLockId,
    expenseCalcLockId, calcType, isFreshIncomeChecklist, isFreshExpenseChecklist,
  } = props;
  const isLossMittAgent = !R.isEmpty(R.intersection(userGroupList, ['lossmitigation', 'lossmitigation-mgr']));
  const financialLockId = calcType === 'incomeCalcData' ? incomeCalcLockId : expenseCalcLockId;
  const isNewFinancialChecklist = calcType === 'incomeCalcData' ? isFreshIncomeChecklist : isFreshExpenseChecklist;
  return (
    (isLossMittAgent) ? (
      <Tooltip aria-label="Add Task" placement="left" title="ADD NEW">
        <Fab
          aria-label="Add"
          color="secondary"
          disabled={historyView || (financialLockId === 0 && !isNewFinancialChecklist)}
          onClick={getFinancialChecklist}
          size="small"
          styleName="addNewbutton"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    ) : null
  );
};

AddChecklist.defaultProps = {
  historyView: false,
  calcType: 'incomeCalcData',
  incomeCalcLockId: null,
  expenseCalcLockId: null,
};

AddChecklist.propTypes = {
  calcType: PropTypes.string,
  expenseCalcLockId: PropTypes.number,
  getFinancialChecklist: PropTypes.func.isRequired,
  historyView: PropTypes.bool,
  incomeCalcLockId: PropTypes.number,
  isFreshExpenseChecklist: PropTypes.bool.isRequired,
  isFreshIncomeChecklist: PropTypes.bool.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  userGroupList: loginSelectors.getGroupList(state),
  historyView: selectors.getHistoryView(state),
  calcType: selectors.getCalcType(state),
  incomeCalcLockId: dashboardSelectors.getIncomeLockIdForWidget(state),
  expenseCalcLockId: dashboardSelectors.getExpenseLockIdForWidget(state),
  isFreshIncomeChecklist: dashboardSelectors.isFreshIncomeChecklist(state),
  isFreshExpenseChecklist: dashboardSelectors.isFreshExpenseChecklist(state),
});

const mapDispatchToProps = dispatch => ({
  getFinancialChecklist: operations.getFinancialChecklistForWidget(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChecklist);
