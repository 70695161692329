import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import './CreateStateEvalCase.css';
import SubmittedRecords from 'components/SubmittedRecords';
import PopupContainer from 'components/PopupContainer';
import InputFieldMultiline from 'components/ProductSupport/InputFieldMultiline';
import SweetAlertBox from '../../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../../state/ducks/dashboard';
import { LOAN, PATH_VARIABLE_CREATE_STATE_EVAL_CASE } from '../../../../constants/busniessAdmin';
import {
  selectors as loginSelectors,
} from '../../../../state/ducks/login';
import { operations as businessAdminOperations, selectors as businessAdminSelectors } from '../../../../state/ducks/admin-tools';


const CreateStateEvalCase = ({
  user,
  handleCreateStateEvalCaseRequest,
  recordsInfo,
  resultOperation, closeSweetAlert,
}) => {
  const defaultCaseObj = {
    type: 'evaluation',
    loanID: 0,
    skipEval: false,
  };

  const [caseObj, setCaseObj] = useState(defaultCaseObj);
  const [idLimitValidation, setIdLimitValidation] = useState(false);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (caseObj.loanID === '') {
      setCaseObj(prevState => ({
        ...prevState,
        loanID: 0,
      }));
    }
  }, [caseObj]);


  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);


  useEffect(() => {
    setCaseObj(defaultCaseObj);
  }, [recordsInfo]);

  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };


  const [compData, setCompData] = useState({
    show: false,
    isDisabled: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCaseObj({ ...caseObj, [name]: checked });
  };

  const handleChangeID = (event) => {
    const { name, value } = event.target;
    const updatedObjectNew = { ...caseObj };
    const validIdEntries = RegExp(/[a-z]/gi);
    updatedObjectNew[name] = !validIdEntries.test(value) ? value.split(/,| /).join('\n') : '';
    const conIdsArray = value && value.split('\n').map(id => +id);
    setCaseObj(updatedObjectNew);
    setIdLimitValidation(conIdsArray.length > 100);
    setCompData({ ...compData, isDisabled: value.length > 0 });
  };

  const clearEdits = () => {
    setCaseObj(defaultCaseObj);
    setCompData({
      show: false,
      isDisabled: false,
      loanID: 0,
    });
  };

  const handleClose = () => {
    const { show } = compData;
    setCompData({
      ...compData, show: !show,
    });
  };

  const [requestData, setRequestData] = useState({});

  const handleSubmit = () => {
    const loanIds = caseObj.loanID.split('\n').map(id => +id).filter(id => id !== 0);
    const requestObj = {
      ...caseObj,
      pathVariable: PATH_VARIABLE_CREATE_STATE_EVAL_CASE,
      action: 'CREATE_STATE_EVAL_CASE',
      busKeyType: LOAN,
      email: user.userDetails.email,
      loanIds,
    };
    setRequestData(requestObj);
    setCompData({ show: true, isDisabled: false });
  };

  const handleConfirm = () => {
    handleCreateStateEvalCaseRequest(requestData);
    handleClose();
  };

  return (
    <div styleName="form-fields-container">
      <InputFieldMultiline
        errorMsg={idLimitValidation && 'Entries exceeded the maximum limit of 100'}
        handleChange={handleChangeID}
        label="loan ID"
        name="loanID"
        value={caseObj.loanID}
      />
      <div
        style={{ justifyContent: 'flex-end', margin: '-12px 0 10px' }}
        styleName="flex-container"
      >
        <FormControlLabel control={<Checkbox checked={caseObj.skipEval} name="skipEval" onChange={handleChange} />} label="Skip Evaluation Rejection Rules" style={{ minWidth: '225px' }} />
      </div>
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={!compData.isDisabled}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={idLimitValidation || !compData.isDisabled} onClick={handleSubmit} variant="contained">
          SUBMIT
        </Button>
      </div>
      <PopupContainer
        handleClose={handleClose}
        show={compData.show}
        sizeSmall
        title="Do you proceed to SUBMIT?"
      >
        <div
          style={{ justifyContent: 'flex-start', padding: '20px' }}
          styleName="flex-container"
        >
          <Button color="primary" onClick={handleConfirm} variant="contained">
            CONFIRM
          </Button>
          <Button
            color="primary"
            onClick={handleClose}
            style={{ marginLeft: '8px' }}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </PopupContainer>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Case ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      {resultOperation && resultOperation.status && (
      <SweetAlertBox
        message={renderContent}
        onConfirm={() => handleDialogClose()}
        show={isOpen}
        type={msgType}
      />
      )}
    </div>
  );
};

CreateStateEvalCase.defaultProps = {
  handleCreateStateEvalCaseRequest: () => { },
  closeSweetAlert: () => {},

};

CreateStateEvalCase.propTypes = {
  closeSweetAlert: PropTypes.func,

  handleCreateStateEvalCaseRequest: PropTypes.func,
  recordsInfo: PropTypes.shape({
    failureCount: PropTypes.number,
    success: PropTypes.bool,
    successCount: PropTypes.number,
    unsuccessfulRecords: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  recordsInfo: businessAdminSelectors.getRecordsInfo(state),
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  handleCreateStateEvalCaseRequest: businessAdminOperations
    .submitModSupportRequestData(dispatch),
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStateEvalCase);
