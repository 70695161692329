import React from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AccountServicesHistory from 'containers/AccountServices/AccountServicesHistory';
import Loader from 'components/Loader/Loader';
import UITaskGenerator from 'components/UITaskGenerator';
import { ACCOUNT_SERVICE_EVENT_TYPES } from 'constants/common';
import accountServiceTemplate from 'constants/AccountService/accountService';
import ConfirmationDialogBox from 'components/Tasks/OptionalTask/ConfirmationDialogBox';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { operations as accountServiceOperations, selectors as accountServiceSelectors } from 'ducks/accountService';
import { operations as checkListOperations } from 'ducks/tasks-and-checklist';

function RejectScreen(props) {
  const {
    children, gatherEscrowDataOperation, showDialog, escrowData, onChange, processActionCommand,
    showConfirmationDialog,
    handleConfirmDialogBoxButtons, closeDialog, eventType, setEventType,
    toggleAccountServiceScreen,
    unAssignLoan, isRejectBtnClicked, showLoaderInPopup,
    clearInfo, clearPromptError, isActionFromLoanSearch, setChecklistCenterPaneData,
    setToCreateNewRqstOperation, showLoaderOperation,
  } = props;

  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item styleName="displayCenter" xs={2}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
                Account Services
            </Typography>
            <AccountServicesHistory />
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={10}>
          <Grid id="rejectButton" styleName="screenHeader">
            <Button
              onClick={() => {
                isRejectBtnClicked(true);
                gatherEscrowDataOperation(ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER);
              }}
              styleName="analysisBreakdown"
            >
                    REJECT
            </Button>
          </Grid>
          <Grid id="close" item styleName="screenHeader">
            <Button
              onClick={() => {
                closeDialog();

                if (isActionFromLoanSearch && !showConfirmationDialog) {
                  clearInfo();
                  setChecklistCenterPaneData('');
                }
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      { children }
      {showDialog && (
      <Dialog onClose={() => {}} open={() => {}}>
        {
          showLoaderInPopup ? (
            <div styleName="loanDetailsPopupLoader">
              <Loader message="Please Wait" />
            </div>
          )
            : (
              <UITaskGenerator
                checklistItems={accountServiceTemplate.loanDetailsTemplate}
                escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER]}
                onChange={onChange}
                processAction={processActionCommand}
              />
            )
        }
      </Dialog>
      )}
      <ConfirmationDialogBox
        isOpen={showConfirmationDialog}
        message=""
        onClose={(value) => {
          handleConfirmDialogBoxButtons(value);
          if (value && eventType === ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER
            && !isActionFromLoanSearch) {
            showLoaderOperation(true);
            setToCreateNewRqstOperation(false);
            setEventType(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
            gatherEscrowDataOperation(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
          }
          if (value && eventType === ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE
            && !isActionFromLoanSearch) {
            toggleAccountServiceScreen(false);
            unAssignLoan();
          }
          if (value && isActionFromLoanSearch) {
            if (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER) {
              toggleAccountServiceScreen(false);
              clearPromptError();
              setEventType(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
              return;
            }
            clearInfo();
            setChecklistCenterPaneData('');
          }
        }}
        title="Are you sure you want to discard the changes?"
      />
    </Grid>
  );
}

RejectScreen.propTypes = {
  children: PropTypes.node.isRequired,
  clearInfo: PropTypes.func.isRequired,
  clearPromptError: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  escrowData: PropTypes.shape.isRequired,
  eventType: PropTypes.string.isRequired,
  gatherEscrowDataOperation: PropTypes.func.isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  isActionFromLoanSearch: PropTypes.bool.isRequired,
  isRejectBtnClicked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  processActionCommand: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setEventType: PropTypes.func.isRequired,
  setToCreateNewRqstOperation: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  showDialog: PropTypes.bool.isRequired,
  showLoaderInPopup: PropTypes.bool.isRequired,
  showLoaderOperation: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  unAssignLoan: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showDialog: accountServiceSelectors.showDialog(state),
  escrowData: accountServiceSelectors.getEscrowData(state),
  showConfirmationDialog: accountServiceSelectors.showConfirmationDialog(state),
  eventType: accountServiceSelectors.getAccountServiceEventType(state),
});

const mapDispatchToProps = dispatch => ({
  gatherEscrowDataOperation: accountServiceOperations.gatherEscrowDataOperation(dispatch),
  onChange: accountServiceOperations.onValueChange(dispatch),
  processActionCommand: checkListOperations.preProcessChecklistItems(dispatch),
  handleConfirmDialogBoxButtons: accountServiceOperations.handleConfirmDialogBoxButtons(dispatch),
  closeDialog: accountServiceOperations.closedialog(dispatch),
  setEventType: accountServiceOperations.setEventType(dispatch),
  toggleAccountServiceScreen: accountServiceOperations.toggleAccountServiceScreen(dispatch),
  isRejectBtnClicked: accountServiceOperations.isRejectBtnClickedOperation(dispatch),
  setToCreateNewRqstOperation: accountServiceOperations.setToCreateNewRqstOperation(dispatch),
  showLoaderOperation: accountServiceOperations.showLoaderOperation(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RejectScreen));
