import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { selectors } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import PopupContainer from '../../../PopupContainer';
import './ForeClosureFirm.css';


const renderDetails = (data, keys) => (
  <Box style={{ padding: '2rem' }} sx={{ flexGrow: 2 }}>
    <Grid alignItems="center" container justify="center" margin={2} spacing={2}>
      {keys ? keys.map(key => (
        <>
          <Grid item styleName="gridItem" xs={6}>
            <div styleName="heading">{key}</div>
            <div styleName="value">{data[key]}</div>
          </Grid>
        </>
      )) : 'No Data'}
    </Grid>
  </Box>
);
const ForeClosureFirm = ({
  foreclosurefirmData, loading, show, handleClose,
}) => (
  <PopupContainer handleClose={handleClose} show={show} title="Foreclosure Firm">
    <div styleName="container">
      {loading ? <Loader message="Please Wait" />
        : renderDetails(foreclosurefirmData, Object.keys(foreclosurefirmData))}
    </div>
  </PopupContainer>
);

ForeClosureFirm.defaultProps = {
  foreclosurefirmData: {},
  loading: false,
  show: false,
};

ForeClosureFirm.propTypes = {
  foreclosurefirmData: PropTypes.arrayOf(PropTypes.shape),
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  show: PropTypes.bool,
};

const mapStateToProps = state => ({
  foreclosurefirmData: selectors.getForeclosureFirmData(state),
  loading: selectors.getLoader(state),
});

export default connect(mapStateToProps)(ForeClosureFirm);
