import { nonNumericKeys } from 'constants/common';
import moment from 'moment-timezone';

const formatDate = dateString => new Date(dateString).toLocaleString('en-US', { month: 'short' });

function splitJoin(str) {
  return str.split('@')[0].split('.').join(' ');
}

function formatAccountServicesData(escrowData) {
  const inputData = Object.assign({}, escrowData);

  Object.keys(inputData).filter(key => !nonNumericKeys.includes(key)).forEach((key) => {
    if (inputData[key] === null) {
      inputData[key] = 0;
    }
  });

  const taxInsTotal = (Math.round((inputData.taxes + inputData.hazardInsurance
    + inputData.mortgageInsurance + inputData.shortage) * 100) / 100);

  const isDelinquentOnPropertyTaxes = inputData.isDelinquentOnPropertyTaxes ? 'Yes' : 'No';

  const compBy = inputData.completedBy ? splitJoin(inputData.completedBy) : '';

  const reqBy = inputData.requestedBy ? splitJoin(inputData.requestedBy) : '';

  const types = {
    escrowInformationRequestDelinquentTaxes: 'Delinquent Taxes',
    escrowInformationRequestLegalFees: 'Legal Fees',
    escrowInformationRequestEscrow: 'Escrow',
  };

  const reqTypes = Object.keys(types).filter(key => inputData[key]).map(key => types[key]);

  if (inputData.escrowInformationRequestAll) {
    reqTypes.splice(0, 3);
    reqTypes.push('All');
  }

  const { analysisBreakdown } = inputData;
  const { currentPayOff, futureDisbursements } = analysisBreakdown;

  if (currentPayOff) {
    Object.keys(currentPayOff).forEach((key) => {
      if (currentPayOff[key] === null) {
        currentPayOff[key] = '';
      }
    });
  }

  const futureDisbursementsUpdated = futureDisbursements
    && futureDisbursements.map(obj => Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value || 0]),
    ));

  const DIS_TBL = futureDisbursementsUpdated.map(({
    transactionLineDate, tax, insurance, mortgageInsurance,
  }) => ({
    month: formatDate(transactionLineDate),
    tax: tax || '0',
    insurance: insurance || '0',
    mi: mortgageInsurance || '0',
  }));

  const analysisBreakdownData = {
    PLP_AMT: currentPayOff && currentPayOff.projectedLowPointAmount,
    EA_CURR_INT_DUE: currentPayOff && currentPayOff.projectedLowPointDate && moment(currentPayOff.projectedLowPointDate).format('YYYY-MM'),
    MON_TAX: currentPayOff && currentPayOff.monthlyTax,
    MON_INS: currentPayOff && currentPayOff.monthlyInsurance,
    MON_MI: currentPayOff && currentPayOff.monthlyMortgageInsurance,
    PRO_SHR: currentPayOff && currentPayOff.projectedShortage,
    PRO_SPR_MON: currentPayOff && currentPayOff.projectedSpreadMonths,
    PRO_SPR_AMT: currentPayOff && currentPayOff.projectedSpreadAmount,
    TOT_ESC_PAY: currentPayOff && currentPayOff && currentPayOff.monthlyEscrowPayment,
    ESC_DISB: currentPayOff && currentPayOff.disbursement,
    ESC_BAL: currentPayOff && currentPayOff.escrowBalance,
    monthlyBorrowerPaidMi: currentPayOff && currentPayOff.monthlyBorrowerPaidMi,
    DIS_TBL,
  };

  return {
    MON_PAI: inputData.monthlyPrincipalAndInterest,
    CURR_INT_DUE: inputData.currentInterestDue,
    UNP_LEG_FEE: inputData.unpaidLegalFees,
    CUR_COR_ADV: inputData.currentCorporateAdvances,
    ESC_ADV: inputData.escrowAdvances,
    AOF_EXP: inputData.allOtherFeesAndExpenses,
    CP_PRIN: inputData.principal,
    NET_DISB: inputData.netDisbursement,
    REF_DATE: inputData.netDisbursementRefreshDate,
    CONV_DISB: inputData.conversionDisbursement,
    ANT_MOD_EFFD: inputData.anticipatedModEffectiveDate,
    MON_SRT_DATE: inputData.monthStart,
    MON_END_DATE: inputData.monthEnd,
    TAXES: inputData.taxes,
    HAZ_INS: inputData.hazardInsurance,
    MORT_INS: inputData.mortgageInsurance,
    SHORTAGE: inputData.shortage,
    TOTAL: taxInsTotal,
    FORCE_ESC: inputData.forceEscrow,
    ESCROW: inputData.escrow,
    TOTAL_ESC_SHORT: inputData.taxes,
    MONTH_SHORT_AMT: inputData.monthlyShortageAmount,
    EST_ESC_DISC: inputData.estimatedEscrowDisbursement,
    MOD_EFF_DATE: inputData.modEffectiveDate,
    REQ_TYPE_CB: reqTypes,
    REQ_DATE: inputData.requestDate,
    REQ_BY: reqBy,
    COMPL_DATE: inputData.completedDate,
    COMPL_BY: compBy,
    DEL_ON_PROP_TAX: isDelinquentOnPropertyTaxes,
    REQ_SPR_MON: inputData.requestSpread,
    REJECT_REASON: inputData.escrowRejectedComments,
    EST_PROP_TAX: inputData.estimatedDelinquentTaxes || '',
    analysisBreakdown: analysisBreakdownData,
  };
}

const transformers = {
  formatAccountServicesData,
};

export default transformers;
