import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core/';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  UPLOAD_GROUP,
  UPLOAD_RESULTS_ACCESS,
  RQST_FILE_ACCESS,
  INVESTOR_REPORT_ACCESS,
} from 'constants/netPresentValue';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as loginSelectors } from 'ducks/login';
import { operations, selectors as netpresentvalueSelectors } from 'ducks/netpresentvalue';
import FileUploadDialog from './FileUploadDialog';

function NPVDataFile(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    groupName, isDisabled, npvRqstStatus, user, userGroupList, NPVResultHistoryData, manualFlag,
  } = props;
  const [manFlag, setManFlag] = useState(manualFlag);
  const accessUpload = R.contains(groupName, UPLOAD_GROUP);
  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    setManFlag(manualFlag);
  }, manualFlag);

  const handleInvestorReportDownload = () => {
    const { npvInvestorReportData, npvCaseId, npvRequestId } = props;
    const data = {
      caseId: npvCaseId,
      requestId: npvRequestId,
    };
    npvInvestorReportData(data);
  };


  const handleRequestFile = () => {
    const { npvRequestFileData, npvCaseId, npvRequestId } = props;
    const data = {
      caseId: npvCaseId,
      requestId: npvRequestId,
      userId: user,
    };
    setManFlag('1');
    npvRequestFileData(data);
  };

  return (
    <Grid container direction="row-reverse" spacing={2}>
      {accessUpload
        && (UPLOAD_RESULTS_ACCESS.some(group => userGroupList.includes(group)))
        && (npvRqstStatus === 'Pending')
        && (
          <Grid item>
            <Button
              color="primary"
              disabled={isDisabled || manFlag !== '1'}
              id="uploadButton"
              onClick={() => setDialogOpen(true)}
              startIcon={<img alt="fileupload" src="/static/img/fileuploadsmall.svg" />}
              variant="contained"
            >
              UPLOAD RESULTS
            </Button>
            <FileUploadDialog
              handleClose={handleClose}
              isOpen={dialogOpen}
            />
          </Grid>
        )
      }
      {(RQST_FILE_ACCESS.some(group => userGroupList.includes(group))) && (npvRqstStatus === 'Pending') && (
        <Grid item>
          <Button
            color="primary"
            disabled={isDisabled}
            id="requestFile"
            onClick={() => handleRequestFile()}
            startIcon={<img alt="filedownload" src="/static/img/filedownload.svg" />}
            variant="contained"
          >
            REQUEST FILE
          </Button>
        </Grid>
      )}
      {(INVESTOR_REPORT_ACCESS.some(group => userGroupList.includes(group))) && (npvRqstStatus !== 'Pending' && npvRqstStatus !== 'Cancelled') && (
        <Grid item>
          {Object.keys(NPVResultHistoryData).length > 0
            ? (
              <Button
                color="primary"
                disabled
                id="investorReport"
                startIcon={<img alt="investorReportDownload" src="/static/img/filedownload.svg" />}
                variant="contained"
              >
                INVESTOR REPORT
              </Button>
            )
            : (
              <Button
                color="primary"
                id="investorReport"
                onClick={() => handleInvestorReportDownload()}
                startIcon={<img alt="investorReportDownload" src="/static/img/filedownload.svg" />}
                variant="contained"
              >
                INVESTOR REPORT
              </Button>
            )
          }
        </Grid>
      )}
    </Grid>

  );
}

NPVDataFile.defaultProps = {
  NPVResultHistoryData: {},
  userGroupList: [],
};

NPVDataFile.propTypes = {
  groupName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  manualFlag: PropTypes.string.isRequired,
  npvCaseId: PropTypes.string.isRequired,
  npvInvestorReportData: PropTypes.func.isRequired,
  npvRequestFileData: PropTypes.func.isRequired,
  npvRequestId: PropTypes.number.isRequired,
  NPVResultHistoryData: PropTypes.shape({
    createBy: PropTypes.string,
    createDate: PropTypes.string,
    npvStatus: PropTypes.string,
  }),
  npvRqstStatus: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  groupName: dashboardSelectors.groupName(state),
  user: loginSelectors.getUserPrincipalName(state),
  userGroupList: loginSelectors.getGroupList(state),
  npvRqstStatus: netpresentvalueSelectors.getNPVRqstStatus(state),
  npvCaseId: netpresentvalueSelectors.getNPVCaseId(state),
  npvRequestId: netpresentvalueSelectors.getNPVRequestId(state),
  manualFlag: netpresentvalueSelectors.getManualFlag(state),
});

const mapDispatchToProps = dispatch => ({
  npvInvestorReportData: operations.npvInvestorReportData(dispatch),
  npvRequestFileData: operations.npvRequestFileData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NPVDataFile);
