import React from 'react';
import { connect } from 'react-redux';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import EvalCase from '../../containers/Eval/EvalCase';
import CaseDetailsComp from '../../containers/Eval/MODCaseDetails';
import RepaymentCaseScreen from '../../containers/Repayment/RepaymentCaseScreen';
import { CASE_DETAILS, REPAYMENT_PLAN_CASE } from '../../constants/loanInfoComponents';
import { operations as evalOperation } from '../../state/ducks/eval';

function EvalWidget(props) {
  const { checklistCenterPaneView, setEvalScreenIcon } = props;
  setEvalScreenIcon('evalWidget');
  switch (checklistCenterPaneView) {
    case CASE_DETAILS:
      return <CaseDetailsComp />;
    case REPAYMENT_PLAN_CASE:
      return <RepaymentCaseScreen />;
    default:
      return <EvalCase navFromEvalWidget />;
  }
}

EvalWidget.defaultProps = {
  checklistCenterPaneView: 'Checklist',
};

EvalWidget.propTypes = {
  checklistCenterPaneView: PropTypes.string,
  setEvalScreenIcon: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
});

const mapDispatchToProps = dispatch => ({
  setEvalScreenIcon: evalOperation.setEvalScreenIconOpn(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalWidget);
