import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'ducks/eval';
import * as R from 'ramda';
// import utils from 'ducks/tasks-and-checklist/utils';
import DisplayCard from './DisplayCard';


const PreModInformation = ({
  bookingTabData,
}) => {
  // const { getCSTDateTime } = utils;
  const upbInfo = [
    {
      id: 'InterestBearingUPB',
      label: 'Interest Bearing UPB (at lock)',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'interestBearingUPB'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'ForecastedUPB',
      label: 'Forecasted UPB',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'forecastedUPB'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'ForebearanceAmount',
      label: 'Forebearance Amount',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'forbearanceAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'GrossUPB',
      label: 'Gross UPB',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'grossUPB'], bookingTabData),
      type: 'currency',
    },
  ];

  const terms = [
    {
      id: 'InterestRate',
      label: 'Interest Rate',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'interestRate'], bookingTabData),
      type: 'rate',
    },
    {
      id: 'MaturityDate',
      label: 'Maturity Date',
      override: 'N',
      value: R.pathOr('-', ['premodInformation', 'maturityDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'RemainingAmortizationDuration',
      label: 'Remaining Amortization Duration',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'amortizationDuration'], bookingTabData),
      type: 'number',
    },
    {
      id: 'LPI',
      label: 'LPI (at lock)',
      override: 'N',
      value: R.pathOr('-', ['premodInformation', 'lpiDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'CaseLockedDate',
      label: 'Case locked Date',
      override: 'N',
      value: R.pathOr('-', ['premodInformation', 'caseLockedDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'CurrentDueDate',
      label: 'Current Due Date',
      override: 'N',
      value: R.pathOr('-', ['premodInformation', 'caseDueDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'PI',
      label: 'PI',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'pi'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'TI',
      label: 'TI',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'ti'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'PITI',
      label: 'PITI',
      override: 'N',
      value: R.pathOr('', ['premodInformation', 'piti'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'FlexDocsOut',
      label: 'Flex Docs Out at 2nd Trial',
      override: 'N',
      value: R.pathOr('NA', ['premodInformation', 'flexDocs'], bookingTabData),
      options: [
        {
          displayName: 'Yes',
          value: 'Yes',
        },
        {
          displayName: 'No',
          value: 'No',
        },
        {
          displayName: 'NA',
          value: 'NA',
        },
      ],
      type: 'radio',
    },
    {
      id: '2Pager',
      label: '2 Pager',
      override: 'N',
      value: R.pathOr('NA', ['premodInformation', 'twoPager'], bookingTabData),
      options: [
        {
          displayName: 'Yes',
          value: 'Y',
        },
        {
          displayName: 'No',
          value: 'N',
        },
        {
          displayName: 'NA',
          value: 'NA',
        },
      ],
      type: 'radio',
    },
    {
      id: 'TrialCompleted',
      label: 'Trial Completed',
      override: 'N',
      value: R.pathOr('-', ['premodInformation', 'trialCompletedDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'BuyoutEligible',
      label: 'Buyout Eligible',
      override: 'N',
      value: R.pathOr('NA', ['premodInformation', 'buyoutEligible'], bookingTabData),
      options: [
        {
          displayName: 'Yes',
          value: 'Y',
        },
        {
          displayName: 'No',
          value: 'N',
        },
        {
          displayName: 'NA',
          value: 'NA',
        },
      ],
      type: 'radio',
    },
    {
      id: 'TrialPaymentNSF',
      label: 'Trial Payment NSF',
      override: 'N',
      value: R.pathOr('NA', ['premodInformation', 'trialPaymentNSF'], bookingTabData),
      options: [
        {
          displayName: 'Yes',
          value: 'Y',
        },
        {
          displayName: 'No',
          value: 'N',
        },
        {
          displayName: 'NA',
          value: 'NA',
        },
      ],
      type: 'radio',
    },

  ];
  return (
    <>
      {upbInfo && <DisplayCard data={upbInfo} highlightCells={['ForecastedUPB', 'GrossUPB']} highlightCellsWithBorder={['ForecastedUPB', 'GrossUPB']} title="UPB Information" />}
      {terms && <DisplayCard data={terms} hideDivider title="Terms" />}

    </>
  );
};

PreModInformation.propTypes = {
  bookingTabData: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  bookingTabData: selectors.bookingTabData(state),
});

export default connect(mapStateToProps)(PreModInformation);
