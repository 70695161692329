import * as R from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect } from 'react';
import '../BoardingTemplatePage.css';
import { PropTypes } from 'prop-types';

const CaseTypeDropDown = ({
  caseTypeOptions, selectedCaseType, setSelectedCaseType, selectedPhase,
  setIsSubmitDisabled, loanIds, setBoardingPhasesOperation, setSelectedPhase,
}) => {
  const handleCaseType = (event) => {
    setSelectedCaseType(event.target.value);
    setBoardingPhasesOperation(event.target.value);
    setSelectedPhase('');
  };

  useEffect(() => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    if (loanIds === '' || !re.test(loanIds)) {
      setIsSubmitDisabled(R.isEmpty(loanIds.trim())
      || R.isEmpty(selectedPhase) || R.isEmpty(selectedCaseType));
    }
  }, [selectedCaseType]);

  return (
    <FormControl variant="outlined">
      <Select
        displayEmpty
        id="eventCategoryDropdown"
        input={<OutlinedInput name="selectedEventCategory" />}
        label="casetype"
        onChange={handleCaseType}
        renderValue={(() => (
          <div>{selectedCaseType || 'Please Select'}</div>
        ))}
        styleName="drop-down-select"
        value={selectedCaseType}
      >
        {caseTypeOptions.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CaseTypeDropDown.propTypes = {
  caseTypeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  loanIds: PropTypes.string.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setBoardingPhasesOperation: PropTypes.func.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setSelectedCaseType: PropTypes.func.isRequired,
  setSelectedPhase: PropTypes.func.isRequired,
};

export default CaseTypeDropDown;
