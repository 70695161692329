// import * as R from 'ramda';
import {
  SET_PAST_DUE_PAYMENT_DETAILS,
  SET_MONTHLY_PAYMENT_DETAILS,
  UPDATE_MONTHLY_PAYMENT_DETAILS,
  SET_REPAYMENT_EVAL_ERROR_MSG,
  ENABLE_CALC,
  SET_PAYMENT_CHANGES_DETAILS,
  SHOW_LOADER, HIDE_LOADER,
  SET_REJECT_REASONS,
  SET_IS_CASE_REJECTED,
  FETCH_REPAY_DATA_LOADER,
  CLEAR_DATA,
  CALCULATE_LOADER,
  UPDATE_CALC_RESPONSE,
  FROM_EVAL_SUMMARY_PAGE,
  TOGGLE_LOCK_CASE,
} from './types';

const initialState = {
  monthlyPayment: {
    fees: 0,
    advances: 0,
    pendingPayment: 0,
  },
  isCaseRejected: false,
  enableCalc: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAST_DUE_PAYMENT_DETAILS: {
      const pastDuePayments = action.payload;
      return {
        ...state,
        pastDuePayments,
      };
    }

    case UPDATE_MONTHLY_PAYMENT_DETAILS: {
      const { key, value } = action.payload;
      // const { monthlyPayment } = state;
      const { caseSpecificFieldValues } = state.monthlyPayment;

      const objIndex = caseSpecificFieldValues.findIndex(e => e.columnName === key);

      if (objIndex > -1 && !Number.isNaN(value)) {
        caseSpecificFieldValues[objIndex].columnVal = value.toString();
        caseSpecificFieldValues[objIndex].isOverride = true;
      }

      return {
        ...state,
        monthlyPayment: { ...state.monthlyPayment, caseSpecificFieldValues },
      };
    }

    case SET_MONTHLY_PAYMENT_DETAILS: {
      const monthlyPayment = action.payload;
      return {
        ...state,
        monthlyPayment,
      };
    }

    case SET_REPAYMENT_EVAL_ERROR_MSG: {
      const errorMessage = action.payload;
      return {
        ...state,
        errorMessage,
      };
    }

    case ENABLE_CALC: {
      const value = action.payload;
      return {
        ...state,
        enableCalc: value,
      };
    }

    case SET_PAYMENT_CHANGES_DETAILS: {
      const paymentChanges = action.payload;
      return {
        ...state,
        paymentChanges,
      };
    }

    case SHOW_LOADER: {
      return {
        ...state,
        inProgress: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        inProgress: false,
      };
    }

    case SET_REJECT_REASONS: {
      const rejectReasons = action.payload;
      return {
        ...state,
        rejectReasons,
      };
    }

    case SET_IS_CASE_REJECTED: {
      const isCaseRejected = action.payload;
      return {
        ...state,
        isCaseRejected,
      };
    }

    case FETCH_REPAY_DATA_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        fetchRepayDataLoading: isLoading,
      };
    }

    case CLEAR_DATA: {
      return initialState;
    }

    case FROM_EVAL_SUMMARY_PAGE: {
      const fromEvalSummaryPage = action.payload;
      return {
        ...state,
        fromEvalSummaryPage,
      };
    }

    case CALCULATE_LOADER: {
      let isLoading = action.payload;
      isLoading = isLoading && (typeof isLoading === 'boolean') ? isLoading : false;
      return {
        ...state,
        calcLoading: isLoading,
      };
    }

    case UPDATE_CALC_RESPONSE: {
      const { payload } = action;
      const { monthlyPayment } = state;
      if (payload && payload !== null) {
        return {
          ...state,
          monthlyPayment: { ...monthlyPayment, ...payload },
        };
      }
      return {
        ...state,
        monthlyPayment,
      };
    }

    case TOGGLE_LOCK_CASE: {
      const lockValidation = action.payload;
      return {
        ...state,
        lockValidation,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
