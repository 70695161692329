import React from 'react';
import * as moment from 'moment';

export const modSupportActions = ['UnBook', 'UnReject', 'UnFail', 'Create State Eval Case', 'Update Interest Rate'];
// export const UPLOAD_DATA_LIST = [
//   'All', 'Case Update', 'Escrow', 'Eval', 'Repay Auto Extend', 'SMDU Manual'
// ];
export const UPLOAD_DATA_LIST = ['All', 'Case Update', 'Escrow', 'Eval', 'SMDU Manual'];
export const MOD_SUPPORT = 'Mod Support';
export const UPLOAD_DATA = 'UPLOAD_DATA';
export const UN_BOOK = 'UnBook';
export const UN_REJECT = 'UnReject';
export const UN_FAIL = 'UnFail';
export const CREATE_STATE_EVAL_CASE = 'Create State Eval Case';
export const UPDATE_INTEREST_RATE = 'Update Interest Rate';
export const UNBOOK_HISTORY_COLUMNS = ['date', 'caseId', 'createdBy', 'status', 'reason', 'unbookReason'];
export const CREATE_STATE_EVAL_CASE_HISTORY_COLUMNS = ['date', 'loanId', 'createdBy', 'skipRule', 'status', 'reason'];
export const UPDATE_INTEREST_RATE_HISTORY_COLUMNS = ['date', 'effectiveDate', 'interestName', 'createdBy', 'status', 'reason', 'currentRate'];
export const UNSUCCESSFUL_CREATE_STATE_EVAL_CASE_COLUMNS = ['loanId', 'reason'];
export const UNSUCCESSFUL_RECORD_TITLE = 'Unsuccessfull Records';
export const LOAN = 'Loan';
export const CASE = 'Case';
export const EVAL = 'Eval';
export const PATH_VARIABLE_CREATE_STATE_EVAL_CASE = 'createStateEvalCase';
export const PATH_VARIABLE_UNBOOK = 'unBook';
export const PATH_VARIABLE_UNFAIL = 'unFail';
export const PATH_VARIABLE_UNREJECT = 'unReject';
export const PATH_VARIABLE_UPDATE_INTEREST_RATE = 'updateInterestRate';
export const DEFAULT_ROWS_PER_PAGE = 50;


export const investorAndClassNameMapping = {
  fhlmc: 'fnmaapollomodrate',
  fnma: 'freddiemodrate',
  lak: 'lak',
  mrcpandemic: 'mrcpandemicrate',
  usaapandemic: 'usaapandemicrate',
  uwm: 'UWMRate',
  vul: 'vulrate',
  win: 'WINRate',
  pmms: 'PMMS',
};


function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const TEMPLATE_FILE_INFO = {
  'Case Update': {
    fileName: 'Case_Update_Template.xlsx',
    columnHeaders: [
      ['LoanId', 'Case Type', 'Phase', 'ExistingEvalId', 'ExistingCaseId', 'Interest Rate', 'Maturity Date', 'Start Date', 'Ammo Duration', 'Income', 'Expenses', 'Taxes', 'Trial Payment', 'Frequency', 'CapModRequestId', 'SMDUTransactionId', 'MoreThan90DLQ', 'Estimated PostMod UPB', 'Previous Forbearance Amount', 'Forbearance Amount', 'PVMID', 'PreMod P&I Payment', 'PreMod T&I Payment', 'Proposed P&I Amount', 'EffectiveSurveyRate', 'Qualifying UPB', 'NPVID', 'Capitalized Amount', 'CapitalizedCorpAdv', 'DelinquentInterest', 'Suspense Balance', 'Workouttype', 'Trial Plan Date 1', '1st Trial Expiration Date', '1- Date Payment Received', 'Trial Plan Date 2', '2nd Trial Expiration Date', '2- Date Payment Received', 'Trial Plan Date 3', '3rd Trial Expiration Date', '3- Date Payment Received', 'Trial Plan Date 4', '4th Trial Expiration Date', '4- Date Payment Received'],
    ],
  },
  Escrow: {
    fileName: 'Escrow_Template.xlsx',
    columnHeaders: [
      ['LoanId', 'RequestQuoteType', 'ModEffectiveDate', 'RequestSpread', 'DelinquentTaxes', 'DelinquentInsurance', 'UnpaidLegalFees'],
    ],
  },
  Eval: {
    fileName: 'Eval_Template.xlsx',
    columnHeaders: [
      ['LoanId', 'IsPreApproved', 'IsNonDelegated', 'IsInflight', 'IsSkipRejectionRules', 'IsSkipCaseRules'],
    ],
  },
  // 'Repay Auto Extend': {
  //   fileName: 'Repay_Template.xlsx',
  //   columnHeaders: [
  //     ['LoanId', 'Duration', 'Brand', 'BypassFlag'],
  //   ],
  // },
  'SMDU Manual': {
    fileName: 'SMDU_Template.xlsx',
    columnHeaders: [
      ['LoanId', 'DisasterType'],
    ],
  },
};

export const HISTORY_COLUMNS = {
  [UN_BOOK]: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Case ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'case ID',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'reason',
      label: 'UnBook Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'UnBook Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'failedReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
  [UN_FAIL]: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Case ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'case ID',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'reason',
      label: 'Unfail Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Unfail Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'failedReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
  [UN_REJECT]: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Case ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'case ID',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'reason',
      label: 'UnReject Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'UnReject Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'failedReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
  [CREATE_STATE_EVAL_CASE]: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Loan ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Loan ID',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'skipRule',
      label: 'Skip Rule',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Skip Rule',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value === 0 ? 'No' : 'Yes'),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'failedReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
  [UPDATE_INTEREST_RATE]: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'investorName',
      label: 'Interest Name',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Interest Name',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? (getKeyByValue(investorAndClassNameMapping, value) || '').toUpperCase() : '-'),
    },
    {
      name: 'currentRate',
      label: 'Rate',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Rate',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? `${value}%` : '-'),
    },
    {
      name: 'effectiveDate',
      label: 'Effective Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Effective Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'failedReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
  default: [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Status',
          size: 'small',
          color: 'action',
        },
      },
      // eslint-disable-next-line react/jsx-filename-extension
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{value === 1 ? 'Success' : 'Unsuccessful'}</span>),
    },
    {
      name: 'reason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Reason',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value || '-'),
    },
  ],
};

export const enumActionTypeMap = Object.freeze({
  [UN_REJECT]: 'UNREJECT',
  [UN_FAIL]: 'UNFAIL',
  [UN_BOOK]: 'UNBOOK',
  [CREATE_STATE_EVAL_CASE]: 'CREATE_STATE_EVAL_CASE',
  [UPDATE_INTEREST_RATE]: 'UPDATE_INTEREST_RATE',
  'Case Update': 'CASE_UPDATE',
  Escrow: 'ESCROW',
  Eval: 'EVAL',
  // 'Repay Auto Extend': 'REPAY_AUTO_EXTEND',
  'SMDU Manual': 'SMDU_MANUAL',
  All: 'EMPTY',
});
