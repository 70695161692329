import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import Timeline from '../../../components/Eval/Timeline/Timeline';
import { selectors, operations } from '../../../state/ducks/eval/index';
import './CalcLog.css';

const CalcLog = (props) => {
  const {
    calcLogsData, isCalcLogsLoading, fetchCalcLogs, isRepayCase,
  } = props;

  useEffect(() => {
    fetchCalcLogs(isRepayCase);
  }, []);

  return (
    <>
      {isCalcLogsLoading ? (
        <div styleName="calcLogLoader">
          <Loader message="Please wait, while fetching CalcLogs data" size={40} />
        </div>
      ) : (
        <div style={{ maxHeight: '60vh', overflow: 'auto' }} styleName="calclog-container">
          <Timeline inputData={calcLogsData} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  calcLogsData: selectors.calcLogs(state),
  isCalcLogsLoading: selectors.isCalcLogsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCalcLogs: operations.fetchCalcLogsData(dispatch),
});

CalcLog.defaultProps = {
  isCalcLogsLoading: false,
  fetchCalcLogs: () => {},
  isRepayCase: false,
};

CalcLog.propTypes = {
  calcLogsData: PropTypes.func.isRequired,
  fetchCalcLogs: PropTypes.func,
  isCalcLogsLoading: PropTypes.bool,
  isRepayCase: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalcLog);

export const TestHooks = {
  CalcLog,
};
