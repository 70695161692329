import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Grid, Typography,
} from '@material-ui/core';
import CardStyles from './CardStyles';
import BookingFields from '../../../../components/Eval/BookingFields';

const DisplayCard = ({
  title, data, hideDivider, highlightCells, highlightCellsWithBorder,
}) => {
  const classes = CardStyles({ hideDivider });
  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>{title}</Typography>
      <Grid container direction="column">
        {data && data.map(element => (
          <BookingFields
            details={element.details}
            highlightCells={highlightCells.includes(element.id)}
            highlightCellsWithBorder={highlightCellsWithBorder.includes(element.id)}
            id={element.id}
            label={element.label}
            options={element.options}
            type={element.type}
            value={element.value}
          />
        ))}
      </Grid>
    </Card>
  );
};

DisplayCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    details: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number,
        code: PropTypes.string,
        description: PropTypes.string,
      })),
      title: PropTypes.string,
    }),
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  hideDivider: PropTypes.bool,
  highlightCells: PropTypes.arrayOf,
  highlightCellsWithBorder: PropTypes.arrayOf,
  title: PropTypes.string,
};

DisplayCard.defaultProps = {
  title: '',
  hideDivider: false,
  highlightCells: [],
  highlightCellsWithBorder: [],
};

export default DisplayCard;
