import processBorrowerData from './processBorrowerData';
import processPartnerData from './processPartnerData';
import incomeTypeData from './incomeTypeData';
import processAddressText from './processAddressText';
import processExpenseBorrowerData from './processExpenseBorrowerData';
import gatherAnalysisBreakDownData from './gatherAnalysisBreakDownData';
import gatherEscrowData from './gatherEscrowData';

const preProcessFunctions = {
  PROCESS_BORROWER_DATA: processBorrowerData,
  PROCESS_PARTNER_DATA: processPartnerData,
  PROCESS_INCOME_TYPE: incomeTypeData,
  PROCESS_ADDR_TEXT: processAddressText,
  PROCESS_EXPENSE_BORROWER_DATA: processExpenseBorrowerData,
  GATHER_ESCROW_DATA: gatherEscrowData,
  GATHER_ANALYSIS_BREAKDOWN_DATA: gatherAnalysisBreakDownData,
};

export default preProcessFunctions;
