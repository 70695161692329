import {
  SET_HISTORY_DATA,
  BOARDING_TEMPLATE_SPEC,
  STORE_BOARDING_DATA,
  SET_EXCEPTION_DATA,
  SAVE_PROCESSED_FILE,
  CASE_TYPES_OPTION,
  BOARDING_PHASES,
} from './types';


const defaultState = {
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HISTORY_DATA: {
      const boardingTemplateHistory = action.payload;
      return {
        ...state,
        boardingTemplateHistory,
      };
    }
    case BOARDING_TEMPLATE_SPEC: {
      const { boardingTemplateSort } = action.payload;
      return {
        ...state,
        sortOrder: boardingTemplateSort,
      };
    }
    case STORE_BOARDING_DATA: {
      const onBoardData = action.payload;
      return {
        ...state,
        onBoardData,
      };
    }
    case SET_EXCEPTION_DATA: {
      const exceptionData = action.payload;
      return {
        ...state,
        exceptionData,
      };
    }
    case SAVE_PROCESSED_FILE: {
      return {
        ...state,
        excelParsedData: action.payload,
      };
    }
    case CASE_TYPES_OPTION: {
      return {
        ...state,
        caseTypes: action.payload,
      };
    }
    case BOARDING_PHASES: {
      return {
        ...state,
        boardingPhases: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
