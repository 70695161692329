
import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { FORMAT } from 'lib/Formatters';
import './RepaymentTableSummary.css';
import { NON_CURRENCY_ONLY_REGEX } from 'constants/eval';
import { DateFormatter } from '../../../lib/DateUtils';

function RepaymentTableSummary({
  row, handleMonthlyRepaymentValueChange,
  isPlanExtended, isEditable,
}) {
  const [monthlyRepaymentValue,
    setMonthlyRepaymentValue] = React.useState(row.paymentAmount);
  const [isDisabled, setIsDisabled] = React.useState(true);

  // const caseSts = status !== 'Open';
  // const isDisasterFlagMatch = ['E', 'B', 'PE', 'PB'].includes(disasterFlag);
  // const isRepaymentAmtEditable = caseSts && isDisasterFlagMatch;

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {row.description}
      </TableCell>
      <TableCell align="right">{DateFormatter(row.paymentDate, 'DD MMM YYYY')}</TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled
          id="outlined-contractualPortion-textBox"
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName="disabled-textBox"
          type="text"
          value={FORMAT.currency(row.contractualAmount.toString())}
        />
      </TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled
          id="outlined-delinqunecyPortion-textBox"
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName="disabled-textBox"
          type="text"
          value={FORMAT.currency(row.delinquencyPortion.toString())}
        />
      </TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled={isDisabled}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton edge="end" style={{ padding: '0' }}>
                {isEditable && (
                <EditIcon onClick={() => { setIsDisabled(false); }} styleName="editIcon" />
                )}
              </IconButton>
            </InputAdornment>
          )}
          id="outlined-monthlyRepaymentAmount-textBox"
          onBlur={() => {
            if (!FORMAT.currencyFloat(monthlyRepaymentValue)) {
              setMonthlyRepaymentValue(0);
            }
            setIsDisabled(true);
          }}
          onChange={(e) => {
            if (
              !NON_CURRENCY_ONLY_REGEX.test(
                e.target.value,
              )
            ) {
              const amt = FORMAT.currencyFloat(e.target.value);
              if (amt) {
                handleMonthlyRepaymentValueChange(amt);
              } else {
                handleMonthlyRepaymentValueChange(0);
              }
              setMonthlyRepaymentValue(e.target.value);
            }
          }
        }
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName={isDisabled ? 'monthly-payment-disabled-textBox' : 'enabled-textBox'}
          type="text"
          value={FORMAT.currency(monthlyRepaymentValue.toString())}
        />
      </TableCell>
      {
        isPlanExtended && (
          <TableCell align="right">{row.extendedDuration}</TableCell>
        )
      }
    </TableRow>
  );
}

RepaymentTableSummary.propTypes = {
  // disasterFlag: PropTypes.string.isRequired,
  handleMonthlyRepaymentValueChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPlanExtended: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    changeType: PropTypes.string.isRequired,
    contractualAmount: PropTypes.string.isRequired,
    delinquencyPortion: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    extendedDuration: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
  }).isRequired,
  // status: PropTypes.string.isRequired,
};


export default RepaymentTableSummary;
