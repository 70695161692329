import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { selectors as evalSelectors } from 'ducks/eval';
import PropTypes from 'prop-types';
import EvalTable from '../../../../components/Eval/EvalTable';


const columns = [
  {
    name: 'date',
    label: 'Date',
    cellFormat: item => moment(item).format('DD MMM YYYY'),
  },
  {
    name: 'rate',
    label: 'Rate',
    cellFormat: item => `${Number.isNaN(Number(item)) ? 0 : (Number(item) * 100).toFixed(3)}%`,
  },
  {
    name: 'amount',
    label: 'Amount',
    cellFormat: item => `$${item}`,
  },
  {
    name: 'duration',
    label: 'Duration',
  },
];

// const mockData = [
//   {
//     rate: 0.03,
//     date: '2023-06-01T00:00:00',
//     amount: 1105.46,
//     duration: 480,
//   },
// ];

const StepRates = ({ stepRatesData }) => (
  <div>
    <EvalTable columns={columns} data={stepRatesData || []} />
  </div>
);

StepRates.defaultProps = {
  stepRatesData: [],
};

StepRates.propTypes = {
  stepRatesData: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = state => ({
  stepRatesData: evalSelectors.stepRates(state),
});


export default connect(mapStateToProps, null)(StepRates);
