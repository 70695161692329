import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import * as R from 'ramda';
import SubmittedRecords from 'components/SubmittedRecords';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField/InputField';
import SweetAlertBox from '../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../state/ducks/dashboard';

import Popup from '../../../components/Popup';
import './EvalDisasterIdRequest.css';

const defaultFormData = {
  ticket: '',
  evalId: '0',
  toUpdate: '',
  disasterID: '0',
  requestorID: '',
  comments: '',
};

const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

const EvalDisasterIdRequest = ({
  setIdType, updateRequest, recordsInfo, resultOperation, closeSweetAlert,
}) => {
  const [evalDisasterObj, setevalDisasterObj] = useState(defaultFormData);
  const [disableButton, setDisableButton] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setIdType('eval');
  }, []);
  const [ispopupVisible, setIspopupVisible] = useState(false);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);

  useEffect(() => {
    if (isSubmitted && recordsInfo.successCount !== 0 && recordsInfo.failureCount !== 0) {
      setevalDisasterObj(defaultFormData);
      setIsSubmitted(false);
      setDisableButton(true);
      setDisableUpdate(true);
    }
  }, [recordsInfo]);

  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...evalDisasterObj };
    updatedObject[name] = value;
    setevalDisasterObj(updatedObject);
  };

  const handleOnBlur = () => {
    const {
      ticket, evalId, disasterID, requestorID, comments,
    } = evalDisasterObj;
    setDisableUpdate(!(checkNotNullAndEmpty(ticket) && checkNotNullAndEmpty(evalId)
        && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)
        && checkNotNullAndEmpty(disasterID)));
    setDisableButton(!(checkNotNullAndEmpty(ticket) || checkNotNullAndEmpty(evalId)
        || checkNotNullAndEmpty(requestorID) || checkNotNullAndEmpty(comments)
        || checkNotNullAndEmpty(disasterID)));
  };

  const handleSubmit = () => {
    updateRequest(evalDisasterObj);
    setIspopupVisible(false);
    setIsSubmitted(true);
  };

  return (
    <div styleName="form-fields-container">
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={evalDisasterObj.ticket}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Eval ID"
        name="evalId"
        type="number"
        value={evalDisasterObj.evalId}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Disaster ID"
        name="disasterID"
        type="number"
        value={evalDisasterObj.disasterID}
      />

      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={evalDisasterObj.requestorID}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={evalDisasterObj.comments}
      />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={() => {
            setevalDisasterObj(defaultFormData);
            setDisableButton(true);
            setDisableUpdate(true);
          }}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button
          color="primary"
          disabled={disableUpdate}
          onClick={() => setIspopupVisible(true)}
          variant="contained"
        >
          UPDATE REQUEST
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Eval ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
      {resultOperation && resultOperation.status && (
      <SweetAlertBox
        message={renderContent}
        onConfirm={() => handleDialogClose()}
        show={isOpen}
        type={msgType}
      />
      )}
    </div>
  );
};

EvalDisasterIdRequest.defaultProps = {
  closeSweetAlert: () => {},
};


EvalDisasterIdRequest.propTypes = {
  closeSweetAlert: PropTypes.func,
  recordsInfo: PropTypes.shape().isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  setIdType: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalDisasterIdRequest);
