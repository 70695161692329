import React from 'react';
import { PropTypes } from 'prop-types';
import Pagination from '../components/Pagination/Pagination';
import OnBoardTable from '../components/Table/OnBoardTable';

const onBoard = ({ isUploadCompleted, resultData }) => (
  <>
    <div>
      <OnBoardTable
        isUploadCompleted={isUploadCompleted}
        resultData={resultData}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Pagination />
      </div>
    </div>
  </>
);
onBoard.propTypes = {
  isUploadCompleted: PropTypes.bool.isRequired,
  resultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default onBoard;
