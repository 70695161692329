const UPLOAD_GROUP = ['BEUW', 'UWSTAGER'];
const EXCEL_FORMATS = ['xlsx', 'xls'];
const ERROR = 'error';
const SUCCESS = 'success';
const ERROR_MSG = 'The NPV results failed to upload. Please check with field';
const ERROR_MSG2 = 'formats and/or values and retry uploading the NPV results.';
const ERROR_MSG3 = 'Incorrect Request ID';
const SUCCESS_MSG = 'Your file has been successfully uploaded.';
const SUCCESS_MSG2 = 'Please reload the screen to update the NPV Result.';
const UPLOAD_RESULTS_ACCESS = ['stager-mgr', 'rshstager-mgr', 'beuw-mgr', 'stager', 'rshstager', 'beuw'];
const RQST_FILE_ACCESS = ['stager-mgr', 'rshstager-mgr', 'beuw-mgr', 'stager', 'rshstager', 'beuw'];
const ORDERED_ACCESS = ['stager-mgr', 'rshstager-mgr', 'beuw-mgr'];
const UPLOAD_FILE_MESSAGE = 'Drag and Drop or click to Browse Files here';
const INVESTOR_REPORT_ACCESS = ['stager-mgr', 'rshstager-mgr', 'beuw-mgr', 'stager', 'rshstager', 'beuw'];

module.exports = {
  UPLOAD_GROUP,
  EXCEL_FORMATS,
  ERROR,
  SUCCESS,
  ERROR_MSG,
  ERROR_MSG2,
  ERROR_MSG3,
  SUCCESS_MSG,
  SUCCESS_MSG2,
  UPLOAD_RESULTS_ACCESS,
  RQST_FILE_ACCESS,
  ORDERED_ACCESS,
  UPLOAD_FILE_MESSAGE,
  INVESTOR_REPORT_ACCESS,
};
