import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GenericTabView from 'components/GenericTabView';
import Loader from 'components/Loader';
import SweetAlertBox from 'components/SweetAlertBox';
import utils from 'ducks/tasks-and-checklist/utils';
import {
  BOOKING,
  CASE_DETAILS,
  ELIGIBILITY, AMORTIZATION_SCHEDULE, CALC_LOG,
  CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  FULFILMENT_CHKLIST, TRACK_PAYMENT,
  NPV_DETAILS, EVAL_WIDGET,
  VA_STREAMLINE_TRIAL, AMRTZTN_SCHDL_UNAPPLICABLE_CASES,
  TRCK_PYMT_APPLICABLE_CASES_FOR_TRIAL, TRCK_PYMT_APPLICABLE_CASES_FOR_MOD,
  TRCK_PYMT_APPLICABLE_CASES_FOR_FORBEARANCE,
  TRCK_PYMT_OTHER_APPLICABLE_CASES, NPV_DETAILS_APPLICABLE_CASES,
  FULFILLMENT_CHECKLIST_APPLICABLE_CASES,
  BOOKING_APPLICABLE_CASES, STATE_ALT_TIER_1_TRIAL,
  CLOSED,
} from '../../../constants/eval';
import { selectors, operations } from '../../../state/ducks/eval';
import CaseDetails from './CaseDetails/index';
import EvalHeader from '../../../components/Eval/EvalHeader';
import CalcLog from '../CalcLog/CalcLog';
import Eligibility from './Eligibility/Eligibility';
import NpvDetails from './NpvDetails/NpvDetails';
import FulfillmentChecklist from '../FulfillmentChecklist/FulfillmentChecklist';
import CertainMortgageLoanChecklist from '../CertainMortgageLoanChecklist';
import './MODCaseDetails.css';
import Amortization from '../Amortization/Amortization';
import TrackPayment from './TrackPayment/TrackPayment';
import Booking from './Booking/Booking';


const CaseDetailsComp = (props) => {
  const {
    caseHeaderInfo, evalScreenIcon, lockExtValidationMsg, clearAlert,
    setToggleLockCase, inProgress,
  } = props;

  const {
    caseType, resolutionId, subStatus, createdDate, status,
    lockFlag, bookedFlag, sentForApprovalFlag, approvalIndicator, createdBy,
  } = caseHeaderInfo;
  const { getCSTDateTime } = utils;
  // const [isCaseEditable, setIsCaseEditable] = useState(false);
  // const [isTrackPayEditable, setIsTrackPayEditable] = useState(false);

  // useEffect(() => {
  // if (status === OPEN && evalScreenIcon === EVAL_WIDGET) {
  //   setIsCaseEditable(true);
  // }
  //   if (status === APPROVED && evalScreenIcon === EVAL_WIDGET) {
  //     setIsTrackPayEditable(true);
  //   }
  // }, [status]);

  const [tabsList, setTabsList] = useState([
    {
      index: 0,
      label: CASE_DETAILS,
      visibility: true,
      component: (
        <CaseDetails />
      ),
    },
    {
      index: 1,
      label: NPV_DETAILS,
      visibility: false,
      component: (
        <NpvDetails />
      ),
    },
    {
      index: 2,
      label: ELIGIBILITY,
      visibility: false,
      component: (
        <Eligibility />
      ),
    },
    {
      index: 3,
      label: AMORTIZATION_SCHEDULE,
      visibility: false,
      component: (
        <Amortization />
      ),
    },
    {
      index: 4,
      label: CALC_LOG,
      visibility: false,
      component: (
        <CalcLog />
      ),
    },
    {
      index: 5,
      label: TRACK_PAYMENT,
      visibility: false,
      component: (
        <TrackPayment />
      ),
    },
    {
      index: 6,
      label: FULFILMENT_CHKLIST,
      visibility: false,
      component: (
        <FulfillmentChecklist
          isfflmtEditable={evalScreenIcon === EVAL_WIDGET}
        />
      ),
    },
    {
      index: 7,
      label: CERTAIN_MORTGAGE_LOAN_CHECKLIST,
      visibility: false,
      component: (
        <CertainMortgageLoanChecklist />
      ),
    },
    {
      index: 8,
      label: BOOKING,
      visibility: false,
      component: (
        <Booking />
      ),
    },
  ]);

  useEffect(() => {
    setTabsList(tabsList.map((item) => {
      if (item.label === CASE_DETAILS) {
        return {
          ...item,
          visibility: true,
        };
      } if (item.label === ELIGIBILITY) {
        if (caseType !== VA_STREAMLINE_TRIAL) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === AMORTIZATION_SCHEDULE) {
        if (!AMRTZTN_SCHDL_UNAPPLICABLE_CASES.includes(caseType)) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === CALC_LOG) {
        if (caseType !== VA_STREAMLINE_TRIAL) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === FULFILMENT_CHKLIST) {
        if (sentForApprovalFlag === 1
          && FULFILLMENT_CHECKLIST_APPLICABLE_CASES.includes(caseType)) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === TRACK_PAYMENT) {
        if ((
          ((
            TRCK_PYMT_APPLICABLE_CASES_FOR_TRIAL.includes(caseType)
            || TRCK_PYMT_APPLICABLE_CASES_FOR_MOD.includes(caseType)
            || TRCK_PYMT_APPLICABLE_CASES_FOR_FORBEARANCE.includes(caseType)
          ) && approvalIndicator === 1
          )
          || (
            TRCK_PYMT_OTHER_APPLICABLE_CASES.includes(caseType)
            && sentForApprovalFlag === 1
          ) || (status === CLOSED)
        )) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === CERTAIN_MORTGAGE_LOAN_CHECKLIST) {
        if (caseType === STATE_ALT_TIER_1_TRIAL) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === BOOKING) {
        if (bookedFlag === 1 && BOOKING_APPLICABLE_CASES.includes(caseType)) {
          return {
            ...item,
            visibility: true,
          };
        }
      } else if (item.label === NPV_DETAILS) {
        if (NPV_DETAILS_APPLICABLE_CASES.includes(caseType) && lockFlag === 1) {
          return {
            ...item,
            visibility: true,
          };
        }
      }

      return {
        ...item,
        visibility: false,
      };
    }));
  }, [lockFlag, sentForApprovalFlag, approvalIndicator, bookedFlag]);

  const headerData = {
    evalCaseHeader: false,
    evalCaseType: caseType,
    createdOn: getCSTDateTime(createdDate),
    createBy: createdBy,
    status,
    subStatus,
    lockedFlag: lockFlag,
    data: {
      caseId: resolutionId,
    },
  };

  return (
    <div styleName="caseScreen">
      <EvalHeader headerData={headerData} />
      {inProgress && (
      <div styleName="caseScreenRejectLoader">
        <Loader message="Please wait" size={30} />
      </div>
      )}
      <GenericTabView tabsList={tabsList.filter(item => item.visibility === true)} />
      {lockExtValidationMsg && lockExtValidationMsg.saga === 'lockCaseWarningMsg' && (
        <SweetAlertBox
          message={lockExtValidationMsg.status}
          onConfirm={() => {
            clearAlert(null);
            setToggleLockCase(true);
          }}
          show={lockExtValidationMsg.saga === 'lockCaseWarningMsg'}
          type="Warning"
        />
      )}
    </div>
  );
};

CaseDetailsComp.defaultProps = {
  caseHeaderInfo: {},
  evalScreenIcon: '',
  clearAlert: () => { },
  setToggleLockCase: () => { },
};

CaseDetailsComp.propTypes = {
  caseHeaderInfo: PropTypes.shape(
    {
      approvalIndicator: PropTypes.number.isRequired,
      bookedFlag: PropTypes.number.isRequired,
      caseType: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      lockFlag: PropTypes.number.isRequired,
      resolutionId: PropTypes.string.isRequired,
      sentForApprovalFlag: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      subStatus: PropTypes.string.isRequired,
    },
  ),
  clearAlert: PropTypes.func,
  evalScreenIcon: PropTypes.string,
  inProgress: PropTypes.bool.isRequired,
  lockExtValidationMsg: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    saga: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setToggleLockCase: PropTypes.func,
};

const mapStateToProps = state => ({
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
  evalScreenIcon: selectors.getEvalScreenIcon(state),
  lockExtValidationMsg: selectors.lockExtValidationMsg(state),
  inProgress: selectors.isRejectCaseLoading(state),
});

const mapDispatchToProps = dispatch => ({
  clearAlert: operations.setLockExtValidationMsgOperation(dispatch),
  setToggleLockCase: operations.setLockToggleOperation(dispatch),
});

const CaseDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(CaseDetailsComp);
export default CaseDetailsContainer;
