const throttle = (cb, t) => {
  let last = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - last < t) return;
    last = now;
    // eslint-disable-next-line consistent-return
    return cb(...args);
  };
};

const debounce = (cb, t) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, t);
  };
};

module.exports = {
  throttle,
  debounce,
};
