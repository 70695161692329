import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { operations, selectors } from 'ducks/tasks-and-checklist';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './TextFields.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HTMLElements from '../../constants/componentTypes';
import {
  REJ_REASONS_SELECTOR, REJ_SUBREASONS_SELECTOR,
} from '../../constants/eval';

function getCurrentDate() {
  const date = new Date();
  const dateTime = moment(date).format('YYYY-MM-DD');
  return dateTime;
}

function getProps(type, props) {
  const {
    MULTILINE_TEXT, DATE, NUMBER, DROPDOWN,
  } = HTMLElements;
  switch (type) {
    case DATE: {
      return { ...props, inputProps: { type: 'date', max: getCurrentDate() } };
    }
    case MULTILINE_TEXT: {
      return {
        ...props, maxRows: 10, multiline: true, rows: 5,
      };
    }
    case NUMBER: {
      return {
        ...props,
        inputProps: {
          type: 'number',
          min: '0',
        },
      };
    }

    case DROPDOWN: {
      return {
        ...props,
        inputProps: {
          type: 'select',
          SelectProps: {
            native: true,
          },
        },
      };
    }
    default: return { ...props };
  }
}

class TextFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = '';
  }

  componentWillMount() {
    const { source, additionalInfo, fetchDropDownOption } = this.props;
    if (!R.isNil(source) && !R.isEmpty(source)) {
      fetchDropDownOption(source, additionalInfo);
    }
  }

  getDropDownSelectOptions() {
    const { getDropDownOption, additionalInfo: { selector } } = this.props;
    const getDropDownOptions = getDropDownOption(selector);
    return (getDropDownOptions
      ? getDropDownOptions.map(option => <MenuItem {...option}>{option.displayName}</MenuItem>)
      : null);
  }

  getDropDownOptions() {
    const { getDropDownOption, additionalInfo: { selector } } = this.props;
    const getDropDownOptions = getDropDownOption(selector);
    const isDisabled = option => !(R.propOr(true, 'isEnabled', option));
    return (getDropDownOptions ? getDropDownOptions.map((option, index) => {
      const idx = index + 1;
      let val;
      let displayName;
      if (selector && selector[0] && selector[0] === REJ_REASONS_SELECTOR) {
        val = option.reasonCd;
        displayName = val === null ? option.reason : `${option.reasonCd}_${option.reason}`;
      } else if (selector && selector[0] && selector[0] === REJ_SUBREASONS_SELECTOR) {
        val = option.subReasonCd;
        displayName = val === null ? option.subReason : `${option.subReasonCd}_${option.subReason}`;
      } else {
        val = option.userPrincipalName;
        const { displayName: name } = option;
        displayName = name;
      }
      return (
        <option
          key={idx}
          disabled={isDisabled(option)}
          value={val}
        >
          {displayName}
        </option>
      );
    }) : null);
  }

  getControl(type) {
    const { DROPDOWN } = HTMLElements;
    const { componentTitle, ...other } = this.props;
    const properties = getProps(type, { ...other });
    const { additionalInfo } = this.props;
    const selector = R.propOr([], 'selector', additionalInfo);
    const value = (selector && selector[0] === REJ_REASONS_SELECTOR)
      ? other.value && other.value.value : other.value;
    if (type === DROPDOWN && R.equals('dropdownSelect', R.propOr('', 'source', other))) {
      return (
        <Select
          displayEmpty
          styleName="dropdownSelectStyle"
          {...this.props}
          value={other.value || ''}
        >
          { this.getDropDownSelectOptions() }
        </Select>
      );
    }
    if (type === DROPDOWN) {
      return (
        <TextField
          styleName="dropDownStyle"
          {...this.props}
          margin="dense"
          select
          SelectProps={{
            native: true,
            MenuProps: {
              styleName: 'dropDownMenuStyle',
            },
          }}
          value={value || ''}
        >
          {
            (selector && selector[0]
              && (selector[0] === REJ_REASONS_SELECTOR || selector[0] === REJ_SUBREASONS_SELECTOR)
            )
              ? <option disabled selected value="" /> : null
          }
          { this.getDropDownOptions() }
        </TextField>
      );
    }

    return (<TextField {...properties} />);
  }

  render() {
    const { type, componentTitle } = this.props;
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" styleName="text-label">{componentTitle}</FormLabel>
        {this.getControl(type)}
      </FormControl>
    );
  }
}

TextFields.propTypes = {
  additionalInfo: PropTypes.string.isRequired,
  componentTitle: PropTypes.string.isRequired,
  fetchDropDownOption: PropTypes.string.isRequired,
  getDropDownOption: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchDropDownOption: operations.fetchDropDownOptions(dispatch),
});

const mapStateToProps = state => ({
  getDropDownOption: selectors.getDropDownOption(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextFields);
