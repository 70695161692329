import React, { useState } from 'react';
import {
  Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import './SubStatusRequest.css';
import PropTypes from 'prop-types';
import SubStatusRequestCase from './SubStatusRequestCase/SubStatusRequestCase';
import SubStatusRequestEval from './SubStatusRequestEval/SubStatusRequestEval';


const SubStatusRequest = ({ changeRadioSelect }) => {
  const [status, setStatus] = useState('case');

  const handleChange = (event) => {
    const { value } = event.target;
    setStatus(value);
    changeRadioSelect(value);
  };

  return (
    <div>
      <RadioGroup
        name="status"
        onChange={handleChange}
        row
        styleName="radio-group-container"
        value={status}
      >
        <FormControlLabel
          control={<Radio size="small" />}
          label="Case Substatus"
          value="case"
        />
        <FormControlLabel
          control={<Radio size="small" />}
          label="Eval Substatus"
          value="eval"
        />
      </RadioGroup>
      {status === 'case' ? <SubStatusRequestCase /> : <SubStatusRequestEval />}
    </div>
  );
};

SubStatusRequest.propTypes = {
  changeRadioSelect: PropTypes.func.isRequired,
};


export default SubStatusRequest;
