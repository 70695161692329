const FETCH_TOMBSTONE_DATA = 'app/indexer/FETCH_TOMBSTONE_DATA';
const RESET_DATA = 'app/indexer/RESET_DATA';
const UPDATE_EVAL_LSAMSDETAILS = 'app/indexer/UPDATE_EVAL_LSAMSDETAILS';
const FETCH_GRID_DATA = 'app/indexer/FETCH_GRID_DATA';
const FETCH_SEARCH_GRID_DATA = 'app/indexer/FETCH_SEARCH_GRID_DATA';
const SET_INDEXER_GRID_DATA = 'app/indexer/SET_INDEXER_GRID_DATA';
const FETCH_ACTIVE_USER = 'app/indexer/FETCH_ACTIVE_USER';
const SET_ACTIVE_USER_INFO = 'app/indexer/SET_ACTIVE_USER_INFO';
const SET_INDEXER_SEARCH_GRID_DATA = 'app/indexer/SET_INDEXER_SEARCH_GRID_DATA';
const SET_INDEXING_PAGE_VISIBILITY = 'app/indexer/SET_INDEXING_PAGE_VISIBILITY';
const SET_BORROWERS_DATA_INDEXER = 'app/indexer/SET_BORROWERS_DATA_INDEXER';

export {
  SET_INDEXING_PAGE_VISIBILITY,
  RESET_DATA,
  FETCH_TOMBSTONE_DATA,
  UPDATE_EVAL_LSAMSDETAILS,
  FETCH_GRID_DATA,
  SET_INDEXER_GRID_DATA,
  FETCH_ACTIVE_USER,
  SET_ACTIVE_USER_INFO,
  SET_INDEXER_SEARCH_GRID_DATA,
  FETCH_SEARCH_GRID_DATA,
  SET_BORROWERS_DATA_INDEXER,
};
