/* eslint-disable import/prefer-default-export */

const statusMessage = {
  202: 'Stager refresh has started',
  400: 'Refresh currently in progress for this bucket',
  500: 'Something went wrong',
};

export {
  statusMessage,
};
