import React from 'react';
import {
  AppBar, Box, Tab, Tabs,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './index.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ButtonTabs = (props) => {
  const {
    setCurrTab, tabsArray, className, currTab, position, disabled,
  } = props;
  const tabUnitStyles = {
    fontSize: '12px', lineHeight: '15px', color: '#4E586E', minHeight: '0', minWidth: '60px',
  };
  const displayHeader = tabsArray && tabsArray.map((tabHeader, index) => {
    const uniqueKey = index + 1;
    return (
      <Tab
        key={uniqueKey}
        disableRipple
        label={tabHeader}
        onClick={() => {
          setCurrTab(index);
        }}
        wrapped
        {...a11yProps(index)}
        disabled={disabled}
        style={tabUnitStyles}
      />
    );
  });

  const styles = { boxShadow: 'none', zIndex: '999' };
  const tabStyles = {
    backgroundColor: 'white', color: '#4E586E', minHeight: '30px', paddingLeft: '10px',
  };
  return (
    <Box className="tabContainer" sx={{ bgcolor: 'background.paper' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <AppBar
          className="tabAppBar"
          color="default"
          position={position === 'fixed' ? 'fixed' : 'relative'}
          style={styles}
        >
          <Tabs
            // allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            className={className}
            scrollButtons="auto"
            style={tabStyles}
            value={currTab}
            variant="scrollable"
          >
            {displayHeader}
          </Tabs>
        </AppBar>
      </Box>
    </Box>
  );
};

ButtonTabs.defaultProps = {
  className: '',
  position: '',
  disabled: false,
};

ButtonTabs.propTypes = {
  className: PropTypes.string,
  currTab: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.string,
  setCurrTab: PropTypes.func.isRequired,
  tabsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ButtonTabs;
