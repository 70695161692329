import {
  DOWNLOAD_FILE,
  GET_NPV_HISTORY_DATA,
  GET_NPV_RESULT_DATA,
  PROCESS_FILE,
  UPLOAD_FILE,
  RESET_UPLOAD_STATUS,
  SET_NPV_REQUEST_ID,
  GET_NPV_INVESTOR_REPORT_DATA,
  GET_NPV_RQST_FILE_DATA,
  CANCEL_NPV,
} from './types';

const downloadFileAction = payload => ({
  type: DOWNLOAD_FILE,
  payload,
});

const processFileAction = payload => ({
  type: PROCESS_FILE,
  payload,
});

const NPVHistoryAction = payload => ({
  type: GET_NPV_HISTORY_DATA,
  payload,
});

const NPVResultAction = payload => ({
  type: GET_NPV_RESULT_DATA,
  payload,
});

const uploadFileAction = () => ({
  type: UPLOAD_FILE,
});

const resetUploadStatus = () => ({
  type: RESET_UPLOAD_STATUS,
});

const setRequestIdAction = payload => ({
  type: SET_NPV_REQUEST_ID,
  payload,
});

const npvInvestorReportDataAction = payload => ({
  type: GET_NPV_INVESTOR_REPORT_DATA,
  payload,
});

const npvRequestFileDataAction = payload => ({
  type: GET_NPV_RQST_FILE_DATA,
  payload,
});

const npvCancelAction = payload => ({
  type: CANCEL_NPV,
  payload,
});

export {
  downloadFileAction,
  processFileAction,
  NPVHistoryAction,
  uploadFileAction,
  resetUploadStatus,
  NPVResultAction,
  setRequestIdAction,
  npvInvestorReportDataAction,
  npvRequestFileDataAction,
  npvCancelAction,
};
