import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { historyColumns } from 'constants/SupportAdmin';
import * as R from 'ramda';
import {
  operations,
  selectors as sbAdminSelectors,
} from '../../../state/ducks/admin-tools';
import SupportAdminTable from '../../../components/ProductSupport/SupportAdminTable/SupportAdminTable';
import Loader from '../../../components/Loader';

function History(props) {
  const {
    historyData,
    loading,
    fetchHistoryData,
    activeTab,
    isFilterEnabled,
    filterPayload,
    filterResults,
    radioSelect,
    isBulkActionIdTypeSelected,
  } = props;

  const fetchGridData = (pageNumber, pageSize) => {
    if (isFilterEnabled) {
      filterResults(filterPayload, pageNumber, pageSize);
    } else {
      fetchHistoryData(pageNumber, pageSize, radioSelect);
    }
  };

  return (
    <>
      {loading ? (
        <Loader message="Please Wait" />
      ) : (
        <SupportAdminTable
          columns={historyColumns[R.contains(activeTab, Object.keys(historyColumns)) ? activeTab : 'default'](radioSelect, isBulkActionIdTypeSelected)}
          data={historyData}
          handleChange={fetchGridData}
          size="small"
        />
      )}
    </>
  );
}

History.defaultProps = {
  historyData: {},
  fetchHistoryData: null,
  loading: false,
  isFilterEnabled: false,
  filterResults: () => {},
  radioSelect: 'case',
  isBulkActionIdTypeSelected: false,
};

History.propTypes = {
  activeTab: PropTypes.string.isRequired,
  fetchHistoryData: PropTypes.func,
  filterPayload: PropTypes.shape().isRequired,
  filterResults: PropTypes.func,
  historyData: PropTypes.shape(),
  isBulkActionIdTypeSelected: PropTypes.bool,
  isFilterEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  radioSelect: PropTypes.string,
};

const mapStateToProps = state => ({
  loading: sbAdminSelectors.getLoader(state),
  isFilterEnabled: sbAdminSelectors.isFilterEnabled(state),
  filterPayload: sbAdminSelectors.filterPayload(state),
});

const mapDispatchToProps = dispatch => ({
  fetchHistoryData: operations.fetchSBHistoryGridData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
