import React from 'react';
import {
  Dialog, DialogContent, Button, Typography, Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

const ExceptionPopup = ({ isOpen, onClose, selectedErrorData }) => (
  <Dialog onClose={onClose} open={isOpen}>
    <DialogContent>
      {Array.isArray(selectedErrorData) && selectedErrorData.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`error-${index}`}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ErrorIcon color="error" />
            <Typography style={{ marginLeft: '8px' }} variant="subtitle1">
              {item.title}
            </Typography>
          </div>
          <Typography style={{ marginLeft: '32px', marginTop: '8px' }} variant="body2">
            {item.message}
          </Typography>
          <Divider style={{ margin: '16px 0' }} />
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Button color="primary" onClick={onClose} variant="contained">
            OK
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);


ExceptionPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedErrorData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })),
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default ExceptionPopup;
