import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import './Checkbox.css';

function CheckBox({
  options,
  title,
  additionalInfo,
  value,
  source,
  onChangeMultipleBox,
  disabled,
  failureReason,
  checklistLoadStatus,
}) {
  const [checkboxValue, setCheckboxValue] = useState(value);
  const { styleName, hasTitle, customType } = additionalInfo;
  const onChangeCheckboxHandler = (e) => {
    setCheckboxValue(e.target.checked);
    onChangeMultipleBox({ target: { value: e.target.checked } });
  };

  const onChangeMultipleCheck = (event) => {
    onChangeMultipleBox({
      target: {
        value: event.target.checked,
      },
    });
  };

  useEffect(() => {
    if (!R.equals(checklistLoadStatus, 'loading')) {
      setCheckboxValue(value);
    }
  });

  if (R.equals(source, 'value') || R.equals(customType, 'single')) {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', cursor: disabled ? 'not-allowed' : 'pointer' }} styleName={styleName || ''}>
        <Checkbox checked={R.equals(checkboxValue, true)} disabled={disabled} onChange={onChangeCheckboxHandler} styleName="radio-control-bubble" />
        {hasTitle && <Typography style={{ paddingLeft: '0.5rem' }}>{title}</Typography>}
      </Box>
    );
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        {hasTitle && <Typography styleName="checkboxTitle">{title}</Typography>}
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={0}>
          {
          options.map(({
            displayName, value: checkValue,
          }, index) => (
            <Grid item xs={index % 2 === 0 ? 8 : 4}>
              <div
                key={displayName}
                style={{ display: 'flex', alignItems: 'center', marginLeft: index % 2 === 0 ? '5.35rem' : '' }}
              >
                <Checkbox
                  checked={R.contains(checkValue, value || [])}
                  color="default"
                  disabled={disabled}
                  error={!R.isNil(failureReason) && !R.isEmpty(failureReason)}
                  name={checkValue}
                  onChange={onChangeMultipleCheck}
                  styleName={disabled ? 'radio-control-bubble-disabled' : 'radio-control-bubble'}
                />
                <Typography>{displayName}</Typography>
              </div>
            </Grid>
          ))
        }
        </Grid>
      </Grid>
    </Grid>
  );
}

CheckBox.defaultProps = {
  additionalInfo: {},
  value: '',
  source: '',
  disabled: false,
  checklistLoadStatus: null,
  failureReason: [],
};

CheckBox.propTypes = {
  additionalInfo: PropTypes.shape({
    customType: PropTypes.string,
    hasTitle: PropTypes.bool,
    styleName: PropTypes.string,
  }),
  checklistLoadStatus: PropTypes.string,
  disabled: PropTypes.bool,
  failureReason: PropTypes.arrayOf({
    level: PropTypes.number,
    message: PropTypes.string,
  }),
  onChangeMultipleBox: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    hint: PropTypes.string,
    isEnabled: PropTypes.bool,
    textColor: PropTypes.string,
    value: PropTypes.string.isRequired,
  })).isRequired,
  source: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default CheckBox;
