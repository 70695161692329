import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { messages } from 'constants/common';
import ConfirmationDialogBox from 'components/Tasks/OptionalTask/ConfirmationDialogBox';
import Popup from 'components/Popup';
import AnalysisBreakDownPopover from './AnalysisBreakDownPopover';
import AccountServicesHistory from './AccountServicesHistory';

function AccountServiceScreen(props) {
  const {
    children, analysisBreakdownData, setOpenAnalysisPopup, isAnalysisScreenOpen,
    processAction, setRejected, completeEscrow, showConfirmationDialog,
    handleConfirmDialogBoxButtons, closeDialog, toggleAccountServiceScreen,
    unAssignLoan, clearInfo, storeAnalysisBreakdownData, escrowData,
    onApprove, enableViewAnalysis,
  } = props;

  const [popupMsg, setMsg] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);

  const validateAndApprove = () => {
    const { thresholdExceeded, msg } = onApprove();
    if (!thresholdExceeded) {
      completeEscrow();
    } else {
      setPopupOpen(thresholdExceeded);
      setMsg(msg);
    }
  };

  const onCancel = () => {
    setPopupOpen(false);
  };

  const onConfirm = () => {
    setPopupOpen(false);
    completeEscrow();
  };

  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item styleName="displayCenter" xs={2}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
              Account Services
            </Typography>
            <AccountServicesHistory />
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={10}>
          <Grid item styleName="screenHeader">
            <div styleName="displayInlineCenter">
              <CloseIcon styleName="close" />
              <div styleName="reject">{messages.PENDING_APPROVAL}</div>
            </div>
          </Grid>
          <Grid id="viewAnalysisButton" styleName="screenHeader">
            <Button
              disabled={!enableViewAnalysis}
              onClick={() => {
                storeAnalysisBreakdownData(escrowData.analysisBreakdown);
                setOpenAnalysisPopup(true);
              }}
              styleName="escrowAnalysis"
            >
              VIEW ANALYSIS BREAKDOWN
            </Button>
          </Grid>
          <Grid id="reject" item styleName="screenHeader">
            <Button onClick={() => { setRejected(true); }} styleName="reject-button" variant="outlined">REJECT</Button>
          </Grid>
          <Grid id="approve" item styleName="screenHeader">
            <Button onClick={() => { validateAndApprove(); }} styleName="approve-button" variant="contained">APPROVE</Button>
          </Grid>
          <Grid id="close" item styleName="screenHeader">
            <Button onClick={() => { closeDialog(); }}><CloseIcon /></Button>
          </Grid>
        </Grid>
      </Grid>
      {children}
      <AnalysisBreakDownPopover
        closeDialog={setOpenAnalysisPopup}
        data={analysisBreakdownData}
        isOpen={isAnalysisScreenOpen}
        processAction={processAction}
      />
      <ConfirmationDialogBox
        isOpen={showConfirmationDialog}
        message=""
        onClose={(value) => {
          handleConfirmDialogBoxButtons(value);
          if (value) {
            toggleAccountServiceScreen(false);
            clearInfo();
            unAssignLoan();
          }
        }}
        title="Are you sure you want to discard the changes without actioning request?"
      />
      <Popup
        cancelButtonText="No"
        confirmButtonText="Yes"
        displayGif={false}
        level=""
        message={popupMsg}
        onCancel={onCancel}
        onConfirm={onConfirm}
        show={isPopupOpen}
        showCancelButton={isPopupOpen}
        title=""
        useOnCancel
      />
    </Grid>
  );
}

AccountServiceScreen.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  children: PropTypes.node.isRequired,
  clearInfo: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  completeEscrow: PropTypes.func.isRequired,
  enableViewAnalysis: PropTypes.bool.isRequired,
  escrowData: PropTypes.shape().isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  onApprove: PropTypes.func.isRequired,
  processAction: PropTypes.func.isRequired,
  setOpenAnalysisPopup: PropTypes.func.isRequired,
  setRejected: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  unAssignLoan: PropTypes.func.isRequired,
};

export default AccountServiceScreen;
