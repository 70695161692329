import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MessageCategory from './MessageCategory';
import styles from './WorkoutType.css';

const WorkoutType = ({ workoutTypeCode, categories, validationData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleWorkoutType = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleWorkoutType();
    }
  };

  const getStatusAndColor = () => {
    const validation = validationData.find(data => data.label === workoutTypeCode);
    if (validation) {
      const { status } = validation;
      const isSuccess = status === 'green';
      return {
        statusText: isSuccess ? 'PASSED' : 'FAILED',
        backgroundColor: isSuccess ? '#00AB84' : '#E20E34',
        icon: isSuccess ? '/static/img/success.svg' : '/static/img/failed.svg',
      };
    }
    return {
      statusText: 'Unknown',
      backgroundColor: 'grey',
      icon: '/static/img/unknown.svg',
    };
  };

  const { statusText, backgroundColor, icon } = getStatusAndColor();

  return (
    <div className={styles.workout_type}>
      <div
        aria-controls={`categories-${workoutTypeCode}`}
        aria-expanded={isExpanded}
        className={styles.accordion}
        onClick={toggleWorkoutType}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className={styles.workout_left}>
          <img alt={statusText} src={icon} style={{ width: '14px', marginRight: '5px' }} />
          <h3>{workoutTypeCode || '-'}</h3>
        </div>
        <div className={styles.workout_right}>
          <span className={styles.status_box} style={{ backgroundColor, marginRight: '15px' }}>
            {statusText}
          </span>
          <div className={styles.accordion_arrow}>
            <img
              alt="toggle"
              height="7"
              src={isExpanded ? '/static/img/upArrow.svg' : '/static/img/downArrow.svg'}
              style={{ width: '14px', marginLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className={styles.categories} id={`categories-${workoutTypeCode}`}>
          {Object.entries(categories).map(([category, messages]) => (
            <div key={category} className={styles.category}>
              <MessageCategory category={category} messages={messages} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

WorkoutType.propTypes = {
  categories: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
  validationData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['green', 'red']).isRequired,
    }),
  ).isRequired,
  workoutTypeCode: PropTypes.string.isRequired,
};

export default WorkoutType;
