import React from 'react';
import CaseDetailsComp from './MODCaseDetails';

const MODCaseDtlWrapper = () => (
  <div>
    <CaseDetailsComp />
  </div>
);

export default MODCaseDtlWrapper;
