const nonSummableFields = ['nstR1stMortgage', 'homeOwnersInsurance', 'loanEscrowShortage', 'mortgageInsurance', 'propertyTaxes'];
const expenseFields = ['nstR1stMortgage',
  'homeOwnersInsurance',
  'homeOwnersAssociation',
  'loanEscrowShortage',
  'carPayment1',
  'carPayment2',
  'autoInsurance',
  'chargeAccount1',
  'chargeAccount2',
  'chargeAccount3',
  'studentLoan',
  'medicalBills',
  'secondMortgage',
  'mortgageInsurance',
  'childCare',
  'propertyTaxes',
  'bankFinanceLoans',
  'healthInsurance',
  'gas',
  'publicTrans',
  'autoMaintenance',
  'gasNaturalPropane',
  'homePhone',
  'electric',
  'cellPhone',
  'garbage',
  'cableTV',
  'waterSewer',
  'bundle',
  'homeMaintenance',
  'food',
  'childSupport',
  'alimony',
  'camperBoatMotorcycle',
  'personalLifeInsurance',
  'clubUnionDues',
  'religiousContributions',
  'rentExpense',
  'otherExpenses',
  'dryCleaning',
  'clothing',
  'entertainment',
  'schoolTuition'];
const checklistTypes = {
  INCOME: 'INCOME',
  EXPENSE: 'EXPENSE',
};

module.exports = {
  checklistTypes,
  nonSummableFields,
  expenseFields,
};
