import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer styleName="footer">
    © Nationstar Mortgage LLC, All rights reserved
  </footer>
);

export default Footer;
