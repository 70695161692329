import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './LinearProgressBar.css';
import {
  IconButton, Grid,
} from '@material-ui/core';

export default function LinearProgressBar(prop) {
  const {
    progress, showUpload, fileName, handleTriggeredFile,
  } = prop;

  const assigncolor = showUpload ? '#F3F5F7' : '#596FEB';

  return (
    <>
      <div
        styleName="outerBar"
      >
        <div
          style={{
            background: `linear-gradient(to right, ${assigncolor} 0%, ${assigncolor} ${progress}%, transparent ${progress}%, transparent 100%)`,
          }}
          styleName="progressBar"
        >
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="space-between"
          >

            <Grid item xs={2}>
              <img alt="filepresent" src="/static/img/filepresent.svg" />
            </Grid>
            <Grid item style={{ marginTop: '8px' }} xs={8}>
              {fileName}
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  handleTriggeredFile();
                }}
                style={{ padding: 0, visibility: showUpload ? '' : 'hidden' }}
              >
                <CloseIcon styleName="close" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </>

  );
}
