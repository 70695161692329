import {
  fetchPaymentSupplementData,
  clearPaymentSupplementData,
} from './actions';

const fetchPaymentSupplementOperation = dispatch => () => dispatch(fetchPaymentSupplementData());
const clearPaymentSupplementOperation = dispatch => () => dispatch(clearPaymentSupplementData());

const operations = {
  fetchPaymentSupplementOperation,
  clearPaymentSupplementOperation,
};

export default operations;
