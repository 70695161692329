const ELIGIBLE = 'Previous Freddie call was eligible';
const INELIGIBLE = 'Previous Freddie call was ineligible';
const NOCALL = 'Not yet submitted';
const EXCEPTION_REQUEST = 'Exception Request has been submitted';
const ID_CATEGORIES = ['Case id(s)', 'Loan Number(s)'];
const FHLMC = 'FHLMC';
const APPROVAL_TYPE = 'FHLMCApprovalType';
const PRE_APPROVAL_TYPE = 'FHLMCPreApproved';
const APPROVAL_REQUEST_TYPE = 'FHLMCPreApprovedRequestType';
const LOAN_NUMBERS_IDTYPE = 'Loan Number(s)';
const HARDSHIP_TYPE = 'Hardship Type';
const HARDSHIP_SOURCE = 'Hardship Source';
const SEX = 'Sex';
const ETHNICITY = 'Ethnicity';
const RACE = 'Race';
const PREAPPROVED_DISASTER_TYPES = ['Disaster', 'Pandemic'];
const STANDARD = 'Standard';
const EXCEPTION_TOGGLE = ['Yes', 'No'];
const CANCEL_REQ_TYPES = ['CXLReq'];

const REGEX_FHLMC_PREAPPROVED_DISASTER = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\"<>?]|^[,]|^[:]/;
const REGEX_FHLMC_COMMON = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]|^[,]/;
const REQUEST_TYPE_REQ = 'Please select request type and upload excel file';
const FILE_UPLOAD_REQ = 'Kindly upload an excel File';
const CANCELLATION_REASON = 'Please select Cancellation Reason and Submit';
const ENABLE_ODM_RERUN = ['EnquiryReq', 'WAReq', 'TPAReq'];
const REQ_PRCS = 'We are processing your request.  Please do not close the browser.';
const COMMENTS_REASON = 'Please enter a valid comment.';
const ENQUIRY_REQUESTTYPE = 'DraftReq';
const COMMENT_EXCEPTON_REQUEST_TYPES = ['CXLReq', 'DraftReq', 'EnquiryReq'];
const DISABLE_WIDGET_INVESTOR_CODES = ['BJ9', 'CAT', 'BX6', 'BX7'];

const GENERATE_BOARDING_TEMPLATE_STATUS = 'Request will be processed in the backend. Proceed with other Loans';
const VALIDATION_FAILURE_MSG = 'One or more entries are not valid. Please check and try again';

const ENQUIRY_REQ = 'EnquiryReq';
const CANCEL_REQ_TXT = 'Cancel Request';
const DRAFT_REQ_TXT = 'Draft Request';
const TPA_REQ_TXT = 'Trial Period Approve Request';
const WA_REQ_TXT = 'Workout Approve Request';
const ENQUIRY_REQ_TXT = 'Enquiry Call';
const SET_REQ_TXT = 'Settlement Request';

module.exports = {
  ENABLE_ODM_RERUN,
  ELIGIBLE,
  INELIGIBLE,
  NOCALL,
  ID_CATEGORIES,
  FHLMC,
  APPROVAL_TYPE,
  PRE_APPROVAL_TYPE,
  APPROVAL_REQUEST_TYPE,
  REGEX_FHLMC_PREAPPROVED_DISASTER,
  REGEX_FHLMC_COMMON,
  LOAN_NUMBERS_IDTYPE,
  PREAPPROVED_DISASTER_TYPES,
  REQUEST_TYPE_REQ,
  FILE_UPLOAD_REQ,
  CANCELLATION_REASON,
  REQ_PRCS,
  GENERATE_BOARDING_TEMPLATE_STATUS,
  HARDSHIP_TYPE,
  HARDSHIP_SOURCE,
  SEX,
  ETHNICITY,
  RACE,
  VALIDATION_FAILURE_MSG,
  STANDARD,
  EXCEPTION_TOGGLE,
  COMMENTS_REASON,
  ENQUIRY_REQUESTTYPE,
  COMMENT_EXCEPTON_REQUEST_TYPES,
  CANCEL_REQ_TYPES,
  ENQUIRY_REQ,
  EXCEPTION_REQUEST,
  DISABLE_WIDGET_INVESTOR_CODES,
  CANCEL_REQ_TXT,
  DRAFT_REQ_TXT,
  TPA_REQ_TXT,
  WA_REQ_TXT,
  ENQUIRY_REQ_TXT,
  SET_REQ_TXT,


};
