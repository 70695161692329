/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lone-blocks */
import React, {
  useState, useEffect,
} from 'react';
import './Timeline.css';

const BasicTimeline = (props) => {
  const [timelineData, setTimlineData] = useState([]);
  const [isSubStepsVisible, setIsSubStepsVisible] = useState(false);

  useEffect(() => {
    const { inputData } = props;
    if (inputData) {
      const objectKeys = Object.keys(inputData).sort();
      objectKeys.forEach((key, index) => {
        setTimlineData(ele => [...ele, {
          index,
          header: key,
          contentList: inputData[key],
          isVisible: false,
        }]);
      });
    }
  }, [props]);

  const toggleStepsVisiblity = (index) => {
    let filteredLog = timelineData.filter(rec => rec.index === index)[0];
    filteredLog = { ...filteredLog, isVisible: !filteredLog.isVisible };
    const updatedLogList = [...timelineData];
    updatedLogList[index] = filteredLog;
    setTimlineData(updatedLogList);
  };

  useEffect(() => {
    const atleastOneSubStepVisible = timelineData.some(rec => rec.isVisible === true);
    setIsSubStepsVisible(atleastOneSubStepVisible);
  }, [timelineData]);

  const hideAllSubSteps = () => {
    const hiddenCalcLogs = timelineData.map(rec => ({ ...rec, isVisible: false }));
    setTimlineData(hiddenCalcLogs);
  };

  const showAllSubSteps = () => {
    const visibleCalcLogs = timelineData.map(rec => ({ ...rec, isVisible: true }));
    setTimlineData(visibleCalcLogs);
  };

  const toggleSubStepsVisibility = () => {
    if (isSubStepsVisible) {
      hideAllSubSteps();
    } else {
      showAllSubSteps();
    }
    setIsSubStepsVisible(!isSubStepsVisible);
  };

  return (
    <div styleName="timeline-root">
      <div styleName="toggle-all-view-div">
        <span onClick={() => toggleSubStepsVisibility()} styleName="toggle-all-view-button">
          {isSubStepsVisible ? 'HIDE ALL SUB STEPS'
            : 'SHOW ALL SUB STEPS'}
        </span>
      </div>
      <div style={{ width: '100%' }}>
        {timelineData.map(({
          index, header, contentList, isVisible,
        }) => (
          <div styleName="custom-timeline">
            <span styleName="timeline-header">{header}</span>
            <div styleName="timeline-div">
              {(index !== timelineData.length - 1) && <span styleName="timeline-seperator" />}
              <div styleName="timeline-content-div">
                {isVisible
              && (
                <div styleName={index === timelineData.length - 1 ? 'timline-last-ele-seperator' : ''}>
                  {contentList.map(subcontent => (
                    <>
                      {index === timelineData.length - 1 && <span styleName="timeline-seperator" />}
                      <div styleName="timeline-sub-content-div">
                        <span styleName="timeline-horizontal-indicator" />
                        <span styleName="timeline-sub-content">{subcontent}</span>
                      </div>
                    </>
                  ))}
                </div>
              )}
                <div styleName="timeline-visible-root">
                  <div />
                  <span onClick={() => toggleStepsVisiblity(index)} styleName="timeline-visible-button" type="button">
                    {isVisible ? 'HIDE SUB STEPS' : 'SHOW SUB STEPS' }
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const TestHooks = {
  BasicTimeline,
};

export default BasicTimeline;
export { TestHooks };
