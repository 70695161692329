import { makeStyles } from '@material-ui/core/styles';

const CardStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#F3F5F9',
    boxShadow: 'none',
    borderBottom: hookData => (hookData.hideDivider ? 'none' : '1px solid #E4E9F5'),
    paddingBottom: hookData => (hookData.hideDivider ? '0px' : '18px'),
    '& .MuiGrid-item': {
      height: '36px',
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '8px',
      paddingRight: '0px',
    },
    '& .MuiTable-root': {
      marginLeft: '13px',

      '& .MuiTableCell-root': {
        minWidth: '50px !important',
        fontSize: '14px',
      },
    },
  },

  title: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    margin: '19px 0px 8px 19px',
  },

}));

export default CardStyles;
