import {
  Dialog,
  Button,
  IconButton,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
}
  from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import './EvalPopup.css';
import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { operations as evalOperations, selectors as evalSelectors } from 'ducks/eval';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import EvalTableWithFilter from '../EvalTableWithFilter';

const EvalPopup = (props) => {
  const {
    showPopup, handleClose, title, saveDisasterDeclaration, states, fetchCountys, countys,
    fetchDisasterDtls, disasterDtls, evalDtls, setDisasterDtls, setCountys, inProgress,
  } = props;
  const [evalObj, setEvalObj] = React.useState(
    {
      state: evalDtls.state ??'',
      county: '',
      effected: evalDtls.disasterFlag === 'E' ? 'yes' : 'no',
    },
  );
  const [data, setData] = React.useState(disasterDtls);
  const [radioGroupCell, setradioGroupCell] = React.useState(0);
  const [isCancelClicked, setCancelClicked] = React.useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedEvalObj = { ...evalObj };
    updatedEvalObj[name] = value;
    setEvalObj(updatedEvalObj);
    if (name === 'state') {
      fetchCountys(value);
      setDisasterDtls([]);
      setradioGroupCell(0);
    }
    if (name === 'county') {
      fetchDisasterDtls({ state: evalObj.state, county: value });
      setradioGroupCell(0);
    }
    if (name === 'disasterId') {
      setradioGroupCell(+value);
    }
  };

  React.useEffect(() => {
    if (evalDtls.disasterId !== null && evalDtls.disasterId !== 0 && showPopup) {
      fetchCountys(evalDtls.state);
      if (evalDtls.county && evalDtls.county !== '') {
        fetchDisasterDtls({ state: evalDtls.state, county: evalDtls.county });
      }
      setEvalObj(prevState => ({
        ...prevState,
        state: evalDtls.state,
        county: evalDtls.county,
      }));
      setradioGroupCell(evalDtls.disasterId ?? 0);
    }
  }, [showPopup]);

  React.useEffect(() => {
    setData(disasterDtls);
  }, [disasterDtls]);

  const columns = [
    {
      name: 'disasterId',
      label: '',
      cellFormat: id => (
        <Radio
          checked={id === radioGroupCell}
          color="primary"
          name="disasterId"
          onChange={handleChange}
          styleName="radio-btn table-radio"
          value={id}
        />
      ),
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'State',
          size: 'small',
        },
      },
    },
    {
      name: 'declaredCountyArea',
      label: 'County',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'County',
          size: 'small',
        },
      },
    },
    {
      name: 'title',
      label: 'Disaster Name',
      options: {
        filter: true,
        sort: false,
        toolTip: {
          title: 'Disaster Name',
          size: 'small',
        },
      },
    },
    {
      name: 'declarationDate',
      label: 'Declared Date',
      cellFormat: dt => (
        dt ? (moment(dt).format('DD MMM YYYY')) : <>{null}</>
      ),
      options: {
        filter: true,
        sort: false,
        toolTip: {
          title: 'Declared Date',
          size: 'small',
        },
      },
    },
  ];

  const handleCancelClick = () => {
    setCancelClicked(!isCancelClicked);
  };

  const handleClosePopup = () => {
    handleClose();
    setCancelClicked(false);
    setEvalObj({
      state: '',
      county: '',
      effected: 'no',
    });
    setradioGroupCell(0);
    setCountys([]);
    setDisasterDtls([]);
  };

  const handleSave = () => {
    if (isCancelClicked) {
      handleClosePopup();
    } else {
      const payload = {
        state: evalObj.state,
        county: evalObj.county,
        disasterId: radioGroupCell,
        effected: evalObj.effected,
      };
      saveDisasterDeclaration(payload);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={handleClosePopup}
      open={showPopup}
      PaperProps={{ style: { minWidth: 640, padding: 16 } }}
    >
      <div style={{ position: 'relative' }}>
        {inProgress && (
        <div styleName="disasterPopupLoader">
          <Loader message="Please wait" size={10} />
        </div>
        )}
        <div styleName="title-container">
          <p styleName="popup-title">{title}</p>
          <IconButton aria-label="close" onClick={isCancelClicked ? handleClosePopup : handleCancelClick}>
            <CloseIcon />
          </IconButton>
        </div>
        {!isCancelClicked
          && (
          <>
            <div styleName="flex">
              <div>
                <p styleName="label-style">
            State
                </p>
                <Select
                  disableUnderline
                  displayEmpty
                  inputProps={{
                    name: 'state',
                    id: 'age-native-label-placeholder',
                  }}
                  onChange={handleChange}
                  style={{ color: '#4E586E' }}
                  styleName="label-style"
                  value={evalObj.state}
                >
                  <MenuItem disabled value="">
              Select State
                  </MenuItem>
                  { states.length > 0
                    ? states.map(option => <MenuItem value={option}>{option}</MenuItem>)
                    : null}
                </Select>
              </div>
              <div styleName="input-container">
                <p styleName="label-style">
            County
                </p>
                <Select
                  disableUnderline
                  displayEmpty
                  inputProps={{
                    name: 'county',
                    id: 'age-native-label-placeholder',
                  }}
                  onChange={handleChange}
                  style={{ color: '#4E586E' }}
                  styleName="label-style"
                  value={evalObj.county}
                >
                  <MenuItem disabled value="">
              Select County
                  </MenuItem>
                  { countys.length > 0
                    ? countys.map(option => <MenuItem value={option}>{option}</MenuItem>)
                    : null}
                </Select>
              </div>
              <div styleName="input-container">
                <p styleName="label-style">Employer Effected?</p>
                <RadioGroup name="effected" onChange={handleChange} row value={evalObj.effected}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    labelPlacement="end"
                    styleName="label-style radio-btn"
                    value="yes"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    style={{ padding: '0 10px' }}
                    styleName="label-style radio-btn"
                    value="no"
                  />
                </RadioGroup>
              </div>
            </div>
            <p styleName="border-style" />
            <div>
              <EvalTableWithFilter
                columns={columns}
                data={data || []}
                radioGroupCell={radioGroupCell}
              />
              {!isCancelClicked && !inProgress && data.length === 0
              && <p styleName="body-text-style">Select State and County to view disasters</p>}
            </div>

          </>
          )}
        {isCancelClicked && <p styleName="body-text-style">Are you sure you want to discard the unsaved changes?</p>}
        <div styleName={!isCancelClicked ? 'button-container' : 'button-container button-shift'}>
          <Button
            color="primary"
            disabled={(!isCancelClicked && (radioGroupCell === 0 || !radioGroupCell))}
            onClick={() => handleSave()}
            variant="contained"
          >
            {isCancelClicked ? 'Yes' : 'Save'}
          </Button>
          <Button
            color="primary"
            onClick={handleCancelClick}
            variant="outlined"
          >
            {isCancelClicked ? 'No' : 'Cancel'}
          </Button>

        </div>
      </div>
    </Dialog>
  );
};


EvalPopup.defaultProps = {
  handleClose: () => { },
  title: '',
  saveDisasterDeclaration: () => { },
  evalDtls: {
    disasterFlag: 'B', disasterId: null, state: null, county: null,
  },
};

EvalPopup.propTypes = {
  countys: PropTypes.shape().isRequired,
  disasterDtls: PropTypes.shape().isRequired,
  evalDtls: PropTypes.shape(),
  fetchCountys: PropTypes.func.isRequired,
  fetchDisasterDtls: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  inProgress: PropTypes.bool.isRequired,
  saveDisasterDeclaration: PropTypes.func,
  setCountys: PropTypes.func.isRequired,
  setDisasterDtls: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  states: PropTypes.shape().isRequired,
  title: PropTypes.string,
};

const mapStateToProps = state => ({
  states: evalSelectors.states(state),
  countys: evalSelectors.countys(state),
  disasterDtls: evalSelectors.disasterDtls(state),
  inProgress: evalSelectors.isDisasterPopupLoading(state),
});

const mapDispatchToProps = dispatch => ({
  saveDisasterDeclaration: evalOperations.saveDisasterDecOperation(dispatch),
  fetchCountys: evalOperations.fetchCountysOperation(dispatch),
  fetchDisasterDtls: evalOperations.fetchDisasterDtlsOperation(dispatch),
  setDisasterDtls: evalOperations.setDisasterDtlsOperation(dispatch),
  setCountys: evalOperations.setCountysOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalPopup);
