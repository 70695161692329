import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import { repaymentTableHeaders, EXTENDED_DURATION } from 'constants/eval';
import RepaymentTableSummary from './RepaymentTableSummary';

function RepaymentTable({
  repaymentMonthlyAmounts, updateMonthlyPaymentDetails,
  handleCalcButton, isPlanExtended, isEditable, status, disasterFlag,
}) {
  const [isValueUpdated, setIsValueUpdated] = useState(false);

  useEffect(() => {
    if (isValueUpdated) {
      handleCalcButton();
    }
  }, [isValueUpdated]);

  return (
    <Grid container item>
      <Grid id="repaymentSummaryTable" item style={{ width: 'inherit' }}>
        <TableContainer component={Paper} style={{ maxHeight: '25rem' }}>
          <Table aria-label="simple table" size="medium">
            <TableHead>
              <TableRow>
                {repaymentTableHeaders.map(header => (
                  <TableCell>{header}</TableCell>
                ))}
                {
                  isPlanExtended && (
                    <TableCell>{EXTENDED_DURATION}</TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {repaymentMonthlyAmounts && repaymentMonthlyAmounts.map((row, index) => (
                <RepaymentTableSummary
                  key={`${row.changeType}_${index + 1}`}
                  disasterFlag={disasterFlag}
                  handleMonthlyRepaymentValueChange={(value) => {
                    const repaymentAmount = [...repaymentMonthlyAmounts];
                    repaymentAmount[index].paymentAmount = value && parseFloat((value.toString()).replace(/,/g, ''));
                    repaymentAmount[index].paymentAmountOverrideFlag = true;
                    updateMonthlyPaymentDetails('repaymentMonthlyAmounts', repaymentAmount);
                    setIsValueUpdated(true);
                  }}
                  index={index}
                  isEditable={isEditable}
                  isPlanExtended={isPlanExtended}
                  row={row}
                  status={status}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

RepaymentTable.propTypes = {
  disasterFlag: PropTypes.string.isRequired,
  handleCalcButton: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPlanExtended: PropTypes.bool.isRequired,
  repaymentMonthlyAmounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  status: PropTypes.string.isRequired,
  updateMonthlyPaymentDetails: PropTypes.func.isRequired,
};

export default RepaymentTable;
