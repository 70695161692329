import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
} from '@material-ui/core';
import './UnRejectRequestCase.css';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SubmittedRecords from 'components/SubmittedRecords';
import InputField from '../../../../components/InputField/InputField';
import SweetAlertBox from '../../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../../state/ducks/dashboard';
import Popup from '../../../../components/Popup';


const UnRejectRequestCase = ({
  unRejectRequest, recordsInfo, resultOperation, closeSweetAlert,
}) => {
  const [disableButton, setDisableButton] = useState(true);
  const [disableUnreject, setDisableUnReject] = useState(true);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    case: '0',
    requestorID: '',
    comments: '',
  });
  const [ispopupVisible, setIspopupVisible] = useState(false);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);

  useEffect(() => {
    if (isSubmitted && recordsInfo.successCount !== 0 && recordsInfo.failureCount !== 0) {
      setCaseObj({
        ticket: '',
        case: '0',
        requestorID: '',
        comments: '',
      });
      setIsSubmitted(false);
      setDisableButton(true);
      setDisableUnReject(true);
    }
  }, [recordsInfo]);

  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
  };

  const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

  const handleOnBlur = () => {
    const {
      ticket, case: caseId, requestorID, comments,
    } = caseObj;
    setDisableUnReject(!(checkNotNullAndEmpty(ticket) && checkNotNullAndEmpty(caseId)
      && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)));
    setDisableButton(!(checkNotNullAndEmpty(ticket) || checkNotNullAndEmpty(caseId)
      || checkNotNullAndEmpty(requestorID) || checkNotNullAndEmpty(comments)));
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      case: '0',
      requestorID: '',
      comments: '',
    });
    setDisableButton(true);
    setDisableUnReject(true);
  };

  const handleSubmit = () => {
    unRejectRequest(caseObj);
    setIspopupVisible(false);
    setIsSubmitted(true);
  };

  return (
    <div styleName="form-fields-container">
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={caseObj.ticket}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Case ID"
        name="case"
        type="number"
        value={caseObj.case}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={caseObj.requestorID}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={caseObj.comments}
      />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableUnreject} onClick={() => setIspopupVisible(true)} variant="contained">
          UNREJECT REQUEST
        </Button>
      </div>
      <div>
        <SubmittedRecords
          successfulRecords={recordsInfo.successCount || 0}
          type="Case ID"
          unSuccessfulRecords={recordsInfo.failureCount || 0}
          unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
        />

        <Popup
          cancelButtonText="Cancel"
          confirmButtonText="Ok"
          level="Info"
          message="Are you sure you want to proceed?"
          onCancel={() => setIspopupVisible(false)}
          onConfirm={handleSubmit}
          show={ispopupVisible}
          showCancelButton
          showConfirmButton
        />
        {resultOperation && resultOperation.status && (
        <SweetAlertBox
          message={renderContent}
          onConfirm={() => handleDialogClose()}
          show={isOpen}
          type={msgType}
        />
        )}
      </div>
    </div>
  );
};

UnRejectRequestCase.defaultProps = {
  closeSweetAlert: () => {},
};


UnRejectRequestCase.propTypes = {
  closeSweetAlert: PropTypes.func,
  recordsInfo: PropTypes.shape().isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  unRejectRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnRejectRequestCase);
