/* eslint-disable no-param-reassign */
import * as R from 'ramda';

const gatherAnalysisBreakDownData = (item) => {
  const { escrowData } = item;
  item.subTasks.forEach((val) => {
    const id = R.prop('id', val);
    if (escrowData[id] !== null && escrowData[id] !== undefined) {
      val.value = escrowData[id];
    } else {
      val.value = '';
    }
    if (!R.isEmpty(val.subTasks)) {
      gatherAnalysisBreakDownData({
        escrowData,
        subTasks: val.subTasks,
      });
    }
  });
  return {
    ...item,
    value: escrowData,
  };
};

export default gatherAnalysisBreakDownData;
