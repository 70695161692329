import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'rgb(252, 244, 220)',
    color: theme.palette.common.black,
    fontSize: 9,
    padding: '5px',
    marginLeft: '0px',
    borderRadius: '4px',
    border: '2px solid lightgrey',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
    position: 'relative',
  },
  arrow: {
    color: 'rgb(252, 244, 220)',
    '&::before': {
      border: '2px solid lightgrey',
    },
    left: '1px',
  },
}))(Tooltip);

export default CustomTooltip;
