import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import './UnReject.css';
import SubmittedRecords from 'components/SubmittedRecords';
import PopupContainer from 'components/PopupContainer';
import InputFieldMultiline from 'components/ProductSupport/InputFieldMultiline';
import SweetAlertBox from '../../../../components/SweetAlertBox';


import { operations as businessAdminOperations, selectors as businessAdminSelectors } from '../../../../state/ducks/admin-tools';
import {
  selectors as loginSelectors,
} from '../../../../state/ducks/login';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../../state/ducks/dashboard';
import {
  CASE, PATH_VARIABLE_UNREJECT,
} from '../../../../constants/busniessAdmin';


const UnReject = ({
  user, handleUnRejectRequest, recordsInfo, resultOperation, closeSweetAlert,
}) => {
  const defaultCaseObj = {
    case: 0,
    reason: '',
  };

  const [caseObj, setCaseObj] = useState(defaultCaseObj);
  const [idLimitValidation, setIdLimitValidation] = useState(false);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (caseObj.case === '') {
      setCaseObj(prevState => ({
        ...prevState,
        case: 0,
      }));
    }
  }, [caseObj]);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);


  useEffect(() => {
    setCaseObj(defaultCaseObj);
  }, [recordsInfo]);


  const [compData, setCompData] = useState({
    show: false,
    isDisabled: false,
  });


  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };

  const validatefields = (obj) => {
    const verifyData = Object.values(obj).map(x => x !== '').every(Boolean) || !idLimitValidation;
    setCompData({ ...compData, isDisabled: verifyData });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
    validatefields(updatedObject);
  };

  const reasonOptions = ['Investor Request', 'Invalid Rejection', 'Extenuating Circumstances', 'Reopen due to Litigation Review', 'Review for additional waterfall options'];
  const renderStatusOptions = () => reasonOptions.map((option) => {
    const value = option.replace('/', '').split(' ').join('-').toLowerCase();
    return <MenuItem value={value}>{option}</MenuItem>;
  });

  const handleChangeID = (event) => {
    const { name, value } = event.target;
    const updatedObjectNew = { ...caseObj };
    const validIdEntries = RegExp(/[a-z]/gi);
    updatedObjectNew[name] = !validIdEntries.test(value) ? value.split(/,| /).join('\n') : '';
    const conIdsArray = value && value.split('\n').map(id => +id);
    setCaseObj(updatedObjectNew);
    setIdLimitValidation(conIdsArray.length > 100);
    validatefields(updatedObjectNew);
  };

  const clearEdits = () => {
    setCaseObj({
      case: 0,
      reason: '',
    });
    setCompData({
      show: false,
      isDisabled: false,
    });
    setIdLimitValidation(false);
  };

  const handleClose = () => {
    const { show } = compData;
    setCompData({
      ...compData, show: !show,
    });
  };

  const [requestData, setRequestData] = useState({});

  const handleSubmit = () => {
    const caseIds = caseObj.case.split('\n').map(id => +id).filter(id => id !== 0);
    const requestObj = {
      ...caseObj,
      pathVariable: PATH_VARIABLE_UNREJECT,
      action: 'UNREJECT',
      busKeyType: CASE,
      email: user.userDetails.email,
      caseIds,
    };
    setRequestData(requestObj);
    setCompData({ show: true, isDisabled: false });
  };

  const handleConfirm = () => {
    handleUnRejectRequest(requestData);
    handleClose();
  };

  return (


    <div styleName="form-fields-container">
      <InputFieldMultiline
        errorMsg={idLimitValidation && 'Entries exceeded the maximum limit of 100'}
        handleChange={handleChangeID}
        label="Case ID"
        name="case"
        value={caseObj.case}
      />
      <div styleName="flex-container select-field-container">
        <p>Unreject Reason</p>
        <Select
          displayEmpty
          label="Select"
          name="reason"
          onChange={handleChange}
          value={caseObj.reason}
        >
          <MenuItem value="">Select</MenuItem>
          {renderStatusOptions()}
        </Select>
      </div>
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={!compData.isDisabled}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={idLimitValidation || caseObj.case === 0 || caseObj.reason === ''} onClick={handleSubmit} variant="contained">
          UnReject
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Case ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <PopupContainer
        handleClose={handleClose}
        show={compData.show}
        sizeSmall
        title="Do you proceed to UNREJECT?"
      >
        <div
          style={{ justifyContent: 'flex-start', padding: '20px' }}
          styleName="flex-container"
        >
          <Button color="primary" onClick={handleConfirm} variant="contained">
            CONFIRM
          </Button>
          <Button
            color="primary"
            onClick={handleClose}
            style={{ marginLeft: '8px' }}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </PopupContainer>
      {resultOperation && resultOperation.status && (
        <SweetAlertBox
          message={renderContent}
          onConfirm={() => handleDialogClose()}
          show={isOpen}
          type={msgType}
        />
      )}
    </div>
  );
};

UnReject.defaultProps = {
  handleUnRejectRequest: () => { },
  closeSweetAlert: () => {},
};

UnReject.propTypes = {
  closeSweetAlert: PropTypes.func,
  handleUnRejectRequest: PropTypes.func,
  recordsInfo: PropTypes.shape({
    failureCount: PropTypes.number,
    success: PropTypes.bool,
    successCount: PropTypes.number,
    unsuccessfulRecords: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  recordsInfo: businessAdminSelectors.getRecordsInfo(state),
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  handleUnRejectRequest: businessAdminOperations
    .submitModSupportRequestData(dispatch),
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UnReject);
