import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { operations as evalOperations, selectors as evalSelectors } from 'ducks/eval';

const ApprovalReasonPopup = (props) => {
  const {
    popupData, generateApprovalLetter,
  } = props;
  const {
    isOpen, message, title, level,
    cancelButtonText, confirmButtonText,
  } = popupData;
  const confirmAction = () => {
    const payload = {
      overrideLetter: true,
    };
    generateApprovalLetter(payload);
  };

  const onCancelAction = () => {
    const payload = {
      overrideLetter: false,
    };
    generateApprovalLetter(payload);
  };

  return (
    <>
      <Popup
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        level={level}
        message={message}
        onCancel={onCancelAction}
        onConfirm={confirmAction}
        show={isOpen}
        showCancelButton
        showConfirmButton
        title={title}
      />
    </>
  );
};
ApprovalReasonPopup.defaultProps = {
};
ApprovalReasonPopup.propTypes = {
  generateApprovalLetter: PropTypes.func.isRequired,
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    source: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  popupData: evalSelectors.getApprovalPopUpData(state),
});

const mapDispatchToProps = dispatch => ({
  generateApprovalLetter: evalOperations.generateApprovalLetterOperation(dispatch),
});

const container = connect(mapStateToProps, mapDispatchToProps)(ApprovalReasonPopup);
const TestHooks = {
  ApprovalReasonPopup,
};
export default withRouter(container);

export { TestHooks };
