import React from 'react';
import ContentHeader from 'components/ContentHeader';
import FullHeightColumn from 'components/FullHeightColumn';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardModel from 'models/Dashboard';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import './LossMitigation.css';

class LossMitigation extends React.PureComponent {
  renderTitle() {
    const { location } = this.props;
    const el = DashboardModel.GROUP_INFO.find(page => page.path === location.pathname);
    return el.task;
  }

  render() {
    return (
      <>
        <ContentHeader title={this.renderTitle()} />
        <FullHeightColumn styleName="lossMitigation-page">
          <PageviewOutlinedIcon styleName="search-icon" />
          <div styleName="search-text">Kindly Search the Loan Number in the Search Box</div>
        </FullHeightColumn>
      </>
    );
  }
}

LossMitigation.defaultProps = {
  location: {
    pathname: '',
  },
};

LossMitigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(LossMitigation);
