const SET_USER_SCHEMA_SAGA = 'app/auth/SET_USER_SCHEMA_SAGA';
const SET_USER_SCHEMA_SUCCESS = 'app/auth/SET_USER_SCHEMA_SUCCESS';
const SET_USER_SCHEMA_FAILED = 'app/config/SET_USER_SCHEMA_FAILED';
const SET_USER_ROLE = 'app/auth/SET_USER_ROLE';
export {
  SET_USER_SCHEMA_SAGA,
  SET_USER_SCHEMA_SUCCESS,
  SET_USER_SCHEMA_FAILED,
  SET_USER_ROLE,
};
