import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Divider, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as R from 'ramda';
import extName from 'ext-name';
import { operations, selectors } from 'ducks/netpresentvalue';
import { operations as boardingTemplateOperation } from 'ducks/boardingTemplate';
import { EXCEL_FORMATS } from 'constants/common';
import DropZone from '../DropZone/DropZone';
import './UploadFile.css';

const FileUploadDialog = (props) => {
  const {
    isOpen, handleClose, onProcessFile, onResetUploadStatus, onUploadComplete, data,
  } = props;

  const [fileName, setFileName] = useState('');

  const onDrop = (accFile) => {
    setFileName(accFile[0].name);
  };

  const handleUploadButton = (event) => {
    if (event.target.files[0]) {
      const fileExtension = extName(event.target.files[0].name);
      const ext = R.compose(
        R.prop('ext'),
        R.head,
      )(fileExtension);
      if (EXCEL_FORMATS.includes(ext)) {
        setFileName(event.target.files[0].name);
        onProcessFile(data, event.target.files[0]);
      }
    }
  };

  const handleAction = () => {
    handleClose();
    onResetUploadStatus();
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = () => {
    if (fileName) {
      handleClose();
      onResetUploadStatus();
      setFileName('');
      onUploadComplete();
    }
  };

  const handleCancel = () => {
    setFileName('');
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleAction}
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: '40%',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
        },
      }}
    >
      <DialogTitle style={{ padding: '0px', overflowX: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography style={{ margin: '0.5rem' }} variant="h6">Upload Documents</Typography>
          <IconButton onClick={handleAction} style={{ margin: '0.5rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {fileName ? (
          <Typography
            style={{
              border: '1px solid',
              backgroundColor: '#BBC6E2',
              color: 'black',
              margin: '1rem',
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem',
            }}
            variant="body1"
          >
            <img alt="upload" src="/static/img/excel.svg" style={{ marginRight: '0.5rem', width: '18px' }} />
            <span style={{ marginRight: 'auto' }}>
              Selected File:
              {fileName}
            </span>
          </Typography>

        ) : (
          <DropZone onDrop={onDrop} text="Drag and drop .XLSX to upload" />
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: fileName ? 'flex-start' : 'center',
          display: 'flex',
        }}
      >
        <div>
          {fileName ? (
            <>
              <Button color="primary" onClick={handleUpload} variant="contained">
                Upload
              </Button>
              <Button
                onClick={handleCancel}
                style={{
                  borderColor: 'primary',
                  backgroundColor: '#FFFFFF',
                  marginLeft: '8px',
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
              >
                <Divider style={{ width: '20%', margin: '10px' }} />
                Or
                <Divider style={{ width: '20%', margin: '10px' }} />
              </div>
              <Button color="primary" onClick={handleButtonClick} variant="contained">
                Browse
              </Button>
            </>
          )}
        </div>
        <input
          ref={fileInputRef}
          onChange={handleUploadButton}
          style={{ display: 'none' }}
          type="file"
        />
      </DialogActions>
    </Dialog>
  );
};

FileUploadDialog.defaultProps = {
  handleClose: () => { },
  onProcessFile: () => { },
  onResetUploadStatus: () => { },
  onUploadComplete: () => { }, // Default function
  uploadStatus: {},
};

FileUploadDialog.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onProcessFile: PropTypes.func,
  onResetUploadStatus: PropTypes.func,
  onUploadComplete: PropTypes.func,
  uploadStatus: PropTypes.shape({
    status: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  uploadStatus: selectors.getUploadStatus(state),
});

const mapDispatchToProps = dispatch => ({
  onProcessFile: boardingTemplateOperation.proceBoardingTemplateOperation(dispatch),
  onResetUploadStatus: operations.onResetUploadStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
