import React from 'react';
import { Button } from '@material-ui/core/index';
import './ReUpload.css';
import UploadIcon from './UploadIcon';

const ReUpload = () => (
  <div styleName="container">
    <UploadIcon />
    <p styleName="typography">Click below to resume uploading</p>
    <Button
      color="primary"
      component="label"
      id="upload"
      onChange={() => {}}
      style={{
        label: 'uploadLabel',
      }}
      variant="contained"
    >
                RE-UPLOAD
      <input
        style={{ display: 'none' }}
        type="file"
      />
    </Button>
  </div>
);

export default ReUpload;
