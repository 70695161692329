import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, IconButton,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EvalTable from 'components/Eval/EvalTable';
import DatePicker from 'components/Eval/DatePicker';
import moment from 'moment-timezone';
// import findSeq from 'lib/CustomFunctions/Eval/findSeqNumber';
import compareArrays from 'lib/CustomFunctions/Eval/compareTrackPaymentData';
import Popup from 'components/Popup';
import './TrackPayment.css';
import utils from 'ducks/tasks-and-checklist/utils';
import Loader from 'components/Loader/Loader';
import { operations as evalOperations, selectors as evalSelectors } from '../../../state/ducks/eval';
import { selectors } from '../../../state/ducks/repaymentEval';
import { selectors as loginSelectors } from '../../../state/ducks/login';
import {
  TRACK_PAYMENT_DELETE_ICON_VISIBILITY_CASE_TYPES, DELETE_ICON_ENABLE_AD_LIST_WITHOUT_WORKOUT_TYPE,
  DELETE_ICON_ENABLE_AD_LIST_WITH_WORKOUT_TYPE, TRACK_PAYMENT_CASE_TYPES_UPDATE,
} from '../../../constants/eval';

const TrackPayment = (props) => {
  const {
    repayTrackPayments, fetchTrackPayments, updateTrackPaymentsDates,
    deleteTrackPayment, isCaseRejected, caseStatus, userGroupList, userEmail,
    caseHeaderInfo, resolutionId, isTrackPayEditable, updateTrackPaymentsResponse,
    inProgress,
  } = props;
  const [tableData, setTableData] = useState(repayTrackPayments.trackPaymentDetailsList);
  const [visible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState(repayTrackPayments.trackPaymentDetailsList);
  const isDeleteIconVisible = TRACK_PAYMENT_DELETE_ICON_VISIBILITY_CASE_TYPES.includes(caseStatus);
  const [isCaseRejectedValue, SetIsCaseRejectedValue] = useState(isCaseRejected);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(
    { isSuccessModalVisible: false, updatedTrackPayment: false },
  );

  const disableDeleteIcon = () => {
    if (userGroupList && userGroupList.length > 0 && caseHeaderInfo && caseHeaderInfo.reslWorkoutType === 'F') {
      if (userGroupList.some(e => DELETE_ICON_ENABLE_AD_LIST_WITH_WORKOUT_TYPE.includes(e))) {
        return false;
      }
    } else if (userGroupList && userGroupList.length > 0 && caseHeaderInfo && caseHeaderInfo.reslWorkoutType !== 'F') {
      if (userGroupList.some(e => DELETE_ICON_ENABLE_AD_LIST_WITHOUT_WORKOUT_TYPE.includes(e))) {
        return false;
      }
    }
    return true;
  };

  const disableDelete = disableDeleteIcon();

  const { getCSTDateTime } = utils;
  useEffect(() => {
    fetchTrackPayments({ resolutionId, isRepaymentEval: 'Yes' });
  }, []);

  useEffect(() => {
    SetIsCaseRejectedValue(isCaseRejected);
  }, [isCaseRejected]);

  useEffect(() => {
    setTableData(repayTrackPayments.trackPaymentDetailsList);
    setInitialData(repayTrackPayments.trackPaymentDetailsList);
  }, [repayTrackPayments]);

  useEffect(() => {
    const newVisible = (compareArrays(initialData, tableData, 1));
    setVisible(newVisible);
  }, [tableData]);

  useEffect(() => {
    if (!R.isNil(updateTrackPaymentsResponse) && showSuccessModal.updatedTrackPayment) {
      setShowSuccessModal(prev => ({ ...prev, isSuccessModalVisible: true }));
    }
  }, [updateTrackPaymentsResponse]);

  const handleDelete = (terms) => {
    // TODO modify the payload with API integration
    deleteTrackPayment({ id: terms, resolutionId, isRepaymentEval: 'Yes' });
  };

  const handleDateChange = (columnName, value, terms) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj, idx) => {
        if (obj.terms === terms && value) {
          return { ...obj, [columnName]: value.format(), [`is${columnName}Updated`]: !moment(initialData[idx][columnName]).isSame(moment(value), 'days') };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleUpdate = (isConfirm) => {
    const validateStatus = TRACK_PAYMENT_CASE_TYPES_UPDATE.includes(caseStatus);
    setShowPopup(validateStatus && !isConfirm);
    if (isConfirm || !validateStatus) {
      const paymentList = initialData.map((item, idx) => ({
        datePaymentReceived: tableData[idx].paymentDate,
        forbDtlId: item.detailId,
        hssnReportedDate: item.hssnreportedDate,
        isPaymentDateUpdated: R.propOr(false, 'ispaymentDateUpdated', tableData[idx]),
        isProcessDateUpdated: false,
        seq: item.terms,
      }));
      const payload = {
        paymentHSSNDatesUpdateReqDataList: paymentList,
        userEmail,
        resolutionId,
      };
      updateTrackPaymentsDates(payload);
      setShowSuccessModal(prev => ({ ...prev, updatedTrackPayment: true }));
    }
  };

  const renderDeleteIcon = (terms, isDisabled) => (
    <IconButton
      disabled={isCaseRejectedValue || isDisabled}
    >
      <DeleteOutlined onClick={() => handleDelete(terms)} styleName={isDisabled ? 'disabledIcon' : 'deleteIcon'} />
    </IconButton>
  );

  const TRACKPAYMENT_COL = [
    {
      name: 'terms',
      label: 'Terms',
      align: 'left',
    },
    {
      name: 'startedDueDate',
      label: 'Stated Due Date',
      align: 'left',
      cellFormat: value => getCSTDateTime(moment(value), 'DD MMM YYYY'),
    },
    {
      name: 'actualDueDate',
      label: 'Actual Due Date',
      align: 'left',
      cellFormat: value => getCSTDateTime(moment(value), 'DD MMM YYYY'),
    },
    {
      name: 'paymentDate',
      label: 'Date Payment Received',
      align: 'left',
      editable: true,
      dateField: true,
      cellFormat: row => (
        <DatePicker
          endDate={new Date()}
          format="DD MMM YYYY"
          handleChange={(columnName, val) => {
            handleDateChange(columnName, val, row.terms);
          }}
          id="paymentDate"
          isViewOnly={isCaseRejectedValue || !isTrackPayEditable}
          label="paymentDate"
          override
          value={!R.isNil(row.paymentDate) ? getCSTDateTime(moment(row.paymentDate), 'DD MMM YYYY') : null}
        />
      ),
    },
    {
      name: 'amount',
      label: 'Amount',
      align: 'left',
    },
    {
      name: 'type',
      label: 'Type',
      align: 'left',
    },
    isDeleteIconVisible && {
      name: 'del',
      label: '',
      align: 'left',
      deleteIcon: true,
      name1: 'terms',
      cellFormat: terms => renderDeleteIcon(terms, disableDelete),
    },
  ];


  return (
    <>
      {inProgress ? <Loader message="Please Wait" size={40} /> : (
        <Box styleName="trackPaymentTab">
          <Box styleName="trackPayment cardBlock">
            <Grid container>
              <Grid item lg={5} md={5} sm={12} styleName="datesBlock" xl={5} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Completion Date</div>
                      <div styleName="fieldValue">
                        {tableData && tableData.completionDate && getCSTDateTime(moment(tableData.completionDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Extended Date</div>
                      <div styleName="fieldValue">
                        {tableData && tableData.extendedDate && getCSTDateTime(moment(tableData.extendedDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Doc Extension Date</div>
                      <div styleName="fieldValue">
                        {tableData && tableData.docExtensionDate && getCSTDateTime(moment(tableData.docExtensionDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid styleName="trackPayment tableBlock">
            <EvalTable
              columns={TRACKPAYMENT_COL}
              data={tableData || []}
            />
          </Grid>
          <Button
            color="primary"
            disabled={isCaseRejectedValue || !visible}
            onClick={() => handleUpdate(false)}
            styleName="updateButton"
            variant="contained"
          >
            UPDATE PAYMENT DATES
          </Button>

          <Popup
            cancelButtonText="NO"
            confirmButtonText="YES"
            message="This will re-open the Case. Are you sure you want to proceed?"
            onCancel={() => setShowPopup(false)}
            onConfirm={() => handleUpdate(true)}
            show={showPopup}
            showCancelButton
            showConfirmButton
          />
          <Popup
            confirmButtonText="OK"
            level="Success"
            onConfirm={() => setShowSuccessModal(prev => ({
              ...prev,
              isSuccessModalVisible: false,
            }))}
            show={showSuccessModal.isSuccessModalVisible}
            showConfirmButton
            title={updateTrackPaymentsResponse && updateTrackPaymentsResponse.status}
          />
        </Box>
      )}
    </>
  );
};


TrackPayment.defaultProps = {
  caseHeaderInfo: {
    reslWorkoutType: '',
  },
  repayTrackPayments: [],
  fetchTrackPayments: () => { },
  updateTrackPaymentsDates: () => { },
  deleteTrackPayment: () => { },
  isCaseRejected: false,
  userGroupList: [],
  updateTrackPaymentsResponse: {},
  inProgress: false,
};

TrackPayment.propTypes = {
  caseHeaderInfo: PropTypes.shape({
    reslWorkoutType: PropTypes.string,
  }),
  caseStatus: PropTypes.string.isRequired,
  deleteTrackPayment: PropTypes.func,
  fetchTrackPayments: PropTypes.func,
  inProgress: PropTypes.bool,
  isCaseRejected: PropTypes.bool,
  isTrackPayEditable: PropTypes.bool.isRequired,
  repayTrackPayments: PropTypes.arrayOf(PropTypes.shape()),
  resolutionId: PropTypes.number.isRequired,
  updateTrackPaymentsDates: PropTypes.func,
  updateTrackPaymentsResponse: PropTypes.shape(),
  userEmail: PropTypes.string.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string.isRequired),
};

const mapStateToProps = state => ({
  caseHeaderInfo: evalSelectors.caseHeaderInfoData(state),
  repayTrackPayments: evalSelectors.trackPayments(state),
  isCaseRejected: selectors.isCaseRejected(state),
  userGroupList: loginSelectors.getGroupList(state),
  userEmail: loginSelectors.getUserPrincipalName(state),
  updateTrackPaymentsResponse: evalSelectors.updateTrackPaymentsResponse(state),
  inProgress: selectors.inProgress(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTrackPayments: evalOperations.fetchTrackPaymentData(dispatch),
  updateTrackPaymentsDates: evalOperations.updateTrackPaymentsDates(dispatch),
  deleteTrackPayment: evalOperations.deleteTrackPaymentOperation(dispatch),
});


const TrackPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(TrackPayment);

export default TrackPaymentContainer;
