import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors as loginSelectors } from 'ducks/login';
import styles from './ProductBusiness.css';
import {
  modSupportActions, MOD_SUPPORT, UPLOAD_DATA_LIST,
  UPDATE_INTEREST_RATE,
} from '../../constants/busniessAdmin';
import { VP } from '../../constants/Groups';

class ProductSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modSupportTabs: modSupportActions,
      uploadDataTabs: UPLOAD_DATA_LIST,
    };
  }

  componentDidMount() {
    const { modSupportTabs } = this.state;
    const { userGroupList } = this.props;
    if (!userGroupList.includes(VP)) {
      const nonVpGrpModSupportTabs = modSupportTabs.filter(item => item !== UPDATE_INTEREST_RATE);
      this.setState({
        modSupportTabs: nonVpGrpModSupportTabs,
      });
    }
  }

  render() {
    const { modSupportTabs, uploadDataTabs } = this.state;
    const { activeTab, handleChange, actionHeader } = this.props;
    const tabsToRender = actionHeader === MOD_SUPPORT ? modSupportTabs : uploadDataTabs;
    return (

      <>
        {tabsToRender.map(tab => (
          <div
            aria-hidden="true"
            className={` ${styles.tab} ${activeTab === tab ? styles.selected : ''}`}
            onClick={() => handleChange(tab)}
          >
            <h4>{tab}</h4>
          </div>
        ))}
      </>
    );
  }
}

ProductSupport.defaultProps = {
  activeTab: 'All',
  handleChange: () => {},
  actionHeader: MOD_SUPPORT,
};


ProductSupport.propTypes = {
  actionHeader: PropTypes.string,
  activeTab: PropTypes.string,
  handleChange: PropTypes.func,
  userGroupList: PropTypes.arrayOf.isRequired,
};

const mapStateToProps = state => ({
  userGroupList: loginSelectors.getGroupList(state),
});

export default connect(mapStateToProps, null)(ProductSupport);
