import {
  FETCH_CALC_LOG_DATA, FETCH_ELIGIBILITY_DATA, FETCH_UNIVERSAL_ACTION_DATA,
  SET_CALC_LOG_DATA, SET_ELIGIBILITY_DATA, SET_UNIVERSAL_ACTION_DATA, FETCH_REJECT_REASONS,
  SET_POPUP_STATUS, SAVE_REJECT_REASON, SAVE_ELIGIBILITY_DATA, FETCH_FULFILLMENT_CHECKLIST,
  FETCH_TRACKPAYMENT_DATA, SET_TRACKPAYMENT_DATA, UPDATE_TRACKPAYMENT_DATES, DELETE_TRACKPAYMENT,
  SUBMIT_COLLATERAL_ACCEPTANCE, SHOW_LOADER, HIDE_LOADER, SUBMIT_FULFILLMENT_CHECKLIST,
  FETCH_BOOKING_DATA, CALCULATE_CASE_RULES, LOCK_CASE_RULES, SAVE_CASEDETAILS,
  GET_CASEHEADERINFO_DATA, SET_CASEHEADERINFO_DATA,
  CLEAR_CASEHEADERINFO_DATA, FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST, FETCH_EVAL_DETAILS, SET_EVAL_DETAILS, SET_INV_GRP_CODES,
  SAVE_ACCEPTANCE_FLAG, FETCH_CASE_TYPES, FETCH_WORKOUTTYPE, FETCH_EVALSCREEN_DETAILS,
  SAVE_DISASTERDEC_DATA,
  SET_SELECTED_CASEID, SAVE_LOCK_CASE, TOGGLE_LOCK_CASE,
  ADD_CASE_TO_WATERFALL_SEQ,
  SET_EVALSCREEN_ICON, CREATE_NEW_MOD,
  CREATE_NEW_EVAL_CASE, FETCH_BOOKING_TAB_DATA,
  MOVE_CASE_SEQ,
  REMOVE_WATERFALL,
  CHECK_LETTER_GENERATION_STATUS,
  SET_LETTER_GENERATION,
  FETCH_STATES,
  SET_STATES,
  TOGGLE_DISASTER_POPUP,
  FETCH_COUNTYS,
  SET_COUNTYS,
  FETCH_DISASTERDTLS,
  SET_DISASTERDTLS,
  FETCH_CASE_NPV_DETAILS,
  FETCH_FINANCIAL_DROPDOWNS, SAVE_FINANCIAL_DATA,
  UPDATE_HARDSHIP_ID, FETCH_CASE_UNIVERSAL_DETAILS,
  SET_EVAL_HEADER_IN_PROGRESS,
  CREATE_NEW_CASE,
  SET_RULE_ENGINE_ERROR_MSG, LOAD_MOD_CALC,
  FINANCIAL_DATA_LOADER,
  SAVE_OVERRIDDEN_RULE,
  FETCH_REPAY_DETAILS, FETCH_PAYMENT_CHANGES_DETAILS,
  SET_LOCK_EXT_VALIDATION_MSG,
  CLEAR_INFO,
  GENERATE_APPROVAL_LETTER,
  SET_SMDU_POPUP,
  FETCH_CASE_REJ_REASONS,
  FETCH_CASE_REJ_SUB_REASONS,
  REJECT_CASE,
  UPDATE_UNREJECT_STATUS,
} from './types';

const fetchUniversalActionDataAction = payload => ({
  type: FETCH_UNIVERSAL_ACTION_DATA,
  payload,
});

const setUniversalActionDataAction = payload => ({
  type: SET_UNIVERSAL_ACTION_DATA,
  payload,
});

const fetchEligibilityDataAction = payload => ({
  type: FETCH_ELIGIBILITY_DATA,
  payload,
});

const setEligibilityDataAction = payload => ({
  type: SET_ELIGIBILITY_DATA,
  payload,
});

const setCaseHeaderInfoDataAction = payload => ({
  type: SET_CASEHEADERINFO_DATA,
  payload,
});

const clearCaseHeaderInfoDataAction = () => ({
  type: CLEAR_CASEHEADERINFO_DATA,
});

const fetchCalcLogsDataAction = payload => ({
  type: FETCH_CALC_LOG_DATA,
  payload,
});

const setCalcLogsDataAction = payload => ({
  type: SET_CALC_LOG_DATA,
  payload,
});

const fetchRejectReasonsAction = () => ({
  type: FETCH_REJECT_REASONS,
});

const setRejectReasonPopupStatusAction = payload => ({
  type: SET_POPUP_STATUS,
  payload,
});

const saveRejectReasonAction = payload => ({
  type: SAVE_REJECT_REASON,
  payload,
});

const saveEligibilityDataAction = payload => ({
  type: SAVE_ELIGIBILITY_DATA,
  payload,
});

const getCaseHeaderInfoAction = payload => ({
  type: GET_CASEHEADERINFO_DATA,
  payload,
});

const fetchTrackPaymentDataAction = payload => ({
  type: FETCH_TRACKPAYMENT_DATA,
  payload,
});

const setTrackPaymentDataAction = payload => ({
  type: SET_TRACKPAYMENT_DATA,
  payload,
});

const updateTrackPaymentDatesAction = payload => ({
  type: UPDATE_TRACKPAYMENT_DATES,
  payload,
});

const deleteTrackPaymentAction = payload => ({
  type: DELETE_TRACKPAYMENT,
  payload,
});

const submitCollateralAcceptance = payload => ({
  type: SUBMIT_COLLATERAL_ACCEPTANCE,
  payload,
});

const showLoader = () => ({
  type: SHOW_LOADER,
});

const hideLoader = () => ({
  type: HIDE_LOADER,
});

const fetchFulfillmentChecklistAction = payload => ({
  type: FETCH_FULFILLMENT_CHECKLIST,
  payload,
});

const saveFulfillmentChecklistAction = payload => ({
  type: SUBMIT_FULFILLMENT_CHECKLIST,
  payload,
});

const fetchBookingTagsAction = payload => ({
  type: FETCH_BOOKING_DATA,
  payload,
});

const calculateCaseRules = payload => ({
  type: CALCULATE_CASE_RULES,
  payload,
});

const lockCaseRules = payload => ({
  type: LOCK_CASE_RULES,
  payload,
});

const toggleLockCase = payload => ({
  type: TOGGLE_LOCK_CASE,
  payload,
});

const saveLockCase = payload => ({
  type: SAVE_LOCK_CASE,
  payload,
});

const generateApprovalLetter = payload => ({
  type: GENERATE_APPROVAL_LETTER,
  payload,
});

const saveCaseDetails = payload => ({
  type: SAVE_CASEDETAILS,
  payload,
});

const fetchCertainMorgageLoanChecklistAction = payload => ({
  type: FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  payload,
});

const saveCertainMortgageLoanChecklistAction = (mortgageData, errorDisplay) => ({
  type: SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  payload: { mortgageData, errorDisplay },
});

const fetchEvalDetailsAction = () => ({
  type: FETCH_EVAL_DETAILS,
});

const setEvalDetailsAction = payload => ({
  type: SET_EVAL_DETAILS,
  payload,
});

const setInvGrpCodesAction = payload => ({
  type: SET_INV_GRP_CODES,
  payload,
});

const saveAcceptanceFlagAction = payload => ({
  type: SAVE_ACCEPTANCE_FLAG,
  payload,
});

const fetchCaseTypesAction = () => ({
  type: FETCH_CASE_TYPES,
});

const fetchWorkoutTypeDataAction = payload => ({
  type: FETCH_WORKOUTTYPE,
  payload,
});

const fetcEvalScreenInfoAction = payload => ({
  type: FETCH_EVALSCREEN_DETAILS,
  payload,
});

const saveDisasterDecAction = payload => ({
  type: SAVE_DISASTERDEC_DATA,
  payload,
});

const setSelectedCaseIdAction = payload => ({
  type: SET_SELECTED_CASEID,
  payload,
});

const addCaseToWaterfallSeqAction = payload => ({
  type: ADD_CASE_TO_WATERFALL_SEQ,
  payload,
});

const createNewModAction = payload => ({
  type: CREATE_NEW_MOD,
  payload,
});


const setEvalScreenIconAction = payload => ({
  type: SET_EVALSCREEN_ICON,
  payload,
});

const createNewEvalCaseAction = payload => ({
  type: CREATE_NEW_EVAL_CASE,
  payload,
});

const fetchBookingTabDataAction = payload => ({
  type: FETCH_BOOKING_TAB_DATA,
  payload,
});

const checkIsLetterGeneratedAction = payload => ({
  type: CHECK_LETTER_GENERATION_STATUS,
  payload,
});

const updateLetterGenerationStatusAction = payload => ({
  type: SET_LETTER_GENERATION,
  payload,
});

const moveCaseSeqAction = payload => ({
  type: MOVE_CASE_SEQ,
  payload,
});

const removeWaterfallAction = payload => ({
  type: REMOVE_WATERFALL,
  payload,
});

const fetchStatesAction = () => ({
  type: FETCH_STATES,
});

const setStatesAction = payload => ({
  type: SET_STATES,
  payload,
});

const toggleDisasterPopupAction = payload => ({
  type: TOGGLE_DISASTER_POPUP,
  payload,
});

const fetchCountysAction = payload => ({
  type: FETCH_COUNTYS,
  payload,
});

const setCountysAction = payload => ({
  type: SET_COUNTYS,
  payload,
});

const fetchDisasterDtlsAction = payload => ({
  type: FETCH_DISASTERDTLS,
  payload,
});

const setDisasterDtlsAction = payload => ({
  type: SET_DISASTERDTLS,
  payload,
});

const fetchCaseNpvDetailsAction = payload => ({
  type: FETCH_CASE_NPV_DETAILS,
  payload,
});

const fetchFinancialDropdownAction = () => ({
  type: FETCH_FINANCIAL_DROPDOWNS,
});

const saveFinancialDataAction = payload => ({
  type: SAVE_FINANCIAL_DATA,
  payload,
});

const updateHardShipIdAction = payload => ({
  type: UPDATE_HARDSHIP_ID,
  payload,
});

const fetchCaseUniversalDataAction = () => ({
  type: FETCH_CASE_UNIVERSAL_DETAILS,
});

const setEvalHeaderInProgress = payload => ({
  type: SET_EVAL_HEADER_IN_PROGRESS,
  payload,
});

const createNewCaseAction = payload => ({
  type: CREATE_NEW_CASE,
  payload,
});

const setRuleEngineErrorAction = payload => ({
  type: SET_RULE_ENGINE_ERROR_MSG,
  payload,
});

const SaveOverriddenRuleAction = payload => ({
  type: SAVE_OVERRIDDEN_RULE,
  payload,
});

const setModCalcIndicatorAction = payload => ({
  type: LOAD_MOD_CALC,
  payload,
});

const setFinancialLoaderAction = payload => ({
  type: FINANCIAL_DATA_LOADER,
  payload,
});

const fetchRepayDetailsAction = payload => ({
  type: FETCH_REPAY_DETAILS,
  payload,
});

const fetchPaymentChangesDetailsAction = payload => ({
  type: FETCH_PAYMENT_CHANGES_DETAILS,
  payload,
});

const setLockExtValidationMsgAction = payload => ({
  type: SET_LOCK_EXT_VALIDATION_MSG,
  payload,
});

const setToggleLockCaseAction = payload => ({
  type: TOGGLE_LOCK_CASE,
  payload,
});

const clearInfoAction = () => ({
  type: CLEAR_INFO,
});

const setSmduPopupAction = payload => ({
  type: SET_SMDU_POPUP,
  payload,
});

const fetchCaseRejReasonsAction = () => ({ type: FETCH_CASE_REJ_REASONS });

const fetchCaseRejSubReasonsAction = payload => ({ type: FETCH_CASE_REJ_SUB_REASONS, payload });

const rejectCaseAction = payload => ({ type: REJECT_CASE, payload });

const updateUnRejectStatusAction = payload => ({
  type: UPDATE_UNREJECT_STATUS,
  payload,
});

export {
  addCaseToWaterfallSeqAction,
  fetchUniversalActionDataAction,
  setUniversalActionDataAction,
  fetchEligibilityDataAction,
  setEligibilityDataAction,
  setCaseHeaderInfoDataAction,
  clearCaseHeaderInfoDataAction,
  fetchCalcLogsDataAction,
  setCalcLogsDataAction,
  saveEligibilityDataAction,
  getCaseHeaderInfoAction,
  fetchRejectReasonsAction,
  saveRejectReasonAction,
  setRejectReasonPopupStatusAction,
  fetchTrackPaymentDataAction,
  setTrackPaymentDataAction,
  updateTrackPaymentDatesAction,
  deleteTrackPaymentAction,
  submitCollateralAcceptance,
  fetchFulfillmentChecklistAction,
  saveFulfillmentChecklistAction,
  fetchBookingTagsAction,
  showLoader,
  hideLoader,
  calculateCaseRules,
  lockCaseRules,
  toggleLockCase,
  saveLockCase,
  generateApprovalLetter,
  saveCaseDetails,
  fetchCertainMorgageLoanChecklistAction,
  saveCertainMortgageLoanChecklistAction,
  fetchEvalDetailsAction,
  setEvalDetailsAction,
  setInvGrpCodesAction,
  saveAcceptanceFlagAction,
  fetchCaseTypesAction,
  fetchWorkoutTypeDataAction,
  fetcEvalScreenInfoAction,
  saveDisasterDecAction,
  setSelectedCaseIdAction,
  createNewModAction,
  setEvalScreenIconAction,
  createNewEvalCaseAction,
  fetchBookingTabDataAction,
  moveCaseSeqAction,
  removeWaterfallAction,
  checkIsLetterGeneratedAction,
  updateLetterGenerationStatusAction,
  fetchStatesAction,
  setStatesAction,
  toggleDisasterPopupAction,
  fetchCountysAction,
  setCountysAction,
  fetchDisasterDtlsAction,
  setDisasterDtlsAction,
  fetchCaseNpvDetailsAction,
  fetchFinancialDropdownAction,
  saveFinancialDataAction,
  updateHardShipIdAction,
  fetchCaseUniversalDataAction,
  setEvalHeaderInProgress,
  createNewCaseAction,
  setRuleEngineErrorAction,
  SaveOverriddenRuleAction,
  setModCalcIndicatorAction,
  setFinancialLoaderAction,
  fetchRepayDetailsAction,
  fetchPaymentChangesDetailsAction,
  setLockExtValidationMsgAction,
  setToggleLockCaseAction,
  clearInfoAction,
  setSmduPopupAction,
  fetchCaseRejReasonsAction,
  fetchCaseRejSubReasonsAction,
  rejectCaseAction,
  updateUnRejectStatusAction,
};
