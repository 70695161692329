import React from 'react';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import utils from 'ducks/tasks-and-checklist/utils';
import styles from './DartsType.css';

const { getCSTDateTime } = utils;
const DartsType = ({ dartHistorys }) => (


  <TableContainer component={Paper} style={{ padding: '10px', background: '#F3F5F9' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.tableHeader}>Servicer Name</TableCell>
          <TableCell className={styles.tableHeader}>Workout Type</TableCell>
          <TableCell className={styles.tableHeader}>Payment Due Dates</TableCell>
          <TableCell className={styles.tableHeader}>Case Status</TableCell>
          <TableCell className={styles.tableHeader}>Campaign Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dartHistorys.map(dartHistory => (
          <TableRow>
            <TableCell className={styles.tableBody}>
              {dartHistory.reportedWorkoutServicerName}
            </TableCell>
            <TableCell className={styles.tableBody}>
              {dartHistory.reportedWorkoutType}
            </TableCell>
            <TableCell className={styles.tableBody}>
              {getCSTDateTime(dartHistory.reportedWorkoutPaymentDueDates)}
            </TableCell>
            <TableCell className={styles.tableBody}>
              {dartHistory.reportedWorkoutCaseStatus}
            </TableCell>
            <TableCell className={styles.tableBody}>
              {dartHistory.reportedWorkoutCampaignName}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>


);
DartsType.propTypes = {
  dartHistorys: PropTypes.arrayOf(
    PropTypes.shape({
      reportedWorkoutCampaignName: PropTypes.string.isRequired,
      reportedWorkoutCaseStatus: PropTypes.string.isRequired,
      reportedWorkoutPaymentDueDates: PropTypes.string.isRequired,
      reportedWorkoutServicerName: PropTypes.string.isRequired,
      reportedWorkoutType: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DartsType;
