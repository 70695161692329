const DOWNLOAD_FILE = 'app/netpresentvalue/DOWNLOAD_FILE';
const SET_DOWNLOAD_RESULT = 'app/netpresentvalue/SET_DOWNLOAD_RESULT';
const PROCESS_FILE = 'app/netpresentvalue/PROCESS_FILE';
const UPLOAD_FILE = 'app/netpresentvalue/UPLOAD_FILE';
const GET_PROCESSED_DATA = 'app/netpresentvalue/GET_PROCESSED_DATA';
const SET_RESULT_OPERATION = 'app/netpresentvalue/SET_RESULT_OPERATION';
const SET_NPV_HISTORY_DATA = 'app/netpresentvalue/SET_NPV_HISTORY_DATA';
const GET_NPV_HISTORY_DATA = 'app/netpresentvalue/GET_NPV_HISTORY_DATA';
const SET_NPV_REQUEST_ID = 'app/netpresentvalue/SET_NPV_REQUEST_ID';
const SET_NPV_RESULT_DATA = 'app/netpresentvalue/SET_NPV_RESULT_DATA';
const GET_NPV_RESULT_DATA = 'app/netpresentvalue/GET_NPV_RESULT_DATA';
const RESET_UPLOAD_STATUS = 'app/netpresentvalue/RESET_UPLOAD_STATUS';
const SET_NPV_RQST_STS = 'app/netpresentvalue/SET_NPV_RQST_STS';
const SET_NPV_MAN_FLG = 'app/netpresentvalue/SET_NPV_MAN_FLG';
const GET_NPV_RQST_FILE_DATA = 'app/netpresentvalue/GET_NPV_RQST_FILE_DATA';
const SET_NPV_RQST_FILE_DATA = 'app/netpresentvalue/SET_NPV_RQST_FILE_DATA';
const SET_NPV_INVESTOR_REPORT_DATA = 'app/netpresentvalue/SET_NPV_INVESTOR_REPORT_DATA';
const GET_NPV_INVESTOR_REPORT_DATA = 'app/netpresentvalue/GET_NPV_INVESTOR_REPORT_DATA';
const SET_CASE_ID = 'app/netpresentvalue/SET_CASE_ID';
const CANCEL_NPV = 'app/netpresentvalue/CANCEL_NPV';
const CANCEL_NPV_STATUS = 'app/netpresentvalue/CANCEL_NPV_STATUS';

export {
  DOWNLOAD_FILE,
  SET_DOWNLOAD_RESULT,
  PROCESS_FILE,
  UPLOAD_FILE,
  GET_PROCESSED_DATA,
  SET_RESULT_OPERATION,
  SET_NPV_HISTORY_DATA,
  GET_NPV_HISTORY_DATA,
  SET_NPV_REQUEST_ID,
  SET_NPV_RESULT_DATA,
  GET_NPV_RESULT_DATA,
  GET_NPV_INVESTOR_REPORT_DATA,
  SET_NPV_INVESTOR_REPORT_DATA,
  RESET_UPLOAD_STATUS,
  SET_NPV_RQST_STS,
  SET_NPV_MAN_FLG,
  GET_NPV_RQST_FILE_DATA,
  SET_NPV_RQST_FILE_DATA,
  SET_CASE_ID,
  CANCEL_NPV,
  CANCEL_NPV_STATUS,
};
