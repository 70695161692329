const Success = '/static/img/success.gif';
const Failed = '/static/img/failed.gif';
const Warning = '/static/img/warning.gif';
const Info = 'static/img/info.gif';
const NotFound = 'static/img/notfound.gif';
const alertTypes = {
  Success,
  Failed,
  Warning,
  Info,
  NotFound,
};
export default alertTypes;
