import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from 'ducks/document-checklist';
import { selectors as stagerSelectors } from 'ducks/stager';
import Grid from '@material-ui/core/Grid';
import './Vasp.css';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DocumentList from '../../../components/IncomeCalc/DocumentList';
import DocumentViewer from '../../../components/IncomeCalc/DocumentViewer/DocumentViewer';
import ConfirmationBox from '../../../components/ConfirmationBox';
import { TO_ORDER_TAB, ORDERED_TAB } from '../../../constants/incomeCalc/DocumentList';
import WidgetBuilder from '../../../components/Widgets/WidgetBuilder';

class Vasp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ispopupVisible: false,
      disableCompleteBtn: true,
      isPopupOpen: false,
    };
  }

  setDisableCompleteBtn = (value) => {
    this.setState({
      disableCompleteBtn: value,
    });
  }

  handleSave = () => {
    const { updatePrcsTask, saveVaspDocs } = this.props;
    updatePrcsTask();
    saveVaspDocs({ operation: 'save' });
  };

  handleCompleted = () => {
    const {
      activeTab, resetVASPState, saveVaspDocs, updatePrcsTask,
    } = this.props;
    this.setState({ disableCompleteBtn: true });
    updatePrcsTask();
    if (activeTab === TO_ORDER_TAB) {
      saveVaspDocs({ operation: 'complete' });
      this.moveToNextTask();
      resetVASPState();
    }
    if (activeTab === ORDERED_TAB) {
      this.setState({ isPopupOpen: true });
    }
  };

  handleOpenPopup = () => {
    const { enableSaveButton, resetVASPState } = this.props;
    if (enableSaveButton) {
      this.setState({ ispopupVisible: true });
    } else {
      this.setState({ ispopupVisible: false });
      resetVASPState();
    }
  }

  handleClosePopup = () => {
    this.setState({ ispopupVisible: false });
  }

  handleClose = () => {
    const { resetVASPState } = this.props;
    this.setState({ ispopupVisible: false });
    resetVASPState();
  };

  handlePopupOk = () => {
    const { resetVASPState, saveVaspDocs } = this.props;
    this.setState({ isPopupOpen: false });
    saveVaspDocs({ operation: 'complete' });
    this.moveToNextTask();
    resetVASPState();
  }

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
  }

  moveToNextTask = () => {
    const {
      getActiveSearchTerm, getRowData, onOrderClick,
    } = this.props;
    onOrderClick([getRowData], getActiveSearchTerm);
  }

  render() {
    const { disableCompleteBtn, ispopupVisible, isPopupOpen } = this.state;
    const { enableSaveButton, enableCompleteButton } = this.props;
    if (enableCompleteButton) {
      this.setDisableCompleteBtn(false);
    }
    const { activeTab } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={10}>
              <div styleName="vasp-content">
                Link All the Required Documents
              </div>
            </Grid>
            <Grid item>
              <div styleName="vasp-actions">
                <Button
                  color="primary"
                  disabled={!enableSaveButton}
                  onClick={this.handleSave}
                  style={{ marginRight: '8px' }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={disableCompleteBtn}
                  onClick={this.handleCompleted}
                  variant="contained"
                >
                  Completed
                </Button>
                <span
                  onClick={this.handleOpenPopup}
                  onKeyPress={this.handleOpenPopup}
                  role="button"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  tabIndex="0"
                >
                  &#x2716;
                </span>
                <ConfirmationBox
                  confirmButtonText="YES"
                  level="default_error.svg"
                  message="Unsaved changes will be lost"
                  onCancel={this.handleClosePopup}
                  onConfirm={this.handleClose}
                  show={ispopupVisible}
                  title="Are you sure you want to leave?"
                />
              </div>
            </Grid>
          </Grid>
          <WidgetBuilder page="VASP" />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" style={{ paddingLeft: '1rem' }}>
            <Grid item xs={9}>
              <Grid container direction="column">
                <Grid item>
                  <div styleName="vasp-document-list">
                    <DocumentList
                      activeTab={activeTab}
                      setDisableCompleteBtn={this.setDisableCompleteBtn}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingLeft: '1rem' }} xs={3}>
              <DocumentViewer
                activeTab={activeTab}
                setDisableCompleteBtn={this.setDisableCompleteBtn}
              />
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={isPopupOpen}
        >
          <DialogTitle>
            {''}
            <IconButton
              aria-label="close"
              onClick={this.handlePopupClose}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              All the documents are linked, Please upload them to VALERI.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handlePopupOk}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

Vasp.propTypes = {
  activeTab: PropTypes.string.isRequired,
  enableCompleteButton: PropTypes.bool.isRequired,
  enableSaveButton: PropTypes.bool.isRequired,
  getActiveSearchTerm: PropTypes.string.isRequired,
  getRowData: PropTypes.shape().isRequired,
  onOrderClick: PropTypes.func.isRequired,
  resetVASPState: PropTypes.func.isRequired,
  saveVaspDocs: PropTypes.func.isRequired,
  updatePrcsTask: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  enableCompleteButton: documentChecklistSelectors.isCompleteVASPEnable(state),
  enableSaveButton: documentChecklistSelectors.isSaveVASPEnable(state),
  getActiveSearchTerm: stagerSelectors.getActiveSearchTerm(state),
  getRowData: stagerSelectors.getRowData(state),
});

const mapDispatchToProps = dispatch => ({
  resetVASPState: documentChecklistOperations.closeVasp(dispatch),
  updatePrcsTask: documentChecklistOperations.updatePrcsTask(dispatch),
  saveVaspDocs: documentChecklistOperations.saveVaspDocsOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vasp);
