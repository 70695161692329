const GET_COMMENTS_SAGA = 'app/comments/GET_COMMENTS_SAGA';
const GET_COMMENTS_RESULT = 'app/comments/GET_COMMENTS_RESULT';
const GET_EVALCOMMENTS_RESULT = 'app/comments/GET_EVALCOMMENTS_RESULT';
const POST_COMMENT_SAGA = 'app/comments/POST_COMMENT_SAGA';
const POST_COMMENT_RESULT = 'app/comments/POST_COMMENT_RESULT';
const GET_EVALCOMMENTS_SAGA = 'app/comments/GET_EVALCOMMENTS_SAGA';
const GET_SEARCH_AREA = 'app/comments/GET_SEARCH_AREA';
const CLEAR_SEARCH = 'app/comments/CLEAR_SEARCH';

export {
  GET_COMMENTS_SAGA,
  GET_COMMENTS_RESULT,
  GET_EVALCOMMENTS_RESULT,
  POST_COMMENT_SAGA,
  POST_COMMENT_RESULT,
  GET_EVALCOMMENTS_SAGA,
  GET_SEARCH_AREA,
  CLEAR_SEARCH,
};
