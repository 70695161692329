import { makeStyles, createTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  textName: {
    marginLeft: '-0.813rem',
    fontSize: '0.9rem',
    marginTop: '-0.41rem',
    alignItems: 'left',
  },
  tab: {
    height: '35px',
    display: 'flex',
    alignItems: 'left',
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#596FEB',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        height: '-57.4rem',
        backgroundColor: 'white',
        minHeight: '1.6rem',
        minWidth: '1.95rem',
      },
    },
  },
});
