/* eslint no-nested-ternary:0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import * as constants from 'constants/paymentSupplement';
import * as R from 'ramda';
import {
  selectors as paymentSupplementSelectors,
  operations as paymentSupplementOperations,
} from 'ducks/paymentSupplement';
import './PaymentSupplement.css';

const renderCurrencyValues = (type, temp) => {
  if (temp) {
    if (type && type.length !== 0) {
      return `${temp} ${type}`;
    }
    return `$ ${parseFloat(temp).toLocaleString('en-US')}`;
  }
  return '';
};

const renderTableRowComponent = (name, category, categoryValue, temp, type, display, style = '') => (
  <TableRow key={name}>
    <TableCell styleName={`${style}`}>
      <>
        <Typography styleName={name.includes('\n') ? 'name' : ''}>{name}</Typography>
        {category && <Typography styleName="category">{category}</Typography>}
        {categoryValue && (
          <List>
            {categoryValue.map(val => (
              <ListItem key={val} styleName="listItem">
                <ListItemText primary={val} />
              </ListItem>
            ))}
          </List>
        )}
      </>
    </TableCell>
    <TableCell styleName="cellValue">
      {display && display.length !== 0 ? (
        <>
          <Typography styleName="maximumMoPR">{display.category}</Typography>
          {Object.keys(display.categoryValue).map((val) => {
            const key = display.categoryValue;
            return (
              <>
                <Typography>{key[val].name}</Typography>
                <Typography styleName="value">{temp && temp[val] ? `$ ${parseFloat(temp[val]).toLocaleString('en-US')}` : (<br />)}</Typography>
              </>
            );
          })}
        </>
      ) : (temp.dataType && temp.dataType === 'boolean' ? (
        <RadioGroup styleName="radioGroup">
          <FormControlLabel checked={R.isNil(R.prop('booleanValue', temp)) ? false : temp.booleanValue} control={<Radio />} disabled label="Yes" value="true" />
          <FormControlLabel checked={R.isNil(R.prop('booleanValue', temp)) ? false : !temp.booleanValue} control={<Radio />} disabled label="No" value="false" />
        </RadioGroup>
      ) : (
        <Typography styleName="value">
          {renderCurrencyValues(type, temp)}
        </Typography>
      )
      )
      }
    </TableCell>
  </TableRow>
);

const renderTableRow = (row, value) => {
  const {
    id, name: rowName, category: rowCategory, categoryValue: rowCategoryValue, type, display,
    style,
  } = row;
  let key = {};
  const temp = value[id];
  if (typeof (rowName) === 'object') {
    return Object.keys(rowName).map((val) => {
      key = rowName[val];
      return renderTableRowComponent(key.name, key.category, key.categoryValue, temp[val], '', '');
    });
  }
  return renderTableRowComponent(rowName, rowCategory, rowCategoryValue, temp, type, display,
    style);
};

class PaymentSupplement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchPaymentSupplementData } = this.props;
    fetchPaymentSupplementData();
  }

  componentWillUnmount() {
    const { clearPaymentSupplementData } = this.props;
    clearPaymentSupplementData();
  }

  render() {
    const { paymentSupplementData } = this.props;
    const steps = Object.keys(paymentSupplementData);
    const { PAYMENT_SUPPLEMENT_STEPS, PAYMENT_SUPPLEMENT_ROWS } = constants;
    return (
      <Box>
        {steps.map(step => (
          <Box styleName="box">
            <Card key={step} styleName="card">
              <CardContent styleName="cardContent">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell styleName="tableCellHead">{PAYMENT_SUPPLEMENT_STEPS[step]}</TableCell>
                        <TableCell styleName="tableCellHead">Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {PAYMENT_SUPPLEMENT_ROWS.filter(row => row.step === step).map(row => (
                        renderTableRow(row, paymentSupplementData[step])
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    );
  }
}

PaymentSupplement.defaultProps = {
  paymentSupplementData: {},
};

PaymentSupplement.propTypes = {
  clearPaymentSupplementData: PropTypes.func.isRequired,
  fetchPaymentSupplementData: PropTypes.func.isRequired,
  paymentSupplementData: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.shape({

        }),
      ]),
    ),
  ),
};

const mapStateToProps = state => ({
  paymentSupplementData: paymentSupplementSelectors.getPaymentSupplement(state),
});

const mapDispatchToProps = dispatch => ({
  clearPaymentSupplementData: paymentSupplementOperations.clearPaymentSupplementOperation(dispatch),
  fetchPaymentSupplementData: paymentSupplementOperations.fetchPaymentSupplementOperation(dispatch),
});

export {
  PaymentSupplement,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSupplement);
