import moment from 'moment-timezone';

const getCaseAprvStatus = (changeDt, creDt, apprvDt, closdDt) => {
  const chngDtVal = changeDt ? moment(changeDt, 'YYYY-MM-DD') : null;
  const creDtVal = creDt ? moment(creDt, 'YYYY-MM-DD') : null;
  const apprvDtVal = apprvDt ? moment(apprvDt, 'YYYY-MM-DD') : null;
  const closdDtVal = closdDt ? moment(closdDt, 'YYYY-MM-DD') : null;

  if (chngDtVal.isBefore(creDtVal, 'day')) {
    return 'rej';
  }
  if (closdDtVal && chngDtVal.isAfter(closdDtVal, 'day')) {
    return 'rej';
  }
  if (apprvDtVal) {
    if (closdDtVal) {
      if (chngDtVal.isBetween(apprvDtVal, closdDtVal, null, '[]')) {
        return 'approved';
      }
    } else if (chngDtVal.isSameOrAfter(apprvDtVal, 'day')) {
      return 'approved';
    }
  }
  return 'pending';
};

const getChangeEffects = (paymentChanges) => {
  let previousRec = null;
  let isInitialRec = true;

  return paymentChanges.reduce((acc, rec) => {
    const updatedRec = { ...rec };
    if (isInitialRec || (previousRec !== null && previousRec.changeFlag !== rec.changeFlag)) {
      updatedRec.effect = 'green';
      updatedRec.direction = 'up';
      isInitialRec = false;
      previousRec = rec;
      acc.push(updatedRec);
      return acc;
    }

    if (previousRec !== null) {
      if (rec.changeFlag === 'R') {
        if (parseFloat(rec.changeRate) < parseFloat(previousRec.changeRate)) {
          updatedRec.effect = 'green';
          updatedRec.direction = 'down';
        } else {
          updatedRec.effect = 'red';
          updatedRec.direction = 'up';
        }
      } else
      if (parseFloat(rec.changeAmount) < parseFloat(previousRec.changeAmount)) {
        updatedRec.direction = 'down';
        updatedRec.effect = rec.changeFlag === 'S' ? 'red' : 'green';
      } else {
        updatedRec.direction = 'up';
        updatedRec.effect = rec.changeFlag === 'S' ? 'green' : 'red';
      }
    }
    previousRec = rec;
    acc.push(updatedRec);
    return acc;
  }, []);
};

export {
  getCaseAprvStatus,
  getChangeEffects,
};
