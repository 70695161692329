import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Divider, Typography, Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { operations, selectors } from 'ducks/netpresentvalue';
import {
  ERROR, SUCCESS, ERROR_MSG, ERROR_MSG2, ERROR_MSG3, SUCCESS_MSG, SUCCESS_MSG2, UPLOAD_FILE_MESSAGE,
} from 'constants/netPresentValue';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DropZone from '../../IncomeCalc/DocumentViewer/components/DropZone';
import LinearProgressBar from './LinearProgressBar';
import './FileUploadDialog.css';

const FileUploadDialog = (props) => {
  const {
    isOpen, handleClose, onProcessFile, onUploadFile, uploadStatus, onResetUploadStatus,
  } = props;

  const [triggerFileUpload, setTriggerFileUpload] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const [fileName, setFileName] = useState('');
  const statusType = R.isNil(uploadStatus) ? '' : uploadStatus.status;

  const makeUploadDelay = () => {
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          setShowUpload(true);
          clearInterval(timer);
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  };

  const handleUpload = (accFile) => {
    makeUploadDelay();
    if (accFile[0]) {
      onProcessFile(accFile[0]);
    } else {
      setShowUpload(false);
      setProgress(0);
    }
  };


  const onDrop = (accFile) => {
    setTriggerFileUpload(false);
    setFileName(accFile[0].name);
    handleUpload(accFile);
  };


  const handleTriggeredFile = () => {
    setTriggerFileUpload(true);
    setShowUpload(false);
  };

  const getIcon = () => {
    switch (statusType) {
      case ERROR:
        return (
          <div style={{ marginTop: '20px' }}>
            <ErrorOutlineOutlinedIcon
              style={{ color: '#D50000' }}
              styleName="dialog-icon-item"
            />
            <Typography noWrap styleName="dialog-status">Error</Typography>
          </div>
        );
      case SUCCESS:
        return (
          <div style={{ marginTop: '20px' }}>
            <img alt="successIcn" src="/static/img/successicon.svg" styleName="dialog-icon-item" />
            <Typography noWrap styleName="dialog-status">Success</Typography>
          </div>
        );
      default:
        return null;
    }
  };

  const getMessage = () => {
    switch (statusType) {
      case ERROR:
        return (
          <div styleName="dialog-message">
            {R.isNil(uploadStatus.reason) ? (
              <>
                <Typography>{ERROR_MSG}</Typography>
                <Typography>{ERROR_MSG2}</Typography>
              </>
            ) : <Typography>{ERROR_MSG3}</Typography>
            }
          </div>
        );
      case SUCCESS:
        return (
          <div styleName="dialog-message">
            <Typography>{SUCCESS_MSG}</Typography>
            <Typography>{SUCCESS_MSG2}</Typography>
          </div>
        );
      default:
        return null;
    }
  };


  const renderFileUpload = () => {
    switch (statusType) {
      case '':
        return (
          <LinearProgressBar
            fileName={fileName}
            handleTriggeredFile={handleTriggeredFile}
            progress={progress}
            showUpload={showUpload}
          />
        );
      case ERROR:
      case SUCCESS:
        return getMessage();
      default:
        return null;
    }
  };

  const handleAction = () => {
    handleClose();
    setTriggerFileUpload(true);
    setShowUpload(false);
    onResetUploadStatus();
  };

  const handleUploadData = () => {
    setTriggerFileUpload(false);
    setShowUpload(false);
    onUploadFile();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onClose={() => { handleClose(); setTriggerFileUpload(true); }}
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: '20%',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
        },
      }}
    >
      <DialogTitle style={{ padding: '0px', overflowX: 'hidden' }}>
        <Grid container direction="row-reverse">
          <Grid item xs={2}>
            { statusType !== 'success'
              && (
              <IconButton
                onClick={() => { handleAction(); }}
              >
                <CloseIcon />
              </IconButton>
              )
            }
          </Grid>
          <Grid item xs={9}>
            {getIcon()}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent styleName="dialog-SubContainer">
        { /* eslint-disable max-len */
        isOpen && (triggerFileUpload ? <DropZone onDrop={onDrop} text={UPLOAD_FILE_MESSAGE} /> : renderFileUpload())
        }
      </DialogContent>
      <Divider style={{ height: '1px', margin: '0px 3px' }} />
      <DialogActions styleName="dialog-Buttons">
        { R.isNil(uploadStatus)
          ? (
            <Button
              color="primary"
              disabled={!showUpload}
              onClick={() => { handleUploadData(); }}
              variant="contained"
            >
             UPLOAD
            </Button>
          )
          : (
            <Button
              color="primary"
              onClick={() => { handleAction(); }}
              variant={statusType === 'success' ? 'contained' : 'text'}
            >
              {statusType === 'success' ? 'complete' : 'OK'}
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};


FileUploadDialog.defaultProps = {
  handleClose: () => {},
  onProcessFile: () => {},
  onUploadFile: () => {},
  onResetUploadStatus: () => {},
  uploadStatus: {},
};

FileUploadDialog.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onProcessFile: PropTypes.func,
  onResetUploadStatus: PropTypes.func,
  onUploadFile: PropTypes.func,
  uploadStatus: PropTypes.shape({
    reason: PropTypes.string,
    status: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  uploadStatus: selectors.getUploadStatus(state),
});

const mapDispatchToProps = dispatch => ({
  onProcessFile: operations.onProcessFile(dispatch),
  onUploadFile: operations.onUploadFile(dispatch),
  onResetUploadStatus: operations.onResetUploadStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
