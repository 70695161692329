const GET_DASHBOARD_COUNTS_SAGA = 'app/stager/GET_DASHBOARD_COUNTS_SAGA';
const SET_STAGER_DATA_COUNTS = 'app/stager/SET_STAGER_DATA_COUNTS';
const GET_DASHBOARD_DATA_SAGA = 'app/stager/GET_DASHBOARD_DATA_SAGA';
const SET_STAGER_DATA = 'app/stager/SET_STAGER_DATA';
const SET_STAGER_DATA_LOADING = 'app/stager/SET_STAGER_DATA_LOADING';
const TABLE_CHECKBOX_SELECT = 'app/stager/TABLE_CHECKBOX_SELECT';
const TABLE_CHECKBOX_SELECT_TRIGGER = 'app/stager/TABLE_CHECKBOX_SELECT_TRIGGER';
const TRIGGER_ORDER_SAGA = 'app/stager/TRIGGER_ORDER_SAGA';
const TRIGGER_DISPOSITION_OPERATION_SAGA = 'app/stager/TRIGGER_DISPOSITION_OPERATION_SAGA';
const SET_STAGER_ACTIVE_SEARCH_TERM = 'app/stager/SET_STAGER_ACTIVE_SEARCH_TERM';
const SET_DOC_GEN_RESPONSE = 'app/stager/SET_DOC_GEN_RESPONSE';
const SET_DOC_GEN_ACTION = 'app/stager/SET_DOC_GEN_ACTION';
const CLEAR_DOC_GEN_RESPONSE = 'app/stager/CLEAR_DOC_GEN_RESPONSE';
const SET_STAGER_VALUE = 'app/stager/SET_STAGER_VALUE';
const SET_START_END_DATE = 'app/stager/SET_START_END_DATE';
const GET_DOWNLOAD_DATA_SAGA = 'app/stager/GET_DOWNLOAD_DATA_SAGA';
const SET_DOWNLOAD_DATA = 'app/stager/SET_DOWNLOAD_DATA';
const SET_PAGE_COUNT = 'app/stager/SET_PAGE_COUNT';
const SET_STAGER_GROUP = 'app/stager/SET_STAGER_GROUP';
const SEARCH_STAGER_LOAN_NUMBER = 'app/stager/SEARCH_STAGER_LOAN_NUMBER';
const GET_STAGER_LOAN_NUMBER = 'app/stager/GET_STAGER_LOAN_NUMBER';
const SET_STAGER_LOAN_NUMBER = 'app/stager/SET_STAGER_LOAN_NUMBER';
const CLEAR_SEARCH_RESPONE = 'app/stager/CLEAR_SEARCH_RESPONE';
const CLEAR_STAGER_RESPONSE = 'app/stager/CLEAR_STAGER_RESPONSE';
const TRIGGER_STAGER_TILE_SAGA = 'app/stager/TRIGGER_STAGER_TILE_SAGA';
const TOGGLE_AZURE_SEARCH = 'app/stager/TOGGLE_AZURE_SEARCH';
const FETCH_STAGER_PAYLOAD = 'app/stager/FETCH_STAGER_PAYLOAD';
const SET_SEARCH_TOGGLE = 'app/stager/SET_SEARCH_TOGGLE';
const SAVE_DELAY_CHECKLIST_DATA = 'app/stager/SAVE_DELAY_CHECKLIST_DATA';
const STORE_DELAY_CHECKLIST = 'app/stager/STORE_DELAY_CHECKLIST';
const FETCH_DELAY_CHECKLIST_HISTORY = 'app/stager/FETCH_DELAY_CHECKLIST_HISTORY';
const STORE_DELAY_CHECKLIST_HISTORY = 'app/stager/STORE_DELAY_CHECKLIST_HISTORY';
const REFRESH_STAGER_TILE = 'app/stager/REFRESH_STAGER_TILE';
const REFRESH_STAGER_TILE_ON_INPUT = 'app/stager/REFRESH_STAGER_TILE_ON_INPUT';
const SET_ROW_DATA = 'app/stager/SET_ROW_DATA';

export {
  REFRESH_STAGER_TILE,
  REFRESH_STAGER_TILE_ON_INPUT,
  SET_SEARCH_TOGGLE,
  FETCH_STAGER_PAYLOAD,
  TOGGLE_AZURE_SEARCH,
  GET_DASHBOARD_COUNTS_SAGA,
  GET_DOWNLOAD_DATA_SAGA,
  SET_STAGER_DATA_COUNTS,
  GET_DASHBOARD_DATA_SAGA,
  SET_STAGER_DATA,
  SET_STAGER_DATA_LOADING,
  TABLE_CHECKBOX_SELECT,
  TABLE_CHECKBOX_SELECT_TRIGGER,
  TRIGGER_ORDER_SAGA,
  TRIGGER_DISPOSITION_OPERATION_SAGA,
  SET_STAGER_ACTIVE_SEARCH_TERM,
  SET_DOC_GEN_RESPONSE,
  SET_DOC_GEN_ACTION,
  CLEAR_DOC_GEN_RESPONSE,
  CLEAR_SEARCH_RESPONE,
  CLEAR_STAGER_RESPONSE,
  SET_STAGER_VALUE,
  SET_START_END_DATE,
  SET_DOWNLOAD_DATA,
  SET_PAGE_COUNT,
  SET_STAGER_GROUP,
  SEARCH_STAGER_LOAN_NUMBER,
  GET_STAGER_LOAN_NUMBER,
  SET_STAGER_LOAN_NUMBER,
  TRIGGER_STAGER_TILE_SAGA,
  SAVE_DELAY_CHECKLIST_DATA,
  STORE_DELAY_CHECKLIST,
  FETCH_DELAY_CHECKLIST_HISTORY,
  STORE_DELAY_CHECKLIST_HISTORY,
  SET_ROW_DATA,
};
