import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './DatePicker.css';
import Box from '@material-ui/core/Box';
import moment from 'moment-timezone';
import utils from 'ducks/tasks-and-checklist/utils';
import { getStyleName } from 'constants/incomeCalc/styleName';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {},
  warning: {
    borderColor: '#ffa400',
  },
});
const { getCSTDateTime } = utils;
function BasicDatePicker(props) {
  const {
    disabled, title, onChange, value, additionalInfo, failureReason,
  } = props;
  const {
    hasTitle, styleName, disableFuture, formatPattern, fromDate, showIcon, isCST,
  } = additionalInfo;
  let date = null;
  const [datePicker, setDatePicker] = useState(date);
  const DATE_FORMAT = formatPattern || 'MM-DD-YYYY';
  if (!R.isNil(value)) {
    if (isCST) {
      date = moment(value).isValid() ? getCSTDateTime(value, DATE_FORMAT) : null;
    } else {
      date = moment(value).isValid() ? moment(value).format(DATE_FORMAT) : null;
    }
  }
  useEffect(() => {
    setDatePicker(date);
  }, [date]);
  const onChangeDatePickerHandler = (selectedDate) => {
    if (selectedDate && selectedDate.isValid()) {
      if (isCST) {
        setDatePicker(getCSTDateTime(selectedDate, DATE_FORMAT));
      } else {
        setDatePicker(selectedDate.format(DATE_FORMAT));
      }

      const fromDateRef = moment(fromDate).isValid() ? moment.utc(fromDate) : null;

      if (fromDateRef && selectedDate < fromDateRef) {
        if (isCST) {
          setDatePicker(getCSTDateTime(fromDateRef, DATE_FORMAT));
        } else {
          setDatePicker(fromDateRef.format(DATE_FORMAT));
        }
      }
    } else {
      date = R.propOr('', '_i', selectedDate);
      setDatePicker(date);
    }
  };

  const onBlur = () => {
    onChange(datePicker);
  };

  const onAccept = (newDate) => {
    const formattedDate = moment(newDate).format(DATE_FORMAT);
    setDatePicker(formattedDate);
    onChange(formattedDate);
  };

  const isError = !R.isNil(failureReason) && !R.isEmpty(R.filter(item => R.equals(item.level, 1),
    failureReason));
  const isWarning = !R.isNil(failureReason)
  && !R.isEmpty(R.filter(item => R.equals(item.level, 2), failureReason));
  const classes = useStyles();
  const getStyles = () => `${getStyleName('datePicker', styleName, !disabled && getStyleName('datePicker', styleName, 'enablePicker') ? 'enablePicker' : 'picker')}`;
  const inputProps = getStyleName('datePicker', styleName, 'inputProps') || {};
  const buttonProps = getStyleName('datePicker', styleName, 'buttonProps') || {};
  const iconProps = {};
  const displayIcon = R.has('showIcon', additionalInfo) ? showIcon : true;
  if (!displayIcon) {
    iconProps.keyboardIcon = null;
  }
  return (
    <Box styleName={getStyleName('datePicker', styleName, 'div')}>
      {hasTitle && (
        <p component="legend" styleName={getStyleName('datePicker', styleName, 'title')}>
          {title}
        </p>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disabled={disabled}
          disableFuture={disableFuture}
          error={isError}
          format={DATE_FORMAT}
          helperText=""
          InputProps={{
            classes: {
              notchedOutline: isWarning ? classes.warning : classes.root,
            },
            style: {
              ...inputProps,
            },
          }}
          inputVariant="outlined"
          KeyboardButtonProps={{
            'aria-label': 'change date',
            style: { padding: '0.2rem', ...buttonProps },
          }}
          {...iconProps}
          minDate={fromDate}
          onAccept={onAccept}
          onBlur={onBlur}
          onChange={onChangeDatePickerHandler}
          size="small"
          styleName={getStyles()}
          value={datePicker}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

BasicDatePicker.defaultProps = {
  disabled: false,
  title: '',
  additionalInfo: {
    hasTitle: false,
    styleName: '',
    disableFuture: false,
    isCST: false,
  },
  failureReason: [],
};

BasicDatePicker.propTypes = {
  additionalInfo: PropTypes.shape({
    disableFuture: PropTypes.bool,
    formatPattern: PropTypes.string,
    fromDate: PropTypes.string,
    hasTitle: PropTypes.bool,
    isCST: PropTypes.bool,
    showIcon: PropTypes.bool,
    styleName: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  failureReason: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default BasicDatePicker;
