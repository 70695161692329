/* eslint-disable no-useless-escape */
const FORMAT = {
  ssn: value => value
    .replace(/[^0-9]/g, '')
    .replace(/^(.{9})(.*)$/, '$1')
    .replace(/(\d{3})(\d{1,})/, '$1-$2')
    .replace(/(\d{3}-\d{2})(\d{1,})/, '$1-$2'),

  phone: value => value
    .replace(/[^0-9]/g, '')
    .replace(/^(.{10})(.*)$/, '$1')
    .replace(/(\d{3})(\d{1,})/, '($1) $2')
    .replace(/(\(\d{3}\)\s\d{3})(\d{1,})/, '$1 $2'),

  currency: (value, roundOff) => {
    let valueToFormat = value;
    let negative = false;
    if (value === 0 || value === '0') {
      return valueToFormat.toString();
    }
    if (!value) {
      return '';
    }
    if (value < 0) {
      negative = true;
      valueToFormat *= -1;
    }
    if (roundOff) {
      valueToFormat = parseFloat((valueToFormat * 100).toFixed(10));
      valueToFormat = (Math.round(valueToFormat) / 100).toFixed(2);
      if (negative) {
        valueToFormat = (valueToFormat * -1).toFixed(2);
        return valueToFormat.toString().replace(/(?<=.*\..*)\./g, '')
          .replace(/[^0-9.\-]/g, '')
          .replace(/(?<=.+)\-/g, '')
          .replace(/(\-?\d*\.?\d{0,2})(.*)/, '$1')
          .replace(/(\d)(?=(\d{3})+(\.\d*)?$)/g, '$1,');
      }
    }
    return valueToFormat.toString()
      .replace(/^0+/, '')
      .replace(/(?<=.*\..*)\./g, '')
      .replace(/[^0-9.]/g, '')
      .replace(/(\d*\.?\d{0,2})(.*)/, '$1')
      .replace(/(\d)(?=(\d{3})+(\.\d*)?$)/g, '$1,');
  },
  'negative-currency': value => value
    .replace(/(?<=.*\..*)\./g, '')
    .replace(/[^0-9.\-]/g, '')
    .replace(/(?<=.+)\-/g, '')
    .replace(/(\-?\d*\.?\d{0,2})(.*)/, '$1')
    .replace(/(\d)(?=(\d{3})+(\.\d*)?$)/g, '$1,'),

  currencyFloat: (value) => {
    const valueToFormat = value && parseFloat((value.toString()).replace(/,/g, ''));
    if (!Number.isNaN(valueToFormat) && Number.isFinite(valueToFormat)) {
      return valueToFormat;
    }

    return null;
  },
};

const UNFORMAT = {
  ssn: value => value.replace(/[^0-9]/g, ''),
  phone: value => value.replace(/[^0-9]/g, ''),
  currency: value => value.replace(/[^0-9.]/g, ''),
  'negative-currency': value => value.replace(/[^0-9.\-]/g, '').replace(/\-$/, ''),
};

const convertToUSCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

module.exports = {
  convertToUSCurrency,
  FORMAT,
  UNFORMAT,
};
