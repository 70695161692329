import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ApprovalReasonPopup from 'components/Eval/ApprovalReasonPopup';
import { selectors as evalSelectors } from 'ducks/eval';
import { connect } from 'react-redux';
import './EndShift.css';

const EndShift = (props) => {
  const { disabled, onClick, approvalPopUpSource } = props;
  return (
    <>
      <Button
        className="material-ui-button"
        color="primary"
        disabled={disabled}
        onClick={onClick}
        styleName={disabled ? 'end-shift-disabled' : 'end-shift'}
        variant="outlined"
      >
    End Shift
      </Button>
      {approvalPopUpSource === 'END_SHIFT' && <ApprovalReasonPopup />}
    </>
  );
};

EndShift.defaultProps = {
  disabled: false,
  onClick: () => {},
  approvalPopUpSource: '',
};

const mapStateToProps = state => ({
  approvalPopUpSource: evalSelectors.getApprovalPopUpSource(state),
});

EndShift.propTypes = {
  approvalPopUpSource: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const EndShiftContainer = connect(mapStateToProps)(EndShift);

const TestHooks = {
  EndShift,
};

export default EndShiftContainer;

export { TestHooks };
