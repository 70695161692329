const WIDGET_TOGGLE = 'app/widgets/WIDGET_TOGGLE';
const TOGGLE_WIDGET_SAGA = 'app/widgets/TOGGLE_WIDGET_SAGA';
const SET_DISABLED_WIDGETS = 'app/widgets/SET_DISABLED_WIDGETS';
const RESET_WIDGET_DATA = 'app/widgets/RESET_WIDGET_DATA';

const WEST_WING_POPUP = 'app/widgets/WEST_WING_POPUP';
const FETCH_WEST_WING_DATA = 'app/widgets/FETCH_WEST_WING_DATA';
const SET_WEST_WING_DATA = 'app/widgets/SET_WEST_WING_DATA';
const SAVE_WEST_WING_WIDGET = 'app/widgets/SAVE_WEST_WING_WIDGET';

const FETCH_WEST_WING_REPAY_FORB_DATA = 'app/widgets/FETCH_WEST_WING_REPAY_FORB_DATA';
const SET_WEST_WING_REPAY_FORB_DATA = 'app/widgets/SET_WEST_WING_REPAY_FORB_DATA';
const SAVE_WEST_WING_REPAY_FORB_DATA = 'app/widgets/SAVE_WEST_WING_REPAY_FORB_DATA';
const RESET_WEST_WING_REPAY_FORB_DATA = 'app/widgets/RESET_WEST_WING_REPAY_FORB_DATA';

export {
  TOGGLE_WIDGET_SAGA,
  WIDGET_TOGGLE,
  SET_DISABLED_WIDGETS,
  RESET_WIDGET_DATA,
  WEST_WING_POPUP,
  FETCH_WEST_WING_DATA,
  SET_WEST_WING_DATA,
  SAVE_WEST_WING_WIDGET,
  FETCH_WEST_WING_REPAY_FORB_DATA,
  SET_WEST_WING_REPAY_FORB_DATA,
  SAVE_WEST_WING_REPAY_FORB_DATA,
  RESET_WEST_WING_REPAY_FORB_DATA,
};
