/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
// import * as R from 'ramda';
import { connect } from 'react-redux';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { selectors as evalSelector } from 'ducks/eval';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { convertToUSCurrency, FORMAT } from 'lib/Formatters';
import './FundsInHouse.css';
import { NON_CURRENCY_ONLY_REGEX } from 'constants/eval';

const FundsInHouse = ({
  updateMonthlyPaymentDetails, handleCalcButton,
  isEditable, caseHeaderInfo, isCaseRejected,
  caseSpecificFieldValues, monthlyPaymentDetails,
}) => {
  const [isPendingPaymentsDisabled, setDisablePendingPayments] = useState(true);
  const [fees, setFees] = useState(0);
  const [advances, setAdvances] = useState(0);
  const [suspense, setSuspense] = useState(0);
  const [isValueUpdated, setIsValueUpdated] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(0);
  const [pastDuePayment, setpastDuePayment] = useState(0);

  // const {
  //   pastDuePayments,
  //   suspense,
  // } = monthlyPaymentDetails;

  const {
    lockFlag,
  } = caseHeaderInfo;

  useEffect(() => {
    if (isValueUpdated) {
      handleCalcButton();
    }
  }, [isValueUpdated]);

  useEffect(() => {
    if (caseSpecificFieldValues.length > 0) {
      const feesValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalFees');
      const advanceValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalAdvance');
      const suspenseValue = caseSpecificFieldValues.filter(e => e.columnName === 'suspenseBalance');
      const pastDuePaymentvalue = caseSpecificFieldValues.filter(e => e.columnName === 'totalContractual');
      setFees(feesValue && feesValue[0]
        && feesValue[0].columnVal ? feesValue[0].columnVal : 0);
      setAdvances(advanceValue && advanceValue[0]
        && advanceValue[0].columnVal ? advanceValue[0].columnVal : 0);
      setSuspense(suspenseValue && suspenseValue[0]
        && suspenseValue[0].columnVal ? suspenseValue[0].columnVal : 0);
      setpastDuePayment(pastDuePaymentvalue && pastDuePaymentvalue[0]
        && pastDuePaymentvalue[0].columnVal ? pastDuePaymentvalue[0].columnVal : 0);
    }
  }, [monthlyPaymentDetails]);

  useEffect(() => {
    if (caseSpecificFieldValues.length > 0) {
      const pendingPaymentsValue = caseSpecificFieldValues.filter(e => e.columnName === 'pendingPayments');
      setPendingPayment(pendingPaymentsValue && pendingPaymentsValue[0]
        && pendingPaymentsValue[0].columnVal ? pendingPaymentsValue[0].columnVal : 0);
    }
  }, [caseSpecificFieldValues]);

  return (
    <Grid id="fundInHouse" item xs={5}>
      <Paper
        elevation={2}
        square
        styleName="fundInHouse"
      >
        <Grid
          container
          direction="column"
          id="fundInHousePaper"
          item
          justifyContent="space-around"
        >
          <Grid
            container
            direction="row"
            id="fundInHouseValue"
            item
            styleName="fundInHouseGrid"
          >
            <Grid item xs={6}>
              <div styleName="card-header">
                Funds In House
              </div>
            </Grid>
            <Grid item xs={6}>
              <div styleName="fundInHouseValue">
                <b>
                  {
                    convertToUSCurrency
                      .format(parseFloat(fees)
                      + parseFloat(advances) + parseFloat(pastDuePayment) - (suspense))
                  }
                </b>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            id="fihPaymentDetails"
            item
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="suspense-misc">
                  Suspense/MISC
                </div>
                <div>{convertToUSCurrency.format(suspense)}</div>
              </div>
            </Grid>
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="textColor">Pending Payments</div>
                <div>
                  <OutlinedInput
                    disabled={isPendingPaymentsDisabled}
                    endAdornment={(
                      <InputAdornment position="end">
                        { isEditable && (
                        <IconButton
                          disabled={lockFlag || isCaseRejected}
                          edge="end"
                          style={{ padding: '0' }}
                        >
                          <EditIcon
                            onClick={() => {
                              setDisablePendingPayments(false);
                            }}
                            styleName="editIcon"
                          />
                        </IconButton>
                        ) }
                      </InputAdornment>
)}
                    id="outlined-pendingpayments-textBox"
                    onBlur={() => {
                      if (!FORMAT.currencyFloat(pendingPayment)) {
                        setPendingPayment(0);
                      }
                      setDisablePendingPayments(true);
                      setIsValueUpdated(true);
                    }}
                    onChange={(e) => {
                      if (
                        !NON_CURRENCY_ONLY_REGEX.test(
                          e.target.value,
                        )
                      ) {
                        const amt = FORMAT.currencyFloat(e.target.value);
                        if (amt) {
                          updateMonthlyPaymentDetails('pendingPayments', amt);
                          setPendingPayment(amt);
                        } else {
                          updateMonthlyPaymentDetails('pendingPayments', 0);
                          setPendingPayment(e.target.value);
                        }
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    styleName={isPendingPaymentsDisabled ? 'disabled-background' : 'enabled-background'}
                    type="text"
                    value={FORMAT.currency(pendingPayment)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

FundsInHouse.defaultProps = {
  caseSpecificFieldValues: [],
};

FundsInHouse.propTypes = {
  // advances: PropTypes.number.isRequired,
  caseHeaderInfo: PropTypes.shape().isRequired,
  caseSpecificFieldValues: PropTypes.arrayOf(PropTypes.shape()),
  // fees: PropTypes.number.isRequired,
  handleCalcButton: PropTypes.func.isRequired,
  isCaseRejected: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  monthlyPaymentDetails: PropTypes.shape().isRequired,
  updateMonthlyPaymentDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  monthlyPaymentDetails: repaymentEvalSelectors.getMonthlyPaymentDetails(state),
  caseHeaderInfo: evalSelector.caseHeaderInfoData(state),
  isCaseRejected: repaymentEvalSelectors.isCaseRejected(state),
  getUpdatedFees: repaymentEvalSelectors.getFees(state),
  getUpdatedAdvances: repaymentEvalSelectors.getAdvances(state),
  caseSpecificFieldValues: repaymentEvalSelectors.caseSpecificFieldValues(state),
});

export default connect(mapStateToProps, null)(FundsInHouse);
