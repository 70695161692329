import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import EvalTable from '../../../../components/Eval/EvalTable/EvalTable';
import { selectors } from '../../../../state/ducks/eval/index';

const columns = [
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
];

// const mockData = [
//   {
//     code: '2F',
//     amount: 540,
//     description: 'AFLGA-FC Fee',
//   },
// ];

const CorporateAdvances = ({ corporateAdvData }) => (
  <div>
    <EvalTable columns={columns} data={corporateAdvData || []} />
  </div>

);

CorporateAdvances.defaultProps = {
  corporateAdvData: [],
};

CorporateAdvances.propTypes = {
  corporateAdvData: PropTypes.func,
};

const mapStateToProps = state => ({
  corporateAdvData: selectors.corporateAdvances(state),
});

export default connect(mapStateToProps, null)(CorporateAdvances);
