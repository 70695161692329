import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PopupContainer from 'components/PopupContainer';
import MUITable from 'components/MUITable/MUITable';
import './SubmittedRecords.css';


const SubmittedRecords = ({
  successfulRecords, unSuccessfulRecords, unsuccessfulRecordsResp, type,
}) => {
  const columns = [
    {
      name: 'id',
      label: type,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const [data, setData] = useState({
    show: false, title: 'Unsuccessfull Records', children: '',
  });
  let records = [];
  records = unsuccessfulRecordsResp && unsuccessfulRecordsResp.map(record => ({
    id: record[0],
    reason: record[1],
  }));

  const handleClose = () => {
    setData({
      ...data, show: !data.show,
    });
  };

  const copyTable = () => {
    const elTable = document.getElementById('caseTable');

    let range;
    let sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand('copy');
    }

    sel.removeAllRanges();
  };
  return (
    <div styleName="footer-container">
      <div styleName="records-container">
        Successful Records:
        {' '}
        <span styleName="records">{successfulRecords}</span>
      </div>
      <div styleName="records-container">
        Unsuccessful Records :
        {' '}
        <span styleName="records">{unSuccessfulRecords}</span>
        {unSuccessfulRecords > 0 && (
          <IconButton onClick={handleClose}>
            <ArrowDropDownIcon />
          </IconButton>
        )}
      </div>
      <PopupContainer
        copyTable={copyTable}
        handleClose={handleClose}
        show={data.show}
        showCopyButton
        sizeSmall
        title={data.title}
      >
        <div id="caseTable">
          <MUITable columns={columns} data={records} />
        </div>
      </PopupContainer>
    </div>
  );
};
SubmittedRecords.defaultProps = {
  successfulRecords: 0,
  unSuccessfulRecords: 0,
  type: 'ID',
};

SubmittedRecords.propTypes = {
  successfulRecords: PropTypes.number,
  type: PropTypes.string,
  unSuccessfulRecords: PropTypes.number,
  unsuccessfulRecordsResp: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ).isRequired,
};
export default SubmittedRecords;
