import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './ConfirmationBox.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ConfirmationBox = ({
  message,
  show,
  onConfirm,
  level,
  confirmButtonText,
  title,
  onCancel,
}) => {
  const classes = useStyles();
  return (
    <Modal
      onClose={onCancel}
      open={show}
    >
      <div
        className={classes.paper}
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        styleName="confirmationbox"
      >
        <img alt="alert-icon" src={`/static/img/${level}`} style={{ width: '30px' }} />
        <h2 id="simple-modal-title">{title}</h2>
        <p id="simple-modal-description" styleName="message">
          {message}
        </p>
        <div styleName="confirm-button">
          {<Button color="primary" onClick={onConfirm} variant="contained">{confirmButtonText}</Button>}
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

ConfirmationBox.defaultProps = {
  show: false,
  onConfirm: () => { },
  onCancel: () => { },
  confirmButtonText: 'Ok',
  title: 'Popup Title',
};

ConfirmationBox.propTypes = {
  confirmButtonText: PropTypes.string,
  level: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
};

export default ConfirmationBox;
