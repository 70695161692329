import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import { selectors, operations } from 'ducks/income-calculator';
import { selectors as loginSelectors } from 'ducks/login';
import { selectors as selectorDash, operations as dashboardOperations } from 'ducks/dashboard';
import ComponentTypes from 'constants/componentTypes';
import './IncomeCalcWidget.css';
import * as R from 'ramda';
import IncomeChecklist from 'components/IncomeCalc/IncomeChecklist';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import {
  LockCalculation,
} from 'components/ContentHeader';
import DashboardModel from 'models/Dashboard';
import ErrorBanner from 'components/ErrorBanner';
import Navigation from '../Dashboard/TasksAndChecklist/Navigation';
import Popup from '../../components/Popup';
import {
  FINANCIAL_CALCULATOR,
} from '../../constants/widgets';

class IncomeCalcWidget extends React.Component {
  constructor(props) {
    super(props);
    this.checkErrors = this.checkErrors.bind(this);
    this.onClickLockCalc = this.onClickLockCalc.bind(this);
    this.renderControls = this.renderControls.bind(this);
  }

  componentDidMount() {
    const {
      incomeCalcChecklist, openWidgetList, processInstance, type,
    } = this.props;
    if (!R.contains(FINANCIAL_CALCULATOR, openWidgetList)) {
      incomeCalcChecklist({ processInstance, type });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      incomeCalcChecklist, openWidgetList, processInstance, type,
    } = this.props;
    if (!R.contains(FINANCIAL_CALCULATOR, openWidgetList)
    && type !== prevProps.type) {
      incomeCalcChecklist({ processInstance, type });
    }
  }

  onClickLockCalc = () => {
    const {
      lockCalculationFromWidget,
    } = this.props;
    lockCalculationFromWidget();
  }

  checkErrors() {
    const {
      onErrorValidation, groupName,
    } = this.props;
    onErrorValidation(groupName);
  }

  renderLockSuccessAlert() {
    const { clearPopupData, popupData } = this.props;
    if (popupData) {
      const {
        isOpen, message, title, level,
        confirmButtonText, onConfirm,
      } = popupData;
      const confirmAction = clearPopupData;
      return (
        <Popup
          confirmButtonText={confirmButtonText}
          level={level}
          message={message}
          onConfirm={() => confirmAction(onConfirm)}
          show={isOpen}
          showConfirmButton
          title={title}
        />
      );
    }
    return null;
  }

  renderControls() {
    const {
      showBanner, errorBanner, enableLockButton, historyView, userGroupList,
      getCalcType, incomeCalcLockId, expenseCalcLockId,
      isFreshExpenseChecklist, isFreshIncomeChecklist,
    } = this.props;
    const financialLockId = getCalcType === 'incomeCalcData' ? incomeCalcLockId : expenseCalcLockId;
    const isNewFinancialChecklist = getCalcType === 'incomeCalcData' ? isFreshIncomeChecklist : isFreshExpenseChecklist;
    const isLossMittAgent = !R.isEmpty(R.intersection(userGroupList, ['lossmitigation', 'lossmitigation-mgr']));
    const showCheckButton = (!historyView && isLossMittAgent) ? (
      <Button
        className="material-ui-button"
        color="primary"
        disabled={(financialLockId && financialLockId !== 0) || historyView
          || (financialLockId === 0 && isNewFinancialChecklist)}
        onClick={() => this.checkErrors()}
        style={{ marginRight: '0.5rem' }}
        styleName="check-btn"
        variant="contained"
      >
        CHECK
      </Button>
    ) : null;

    const showLock = (!historyView && isLossMittAgent) ? (
      <LockCalculation
        disabled={!enableLockButton || (financialLockId && financialLockId !== 0) || historyView
          || (financialLockId === 0 && isNewFinancialChecklist)}
        onClick={() => this.onClickLockCalc()}
      />
    ) : null;
    return (
      <Grid alignItems="center" container justifyContent="center" style={{ padding: '0.5rem' }}>
        {this.renderLockSuccessAlert()}
        <Grid item xs={10}>{showBanner && <ErrorBanner errorBanner={errorBanner} />}</Grid>
        <Grid item>{showCheckButton}</Grid>
        <Grid item>{showLock}</Grid>
      </Grid>
    );
  }

  render() {
    const {
      inProgress, checklistItems, onChecklistChange, historyView,
      disabledChecklist, openWidgetList, type, groupName,
      getCalcType, incomeCalcLockId, expenseCalcLockId,
      isFreshExpenseChecklist, isFreshIncomeChecklist,
    } = this.props;
    const financialLockId = getCalcType === 'incomeCalcData' ? incomeCalcLockId : expenseCalcLockId;
    const isNewFinancialChecklist = getCalcType === 'incomeCalcData' ? isFreshIncomeChecklist : isFreshExpenseChecklist;
    const isLossMittAgentCalcWidget = R.contains(FINANCIAL_CALCULATOR, openWidgetList)
    && groupName === DashboardModel.SEARCH_LOAN;
    if (inProgress) {
      return (
        <div styleName="income-loader">
          <Loader message="Please Wait" />
        </div>
      );
    }
    if (isLossMittAgentCalcWidget) {
      return (
        <div styleName="income-checklist">
          {this.renderControls()}
          <div>
            <IncomeChecklist
              checklistItems={checklistItems}
              checklistType={type}
              disabled={historyView || (financialLockId && financialLockId !== 0)
                || (financialLockId === 0 && isNewFinancialChecklist)}
              isLossMittAgent
              onChange={onChecklistChange}
              onDelete={this.onDeleteItem}
              styleName="widget"
            />
            <Navigation />
          </div>
        </div>
      );
    }
    if (R.contains(FINANCIAL_CALCULATOR, openWidgetList)) {
      return (
        <div styleName="income-checklist">
          <IncomeChecklist
            checklistItems={checklistItems}
            checklistType={type}
            disabled={disabledChecklist || historyView}
            onChange={onChecklistChange}
            onDelete={this.onDeleteItem}
            styleName="widget"
          />
        </div>
      );
    }

    return (
      <IncomeChecklist
        checklistItems={checklistItems}
        checklistType={type}
        disabled={disabledChecklist || historyView}
        onChange={onChecklistChange}
        onDelete={this.onDeleteItem}
        styleName="checklist"
      />
    );
  }
}


IncomeCalcWidget.defaultProps = {
  inProgress: false,
  checklistItems: [],
  historyView: false,
  disabledChecklist: false,
  openWidgetList: [],
  type: 'income-calculator',
  lockCalculationFromWidget: () => {},
  showBanner: false,
  enableLockButton: false,
  popupData: {},
};

IncomeCalcWidget.propTypes = {
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      showPushData: PropTypes.bool,
      taskCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(ComponentTypes)).isRequired,
    }),
  ),
  clearPopupData: PropTypes.func.isRequired,
  disabledChecklist: PropTypes.bool,
  enableLockButton: PropTypes.bool,
  errorBanner: PropTypes.shape({
    errors: PropTypes.array,
    warnings: PropTypes.array,
  }).isRequired,
  expenseCalcLockId: PropTypes.number.isRequired,
  getCalcType: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  historyView: PropTypes.bool,
  incomeCalcChecklist: PropTypes.func.isRequired,
  incomeCalcLockId: PropTypes.number.isRequired,
  inProgress: PropTypes.bool,
  isFreshExpenseChecklist: PropTypes.bool.isRequired,
  isFreshIncomeChecklist: PropTypes.bool.isRequired,
  lockCalculationFromWidget: PropTypes.func,
  onChecklistChange: PropTypes.func.isRequired,
  onErrorValidation: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  popupData: PropTypes.shape({
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
  }),
  processInstance: PropTypes.string.isRequired,
  showBanner: PropTypes.bool,
  type: PropTypes.string,
  userGroupList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TestHooks = {
  IncomeCalcWidget,
};

const mapStateToProps = state => ({
  borrowersInfo: selectors.getBorrowersInfo(state),
  inProgress: selectors.inProgress(state),
  loanNumber: selectorDash.loanNumber(state),
  getIncomeType: selectors.getIncomeType(state),
  groupName: selectorDash.groupName(state),
  getSelectedBorrower: selectors.getSelectedBorrower(state),
  checklistItems: selectors.getChecklistItems(state),
  historyView: selectors.getHistoryView(state),
  disabledChecklist: selectors.disabledChecklist(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  userGroupList: loginSelectors.getGroupList(state),
  showBanner: selectorDash.showBanner(state),
  errorBanner: selectors.getErrorBanner(state),
  enableLockButton: selectorDash.enableLockButton(state),
  incomeCalcLockId: selectorDash.getIncomeLockIdForWidget(state),
  expenseCalcLockId: selectorDash.getExpenseLockIdForWidget(state),
  getCalcType: selectors.getCalcType(state),
  isFreshIncomeChecklist: selectorDash.isFreshIncomeChecklist(state),
  isFreshExpenseChecklist: selectorDash.isFreshExpenseChecklist(state),
  popupData: selectorDash.getPopupData(state),
});

const mapDispatchToProps = dispatch => ({
  onErrorValidation: dashboardOperations.onErrorValidation(dispatch),
  incomeCalcChecklist: operations.incomeCalcChecklist(dispatch),
  onChecklistChange: operations.handleChecklistItemValueChange(dispatch),
  lockCalculationFromWidget: operations.lockCalculationFromWidget(dispatch),
  clearPopupData: dashboardOperations.clearPopupData(dispatch),
});

const container = connect(mapStateToProps, mapDispatchToProps)(IncomeCalcWidget);
export default container;
export {
  TestHooks,
};
