import * as R from 'ramda';

const generalInformation = state => R.pathOr([], ['evalCase', 'generalInformation'], state);
const caseInformation = state => R.pathOr([], ['evalCase', 'caseInformation'], state);
const corporateAdvances = state => R.pathOr([], ['evalCase', 'corporateAdvances'], state);
const stepRates = state => R.pathOr([], ['evalCase', 'stepRates'], state);
const ratios = state => R.pathOr({}, ['evalCase', 'ratios'], state);
const eligibility = state => R.pathOr([], ['evalCase', 'eligibility'], state);
const calcLogs = state => R.pathOr([], ['evalCase', 'calcLogs'], state);
const amortizationDetails = state => R.pathOr([], ['evalCase', 'amortizationDetails'], state);
const finalHardShipId = state => R.pathOr(0, ['evalCase', 'finalHardShipId'], state);
const evalRejectReasons = state => R.pathOr([], ['evalCase', 'evalRejectReasons'], state);
const popUpStatus = state => R.pathOr(false, ['evalCase', 'status'], state);
const trackPayments = state => R.pathOr({}, ['evalCase', 'trackPayments'], state);
const inProgress = state => R.pathOr(false, ['evalCase', 'inProgress'], state);
const fulfillmentChecklist = state => R.pathOr([], ['evalCase', 'fulfillmentCheckList'], state);
const certainMorgageLoanChecklist = state => R.pathOr([], ['evalCase', 'certainMorgageLoanChecklist'], state);
const saveCMLCStatus = state => R.pathOr({}, ['evalCase', 'saveCMLCStatus'], state);
const saveFulfillmentList = state => R.pathOr({}, ['evalCase', 'taskStatusUpdate'], state);
const bookingData = state => R.pathOr({}, ['evalCase', 'bookingData'], state);
const isCalcLogsLoading = state => R.pathOr(false, ['evalCase', 'isCalcLogsLoading'], state);
const forbearancePlanSchedule = state => R.pathOr([], ['evalCase', 'universalActionResp', 'forbearancePlanSchedule'], state);
const planStartDates = state => R.pathOr({}, ['evalCase', 'universalActionResp', 'planStartDates'], state);
const isInterestRateChanged = state => R.pathOr(null, ['evalCase', 'isInterestRateChanged'], state);
const approvalHistory = state => R.pathOr({}, ['evalCase', 'universalActionResp', 'approvalHistory'], state);
const universalActionResp = state => R.pathOr({}, ['evalCase', 'universalActionResp'], state);
const isCalculateLoading = state => R.pathOr(false, ['evalCase', 'isCalculateLoading'], state);
const caseHeaderInfoData = state => R.pathOr({}, ['evalCase', 'caseHeaderInfo'], state);
const deferralType = state => R.pathOr([], ['evalCase', 'universalActionResp', 'deferralType'], state);
const workoutTypes = state => R.pathOr([], ['evalCase', 'universalActionResp', 'workoutTypes'], state);
const capitalizeCurrentForbearance = state => R.pathOr([], ['evalCase', 'universalActionResp', 'uiLookupValuesKvp', 'capitalizeCurrentForbearance'], state);
const capitalizeFutureEscrow = state => R.pathOr([], ['evalCase', 'universalActionResp', 'uiLookupValuesKvp', 'capitalizeFutureEscrow'], state);
const trialDuration = state => R.pathOr([], ['evalCase', 'universalActionResp', 'uiLookupValuesKvp', 'trialDuration'], state);
const escrowSpreadMonths = state => R.pathOr([], ['evalCase', 'universalActionResp', 'uiLookupValuesKvp', 'escrowSpreadMonths'], state);
const invGrpCodes = state => R.pathOr(null, ['evalCase', 'invGrpCodes'], state);
const evalDetails = state => R.pathOr({}, ['evalCase', 'evalDetails'], state);
const preApprovedFlg = state => R.pathOr([], ['evalCase', 'evalDetails', 'preAprvFlag'], state);
const getCaseTypes = state => R.pathOr([], ['evalCase', 'caseTypes'], state);
const getCaseData = state => R.pathOr([], ['evalCase', 'caseTypesData'], state);
const getWorkoutTypes = state => R.pathOr([], ['evalCase', 'workoutTypes'], state);
const getCreateButtonStatus = state => R.pathOr(true, ['evalCase', 'createButtonStatus'], state);
const getEvalScreenData = state => R.pathOr({}, ['evalCase', 'evalScreenInfo'], state);
const getSelectedCaseId = state => R.pathOr(null, ['evalCase', 'selectedCaseId'], state);
const lockValicationStatus = state => R.pathOr(false, ['evalCase', 'lockValication'], state);
const getIsEvalApproved = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalCase', 'approved'], state);
const getIsEvalRejected = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalCase', 'rejected'], state);
const getSelectedCaseDetail = state => R.pathOr(null, ['evalCase', 'selectedCaseDetail'], state);
const getEvalScreenIcon = state => R.pathOr(null, ['evalCase', 'evalScreenIcon'], state);
const planDurations = state => R.pathOr({}, ['evalCase', 'universalActionResp', 'planDurations'], state);
const getEvalType = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalHeader', 'evalType'], state);
const getNewCaseDetails = state => R.pathOr(null, ['evalCase', 'newCaseDetails'], state);
const getApprovalPopUpData = state => R.pathOr(null, ['evalCase', 'approvalPopUpData'], state);
const getApprovalPopUpSource = state => R.pathOr(null, ['evalCase', 'approvalPopUpData', 'source'], state);
const getIsLetterGeneratedForApproval = state => R.pathOr(null, ['evalCase', 'isLetterGeneratedForApproval'], state);
const islockOverride = state => R.pathOr(false, ['evalCase', 'lockOverride'], state);
const getPreLockCaseValidation = state => R.pathOr(null, ['evalCase', 'preLockCaseValidation'], state);
const bookingTabData = state => R.pathOr({}, ['evalCase', 'bookingTabData'], state);
const bookingTabCorpAdvnData = state => R.pathOr([], ['evalCase', 'bookingTabCorpAdvnData'], state);
const repayPlanSchedules = state => R.pathOr({}, ['evalCase', 'universalActionResp', 'repayPlanSchedules'], state);
const updateTrackPaymentsResponse = state => R.pathOr(null, ['evalCase', 'updateTrackPaymentResponse'], state);
const fetchLetterGeneratedStatus = state => R.pathOr([], ['evalCase', 'letterGenerated'], state);
const getDisasterId = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalHeader', 'disasterId'], state);
const getDisasterFlag = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalHeader', 'disasterFlag'], state);
const states = state => R.pathOr([], ['evalCase', 'states'], state);
const countys = state => R.pathOr([], ['evalCase', 'countys'], state);
const disasterDtls = state => R.pathOr([], ['evalCase', 'disasterDtls'], state);
const isDisasterPopupOpen = state => R.pathOr(false, ['evalCase', 'disasterPopupOpen'], state);
const caseNpvData = state => R.pathOr(false, ['evalCase', 'npvData'], state);
const isDisasterPopupLoading = state => R.pathOr(false, ['evalCase', 'isDisasterPopupLoading'], state);
const financialDropdowns = state => R.pathOr(false, ['evalCase', 'dropdowns'], state);
const isCreateEvalLoading = state => R.pathOr(false, ['evalCase', 'isCreateEvalLoading'], state);
const isworkOutTypeLoading = state => R.pathOr(false, ['evalCase', 'isworkOutTypeLoading'], state);
const isEvalHeaderLoading = state => R.pathOr(false, ['evalCase', 'evalHeaderInProgress'], state);
const getRuleEngineErrorMsg = state => R.pathOr(null, ['evalCase', 'ruleEngineErrMsg'], state);
const getMiscErrorMsg = state => R.pathOr(null, ['evalCase', 'miscErrorMsg'], state);
const loadModCalcCall = state => R.pathOr(false, ['evalCase', 'makeModCalcCall'], state);
const getFinancialData = state => R.pathOr({}, ['evalCase', 'evalScreenInfo', 'financialDetails'], state);
const financialDetailLoader = state => R.pathOr(false, ['evalCase', 'financialDetailLoading'], state);
const isCaseTypeLoading = state => R.pathOr(false, ['evalCase', 'isCaseTypeLoading'], state);
const isFinancialDropdownLoading = state => R.pathOr(false, ['evalCase', 'isFinancialDropdownLoading'], state);
const lockExtValidationMsg = state => R.pathOr(false, ['evalCase', 'lockExtValidationMsg'], state);
const userOverrideRuleList = state => R.pathOr(false, ['evalCase', 'userOverrideRuleList'], state);
const showSmduPopup = state => R.pathOr(false, ['evalCase', 'isSmduPopupOpen'], state);
const caseRejReasons = state => R.pathOr([], ['evalCase', 'caseRejectReasons'], state);
const caseRejSubReasons = state => R.pathOr([], ['evalCase', 'caseRejectSubReasons'], state);
const getEvalStatus = state => R.pathOr(null, ['evalCase', 'evalScreenInfo', 'evalCase', 'status'], state);
const isRejectSubRsnsLoading = state => R.pathOr(false, ['evalCase', 'isRejectSubRsnsLoading'], state);
const isRejectCaseLoading = state => R.pathOr(false, ['evalCase', 'isRejectCaseLoading'], state);
const getInvWaterFallInfo = state => R.pathOr(false, ['evalCase', 'invWaterFallInfo'], state);

const selectors = {
  generalInformation,
  caseInformation,
  corporateAdvances,
  stepRates,
  ratios,
  eligibility,
  calcLogs,
  amortizationDetails,
  finalHardShipId,
  evalRejectReasons,
  popUpStatus,
  trackPayments,
  fulfillmentChecklist,
  inProgress,
  saveFulfillmentList,
  bookingData,
  isCalcLogsLoading,
  forbearancePlanSchedule,
  planStartDates,
  isInterestRateChanged,
  approvalHistory,
  universalActionResp,
  isCalculateLoading,
  caseHeaderInfoData,
  certainMorgageLoanChecklist,
  saveCMLCStatus,
  deferralType,
  workoutTypes,
  capitalizeCurrentForbearance,
  capitalizeFutureEscrow,
  trialDuration,
  escrowSpreadMonths,
  invGrpCodes,
  evalDetails,
  preApprovedFlg,
  getCaseTypes,
  getIsLetterGeneratedForApproval,
  getCaseData,
  getWorkoutTypes,
  getCreateButtonStatus,
  getEvalScreenData,
  getSelectedCaseId,
  lockValicationStatus,
  getIsEvalApproved,
  getIsEvalRejected,
  getSelectedCaseDetail,
  getEvalScreenIcon,
  planDurations,
  islockOverride,
  getPreLockCaseValidation,
  getEvalType,
  getNewCaseDetails,
  bookingTabData,
  bookingTabCorpAdvnData,
  repayPlanSchedules,
  updateTrackPaymentsResponse,
  fetchLetterGeneratedStatus,
  getDisasterId,
  getDisasterFlag,
  states,
  countys,
  isDisasterPopupOpen,
  disasterDtls,
  caseNpvData,
  isDisasterPopupLoading,
  financialDropdowns,
  isCreateEvalLoading,
  isworkOutTypeLoading,
  getApprovalPopUpData,
  isEvalHeaderLoading,
  getRuleEngineErrorMsg,
  getMiscErrorMsg,
  loadModCalcCall,
  getFinancialData,
  financialDetailLoader,
  isCaseTypeLoading,
  isFinancialDropdownLoading,
  lockExtValidationMsg,
  userOverrideRuleList,
  getApprovalPopUpSource,
  showSmduPopup,
  caseRejReasons,
  caseRejSubReasons,
  isRejectSubRsnsLoading,
  getEvalStatus,
  isRejectCaseLoading,
  getInvWaterFallInfo,
};

export default selectors;
