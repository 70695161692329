/* eslint-disable import/prefer-default-export */
import {
  takeEvery, all, put,
  call, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_RESULT_OPERATION,
} from 'ducks/dashboard/types';
// import {
//   ERROR,
// } from 'constants/common';
import DashboardModel from '../../../models/Dashboard';
import {
  FETCH_HISTORY_DATA,
  SET_HISTORY_DATA,
  BOARDING_TEMPLATE_SPEC,
  BOARDING_TEMPLATE_SUBMIT,
  ONBOARD_TEMPLATE_DATA,
  STORE_BOARDING_DATA,
  SET_EXCEPTION_DATA,
  PROCESS_BOARDING_TEMPLATE,
  INITIATE_IMPORT_PROCESS,
  SAVE_PROCESSED_FILE,
  SET_CASE_TYPE_OPTIONS,
  CASE_TYPES_OPTION,
  SET_BOARDING_PHASES,
  BOARDING_PHASES,
} from './types';
import processExcel from '../../../lib/excelParser';
import { selectors as loginSelectors } from '../login';
import selectors from './selectors';

const {
  Messages:
  {
    LEVEL_FAILED,
    MSG_FILE_UPLOAD_FAILURE,
  },
} = DashboardModel;


function* gatherHistoryData(action) {
  const {
    pageNumber, pageSize, filter, order,
  } = action.payload;
  try {
    yield put({ type: SHOW_LOADER });
    const requestBody = {};
    if (order && !R.isEmpty(order)) {
      requestBody.boardingTemplateSort = order;
    }
    if (filter && !R.isEmpty(filter)) {
      requestBody.boardingTemplateFilter = filter;
    }
    yield put({
      type: BOARDING_TEMPLATE_SPEC,
      payload: requestBody,
    });
    const response = yield call(Api.callPost, `/api/boardingservice/boarding/getBoardingHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`, requestBody);
    if (response) {
      yield put({
        type: SET_HISTORY_DATA,
        payload: response,
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Failed to fetch Data!',
          level: LEVEL_FAILED,
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Something went wrong while fetching Data!',
        level: LEVEL_FAILED,
      },
    });
  }
}


function* processFile(action) {
  const { onboardTemplate, file } = action.payload;
  const filePayload = {};
  try {
    if (file) {
      const data = yield call(processExcel, file);
      if (data) {
        let errorMessage;
        const jsonData = JSON.parse(data);
        filePayload.uploadRequest = jsonData;
        filePayload.fileName = file.name;
        filePayload.userEmail = yield select(loginSelectors.getUserPrincipalName);
        const loanIds = R.pluck('Loan ID', jsonData);
        const loanSet = new Set(loanIds);
        const keysToPick = ['Case Type', 'Phase', 'Pre Approved', 'InFlight'];
        const keyValuesToCompare = R.map(R.pick(keysToPick), jsonData);
        const areMandateKeyValuesSame = R.all(
          R.equals(R.head(keyValuesToCompare)), keyValuesToCompare,
        );
        const templateKeys = [...new Set(onboardTemplate.flatMap(Object.keys))];
        const uploadedFileKeys = [...new Set(jsonData.flatMap(Object.keys))];
        if (jsonData.length > 500) {
          errorMessage = 'The uploaded file exceeds the 500-loan limit. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (jsonData.length !== loanSet.size) {
          errorMessage = 'This file cannot be uploaded because duplicate loan numbers have been provided. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (!areMandateKeyValuesSame) {
          errorMessage = 'This file cannot be uploaded because the Case Type, Phase, Pre Approved, and Inflight columns must be the same. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (templateKeys.length !== uploadedFileKeys.length) {
          errorMessage = 'This file cannot be uploaded, because there is an unrecognized column provided on template. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        yield put({
          type: SAVE_PROCESSED_FILE,
          payload: filePayload,
        });
      }

      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Excel file uploaded successfully',
          level: 'Success',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: MSG_FILE_UPLOAD_FAILURE,
        level: 'Failed',
      },
    });
  }
}


function* validateBoardingTemplate() {
  try {
    const processedFileData = yield select(selectors.getProcesseFiledData);
    const response = yield call(Api.callPost, '/api/boardingservice/boarding/boardFile', processedFileData);
    yield put({
      type: SET_EXCEPTION_DATA,
      payload: response || [],
    });
  } catch (e) {
    console.error('Something went wrong while validating boarding template data ', e);
    yield put({
      type: SET_EXCEPTION_DATA,
      payload: [],
    });
  }
}

function* onBoardTemplateData(action) {
  try {
    const response = yield call(Api.callPost, '/api/boardingservice/boarding/downloadBoardingExcel', action.payload);
    yield put({
      type: STORE_BOARDING_DATA,
      payload: response || [],
    });
  } catch (e) {
    console.error('Something went wrong while on boarding template data ', e);
    yield put({
      type: STORE_BOARDING_DATA,
      payload: [],
    });
  }
}

function* initiateImportProcess() {
  const exceptionData = yield select(selectors.getExceptionData);
  const validLoanIds = exceptionData.filter(
    record => !record['Critical Severity Exceptions'] || record['Critical Severity Exceptions'].length === 0,
  ).map(record => record['Loan Id']);
  const processedFileData = yield select(selectors.getProcesseFiledData);
  const recordsWithoutCriticalException = processedFileData.uploadRequest.filter(record => validLoanIds.includes(record['Loan ID']));
  const payload = {
    fileName: processedFileData.fileName,
    userEmail: processedFileData.userEmail,
    uploadRequest: recordsWithoutCriticalException,
  };
  const response = yield call(Api.callPost, '/api/boardingservice/boarding/importBoardingTemplate', payload);
  if (response.status === 200) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'success',
      },
    });
  }
}

function* setCaseTypeOptions() {
  try {
    const response = yield call(Api.callGet, '/api/boardingservice/boarding/getCaseTypes');
    yield put({
      type: CASE_TYPES_OPTION,
      payload: response,
    });
    if (!response) {
      yield put({
        type: CASE_TYPES_OPTION,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: CASE_TYPES_OPTION,
      payload: [],
    });
  }
}

function* setBoardingPhases(action) {
  try {
    const caseType = action.payload;
    const response = yield call(Api.callGet, `/api/boardingservice/boarding/getBoardingPhases/${caseType}`);
    yield put({
      type: BOARDING_PHASES,
      payload: response,
    });
    if (!response) {
      yield put({
        type: BOARDING_PHASES,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: BOARDING_PHASES,
      payload: [],
    });
  }
}

function* watchFetchHistoryDetails() {
  yield takeEvery(FETCH_HISTORY_DATA, gatherHistoryData);
}

function* watchValidateBoardingTemplate() {
  yield takeEvery(BOARDING_TEMPLATE_SUBMIT, validateBoardingTemplate);
}

function* watchOnBoardTemplateData() {
  yield takeEvery(ONBOARD_TEMPLATE_DATA, onBoardTemplateData);
}

function* watchProcessFile() {
  yield takeEvery(PROCESS_BOARDING_TEMPLATE, processFile);
}

function* watchInitiateImportProcess() {
  yield takeEvery(INITIATE_IMPORT_PROCESS, initiateImportProcess);
}

function* watchSetCaseTypeOptions() {
  yield takeEvery(SET_CASE_TYPE_OPTIONS, setCaseTypeOptions);
}

function* watchSetBoardingPhases() {
  yield takeEvery(SET_BOARDING_PHASES, setBoardingPhases);
}
export const combinedSaga = function* combinedSaga() {
  yield all([
    watchFetchHistoryDetails(),
    watchValidateBoardingTemplate(),
    watchOnBoardTemplateData(),
    watchProcessFile(),
    watchInitiateImportProcess(),
    watchSetCaseTypeOptions(),
    watchSetBoardingPhases(),
  ]);
};
