import {
  FETCH_PAYMENT_SUPPLEMENT_DATA,
  SET_PAYMENT_SUPPLEMENT_DATA,
  CLEAR_PAYMENT_SUPPLEMENT_DATA,
} from './types';

const initialState = {
  paymentSupplementData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_SUPPLEMENT_DATA: {
      return {
        ...state,
      };
    }

    case SET_PAYMENT_SUPPLEMENT_DATA: {
      return {
        ...state,
        paymentSupplementData: action.payload,
      };
    }

    case CLEAR_PAYMENT_SUPPLEMENT_DATA: {
      return {
        ...state,
        paymentSupplementData: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
