import React from 'react';
import PropTypes from 'prop-types';
import MilestoneTracker from 'components/MilestoneTracker';
import AccountServices from 'containers/AccountServices';
import Tombstone from 'containers/Dashboard/Tombstone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNotification from 'components/UserNotification/UserNotification';
import FullHeightColumn from 'components/FullHeightColumn';
import { selectors } from 'ducks/dashboard';
import './OrderEscrow.css';

function OrderEscrow(props) {
  const {
    userNotification, milestoneDetails, currentSelection,
  } = props;
  return (
    <>
      <div styleName="title">
            BACKEND STAGER
      </div>
      <div styleName="milestone-tracker">
        <MilestoneTracker currentSelection={currentSelection} trackerItems={milestoneDetails} />
      </div>
      <div styleName="title-row">
        {(userNotification && userNotification.status)
          ? <UserNotification level={userNotification.level} message={userNotification.status} type="alert-box" />
          : ''
          }
      </div>

      <div styleName="displayInRow">
        <Tombstone />
        <div styleName="main-panel">
          <FullHeightColumn styleName="columns-container">
            <AccountServices />
          </FullHeightColumn>
        </div>
      </div>
    </>
  );
}

OrderEscrow.defaultProps = {
  userNotification: { level: '', status: '' },
  milestoneDetails: [],
};

OrderEscrow.propTypes = {
  currentSelection: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  milestoneDetails: PropTypes.arrayOf(PropTypes.shape({
    mlstnNm: PropTypes.string,
    taskId: PropTypes.string,
    visited: PropTypes.string,
  })),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    skills: PropTypes.arrayOf(PropTypes.string).isRequired,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
  userNotification: PropTypes.shape({
    level: PropTypes.string,
    status: PropTypes.string,
  }),
};
const mapStateToProps = state => ({
  currentSelection: selectors.getCurrentMilestoneIndex(state),
  milestoneDetails: selectors.getMilestoneDetails(state),
  userNotification: selectors.userNotification(state),
});


const container = connect(mapStateToProps, null)(OrderEscrow);
const TestHooks = {
  OrderEscrow,
};
export default withRouter(container);

export { TestHooks };
