import React, { useState } from 'react';
import {
  Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import './UnRejectRequest.css';
import PropTypes from 'prop-types';
import UnRejectRequestCase from './UnRejectRequestCase/UnRejectRequestCase';
import UnRejectRequestEval from './UnRejectRequestEval/UnRejectRequestEval';


const UnRejectRequest = ({ changeRadioSelect, unRejectRequest, recordsInfo }) => {
  const [status, setStatus] = useState('case');

  const handleChange = (event) => {
    const { value } = event.target;
    setStatus(value);
    changeRadioSelect(value);
  };

  return (
    <div>
      <RadioGroup
        name="unReject"
        onChange={handleChange}
        row
        styleName="radio-group-container"
        value={status}
      >
        <FormControlLabel
          control={<Radio size="small" />}
          label="Case UnReject"
          value="case"
        />
        <FormControlLabel
          control={<Radio size="small" />}
          label="Eval UnReject"
          value="eval"
        />
      </RadioGroup>
      {status === 'case' ? <UnRejectRequestCase recordsInfo={recordsInfo} unRejectRequest={unRejectRequest} /> : <UnRejectRequestEval recordsInfo={recordsInfo} unRejectRequest={unRejectRequest} />}
    </div>
  );
};

UnRejectRequest.propTypes = {
  changeRadioSelect: PropTypes.func.isRequired,
  recordsInfo: PropTypes.shape().isRequired,
  unRejectRequest: PropTypes.func.isRequired,
};

export default UnRejectRequest;
