import React, { useState, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Popper,
  Paper,
  ClickAwayListener,
  makeStyles,
} from '@material-ui/core';
import '../RightPane/RightPane.css';
import { PropTypes } from 'prop-types';
import * as R from 'ramda';


const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  menuItem: {
    cursor: 'pointer',
    width: '19.5rem',
    borderRadius: '5px',
    background: '#fafafa',
  },
  textField: {
    padding: '.7rem',
  },
}));

const FilterDropdown = ({
  handleApplyButton, selectedFile, selectedUser, handleSetFile, hanldeUserSet,
  historyData,
}) => {
  const classes = useStyles();
  const [inputFile, setInputfile] = useState(selectedFile);
  const [inputUser, setInputUser] = useState(selectedUser);
  const [fileAnchorEl, setFileAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const getUsers = () => {
    if (R.isNil(historyData) || R.isEmpty(historyData)
         || (historyData.records && !historyData.records.length > 0)) {
      return null;
    }
    const users = historyData.records.map(item => item.createdBy);
    return users;
  };


  const getFiles = () => {
    if (R.isNil(historyData) || R.isEmpty(historyData)
      || (historyData.records && !historyData.records.length > 0)) {
      return null;
    }
    const files = historyData.records.map(item => item.fileName);
    return files;
  };

  const fileNames = getFiles();
  const userNames = getUsers();

  useEffect(() => {
    setInputfile(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setInputUser(selectedUser);
  }, [selectedUser]);

  const filteredFileSuggestions = () => {
    if (!R.isNil(fileNames)) {
      return fileNames.filter(
        item => item.toLowerCase().includes(inputFile.toLowerCase()),
      );
    }
    return null;
  };

  const filteredUserSuggestions = () => {
    if (!R.isNil(userNames)) {
      return userNames.filter(
        item => item.toLowerCase().includes(inputUser.toLowerCase()),
      );
    }
    return null;
  };


  const handleFileInputChange = (event) => {
    setInputfile(event.target.value);
    handleSetFile(event.target.value);
    if (event.target.value) {
      setFileAnchorEl(event.currentTarget);
      handleApplyButton();
    } else {
      setFileAnchorEl(null);
    }
  };

  const handleUserInputChange = (event) => {
    setInputUser(event.target.value);
    hanldeUserSet(event.target.value);
    if (event.target.value) {
      setUserAnchorEl(event.currentTarget);
      handleApplyButton();
    } else {
      setUserAnchorEl(null);
    }
  };


  const handleFileSuggestionClick = (suggestion) => {
    setInputfile(suggestion);
    setFileAnchorEl(null);
  };

  const handleUserSuggestionClick = (suggestion) => {
    setInputUser(suggestion);
    setUserAnchorEl(null);
  };

  const handleClickAway = () => {
    setFileAnchorEl(null);
    setUserAnchorEl(null);
  };

  const handleKeyDownForFile = (event) => {
    const fileSuggestions = filteredFileSuggestions();
    if (event.key === 'Enter' && !R.isNil(fileSuggestions) && fileSuggestions.length > 0) {
      handleFileSuggestionClick(fileSuggestions[0]);
    }
  };

  const handleKeyDownForUser = (event) => {
    const userSuggestion = filteredUserSuggestions();
    if (event.key === 'Enter' && !R.isNil(userSuggestion) && userSuggestion.length > 0) {
      handleUserSuggestionClick(userSuggestion[0]);
    }
  };


  return (
    <>
      <div styleName="dropDown">
        <span styleName="typography">File Name</span>
        <TextField
          fullWidth
          InputProps={{
            classes: {
              input: classes.textField,
            },
          }}
          onChange={handleFileInputChange}
          onKeyDown={handleKeyDownForFile}
          placeholder="Type to search..."
          value={inputFile}
          variant="outlined"
        />
        <Popper
          anchorEl={fileAnchorEl}
          className={classes.popper}
          open={Boolean(fileAnchorEl)}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.paper}>
              {filteredFileSuggestions() && filteredFileSuggestions().map(suggestion => (
                <MenuItem
                  key={suggestion}
                  className={classes.menuItem}
                  onClick={() => handleFileSuggestionClick(suggestion)}
                >
                  {suggestion}
                </MenuItem>
              ))}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
      <div styleName="dropDown">
        <span styleName="typography">User Name</span>
        <TextField
          fullWidth
          InputProps={{
            classes: {
              input: classes.textField,
            },
          }}
          onChange={handleUserInputChange}
          onKeyDown={handleKeyDownForUser}
          placeholder="Type to search..."
          value={inputUser}
          variant="outlined"
        />
        <Popper
          anchorEl={userAnchorEl}
          className={classes.popper}
          open={Boolean(userAnchorEl)}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.paper}>
              {filteredFileSuggestions() && filteredUserSuggestions().map(suggestion => (
                <MenuItem
                  key={suggestion}
                  className={classes.menuItem}
                  onClick={() => handleUserSuggestionClick(suggestion)}
                >
                  {suggestion}
                </MenuItem>
              ))}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    </>
  );
};
FilterDropdown.propTypes = {
  // files: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleApplyButton: PropTypes.func.isRequired,
  handleSetFile: PropTypes.func.isRequired,
  hanldeUserSet: PropTypes.func.isRequired,
  historyData: PropTypes.shape().isRequired,
  selectedFile: PropTypes.string.isRequired,
  selectedUser: PropTypes.string.isRequired,
  // users: PropTypes.arrayOf(PropTypes.string).isRequired,
};


export default FilterDropdown;
