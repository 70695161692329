const SUBMIT_TO_FNMA = 'app/smdu/SUBMIT_TO_FNMA';
const FETCH_SMDU_HISTORY = 'app/smdu/FETCH_SMDU_HISTORY';
const SET_SMDU_RESPONSE_DATA = 'app/smdu/SET_SMDU_RESPONSE_DATA';
const FETCH_SMDU_HISTORY_DATA = 'app/smdu/FETCH_SMDU_HISTORY_DATA';
const SET_SMDU_HISTORY_DATA = 'app/smdu/SET_SMDU_HISTORY_DATA';
const SET_SMDU_HISTORY_DARTS_DATA = 'app/smdu/SET_SMDU_HISTORY_DARTS_DATA';
const FETCH_TRANSACTION_DATA = 'app/smdu/FETCH_TRANSACTION_DATA';
const SET_TRANSACTION_DATA = 'app/smdu/SET_TRANSACTION_DATA';
const LOADING_SMDU_WIDGET = 'app/smdu/LOADING_SMDU_WIDGET';
const SET_SMDU_CASE_MANAGEMENT = 'app/smdu/SET_SMDU_CASE_MANAGEMENT';
const HANDLE_SMDU_FAILURE = 'app/smdu/HANDLE_SMDU_FAILURE';
const CLOSE_POP_UP = 'app/smdu/CLOSE_POP_UP';
const FETCH_SMDU_PRIOR_WORKOUT_DROPDOWN = 'app/dashboard/FETCH_SMDU_PRIOR_WORKOUT_DROPDOWN';
const SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN = 'app/dashboard/SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN';
const SET_TO_REPORT_INVESTOR = 'app/smdu/SET_TO_REPORT_INVESTOR';
const STOP_LOADING_SMDU_WIDGET = 'app/smdu/STOP_LOADING_SMDU_WIDGET';
const SUBMIT_TO_REPORT_INVESTOR = 'app/smdu/SUBMIT_TO_REPORT_INVESTOR';
const SAVE_PRIOR_WORKOUT = 'app/smdu/SAVE_PRIOR_WORKOUT';
const HANDLE_SMDU_SUCCESS = 'app/smdu/HANDLE_SMDU_SUCCESS';
const SET_PRIOR_WORKOUT_HISTORY = 'app/smdu/SET_PRIOR_WORKOUT_HISTORY';
const HANDLE_ADD_PRIOR_WORKOUT_SUCCESS = 'app/smdu/HANDLE_ADD_PRIOR_WORKOUT_SUCCESS';
const FETCH_REPORTING_DATA = 'app/smdu/FETCH_REPORTIN_DATA';
const SET_SMDU_REPORTING_UPDATES = 'app/smdu/SET_SMDU_REPORTING_UPDATES';

export {
  SUBMIT_TO_FNMA,
  FETCH_SMDU_HISTORY,
  SET_SMDU_RESPONSE_DATA,
  FETCH_SMDU_HISTORY_DATA,
  SET_SMDU_HISTORY_DATA,
  SET_SMDU_HISTORY_DARTS_DATA,
  FETCH_TRANSACTION_DATA,
  SET_TRANSACTION_DATA,
  LOADING_SMDU_WIDGET,
  SET_SMDU_CASE_MANAGEMENT,
  HANDLE_SMDU_FAILURE,
  FETCH_SMDU_PRIOR_WORKOUT_DROPDOWN,
  SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN,
  SET_TO_REPORT_INVESTOR,
  STOP_LOADING_SMDU_WIDGET,
  SUBMIT_TO_REPORT_INVESTOR,
  SAVE_PRIOR_WORKOUT,
  CLOSE_POP_UP,
  HANDLE_SMDU_SUCCESS,
  SET_PRIOR_WORKOUT_HISTORY,
  HANDLE_ADD_PRIOR_WORKOUT_SUCCESS,
  FETCH_REPORTING_DATA,
  SET_SMDU_REPORTING_UPDATES,
};
