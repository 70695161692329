import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { selectors as evalSelectors, operations } from 'ducks/eval';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './CaseDetails.css';

const Hardship = (props) => {
  const { isCaseEditable, hardshipId, updatehardShipId } = props;

  const handleChange = (event) => {
    updatehardShipId(Number(event.target.value));
  };
  return (
    <div>
      <FormControl>
        <RadioGroup
          name="row-radio-buttons-group"
          onChange={handleChange}
          row
          styleName="hard-ship"
          value={hardshipId}
        >
          <FormControlLabel control={<Radio disabled={!isCaseEditable} />} label="Death" value={1} />
          <FormControlLabel control={<Radio disabled={!isCaseEditable} />} label="Divorce" value={2} />
          <FormControlLabel control={<Radio disabled={!isCaseEditable} />} label="Disability/Illness" value={3} />
          <FormControlLabel control={<Radio disabled={!isCaseEditable} />} label="None of the Above" value={4} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

Hardship.defaultProps = {
  hardshipId: 0,
  isCaseEditable: false,
  updatehardShipId: () => {},
};

Hardship.propTypes = {
  hardshipId: PropTypes.number,
  isCaseEditable: PropTypes.bool,
  updatehardShipId: PropTypes.func,
};

const mapStateToProps = state => ({
  hardshipId: evalSelectors.finalHardShipId(state),
});

const mapDispatchToProps = dispatch => ({
  updatehardShipId: operations.updateHardshipIdOperation(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(Hardship);
// export default Hardship;
