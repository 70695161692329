import {
  fetchTombstoneDataAction, updateLSAMSDetailsAction,
  fetchGridDetails, fetchActiveUserInfoAction, fetchSearchGridDetails,
  setIndexingPageVisibilityAction, setBorrowersIndexerAction,
  setIsUserActiveInfoAction,
} from './actions';

const fetchTombstoneData = dispatch => (payload) => {
  dispatch(fetchTombstoneDataAction(payload));
};

const updateLSAMSDetails = dispatch => (payload) => {
  dispatch(updateLSAMSDetailsAction(payload));
};

const fetchIndexerGridData = dispatch => (pageNumber, pageSize) => {
  dispatch(fetchGridDetails(pageNumber, pageSize));
};

const fetchIndexerSearchGridData = dispatch => (payload) => {
  dispatch(fetchSearchGridDetails(payload));
};

const fetchInReviewActiveUserInfo = dispatch => (loanId, evalId, assignedTo) => {
  dispatch(fetchActiveUserInfoAction(loanId, evalId, assignedTo));
};

const setIndexingPageVisibility = dispath => (value) => {
  dispath(setIndexingPageVisibilityAction(value));
};

const fetchBorrowersIndexer = dispatch => (value) => {
  dispatch(setBorrowersIndexerAction(value));
};

const fetchIsUserActiveInfoOperation = dispatch => (value) => {
  dispatch(setIsUserActiveInfoAction(value));
};

const operations = {
  setIndexingPageVisibility,
  fetchTombstoneData,
  updateLSAMSDetails,
  fetchIndexerGridData,
  fetchInReviewActiveUserInfo,
  fetchIndexerSearchGridData,
  fetchBorrowersIndexer,
  fetchIsUserActiveInfoOperation,
};

export default operations;
