import { makeStyles } from '@material-ui/core/styles';

const EvalDialogBoxStyles = makeStyles(() => ({
  root: {
    left: '30%',
    top: '40%',
  },
  DialogBox: {
    '& .MuiPaper-root': {
      boxSizing: 'border-box',
      width: '476px',
      minHeight: '187px',
      background: '#FFFFFF',
      border: '1px solid #4E586E',
      borderRadius: '10px',
    },
  },
  DialogTitle: {
    paddingTop: '23px',
    fontFamily: 'Lato',
    color: '#4E586E',
    '& .MuiTypography-h6': {
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '22px',
    },
  },
  CloseIcon: {
    width: '15px',
    height: '15px',
    float: 'right',
    paddding: '0px',
    marginRight: '17px',
    marginTop: '17px',
  },
  DialogContent: {
    overflow: 'hidden',
    '& .MuiPaper-root': {
      width: '435px',
      background: '#FFFFFF',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      border: 'none',
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#FFFFFF',
      fontWeight: '400',
      fontSize: '12px',
    },
    '& .MuiTableCell-head': {
      color: '#B8B8BA !important',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root': {
      fontSize: '12px',
      color: '#4E586E',
    },
  },
  Total: {
    '& .MuiTableCell-root': {
      fontSize: '12px',
      fontWeight: '700',
      border: 'none',
      color: '#4E586E',
    },
  },
}));

export default EvalDialogBoxStyles;
