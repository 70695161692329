import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import InputTextFieldStyles from './InputTextFieldStyles';

const InputTextField = ({
  id, label, onChange, override, value,
}) => {
  const [textValue, setValue] = useState(value);
  const classes = InputTextFieldStyles({ override });
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    onChange(label, inputValue);
  };
  return (
    <FormControl className={classes.root}>
      <TextField
        className={classes.TextFieldDiv}
        disabled={override}
        id={id}
        InputProps={{
          disableUnderline: true,
          style: { paddingLeft: '5px' },
        }}
        onChange={handleChange}
        value={textValue}
      />
    </FormControl>
  );
};

InputTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  override: PropTypes.bool,
  value: PropTypes.string,
};

InputTextField.defaultProps = {
  label: '',
  onChange: () => { },
  override: true,
  value: '',
};

export default InputTextField;
