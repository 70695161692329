import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './InputSearchFilter.css';

const InputSearchFilter = ({
  name, value, changeFilterHandler, list, getInputValue, onBlur, isDDvisible,
}) => (
  <>
    <div styleName="input-container">
      <input
        autoComplete="off"
        id={name}
        onBlur={onBlur}
        onChange={(e) => { changeFilterHandler(e, e.target.value); }}
        styleName="inputTextElem"
        value={value}
      />
      <div styleName="results-list">
        {isDDvisible === name && list && R.uniq(list).map(result => (
          <div
            key={result}
            aria-hidden="true"
            onMouseDown={() => getInputValue(result, name)}
            styleName="search-result"
          >
            {result}
          </div>
        ))}
      </div>
    </div>
  </>
);


export default InputSearchFilter;

InputSearchFilter.defaultProps = {
  changeFilterHandler: () => { },
  name: '',
  list: [],
  value: '',
  onBlur: () => {},
  getInputValue: () => { },
  isDDvisible: false,
};

InputSearchFilter.propTypes = {
  changeFilterHandler: PropTypes.func,
  getInputValue: PropTypes.func,
  isDDvisible: PropTypes.bool,
  list: PropTypes.arrayOf(),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
};
