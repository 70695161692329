import React from 'react';
import AccountServices from 'containers/AccountServices';
import {
  RFD, COLLATERAL, REASONABLE_EFFECT, HARDHSIP, ACCOUNT_SERVICE, NPV_RESULTS,
} from '../../constants/loanInfoComponents';
import RFDContent from './TombstoneComponents/RFDContent';
import CollateralContent from './TombstoneComponents/CollateralContent';
import ReasonableEffort from './TombstoneComponents/ReasonableEffort';
import HardshipAffidavit from './TombstoneComponents/HardshipAffidavit/HardshipAffidavit';
import NPVResults from './TombstoneComponents/NPVResult';

const tombstonePopupMap = {
  [RFD]: <RFDContent />,
  [COLLATERAL]: <CollateralContent />,
  [REASONABLE_EFFECT]: <ReasonableEffort />,
  [HARDHSIP]: <HardshipAffidavit />,
  [ACCOUNT_SERVICE]: <AccountServices isNavigatedFromTombstone />,
  [NPV_RESULTS]: <NPVResults />,
};

function getTombstonePopup(data) {
  return tombstonePopupMap[data];
}

export default getTombstonePopup;
