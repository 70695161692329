import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Box from '@material-ui/core/Box';

const GenericTabView = (props) => {
  const {
    tabsList,
  } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="pad-cont-10">
      <Box className="tabContainer" sx={{ bgcolor: 'background.paper' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              {
                tabsList && tabsList.map((item, index) => <Tab label={item.label} value={index} />)
              }
            </TabList>
          </Box>
          {
            tabsList
            && tabsList.map((item, index) => <TabPanel value={index}>{item.component}</TabPanel>)
          }
        </TabContext>
      </Box>
    </div>
  );
};

GenericTabView.propTypes = {
  tabsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GenericTabView;
