function getOr(key, obj, fallbackValue) {
  if (obj === null) return fallbackValue;
  const value = obj[key];
  return value || value === 0 ? value : fallbackValue;
}

function isNotNullAndNotEmptyorNotZero(value) {
  return value !== null && value !== '' && value !== '0';
}

const Validators = {
  getOr,
  isNotNullAndNotEmptyorNotZero,
};

export default Validators;
