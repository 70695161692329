import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react';
import Button from '@material-ui/core/Button';
import './RightPane.css';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import DateRangePicker from './DateRangePicker';
import FilterDropdown from '../DropDowns/FilterDropdown';


const RightPane = ({ history, setFilterData }) => {
  // const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEndDateSelected, enableApplyButton] = useState(false);
  const [selectedUser, setUser] = useState('All');
  const [selectedFile, setFileName] = useState('All');
  const [historyData, setHistoryData] = useState([]);


  useEffect(() => {
    if (!R.isNil(history) || !R.isEmpty(history)) {
      setHistoryData(history);
    }
  }, [history]);


  const handleApplyButton = () => {
    enableApplyButton(true);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleFileSet = (fileName) => {
    setFileName(fileName);
  };

  const handleUserSet = (userName) => {
    setUser(userName);
  };


  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setUser('All');
    setFileName('All');
  };


  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setUser('All');
    setFileName('All');
    setFilterData({
      startDate: null,
      endDate: null,
      selectedFile: null,
      selectedUser: null,
    });
  };
  const handleApply = () => {
    setFilterData({
      startDate,
      endDate,
      selectedFile,
      selectedUser,
    });
  };


  return (
    <div styleName="rightPane">
      <div styleName="container">
        <img alt="FilterImage" src="/static/img/filter.svg" style={{ marginRight: '5px' }} />
        <span style={{ flexGrow: 1 }} styleName="typography">FILTER</span>
        <Button
          className="material-ui-button"
          color="primary"
          id="RESET"
          onClick={handleReset}
          variant="text"
        >
          <span styleName="typography">RESET</span>
        </Button>
      </div>
      <div styleName="dateContainer">
        <span styleName="typography">Date Range</span>
        <DateRangePicker
          endDate1={endDate}
          handleApplyButton={handleApplyButton}
          handleEndDate={handleEndDate}
          handleStartDate={handleStartDate}
          startDate1={startDate}
        />
      </div>
      <div>
        <FilterDropdown
          handleApplyButton={handleApplyButton}
          handleSetFile={handleFileSet}
          hanldeUserSet={handleUserSet}
          historyData={historyData}
          selectedFile={selectedFile}
          selectedUser={selectedUser}
        />
      </div>

      <div styleName="block">
        <div styleName="endcontainer">
          <Button
            className="material-ui-button"
            color="primary"
            disabled={startDate === null}
            id="CLEAR"
            onClick={handleClear}
            variant="text"
          >
            <span styleName="typography">CLEAR</span>
          </Button>
          <Button
            className="material-ui-button"
            color="primary"
            disabled={!isEndDateSelected}
            id="APPLY"
            onClick={handleApply}
            variant="contained"
          >
            <span styleName="typography">APPLY</span>
          </Button>
        </div>
      </div>
    </div>
  );
};


RightPane.propTypes = {
  history: PropTypes.shape().isRequired,
  setFilterData: PropTypes.func.isRequired,
};

export default RightPane;
