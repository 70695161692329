import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, IconButton, MenuItem, Select, FormControl, Button, TextField,
  Table, TableHead, TableRow, TableContainer, TableCell, TableBody, Paper,
} from '@material-ui/core/index';
import { PropTypes } from 'prop-types';
import SweetAlertBox from 'components/SweetAlertBox';
import * as R from 'ramda';
import moment from 'moment-timezone';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import './AddPriorWorkout.css';
import {
  operations as smduOperations,
  selectors as smduSelectors,
} from 'ducks/smdu';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import { connect } from 'react-redux';
import utils from 'ducks/tasks-and-checklist/utils';
import WidgetLoader from 'components/Widgets/WidgetLoader';
import { FAIL_CANCEL_REASONS, WORKOUT_STATUS_TYPES, ACTIVITY_TYPES } from 'constants/widgets';


const defaultFormData = {
  workoutTypes: '',
  statusTypes: '',
  statusDate: '',
  failCancelReasons: '',
  paymentReduction: '',
  subsequentDelinquencySeverities: '',
  activityTypes: '',
};

function getCurrentDate() {
  const date = new Date();
  const dateTime = moment(date).format('YYYY-MM-DD');
  return dateTime;
}


const { getCSTDateTime } = utils;
const styles = {
  tableContainer: {
    borderRadius: '8px',
    border: '1 px solid #4E586E',
    boxShadow: 'none',
    backgroundColor: '#F9F9F9',
  },
  headerRow: {
    borderBottom: 'none',
  },
  headerCell: {
    fontWeight: 'bold',
    padding: '8px',
    fontSize: '0.875rem',
    color: '#333',
    borderBottom: 'none',
  },
  bodyRow: {
    borderBottom: 'none',
  },
  bodyCell: {
    padding: '8px',
    fontSize: '0.875rem',
    color: '#555',
    borderBottom: 'none',
  },
  headerRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    fontSize: '.75rem',
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
  },
};

const AddPriorWorkout = (props) => {
  const [priorData, setPriorData] = useState(defaultFormData);
  const {
    handleBackPriorWorkout, SMDUPriorWorkoutDropdownOptions, savePriorWorkout, getPopUpMessage,
    isPopUpEnabled, popUpType, loading, smduPriorWorkoutHistory, resolutionChoiceType,
  } = props;

  const [statusDate] = useState(getCurrentDate());

  const [priorWorkoutHistoryData, setPriorWorkoutHistoryData] = useState(smduPriorWorkoutHistory);
  const [canSavePriorWorkout, setCanSavePriorWorkout] = useState(false);


  useEffect(() => {
    setPriorWorkoutHistoryData(smduPriorWorkoutHistory);
  }, [smduPriorWorkoutHistory]);

  useEffect(() => {
    const allFieldsFilled = R.all(field => !R.isEmpty(field), R.values(priorData));
    setCanSavePriorWorkout(allFieldsFilled);
  }, [priorData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    priorData.statusDate = getCurrentDate();
    const updatedObject = { ...priorData, [name]: value };
    setPriorData(updatedObject);
  };

  const handleClose = () => {
    const { closePopUp } = props;
    closePopUp();
    setPriorWorkoutHistoryData(smduPriorWorkoutHistory);
  };

  let renderComponent = null;
  renderComponent = (
    <SweetAlertBox
      message={getPopUpMessage}
      onConfirm={() => handleClose()}
      show={isPopUpEnabled}
      type={popUpType}
    />
  );

  const handleSave = () => {
    savePriorWorkout(priorData);
  };

  const renderStatusOptions = (option) => {
    if (!R.isEmpty(SMDUPriorWorkoutDropdownOptions) && !R.has('status', SMDUPriorWorkoutDropdownOptions)) {
      let severityData = [];
      if (option === 'severity') {
        severityData = R.filter(val => !R.isEmpty(val), R.split('.', SMDUPriorWorkoutDropdownOptions.subsequentDelinquencySeverities[0].value)).map(n => Number(n));
        // eslint-disable-next-line max-len
        return R.unfold(n => n <= severityData[1] && [n, n + 1], severityData[0]).map(ele => <MenuItem key={ele} value={ele}>{ele}</MenuItem>);
      }

      const optionData = SMDUPriorWorkoutDropdownOptions[option];
      if (optionData && typeof optionData === 'object') {
        const optionArray = Object.entries(optionData);
        // eslint-disable-next-line max-len
        return optionArray.map(([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>);
      }
    }

    return null;
  };

  const lastUpdateStatusTypeFromResponse = priorWorkoutHistoryData
    && priorWorkoutHistoryData.length > 0
    ? priorWorkoutHistoryData[0].priorWorkoutStatusType : '';

  const statusTypeObject = Object.entries(WORKOUT_STATUS_TYPES)
    .map(item => item[1])
    .find(data => data.value === Number(lastUpdateStatusTypeFromResponse));

  const statusTypeLabel = statusTypeObject ? statusTypeObject.label : '';


  return (
    <>
      <div styleName="main-container">
        <section>
          <Grid container>
            <Grid item xs={12}>
              <IconButton aria-label="back" onClick={() => handleBackPriorWorkout(false)}>
                <KeyboardBackspaceIcon fontSize="inherit" />
              </IconButton>
              <Typography styleName="title" variant="h2">Prior Workout</Typography>
            </Grid>
            {loading ? <WidgetLoader /> : (
              <Grid item xs={12}>
                <div styleName="grey-bg form-container">
                  <FormControl styleName="select-container">
                    <p>Workout Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="workoutTypes"
                      onChange={handleChange}
                      value={priorData.workoutTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('workoutTypes')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Status Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="statusTypes"
                      onChange={handleChange}
                      value={priorData.statusTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('statusTypes')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Status Date</p>
                    <TextField
                      disabled
                      hiddenLabel
                      name="statusDate"
                      type="date"
                      value={getCSTDateTime(statusDate)}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Fail/Cancel Reason</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="failCancelReasons"
                      onChange={handleChange}
                      value={priorData.failCancelReasons}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('failCancelReasons')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Payment Reduction</p>
                    <TextField
                      hiddenLabel
                      name="paymentReduction"
                      onChange={handleChange}
                      value={priorData.paymentReduction}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Severity</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="subsequentDelinquencySeverities"
                      onChange={handleChange}
                      value={priorData.subsequentDelinquencySeverities}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('subsequentDelinquencySeverities')}
                    </Select>
                  </FormControl>

                  <FormControl styleName="select-container">
                    <p>Activity Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="activityTypes"
                      onChange={handleChange}
                      value={priorData.activityTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('activityTypes')}
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    disabled={!canSavePriorWorkout}
                    onClick={() => handleSave()}
                    variant="contained"
                  >
                    Add to Prior Workout
                  </Button>
                </div>

                <Grid item xs={12}>
                  <Typography styleName="title" variant="h2">Prior Workout History</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper} style={styles.tableContainer} styleName="grey-bg history-table">
                    <div style={styles.headerRowContainer}>
                      <div style={styles.headerItem}>
                        <Typography style={{ fontSize: '1rem ' }} variant="body1">{resolutionChoiceType}</Typography>
                      </div>
                      <div style={styles.headerItem}>
                        <Typography style={{ fontSize: '1rem' }} variant="body1">Status:</Typography>
                        <Typography style={{ fontSize: '1rem', paddingLeft: '.5rem' }} variant="body1">
                          {statusTypeLabel}
                        </Typography>
                      </div>
                    </div>
                    <Table aria-label="prior workout history table">
                      <TableHead>
                        <TableRow style={styles.headerRow}>
                          <TableCell style={styles.headerCell}>Fail / Cancel Reason</TableCell>
                          <TableCell style={styles.headerCell}>Status Date</TableCell>
                          <TableCell style={styles.headerCell}>Payment Reduction</TableCell>
                          <TableCell style={styles.headerCell}>Severity</TableCell>
                          <TableCell style={styles.headerCell}>Activity Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!R.isEmpty(priorWorkoutHistoryData)
                          && priorWorkoutHistoryData.map((data) => {
                            const failCancelReasonFromResponse = data.priorWorkoutFailCancelReason
                              ? data.priorWorkoutFailCancelReason : data.failCancelReasonType;
                            const reasonsArray = Object.entries(FAIL_CANCEL_REASONS)
                              .map(item => item[1]);
                            const failCancelReason = reasonsArray.filter(
                              item => item.id === Number(failCancelReasonFromResponse),
                            )[0];
                            const activityTypeObject = Object.entries(ACTIVITY_TYPES)
                              .map(item => item[1])
                              .find(record => record.value === data.priorWorkoutActivityType);

                            return (
                              <TableRow style={styles.bodyRow}>
                                <TableCell style={styles.bodyCell}>
                                  {failCancelReason ? failCancelReason.reason : ''}
                                </TableCell>
                                <TableCell style={styles.bodyCell}>
                                  {moment(data.priorWorkoutStatusDate).format('MM/DD/YYYY')}
                                </TableCell>
                                <TableCell style={styles.bodyCell}>
                                  {data.priorWorkoutPaymentReductionPercentage
                                    ? data.priorWorkoutPaymentReductionPercentage
                                    : data.paymentReductionPercent}
                                </TableCell>
                                <TableCell style={styles.bodyCell}>
                                  {data.priorWorkoutSubsequentDelinquencyServerity
                                    ? data.priorWorkoutSubsequentDelinquencyServerity
                                    : data.subsequentDelinquencySeverity}
                                </TableCell>
                                <TableCell style={styles.bodyCell}>
                                  {activityTypeObject ? activityTypeObject.label : ''}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </section>
      </div>
      {renderComponent}
    </>
  );
};

AddPriorWorkout.defaultProps = {
  resolutionChoiceType: '',
};

AddPriorWorkout.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  getPopUpMessage: PropTypes.string.isRequired,
  handleBackPriorWorkout: PropTypes.func.isRequired,
  isPopUpEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  popUpType: PropTypes.string.isRequired,
  resolutionChoiceType: PropTypes.string,
  savePriorWorkout: PropTypes.func.isRequired,
  SMDUPriorWorkoutDropdownOptions: PropTypes.shape({
    activityTypes: PropTypes.object.isRequired,
    failCancelReasons: PropTypes.object.isRequired,
    statusTypes: PropTypes.object.isRequired,
    subsequentDelinquencySeverities: PropTypes.object.isRequired,
    workoutTypes: PropTypes.object.isRequired,
  }).isRequired,
  smduPriorWorkoutHistory: PropTypes.shape().isRequired,
};

const mapStateTopProps = state => ({
  getPopUpMessage: smduSelectors.getPrioWorkoutPopupMessage(state),
  isPopUpEnabled: smduSelectors.isPopUpEnabled(state),
  loading: smduSelectors.isLoading(state),
  smduPriorWorkoutHistory: smduSelectors.getSmduPriorWorkoutHistory(state),
  resolutionChoiceType: tombstoneSelectors.getResolutionChoiceType(state),
  SMDUPriorWorkoutDropdownOptions: smduSelectors.getSMDUPriorWorkoutDropdownOptions(state),
});

const mapDispatchToProps = dispatch => ({
  savePriorWorkout: smduOperations.savePriorWorkout(dispatch),
  closePopUp: smduOperations.closePopUpOperation(dispatch),
});

const TestHooks = {
  AddPriorWorkout,
};
export default connect(mapStateTopProps, mapDispatchToProps)(AddPriorWorkout);
export { TestHooks };
