import {
  FETCH_TOMBSTONE_DATA, UPDATE_EVAL_LSAMSDETAILS,
  FETCH_GRID_DATA, FETCH_ACTIVE_USER, FETCH_SEARCH_GRID_DATA,
  SET_INDEXING_PAGE_VISIBILITY, SET_BORROWERS_DATA_INDEXER,
  SET_ACTIVE_USER_INFO,
} from './types';

const fetchTombstoneDataAction = payload => ({
  type: FETCH_TOMBSTONE_DATA,
  payload,
});

const updateLSAMSDetailsAction = payload => ({
  type: UPDATE_EVAL_LSAMSDETAILS,
  payload,
});

const fetchGridDetails = (pageNumber, pageSize) => ({
  type: FETCH_GRID_DATA,
  payload: { pageNumber, pageSize },
});

const fetchSearchGridDetails = payload => ({
  type: FETCH_SEARCH_GRID_DATA,
  payload,
});

const fetchActiveUserInfoAction = (loanId, evalId, assignedTo) => ({
  type: FETCH_ACTIVE_USER,
  payload: { loanId, evalId, assignedTo },
});

const setIndexingPageVisibilityAction = value => ({
  type: SET_INDEXING_PAGE_VISIBILITY,
  payload: value,
});

const setBorrowersIndexerAction = value => ({
  type: SET_BORROWERS_DATA_INDEXER,
  payload: value,
});

const setIsUserActiveInfoAction = value => ({
  type: SET_ACTIVE_USER_INFO,
  payload: value,
});

export {
  setIndexingPageVisibilityAction,
  fetchGridDetails,
  fetchTombstoneDataAction,
  updateLSAMSDetailsAction,
  fetchActiveUserInfoAction,
  fetchSearchGridDetails,
  setBorrowersIndexerAction,
  setIsUserActiveInfoAction,
};
