import * as R from 'ramda';
import { arrayToString } from 'lib/ArrayUtils';
import DashboardModel from '../Dashboard';

const ONE_OF = 'oneOf';
const NONE = 'none';

const generateErrorMessagesFromDiscrepancy = R.compose(
  R.map(([fieldName, discrepancy]) => {
    const expected = R.is(Array, discrepancy.expected)
      ? discrepancy.expected : [discrepancy.expected];
    if (discrepancy.validation === NONE) {
      return discrepancy.error;
    }
    if (discrepancy.validation === ONE_OF) {
      return `${arrayToString([fieldName])} should be ${arrayToString(expected)}`;
    }
    return `${arrayToString([fieldName])} should not be ${arrayToString(expected)}`;
  }),
  R.toPairs,
);

function getErrorMessages(discrepancies) {
  if (discrepancies) {
    return generateErrorMessagesFromDiscrepancy(discrepancies);
  }
  return [];
}

function getStagerErrorMessages(item) {
  if (item.source === 'VALIDATION') {
    const errorMessages = getErrorMessages(item.message.discrepancies);
    return R.is(Array, errorMessages)
      ? errorMessages.reduce(DashboardModel.Messages.reduceMessageListToMessage, [])
      : errorMessages;
  }
  return item.message;
}

function getBulkErrorMessages(data) {
  const errorResponse = R.map((item) => {
    const result = { ...item };
    const error = getStagerErrorMessages(result);
    result.message = error;
    return result;
  }, data);

  return errorResponse;
}

const getErrorMsgFromRulesEngine = (rulesData) => {
  const errors = rulesData;
  let errorMessages = '';
  if (Array.isArray(errors) && errors.length > 0) {
    if (errors.length === 1) {
      errorMessages = errors[0].detailedMessage ? errors[0].detailedMessage : errors[0].message;
    } else {
      errorMessages = errors.map(err => `${err.detailedMessage ? err.detailedMessage : err.message}`);
    }
  } else {
    errorMessages = errors;
  }
  return errorMessages;
};

const isTaskSendForApprv = (group, task) => {
  const isFound = DashboardModel.SENDFORAPRV_VALIDATION.some(val => (
    val.task === task && val.groups.includes(group)
  ));
  if (isFound) {
    return true;
  }
  return false;
};

const Disposition = {
  getErrorMessages,
  getBulkErrorMessages,
  getErrorMsgFromRulesEngine,
  isTaskSendForApprv,
};

export default Disposition;
