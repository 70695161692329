
export const BULK_CASE_STATUS_OPTIONS = {
  Locked: ['Open', 'Send For Approval', 'Closed'],
  'Send For Approval': ['Open', 'Approved', 'Closed', 'Locked'],
  Approved: ['Open', 'Closed', 'Send For Approval', 'Locked'],
  Closed: ['Open', 'Send For Approval'],
  Open: ['Locked', 'Closed'],
  Booked: ['Send For Approval'],
  'Send for Booked': ['Send For Approval'],
};

export const DEFAULT_TYPE = 'ID';
export const CASE_ID_TYPE = 'Case ID';
export const EVAL_ID_TYPE = 'Eval ID';
export const CASE_SUB_STATUS = 'Case Substatus';
export const EVAL_SUB_STATUS = 'Eval Substatus';
export const EVAL_STATUS = 'Eval Status';
export const CASE_STATUS = 'Case Status';
