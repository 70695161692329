import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  operations as smduOperations,
  selectors as smduSelectors,
} from 'ducks/smdu';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import SweetAlertBox from 'components/SweetAlertBox';
import {
  Button,
} from '@material-ui/core/index';

import ReportedData from './ReportedData';
import styles from './CaseManagement.css';

class CaseManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      value: '1',
      isOpen: true,
    };
  }

  toggleAccordion = () => {
    const { caseManagements } = this.props;
    const errorsData = caseManagements.errorsData || [];

    if (errorsData.length > 0) {
      this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      this.toggleAccordion();
    }
  };

    submitToReportInvestor = () => {
      const { submitToReportInvestors } = this.props;
      submitToReportInvestors();
    };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleClose=() => {
    const { updateReportInvestor } = this.props;
    updateReportInvestor('');
    this.setState(prev => ({ ...prev, isOpen: false }));
  }


  ArrowIcon = () => {
    const { canReportToInvestor } = this.props;
    const arrowFill = canReportToInvestor === false ? '#4E586E' : '#596FEB';
    return (
      <svg fill="none" height="11" viewBox="0 0 13 11" width="13" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.625 10.4582L7.63333 9.4488L10.1656 6.9165H3.3125C2.42708 6.9165 1.67448 6.60661 1.05469 5.98682C0.434896 5.36702 0.125 4.61442 0.125 3.729C0.125 2.84359 0.434896 2.09098 1.05469 1.47119C1.67448 0.8514 2.42708 0.541504 3.3125 0.541504H3.66667V1.95817H3.3125C2.81667 1.95817 2.39757 2.12935 2.05521 2.47171C1.71285 2.81407 1.54167 3.23317 1.54167 3.729C1.54167 4.22484 1.71285 4.64393 2.05521 4.9863C2.39757 5.32866 2.81667 5.49984 3.3125 5.49984H10.1656L7.63333 2.94984L8.625 1.95817L12.875 6.20817L8.625 10.4582Z" fill={arrowFill} />
      </svg>
    );
  };

  render() {
    const {
      caseManagements = { caseManagements: [] }, reportToInvestor,
      canReportToInvestor, groupName,
    } = this.props;
    const { isExpanded, value, isOpen } = this.state;
    const errorsData = typeof caseManagements.errorsData === 'object' ? caseManagements.errorsData : [];
    let renderComponent = null;
    const reportToInvestorStyle = canReportToInvestor === false ? 'report_to_inv_button_disabled'
      : 'report_to_inv_button';
    const background = errorsData.length > 0 ? 'white' : 'rgb(243, 245, 249)';

    if (reportToInvestor !== '' && reportToInvestor) {
      const { message, level } = reportToInvestor;
      renderComponent = (
        <SweetAlertBox
          message={message}
          onConfirm={this.handleClose}
          show={isOpen}
          type={level}
        />
      );
    }

    return (
      <>
        {renderComponent}
        <TabContext style={{ padding: '2px', background: '#F3F5F9' }} value={value}>
          <TabList
            aria-label="simple tabs example"
            onChange={this.handleChange}
            style={{
              padding: '2px',
              background: '#F3F5F9',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#6A81F0',
                marginLeft: '67px',
                width: '18px',
                borderRadius: '3px',
              },
            }}
          >
            <Tab label="Reported Data" style={{ textTransform: 'none', fontSize: '14px' }} value="1" />
            <Tab label="Errors" style={{ textTransform: 'none', fontSize: '14px' }} value="2" />
            {groupName === 'LA' ? (
              <Button
                disabled={!canReportToInvestor}
                onClick={this.submitToReportInvestor}
                styleName={reportToInvestorStyle}
                variant="outlined"
              >
                <this.ArrowIcon />
            &nbsp;&nbsp;
                <strong>REPORT TO INVESTOR</strong>
              </Button>
            ) : (
              <> </>
            )}
          </TabList>
          <hr
            style={{
              borderColor: 'rgba(204, 207, 214, 0.33)',
              marginTop: '-.2rem',
            }}
          />
          <TabPanel value="1">
            <ReportedData />
          </TabPanel>
          <TabPanel value="2">
            <div
              aria-expanded={isExpanded}
              className={errorsData.length > 0 ? styles.accordion_with_data : styles.accordian}
              onClick={this.toggleAccordion}
              onKeyDown={this.handleKeyDown}
              role="button"
              tabIndex={0}
            >
              <div className={styles.accordion_content}>
                <div className={styles.accordion_title}>
                Errors
                </div>
                <div>
                  <img
                    alt="toggle"
                    height="7"
                    src={isExpanded ? '/static/img/upArrow.svg' : '/static/img/downArrow.svg'}
                    style={{ width: '14px', marginLeft: '5px' }}
                  />
                </div>
              </div>
            </div>
            {isExpanded && errorsData.length > 0 && (
            <>
              <TableContainer component={Paper} style={{ padding: '10px', background: { background } }}>
                <Table>
                  <TableBody>
                    {errorsData.map(messages => (
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          <strong>HSSN Reporting Error   </strong>
                            :
                          <strong> System</strong>
                        </TableCell>
                        <TableCell>{messages}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {' '}

            </>
            )}
          </TabPanel>
        </TabContext>
      </>
    );
  }
}

CaseManagement.propTypes = {
  canReportToInvestor: PropTypes.bool.isRequired,
  caseManagements: PropTypes.shape({
    date: PropTypes.string.isRequired,

    errorsData: PropTypes.arrayOf(
      PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
        Result: PropTypes.string.isRequired,
      }).isRequired,
    ),
    reportsData: PropTypes.arrayOf(
      PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
        Result: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
  groupName: PropTypes.string.isRequired,
  reportToInvestor: PropTypes.string.isRequired,
  submitToReportInvestors: PropTypes.func.isRequired,
  updateReportInvestor: PropTypes.func.isRequired,
};

CaseManagement.defaultProps = {
  caseManagements: {},
};

const mapStateToProps = state => ({
  reportToInvestor: smduSelectors.getReportToInvestor(state),
  canReportToInvestor: smduSelectors.canReportToInvestor(state),
});
const mapDispatchToProps = dispatch => ({
  submitToReportInvestors: smduOperations.submitToReportInvestorOperation(dispatch),
  updateReportInvestor: smduOperations.updateReportInvestorOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
