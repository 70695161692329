import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { operations } from '../../state/ducks/smdu';
import ServicerHistory from './ServicerHistory';
import styles from './ReportedData.css';


const ReportedData = (props) => {
  const {
    caseManagements, fetchReportingData,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    fetchReportingData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchReportingData();
    }, 60000);
    return () => clearInterval(interval);
  }, [fetchReportingData]);


  const toggleAccordion = () => {
    const reportsData = caseManagements.reportsData || [];

    if (reportsData.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
    }
  };

  const reportsData = !R.isNil(caseManagements) && typeof caseManagements.reportsData === 'object' ? caseManagements.reportsData : [];
  const [isVisible] = reportsData.length === 0 ? useState(false) : useState(true);
  const fullDate = !R.isNil(caseManagements) && caseManagements.date !== null ? caseManagements.date : '';
  const name = !R.isNil(caseManagements) && caseManagements.name ? caseManagements.name : 'HSSN Initiate Request';
  // const date = fullDate.replace('[HSSN Initiate Request]', '');
  const background = !reportsData.length > 0 ? 'rgb(243, 245, 249)' : 'white';
  return (
    < >
      <div style={{ backgroundColor: { background }, paddingBottom: '1rem', borderRadius: '.5rem' }}>
        <div
          aria-expanded={isExpanded}
          className={styles.accordion}
          onClick={toggleAccordion}
          onKeyDown={handleKeyDown}
          role="button"
          style={{ height: '1rem' }}
          tabIndex={0}
        >
          <div className={styles.accordion_content}>
            <div className={styles.accordion_title}>
              {name}
            </div>

            <div className={styles.info_section}>
              <div className={styles.calendar_info}>
                { isVisible && <img alt="Calendar" className={styles.icon} src="/static/img/calendar.png" />}
                <span>
                  &nbsp;
                  {fullDate}
                </span>

              </div>
            </div>
            <div className={styles.accordion_arrow}>
              <img
                alt="toggle"
                height="7"
                src={isExpanded ? '/static/img/upArrow.svg' : '/static/img/downArrow.svg'}
                style={{ width: '14px', marginLeft: '5px' }}
              />
            </div>
          </div>
        </div>
        <hr style={{
          borderColor: 'rgba(204, 207, 214, 0.33)', width: '95%', marginLeft: '10px', marginTop: '2rem',
        }}
        />
        {isVisible && isExpanded && reportsData.length > 0 && (
          <ServicerHistory reportsData={reportsData} />
        )}
      </div>
    </>
  );
};

ReportedData.propTypes = {
  caseManagements: PropTypes.shape().isRequired,
  fetchReportingData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { smduCaseManagementData: caseManagements } = state.smdu;
  return { caseManagements: caseManagements || {} };
};

const mapDispatchToProps = dispatch => ({
  fetchReportingData: operations.fetchReportingData(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ReportedData);
