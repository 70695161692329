import React, { useState, useEffect } from 'react';
import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import * as R from 'ramda';
import SubmittedRecords from 'components/SubmittedRecords';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectors as loginSelectors,
} from '../../../../state/ducks/login';
import SweetAlertBox from '../../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../../state/ducks/dashboard';

import {
  selectors as admintoolSelectors, operations as admintoolOpertaions,
} from '../../../../state/ducks/admin-tools';
import InputField from '../../../../components/InputField/InputField';
import './SubStatusRequestCase.css';
import Popup from '../../../../components/Popup';
import { CASE_SUB_STATUS, CASE_ID_TYPE } from '../../constants';
import { SUB_STATUS_REQUEST } from '../../../../constants/SupportAdmin';

const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

const SubStatusRequestCase = ({
  user, setSubStatusRequestOptions, subStatusRequestOptions,
  setSubmitStatusRequestOptions, recordsInfo,
  resultOperation, closeSweetAlert,
}) => {
  const initialCaseObj = {
    ticket: 0,
    toStatus: '',
    requestorID: '',
    comments: '',
    case: '0',
    type: CASE_ID_TYPE,
  };
  const [caseObj, setCaseObj] = useState(initialCaseObj);
  const [disableButton, setDisableButton] = useState(true);
  const [disableChangeStatus, setDisableChangeStatus] = useState(true);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  let idValue = 0;

  const [ispopupVisible, setIspopupVisible] = useState(false);
  useEffect(() => {
    setSubStatusRequestOptions(CASE_SUB_STATUS);
  }, []);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);

  useEffect(() => {
    if (isSubmitted && recordsInfo.successCount !== 0 && recordsInfo.failureCount !== 0) {
      setCaseObj(initialCaseObj);
      setIsSubmitted(false);
      setDisableButton(true);
      setDisableChangeStatus(true);
    }
  }, [recordsInfo]);

  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };

  const handleOnBlur = () => {
    const {
      ticket, case: caseId, requestorID, comments, toStatus,
    } = caseObj;
    setDisableChangeStatus(!(checkNotNullAndEmpty(ticket) && checkNotNullAndEmpty(caseId)
      && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)
      && checkNotNullAndEmpty(toStatus)));
    setDisableButton(!(checkNotNullAndEmpty(ticket) || checkNotNullAndEmpty(caseId)
      || checkNotNullAndEmpty(requestorID) || checkNotNullAndEmpty(comments)
      || checkNotNullAndEmpty(toStatus)));
  };

  const renderStatusOptions = () => subStatusRequestOptions.length > 0
    && subStatusRequestOptions.map((option) => {
      const value = option.replace('/', '').split(' ').join('-').toLowerCase();
      return <MenuItem value={value}>{option}</MenuItem>;
    });

  const clearEdits = () => {
    setCaseObj(initialCaseObj);
    setDisableButton(true);
    setDisableChangeStatus(true);
  };
  const handleSubmit = () => {
    idValue = caseObj.case;
    const requestData = {
      ...caseObj,
      busKeyType: 'case',
      action: SUB_STATUS_REQUEST,
      createdBy: user.userDetails.name,
      id: idValue,
    };
    setSubmitStatusRequestOptions(requestData);
    setIspopupVisible(false);
    setIsSubmitted(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
  };

  return (
    <div styleName="formContainer">
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={caseObj.ticket}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Case ID"
        name="case"
        type="number"
        value={caseObj.case}
      />
      <div styleName="flexContainer selectRoot">
        <p>To Substatus</p>
        <Select
          displayEmpty
          handleBlur={handleOnBlur}
          label="Select"
          name="toStatus"
          onChange={handleChange}
          value={caseObj.toStatus}
        >
          <MenuItem value="">Select</MenuItem>
          {renderStatusOptions()}
        </Select>
      </div>

      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={caseObj.requestorID}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={caseObj.comments}
      />
      <span styleName="dividerStyle" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flexContainer"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableChangeStatus} onClick={() => setIspopupVisible(true)} variant="contained">
          CHANGE SUBSTATUS
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type={initialCaseObj.type}
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
      {resultOperation && resultOperation.status && (
        <SweetAlertBox
          message={renderContent}
          onConfirm={() => handleDialogClose()}
          show={isOpen}
          type={msgType}
        />
      )}
    </div>
  );
};
SubStatusRequestCase.defaultProps = {
  setSubStatusRequestOptions: () => { },
  subStatusRequestOptions: {},
  setSubmitStatusRequestOptions: () => { },
  closeSweetAlert: () => {},

};
SubStatusRequestCase.propTypes = {
  closeSweetAlert: PropTypes.func,
  recordsInfo: PropTypes.shape().isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  setSubmitStatusRequestOptions: PropTypes.func,
  setSubStatusRequestOptions: PropTypes.func,
  submitStatusResponse: PropTypes.shape({
    failureCount: PropTypes.number,
    message: PropTypes.string,
    success: PropTypes.bool,
    successCount: PropTypes.number,
    unsuccessfulRecords: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
    ),
  }).isRequired,
  subStatusRequestOptions: PropTypes.shape(),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  subStatusRequestOptions: admintoolSelectors.getCaseSubstatusOptions(state),
  recordsInfo: admintoolSelectors.getRecordsInfo(state),
  resultOperation: dashboardSelectors.resultOperation(state),

});
const mapDispatchToProps = dispatch => ({
  setSubStatusRequestOptions: admintoolOpertaions.fetchSubStatusRequestOptionOperation(dispatch),
  setSubmitStatusRequestOptions: admintoolOpertaions.submitStatusOperation(dispatch),
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(SubStatusRequestCase);
