import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    width: '800px',
    height: '60px',
  },
};

const CustomTextField = ({ classes, ...props }) => (
  <TextField
    className={classes.root}
    multiline
    rows={2}
    {...props}
  />
);

CustomTextField.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(CustomTextField);
