import React, { useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@material-ui/core';

const Paging = ({
  selectedPage,
  totalPages,
  onPageChange,
  styleProps,
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#2196f3',
      },
    },
  });
  const [currentPage, setCurrentPage] = React.useState(selectedPage || 1);
  useEffect(() => {
    if (selectedPage !== currentPage) setCurrentPage(selectedPage);
  }, [selectedPage]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    onPageChange(value);
  };
  return (
    <ThemeProvider theme={theme}>
      <Pagination
        color="primary"
        count={totalPages}
        id="paginate"
        onChange={handlePageChange}
        page={currentPage}
        showFirstButton
        showLastButton
        size={styleProps.size || 'small'}
      />
    </ThemeProvider>
  );
};

Paging.propTypes = {
  onPageChange: PropTypes.func,
  selectedPage: PropTypes.number,
  styleProps: PropTypes.shape({
    size: PropTypes.string,
  }),
  totalPages: PropTypes.number,
};

Paging.defaultProps = {
  onPageChange: () => { },
  selectedPage: 1,
  styleProps: {},
  totalPages: 1,
};

export default Paging;
