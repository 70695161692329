import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'ducks/eval';
import * as R from 'ramda';
import DisplayCard from './DisplayCard';

const PostModInformation = ({
  bookingTabData,
}) => {
  const upbInfo = [
    {
      id: 'InterestBearingUPB',
      label: 'Interest Bearing UPB',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'interestBearingUPB'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'ForebearanceAmount',
      label: 'Forebearance Amount',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'forbearanceAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'ForbearancePercentage',
      label: 'Forbearance Percentage',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'forbearancePercentage'], bookingTabData),
      type: 'rate',
    },
    {
      id: 'ForgivenessAmount',
      label: 'Forgiveness Amount',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'forgivenessAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'PartialClaimAmount',
      label: 'Partial Claim Amount',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'partialClaimAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'DownPaymentAmount',
      label: 'Down Payment Amount',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'downPaymentAmount'], bookingTabData),
      type: 'currency',
    },
    {
      id: 'GrossUPB',
      label: 'Gross UPB',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'grossUPB'], bookingTabData),
      type: 'currency',
    },
  ];

  const terms = [
    {
      id: 'MaturityDate',
      label: 'Maturity Date',
      override: 'N',
      value: R.pathOr('-', ['postmodInformation', 'maturityDate'], bookingTabData),
      type: 'date',
    },
    {
      id: 'AmortizationDuration',
      label: 'Amortization Duration',
      override: 'N',
      value: R.pathOr('', ['postmodInformation', 'amortizationDuration'], bookingTabData),
      type: 'number',
    },
    {
      id: 'ForecastedLPIDate',
      label: 'Forecasted LPI Date',
      override: 'N',
      value: R.pathOr('-', ['postmodInformation', 'lpiDate'], bookingTabData),
      type: 'date',
    },
  ];
  return (
    <>
      {upbInfo && <DisplayCard data={upbInfo} highlightCells={['InterestBearingUPB', 'ForebearanceAmount', 'GrossUPB']} highlightCellsWithBorder={['GrossUPB']} title="UPB Information" />}
      {terms && <DisplayCard data={terms} hideDivider title="Terms" />}
    </>
  );
};

PostModInformation.propTypes = {
  bookingTabData: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  bookingTabData: selectors.bookingTabData(state),
});

export default connect(mapStateToProps)(PostModInformation);
