import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popup: {
    position: 'absolute',
    width: '400px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    padding: '32px',
    textAlign: 'center',
    outline: 'none',
  },
  icon: {
    color: '#4caf50',
    fontSize: '100px',
    marginBottom: '16px',
  },
  button: {
    marginTop: '24px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
}));

const DonePopup = ({ message, show, onConfirm }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-describedby="popup-description"
      aria-labelledby="popup-title"
      className={classes.modal}
      onClose={onConfirm}
      open={show}
    >
      <div className={classes.popup}>
        <CheckCircleOutlineIcon className={classes.icon} />
        <h2 id="popup-title">{message}</h2>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={onConfirm} variant="contained">
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DonePopup.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DonePopup;
