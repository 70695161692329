import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { PropTypes } from 'prop-types';
import '../BoardingTemplatePage.css';
import * as R from 'ramda';

const InFlightToggleSwitch = ({
  inFlightStatus, setInFlightStatus, setIsSubmitDisabled, preApprovedStatus,
  loanIds, selectedCaseType, selectedPhase,
}) => {
  const handleToggle = () => {
    setInFlightStatus(!inFlightStatus);
  };

  useEffect(() => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    if (loanIds === '' || !re.test(loanIds)) {
      setIsSubmitDisabled(!(loanIds.trim()
        && !R.isEmpty(selectedPhase) && !R.isEmpty(selectedCaseType)
        && (inFlightStatus || preApprovedStatus)
      ));
    }
  }, [inFlightStatus]);

  return (
    <>
      <div styleName="loan-numbers">
        <span styleName="typography">
          {'InFlight'}
        </span>
        <span styleName="errorIcon">
          <Tooltip
            placement="right-end"
            title={(
              <Typography>
                Select Yes if you want to hold the doc gen request file
                from being sent automatically to the vendor for up to 5 days.
              </Typography>
            )}
          >
            <ErrorIcon styleName="errorSvg" />
          </Tooltip>
        </span>
      </div>
      <Grid alignItems="center" component="label" container>
        <Grid item>
          <Switch
            checked={inFlightStatus}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name="toggle"
            onChange={handleToggle}
          />
        </Grid>
      </Grid>
    </>
  );
};

InFlightToggleSwitch.propTypes = {
  inFlightStatus: PropTypes.bool.isRequired,
  loanIds: PropTypes.string.isRequired,
  preApprovedStatus: PropTypes.bool.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setInFlightStatus: PropTypes.func.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,

};

export default InFlightToggleSwitch;
