import * as R from 'ramda';

const getIndexerGridData = state => R.pathOr({}, ['indexer', 'indexerGridData'], state);
const getIndexerSearchGridData = state => R.pathOr([], ['indexer', 'indexerSearchGridData'], state);
const isUserActive = state => R.pathOr(false, ['indexer', 'isUserActive'], state);
const isIndexingPageVisible = state => R.pathOr(false, ['indexer', 'isIndexingPageVisible'], state);


const selectors = {
  isIndexingPageVisible,
  getIndexerGridData,
  isUserActive,
  getIndexerSearchGridData,
};

export default selectors;
