import {
  RESET_DATA,
  SET_INDEXER_GRID_DATA,
  SET_ACTIVE_USER_INFO,
  SET_INDEXER_SEARCH_GRID_DATA,
  SET_INDEXING_PAGE_VISIBILITY,
} from './types';


const defaultState = {
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_DATA:
      return defaultState;
    case SET_INDEXER_GRID_DATA: {
      const indexerGridData = action.payload;
      return {
        ...state,
        indexerGridData,
      };
    }
    case SET_ACTIVE_USER_INFO: {
      const isUserActive = action.payload;
      return {
        ...state,
        isUserActive,
      };
    }
    case SET_INDEXER_SEARCH_GRID_DATA: {
      const indexerSearchGridData = action.payload;
      return {
        ...state,
        indexerSearchGridData,
      };
    }
    case SET_INDEXING_PAGE_VISIBILITY: {
      const isIndexingPageVisible = action.payload;
      return {
        ...state,
        isIndexingPageVisible,
      };
    }
    default:
      return state;
  }
};

export default reducer;
