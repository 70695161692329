import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import './PopupContainer.css';

const PopupContainer = ({
  show, title, handleClose, children, showCopyButton, copyTable, sizeSmall,
}) => (
  <Modal
    open={show}
  >
    <div
      style={{
        top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: sizeSmall ? '400px' : '700px',
      }}
      styleName={showCopyButton ? 'copy-container popup' : 'popup'}
    >
      <Grid alignItems="center" container direction="row" justify="space-between" styleName="popup-title">
        <Grid item>
          <h2 id="simple-modal-title">
            {title}
          </h2>
        </Grid>
        <Grid item>
          {showCopyButton && (
            <IconButton aria-label="close" onClick={() => copyTable()}>
              <FileCopyIcon />
            </IconButton>
          )}
          <IconButton aria-label="close" onClick={() => handleClose(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid alignItems="center" container direction="row" justify="space-between">
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  </Modal>
);

PopupContainer.defaultProps = {
  show: false,
  title: '',
  sizeSmall: false,
  showCopyButton: false,
  copyTable: () => { },
};

PopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  copyTable: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  showCopyButton: PropTypes.bool,
  sizeSmall: PropTypes.bool,
  title: PropTypes.string,
};

export default PopupContainer;
